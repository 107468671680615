import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgMatSearchBarModule } from 'ng-mat-search-bar';
import { ListContainerComponent } from './category-list/category-list.component';
import { OptionsConfigurationComponent } from './configuration/configuration.component';
import { OptionDetailsComponent } from './option/option-details.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OptionListDetailsComponent } from './option-list/option-list-details.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { OptionDialogComponent } from './options-dialog/options-dialog';


@NgModule({
    declarations: [
        ListContainerComponent,
        OptionListDetailsComponent,
        OptionsConfigurationComponent,
        OptionDetailsComponent,
        OptionDialogComponent
    ],
    entryComponents: [
        OptionDialogComponent
    ],
    imports: [
        CommonModule,
        MatSidenavModule,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatListModule,
        MatStepperModule,
        MatFormFieldModule,
        FormsModule,
        MatInputModule,
        MatProgressBarModule,
        CdkStepperModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatMenuModule,
        NgMatSearchBarModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatSortModule,
        MatPaginatorModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatRadioModule,
        MatSnackBarModule,
        MatRadioModule,
        DragDropModule
    ], exports: [
        OptionsConfigurationComponent,

    ],
    providers: [
    ]
})
export class OptionListModule {
}
