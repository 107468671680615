import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { CreateDatasetDto } from '../../../models/dtos/create-dataset-dto';
import { DatasetService } from '../../../services/api/dataset.service';
import { IgniteTabService } from '../../../services/ignite-tab.service';

// const ICON_LIST: Icon[] = [
//     { label: 'People', icon: 'groups' },
//     { label: 'Assignment', icon: 'assignment' },
//     { label: 'Drive File', icon: 'insert_drive_file' },
//     { label: 'Assignment User', icon: 'assignment_ind' },
//     { label: 'Description', icon: 'description' },
//     { label: 'Receipt', icon: 'receipt' },
//     { label: 'Shopping Cart', icon: 'shopping_cart' },
//     { label: 'Store', icon: 'store' },
//     { label: 'Timeline', icon: 'timeline' },
//     { label: 'Work', icon: 'work_outline' },
//     { label: 'Contact Mail', icon: 'contact_mail' },
//     { label: 'Contact Phone', icon: 'contact_phone' },
//     { label: 'Contacts', icon: 'contacts' },
//     { label: 'List', icon: 'list_alt' },
//     { label: 'Library Books', icon: 'library_books' },
//     { label: 'Archive', icon: 'archive' },
//     { label: 'Ballot', icon: 'ballot' },
//     { label: 'File Copy', icon: 'file_copy' },
//     { label: 'Attach File', icon: 'attach_file' },
//     { label: 'Chart', icon: 'insert_chart_outlined' },
//     { label: 'Invitation', icon: 'insert_invitation' },
//     { label: 'Folder', icon: 'folder_open' },
//     { label: 'Folder Shared', icon: 'folder_shared' },
//     { label: 'Group', icon: 'group' },
//     { label: 'Person', icon: 'person_outline' },
//     { label: 'Money', icon: 'attach_money' },
// ];


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'new-dataset',
    templateUrl: './new-dataset.component.html',
    styleUrls: ['./new-dataset.component.scss'],
})
export class NewDatasetComponent {

    isCreating: boolean;
    datasetName: FormControl = new FormControl(null, [Validators.required]);

    constructor(
        public dialogRef: MatDialogRef<NewDatasetComponent>,
        private _datasetService: DatasetService,
        private _snackBar: MatSnackBar,
        private _igniteTabService: IgniteTabService,
        private _router: Router
    ) { }


    public closeDialog() {
        this.dialogRef.close({ id: null });
    }

    public async createDataset() {
        if (this.datasetName.hasError('required')) return;
        this.isCreating = true;
        let datasetId = this._igniteTabService.getGuid()
        const files = await this._igniteTabService.initDefaultTab(this.datasetName.value, datasetId);
        const datasetInput: CreateDatasetDto = {
            Id: datasetId,
            Title: this.datasetName.value,
            Files: files
        }
        var response = await this._datasetService.create(datasetInput).toPromise();
        if (response.isSuccess) {
            this._showMsg(`Dataset created successfully`, 'success-snackbar');
            this.dialogRef.close();
            this._router.navigate(['datasets', response?.result, 'schema']);
        } else {
            if(response.result.includes("Dataset with same name already exists")) {
                this._showMsg(`Dataset with same name already exists.`, 'error-snackbar');
            } else {
                this._showMsg(`Error while creating dataset. Error: ${response.result}`, 'error-snackbar')
            }
            this.isCreating = false;
        }
        this.isCreating = false;
    }

    private _showMsg(msg: string, panelClass: string) {
        this._snackBar.open(msg, 'close', {
            duration: 2000,
            politeness: 'polite',
            panelClass: panelClass
        });
    }
}
