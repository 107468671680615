import { Injectable } from '@angular/core';
import { DatasetService } from '@app/ignite/components/reports/services/api/dataset.service';
import { ReplaySubject } from 'rxjs';
import { ISidebarItem } from '../models';

@Injectable({ providedIn: 'root' })
export class VerticalSidebarService {

    private _updateStatus$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    oUpdateStatus = this._updateStatus$.asObservable();

    constructor(
        private _datasetService: DatasetService) {
    }

    setUpdateStatus(value: boolean) {
        this._updateStatus$.next(value);
    }
    async getDatasetCounts(datasetId: string) {
        const response = await this._datasetService.getCounts(datasetId).toPromise();
        if (response.isSuccess) {
            const { queueLength, resultLength } = response?.result;
            return { queueLength, resultLength };
        } else return { queueLength: 0, resultLength: 0 };
    }

    getSidebarItemById(sidebarItemId: string) {
        var item
        SIDEBAR_ITEMS.forEach(item => {
            if (!item?.children && (item.id === sidebarItemId)) item = item
            if (item?.children) item.children.forEach(child => { if (child.id == sidebarItemId) item = child })
        });
        return item;
    }
}



export const SIDEBAR_ITEMS: ISidebarItem[] = [
    {
        id: 'configuration', title: '', icon: null, children: [
            {
                id: 'schema', title: 'Fields',
                icon: 'heroicons_outline:view-columns'
                , route: 'schema'
            },
            {
                id: 'integrations', title: 'Connections',
                icon: 'link',
                route: 'integrations'
            },
            { id: 'settings', title: 'Settings', icon: 'settings', route: 'settings' }

        ]
    },
    {
        id: 'pipeline', title: 'Dataset', icon: null, children: [
            { id: 'queue', title: 'Queue', icon: 'heroicons_outline:inbox-arrow-down', route: 'queue', badge: '0', isLoading: false },
            { id: 'records', title: 'Records', icon: 'feather:database', route: 'records', badge: '0', isLoading: false },
        ]
    }
]
