import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'phone',
})
export class PhonePipe implements PipeTransform {
    transform(tel) {
        if (tel) {
            const note = tel.replace(/[^a-zA-Z ]/g, '');
            const value = tel.toString().trim().replace(/^\+/, '').replace(/[a-zA-Z]+/g, '').replace(/\s/g, '').replace('(', '').replace(')', '').replace('-', '');
            if (value.match(/[^0-9]/)) {
                return tel;
            }

            let country: any;
            let city = '';
            let phoneNumber = '';
            switch (value.length) {
                case 10:
                    country = 1;
                    city = value.slice(0, 3);
                    phoneNumber = value.slice(3);
                    break;

                case 11:
                    country = value[0];
                    city = value.slice(1, 4);
                    phoneNumber = value.slice(4);
                    break;
                default:
                    return tel;
            }

            if (country === 1) {
                country = '';
            }

            phoneNumber = phoneNumber.slice(0, 3) + '-' + phoneNumber.slice(3);

            return (country + ' (' + city + ') ' + phoneNumber + ' ' + note).trim();
        }
    }
}
