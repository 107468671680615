<div class="main-result">
    <div *ngIf="loading" class="loading-area">
        <!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->
        <ngx-skeleton-loader *ngIf="loading" count="6" animation="progress-dark" [theme]="{
            height: '20px',
            opacity: '0.8',
            'margin-bottom': '20px'
          }">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader *ngIf="loading" count="1" animation="progress-dark" [theme]="{
            height: '200px',
            opacity: '0.8',
            'margin-bottom': '20px'
          }">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader *ngIf="loading" count="6" animation="progress-dark" [theme]="{
            height: '20px',
            opacity: '0.8',
            'margin-bottom': '20px'
          }">
        </ngx-skeleton-loader>
    </div>

    <div class="content">
        <!-- <div class="flex gap-2 w-full items-center doc-control">

            <div class="flex gap-1 text-3xl cursor-pointer items-center" [matMenuTriggerFor]="menu">
                <b class="whitespace-nowrap"
                    *ngIf="fileDetailsHandlerService.schemaService.oSelectedDocument | async as selcetedDoc">{{selcetedDoc?.field?.display}}</b>
                <mat-icon >keyboard_arrow_down</mat-icon>
            </div>
            <mat-menu #menu="matMenu">
                <button *ngFor="let doc of fileDetailsHandlerService.schemaService.oDocumentList | async " mat-menu-item
                    (click)="fileDetailsHandlerService.schemaService.setCurrentDocuemnt(doc.key)">
                    <span>{{doc.display}}</span>
                </button>
            </mat-menu>

        </div> -->
        <div class="fields">
            <ng-container *ngFor="let field of (fields | async)">
                <div *ngIf="field.type == 'document'" class="document-container">
                    <div class="document-title">
                        {{field.display}}
                    </div>
                    <div class="document-fields-container">
                        <ng-container *ngFor="let subField of field.schema">
                            <ng-container *ngTemplateOutlet="FIELDS; context: {'field': subField}"></ng-container>
                        </ng-container>
                    </div>
                </div>

                <div *ngIf="field.type == 'single'" class="field">
                    <p class="field-name">{{ field?.display }}</p>
                    <p class="field-value">{{ field?.value || '-' }}</p>
                </div>

                <div *ngIf="field.type == 'mat-divider'" class="document-container">
                    <mat-divider  class="divider" ></mat-divider>
                </div>

                <div *ngIf="field.type == 'section'" class="section-container">
                    <div class="section-title">
                        {{field.display}}
                    </div>
                    <div class="fields">
                        <div *ngFor="let sub of field.schema" class="field">
                            <p class="field-name">{{ sub.display }}</p>
                            <p class="field-value">{{ sub.value || '-' }}</p>
                        </div>
                    </div>
                </div>
                <div *ngIf="field.type == 'table'" class="table-container">
                    <app-table-object [data]="field"
                        [columnSettings]="columnSettings | async"></app-table-object>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #FIELDS let-field="field">

    <div *ngIf="field.type == 'single'" class="field">
        <p class="field-name">{{ field?.display }}</p>
        <p class="field-value">{{ field?.value || '-' }}</p>
    </div>

    <div *ngIf="field.type == 'section'" class="section-container">
        <div class="section-title">
            {{field.display}}
        </div>
        <div class="fields">
            <div *ngFor="let sub of field.schema" class="field">
                <p class="field-name">{{ sub.display }}</p>
                <p class="field-value">{{ sub.value || '-' }}</p>
            </div>
        </div>
    </div>
    <div *ngIf="field.type == 'table'" class="table-container">
        <app-table-object [data]="field"
            [columnSettings]="columnSettings | async"></app-table-object>
    </div>
</ng-template>
