import { ReplaySubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface IStateService<T> {
    oState: Observable<T | { initOnly: boolean }>;
    getState(): T | { initOnly: boolean };
    setValue(val: any, reset: boolean);
    destroy();
}

export class StateService<T> implements IStateService<T> {

    public oState: Observable<T>;

    private $State: ReplaySubject<T> = new ReplaySubject(1);
    private state: T;

    constructor(initalState: T) {
        this.oState = this.$State.asObservable();
        this.$State.asObservable().subscribe(s => {
            this.state = s;
        });
        this.setValue(initalState); //first init triggers change
    }

    public setValue(val: any, reset = false) {
        this._setValue(val, reset);
    }

    public setField(key: string, val: any) {
        this.setValue({ [key]: val }, false);
    }

    public getState(): T {
        return Object.assign({}, this.state);
    }

    public destroy() {
        if (this.$State != null) {
            this.$State.unsubscribe();
            this.$State = null;
        }
    }

    private _setValue(val: any, reset: boolean) {
        if (reset) {
            this.$State?.next(Object.assign({}, val));
        } else {
            this.$State?.next(Object.assign({}, this?.state, val));
        }
    }

}
