import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, UserData, UserDataService } from '@discoverer/app-core';
import { environment } from '@env/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [UserDataService]
})
export class AppComponent {
  public loggedIn = false;
  title = 'docpipeline';

  constructor(
    private _userDataService: UserDataService,
    private _authService: AuthService,
    private _activated: ActivatedRoute,
    private _router: Router
  ) {
    console.log("Hello World")

    // this._router.navigate([environment.auth.productKey, 'home'], { queryParams: {isNew: true} });

    //this._authService.monitorForTokenParamAndFetchUser();
    this._authService.gatewayIdentityUserObservable.subscribe(user => {
      if (!!user) {
        const userData = new UserData();
        userData.name = user.fullName;
        userData.email = user.email;
        userData.id = user.userId;
        userData.token = user.token;
        userData.roles = new Set<string>(user.roles);
        this._userDataService.setUser(userData);
      }
    });
  }
}

