import {
    Pipe,
    PipeTransform
} from '@angular/core';
import { IDirRequest } from '@discoverer/core/services';
import { FlattenedDirRequests } from '@discoverer/dynamic-reports/common/filtered-reports-view/filtered-reports-view.component';


@Pipe({
    name: 'flattenDirsReq',
    pure: true
})
export class FlattenDirsRequestsPipe implements PipeTransform {
    transform(items: IDirRequest[], tabName?: string, tabKey? : string): FlattenedDirRequests[] {
        if (!items) {
            return [];
        }
        const reqs = [];
        items.forEach(dir =>
            dir.requests.forEach(req =>
                reqs.push({
                    ...req,
                    tabName,
                    tabKey,
                    directoryName: dir.directoryName,
                    isDirCommon: dir.userId === 0
                })
            ));
        return reqs;
    }
}
