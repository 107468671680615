import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'options-dialog',
    templateUrl: './options-dialog.html',
    styleUrls: ['./options-dialog.scss']
})
export class OptionDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<OptionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }
    close(r = false) {
        this.dialogRef.close(r);
    }
}
