<mat-chip-list #chipList>
    <mat-chip *ngFor="let option of selectedOptions" [selectable]="true" [removable]="true"
        [disabled]="field?.templateOptions?.disabled" (removed)="remove(option)">
        {{option?.description}}
        <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input #optionInput [formControl]="optionControl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="false" [tabIndex]="to?.tabindex"
        [disabled]="field?.templateOptions?.disabled" (matChipInputTokenEnd)="add($event)">
</mat-chip-list>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let option of filteredOptions" [value]="option">
        {{option.description}}
    </mat-option>
</mat-autocomplete>