import { Component, ViewEncapsulation, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface IConfirmDialog {
    title: string;
    message: string;
}
@Component({
    selector: 'confirm',
    templateUrl: './confirm.dialog.html',
    styleUrls: ['./confirm.dialog.scss'],
    encapsulation: ViewEncapsulation.None
})
// tslint:disable-next-line:component-class-suffix
export class CustomConfirmDialog implements OnInit {
    public title: string;
    public message: string;

    constructor(
        private dialogRef: MatDialogRef<CustomConfirmDialog>,
        @Inject(MAT_DIALOG_DATA) private dialogData: IConfirmDialog
    ) {
    }

    public ngOnInit(): void {
        this.title = this.dialogData.title;
        this.message = this.dialogData.message;
    }

    public close(isConfirmed) {
        this.dialogRef.close(isConfirmed);
    }
    public save() {
        this.close(true);
    }


}
