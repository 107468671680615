<facet1 [title]="display || facet?.display" [facetKey]="facet?.fieldName" [isCollapsible]="isCollapsible" [showCount]="showCount"
    *ngIf="!!facet && facet?.type == 'text' || facet?.type == 'boolean'" [isPinned]="isPinned()"
    [isDynamic]="facet?.isDynamicFacet == null ? true : facet?.isDynamicFacet" [isOpen]="isOpen"
    [queryService]="queryService" [serviceUrl]="currentTab.serviceUrl" [facetType]="facet?.type"
    [queryInfoServiceUrl]="(currentTab.facetTranslationUrl +'/'+ facet?.fieldName + '/' + facet?.tableName )"
    [filterMode]="filterMode" [includeBlank]="currentTab?.app?.key == 'obi'"
    [optionListId]="facet?.form?.templateOptions?.optionsListId" [clearButtonKey]="clearButtonKey"
    [isStringArrayFacetType]="facet?.dataType == 'string_array'" (pinFilter)="pinFilter($event)"
    [independent]="independent" [currentTab] = "currentTab" [showPin]="showPin" [showDoneButton]="showDoneButton" (doneButtonClicked)="doneButtonClicked.emit()">
</facet1>
<div [ngClass]="{'facet-wrapper': !isCollapsible}">
    <date-range-facet *ngIf="!!facet && facet?.type == 'date'"  [clearButtonKey]="clearButtonKey" [isCollapsible]="isCollapsible" [title]="display || facet?.display"
        [isPinned]="isPinned()" [isOpen]="isOpen" [facetKey]="facet?.fieldName" [queryService]="queryService"
        [serviceUrl]="currentTab.serviceUrl" [filterMode]="filterMode" [allKey]="currentTab.allKey"
         (pinFilter)="pinFilter($event)" [showPin]="showPin" [showDoneButton]="showDoneButton" (doneButtonClicked)="doneButtonClicked.emit()">
    </date-range-facet>
    <numeric-range-filter *ngIf="!!facet && facet?.type == 'numeric'"  [clearButtonKey]="clearButtonKey" [isCollapsible]="isCollapsible" [title]="display || facet?.display"
        [facetKey]="facet?.fieldName" [isOpen]="isOpen" [excludeRange]="isFilterExcluded" [isPinned]="isPinned()" (pinFilter)="pinFilter($event)"
        [filterMode]="filterMode" [queryService]="queryService"
        [serviceUrl]="currentTab.serviceUrl" [format]="facet?.format" [allKey]="currentTab.allKey" [showPin]="showPin" [showDoneButton]="showDoneButton" (doneButtonClicked)="doneButtonClicked.emit()">
    </numeric-range-filter>
</div>
