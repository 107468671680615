import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { IOption } from '../models/option';
import { OptionDialogComponent } from '../options-dialog/options-dialog';
import { MutateOptionBloc } from '../shared-services/options.bloc';

export type DetailsMode = 'display' | 'edit' | 'add';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'option-details',
  templateUrl: './option-details.component.html',
  styleUrls: ['./option-details.component.scss']
})
export class OptionDetailsComponent {
  public optionDetailsForm: FormGroup;

  @Input()
  set option(value: IOption) {
    this._option = value;
    this.fillOptionData(this._option);
  }
  get option(): IOption {
    return this._option;
  }

  @Input()
  set mode(value: DetailsMode) {
    this._mode = value;
  }
  get mode(): DetailsMode {
    return this._mode
  }

  @Output()
  listChanged = new EventEmitter<boolean>();


  public optionListId: number;

  private _option: IOption;
  private _mode: DetailsMode;

  constructor(
    private formBuilder: FormBuilder,
    public optionMutation: MutateOptionBloc,
    public dialog: MatDialog
  ) {
    this.initForm();
  }

  public saveChanges() {
    if (!this.optionDetailsForm.invalid) {
      const option: IOption = Object.assign(this.option, this.optionDetailsForm.value);

      this.optionMutation.addOrUpdateOption(option);
      this.listChanged.next(true);
    } else {
      this.markAsTouched();
    }
  }

  public cancelChanges() {
    this.fillOptionData(this._option);
    this.listChanged.next(false);
  }

  public deleteOption(event, option) {
    this.openOptionDialog('Are you sure you want to delete this option?').afterClosed().subscribe(x => {
      if (x) {
        this.optionMutation.deleteOption(option);
        this.listChanged.next(true);
        event.stopPropagation();
      }
    });

  }
  private fillOptionData(selectedOption: IOption) {
    const formData = {
      description: selectedOption.description,
      value: selectedOption.value,
      code: selectedOption.code,
      group: selectedOption.group
    };
    this.optionDetailsForm.setValue(formData);
  }

  private initForm() {
    this.optionDetailsForm = this.formBuilder.group({
      description: ['', Validators.required],
      value: null,
      code: '',
      group: ''
    });
  }

  private markAsTouched() {
    Object.keys(this.optionDetailsForm.controls).forEach(key => {
      this.optionDetailsForm.controls[key].markAsTouched();
    });
  }
  private openOptionDialog(message) {
    return this.dialog.open(OptionDialogComponent, {
      data: { message, isConfirmation: true },
      autoFocus: false
    });
  }
}
