import { Component, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, UrlTree } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSidenav } from '@angular/material/sidenav';
import { EventEmitter } from '@angular/core';
import { IgniteTabService } from '../../../services/ignite-tab.service';
import { FileInfoDto } from '../../../models/datasetFile.models';
import { TaskService } from '../../../services/api/task.service'
import { DatasetFileService } from '../../../services/api/dataset-file.service';
import { DatasetService } from '../../../services/api/dataset.service';

const TEMPLATE_CARDS = [
  { label: 'Purchase Order', value: 'purchase_order' },
  { label: 'HR Contract', value: 'hr_contract' },
  { label: 'MSA Contract', value: 'msa_contract' },
  { label: 'Receipt', value: 'receipt' },
  { label: 'Bill of Lading', value: 'bill_of_lading' },
  { label: 'Invoice', value: 'invoice' },
]
@Component({
  selector: 'app-schema',
  templateUrl: './schema.component.html',
  styleUrls: ['./schema.component.scss']
})
export class SchemaComponent implements OnInit {
  @Input() datasetId: string;
  @Input() generateSchema: boolean

  @Output() schemaUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() fileUploaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  isLoading: boolean = false
  currentUrl: UrlTree
  templateCards = TEMPLATE_CARDS;
  searchText: string = '';
  isUploading: boolean = false;

  constructor(
    private _snackbar: MatSnackBar,
    private _datasetService: DatasetService,
    private _activated: ActivatedRoute,
    private _igniteTabService: IgniteTabService,
    private _taskService: TaskService,
    private _datasetFileService: DatasetFileService
  ) { }

  async ngOnInit(): Promise<void> {
    this.datasetId = this._activated.snapshot.params['datasetId'];
  }

  async updateSchemaToTemplate(templateName: string) {
    this.isLoading = true;
    const fields = await this._datasetService.getTemplateFields(templateName).toPromise();
    const files = await this._igniteTabService.initTab("Records", fields?.result, this.datasetId);
    await this._datasetService.createOrUpdateAssets(this.datasetId, files).toPromise()
    var response = await this._datasetService.updateFieldsByTemplate(this.datasetId, templateName).toPromise();
    if (response.isSuccess) {
      this.schemaUpdated.emit(response?.result);
      this._showSnackbar('schema updated successfully')
    } else {
      this._showSnackbar("Error updating schema. Try again")
    }
    this.isLoading = false;
  }

//   public async handleFileList(fileList: FileList) {
//     let fileId = await this._generateSchema(fileList[0])

//     if (!!fileId) {
//     //   localStorage.setItem(this.datasetId, fileId);
//       this.fileUploaded.emit(true);
//     }
//   }

//   private async _generateSchema(file: File): Promise<string> {
//     this.isUploading = true;
//     let formData = new FormData();
//     formData.append(`inputFile`, file);
//     const response = await this._datasetService.createFieldsByFile(this.datasetId, formData).toPromise();
//     if (!response?.isSuccess) return null;
//     let fileInfo: FileInfoDto = response?.result;
//     let successOpt: boolean = await this._taskService.waitForTask(fileInfo?.taskId)
//     if (successOpt) {
//       const files = await this._igniteTabService.initTab('Resutls', (await this._datasetService.getFields(this.datasetId).toPromise()).result, this.datasetId)
//       await this._datasetService.createOrUpdateAssets(this.datasetId, files).toPromise()
//       await this._datasetFileService.reprocessById(this.datasetId, fileInfo.fileId).toPromise();
//       this._showSnackbar(`Schema generated successfully`);
//     }
//     else { this._showSnackbar(`Error while generating schema`); }
//     this.isUploading = false;
//     return fileInfo.fileId;
//   }

  private _showSnackbar(msg: string) {
    this._snackbar.open(msg, 'close', {
      duration: 3000,
      politeness: 'polite',
    });
  }
}
