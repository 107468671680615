import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SaveReportDialog } from '../save-report/save-report.dialog';
import { FormControl, Validators } from '@angular/forms';
import { ITab2 } from '@discoverer/core/services';

@Component({
    selector: 'tab-selector',
    templateUrl: './tab-selector.dialog.html',
    styleUrls: ['./tab-selector.dialog.scss']
})
export class TabSelectorComponent implements OnInit {

    public tabControl: FormControl;
    tabs: ITab2[] = [];
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { tabs: ITab2[] },
        private dialogRef: MatDialogRef<TabSelectorComponent>) {
        this.tabs = data.tabs
        this.tabControl = new FormControl('', [Validators.required]);
    }

    ngOnInit(): void {
    }

    public close() {
        this.dialogRef.close();
    }
    public open() {
        if(this.tabControl.valid)
        this.dialogRef.close(this.tabControl.value);
    }
}
