import { Injectable } from '@angular/core';
import { IProfileInput } from '../models'

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  constructor(
  ) { }

  async updateProfileDetails(profileFields: IProfileInput) {
  }

  async updatePassword(newPassword: string) {
  }
}
