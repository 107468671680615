<div class="header">
    Drill Down Into:

</div>
<mat-divider></mat-divider>
<div class="body">
    <auto-complete-search [showSelectField]="false" [label]="'Field'" class="select-field"
        [fieldName]="selectedColumn?.colName || ''" [availableColumns]="columns" [readonly]="false"
        (changeFieldName)="select($event)">
    </auto-complete-search>
    <div class="fields-container" *ngIf="selectedColumn?.type==='date'">
        <mat-form-field class="secound-list" style="width: 45% !important;">
            <mat-label>Group By</mat-label>
            <mat-select placeholder="Group By" [(value)]="selectedColumn.groupByName" (selectionChange)="dateGroupByChanged()">
                <mat-option *ngFor="let name of availableDateGroups" [value]="name"> {{name}} </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="selectedColumn.groupByName" style="width: 45% !important">
            <mat-label>{{(selectedColumn.groupByName || '-')+' No.'}}</mat-label>
            <input type="email" matInput [(ngModel)]="selectedColumn.numberOfGroups" (input)="dateGroupByChanged()">
        </mat-form-field>
    </div>
    <div *ngIf="selectedColumn?.type==='numeric'">
        <mat-progress-bar mode="indeterminate" [ngClass]="loading ? 'visible':'hidden'"></mat-progress-bar>

        <div class="fields-container">
            <mat-form-field style="width: 30%;">
                <mat-label>Min</mat-label>
                <input matInput [(ngModel)]="selectedColumn.groupBy.start" (input)="numericGroupByChanged()"/>
            </mat-form-field>
            <mat-form-field style="width: 30%;">
                <mat-label>Max</mat-label>
                <input matInput [(ngModel)]="selectedColumn.groupBy.end" (input)="numericGroupByChanged()"/>
            </mat-form-field>
            <mat-form-field style="width: 30%;">
                <mat-label>Steps</mat-label>
                <input matInput [(ngModel)]="selectedColumn.groupBy.stepsNo" (input)="numericGroupByChanged()"/>
            </mat-form-field>
        </div>
    </div>

</div>
<mat-divider></mat-divider>
<div class="footer">
    <button class="no-border-radius" mat-raised-button (click)="onNoClick()">Cancel</button>
    <button class="no-border-radius" color="primary" mat-raised-button [disabled]="!selectedColumn.colName"
        (click)="open()">View</button>
</div>
