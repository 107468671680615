<div class="filter-column noselect" [style.width.px]="width">

    <div id="filters-scroll" class="filters-container" *ngIf="!searchTerm else FULL_LIST">
        <div *ngIf="!selectedGroup" @slideOut>
            <div *ngIf="this.getShortDisplayTransform('') as key1DisplayTransform" [class.pt-0]="goupedFacetList.size > 1"
                class="padding-24  flex col">
                <div class="flex col"
                    *ngFor="let row of (goupedFacetList.get('') | groupBy: this.getNextGroupByKey: key1DisplayTransform | keyvalue); trackBy: this.trackByKey; let i = index">
                    <h2 *ngIf="row.key" [class.mt-2]="i>0" style="font-weight: 500 !important;" class="mb-2">{{row.key}}
                    </h2>
                    <div *ngIf="this.getShortDisplayTransform(row.key) as key2DisplayTransform" class="flex row">
                        <div class="facet-container"
                            *ngFor="let col of (row.value | chunkList: columns); trackBy: this.trackByLength">
                            <div class="facet-item" *ngFor="let facet of col; trackBy: this.trackByField">
                                <ng-container
                                    *ngIf="(facet.display | transformBy : key1DisplayTransform | transformBy: key2DisplayTransform) as display">
                                    <facet-container [isCollapsible]="false" [facet]="facet" [currentTab]="tab"
                                        [display]="display" [clearButtonKey]="clearButton" [filterMode]="filterMode"
                                        [queryService]="queryService" [isFilterExcluded]="facet.excludeRange"
                                        [isCollapsible]="true">
                                    </facet-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="groups">
                <div class="group"
                    *ngFor="let groupedKey of groupedFacetListKeys | keyvalue; trackBy: this.trackByKey ">
                    <div *ngIf="groupedKey.key > 1">
                    <mat-card class="tab-card" (click)="selectedGroup = groupedKey">
                        <mat-card-content class="tab-card-content">
                            <div class="header-wrapper">
                                <h1 class="title" *ngIf="groupedFacetListKeys.size >= 1">
                                    {{groupedKey.value}}</h1>
                                <button mat-icon-button><mat-icon>keyboard_arrow_right</mat-icon></button>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="selectedGroup" @slideIn>
            <div class="header" *ngIf="selectedGroup.key > 1">
                <div class="left"><mat-icon (click)="selectedGroup = null">arrow_back_ios</mat-icon></div>
                <div class="center">{{selectedGroup.value}}</div>
            </div>
            <div [class.facet-list]="selectedGroup.key > 1" *ngIf="this.getShortDisplayTransform(selectedGroup.value) as key1DisplayTransform" [class.pt-0]="goupedFacetList.size > 1"
                class="padding-24  flex col">
                <div class="flex col"
                    *ngFor="let row of (goupedFacetList.get(selectedGroup.value) | groupBy: this.getNextGroupByKey: key1DisplayTransform | keyvalue); trackBy: this.trackByKey; let i = index">
                    <h2 *ngIf="row.key" [class.mt-2]="i>0" style="font-weight: 500 !important;" class="mb-2">{{row.key}}
                    </h2>
                    <div *ngIf="this.getShortDisplayTransform(row.key) as key2DisplayTransform" class="flex row">
                        <div class="facet-container"
                            *ngFor="let col of (row.value | chunkList: columns); trackBy: this.trackByLength">
                            <div class="facet-item" *ngFor="let facet of col; trackBy: this.trackByField">
                                <ng-container
                                    *ngIf="(facet.display | transformBy : key1DisplayTransform | transformBy: key2DisplayTransform) as display">
                                    <facet-container [isCollapsible]="false" [facet]="facet" [currentTab]="tab"
                                        [display]="display" [clearButtonKey]="clearButton" [filterMode]="filterMode"
                                        [queryService]="queryService" [isFilterExcluded]="facet.excludeRange"
                                        [isCollapsible]="true">
                                    </facet-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #FULL_LIST>
        <div class="filters-container">
            <div class="groups">
                <div class="group"
                    *ngFor="let groupedKey of groupedFacetListKeys | keyvalue; let i = index; trackBy:  trackByKey">

                    <div *ngIf="this.getShortDisplayTransform(groupedKey.value) as key1DisplayTransform"
                        class="mt-0 mb-2 flex col">
                        <div
                            *ngIf="groupedFacetListKeys.size > 1
                            && (goupedFacetList.get(groupedKey.value) | groupBy: this.getNextGroupByKey: key1DisplayTransform | keyvalue).length > 0">
                            <div class="mb-2 font-bold text-xl">{{groupedKey.value}}</div>
                            <mat-divider class="mb-2"></mat-divider>
                        </div>
                        <div class="flex col mb-2"
                            *ngFor="let row of (goupedFacetList.get(groupedKey.value) | groupBy: this.getNextGroupByKey: key1DisplayTransform | keyvalue); trackBy: this.trackByKey; let j = index">
                            <h2 *ngIf="row.key" [class.mt-2]="j>0" style="font-weight: 500 !important;" class="mb-2">
                                {{row.key}} </h2>
                            <div *ngIf="this.getShortDisplayTransform(row.key) as key2DisplayTransform"
                                class="flex row">
                                <div class="facet-container"
                                    *ngFor="let col of (row.value | chunkList: columns); trackBy: this.trackByLength">
                                    <div class="facet-item" *ngFor="let facet of col; trackBy: this.trackByField">
                                        <ng-container
                                            *ngIf="(facet.display | transformBy : key1DisplayTransform | transformBy: key2DisplayTransform) as display">
                                            <facet-container [isCollapsible]="false" [facet]="facet" [currentTab]="tab"
                                                [display]="display" [clearButtonKey]="clearButton"
                                                [filterMode]="filterMode" [queryService]="queryService"
                                                [isFilterExcluded]="facet.excludeRange" [isCollapsible]="true">
                                            </facet-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </ng-template>

    <mat-divider></mat-divider>
    <div class="footer">
        <div class="count">
            <span class="mr-1 font-medium">{{(tableHeaderService.tableHeader | async)?.resultLength | number}}</span>
            <span class="font-14">{{tab?.name}}</span>
        </div>
        <button class="border-rad-6" color="primary" mat-raised-button (click)="close.emit()"> Done
        </button>
    </div>

</div>

<mat-divider></mat-divider>
