<div *ngIf="(reportPersistService.dashboardViewState.oState | async)?.filters as appliedFilters" class="filter-group">
    <ng-container *ngFor="let appliedFilter of appliedFilters; trackBy: trackByFields">
        <div class="top-label" *ngIf="appliedFilter.filter.fields[0] as filterField ">
            <div class="filter-title" *ngIf="getCol(filterField) as filter">
                <list-form [title]="filter.display" [initializeOpened]="false" class="list"
                    [info]="getTitle(appliedFilter.filter.facetValues)">
                    <div class="flex row">
                        <div class="facet-container">
                            <div class="facet-item">
                                <facet-container [independent]="true" [isCollapsible]="false" [facet]="filter"
                                    [clearButtonKey]="false" [showCount]="false"
                                    [currentTab]="currentTabs[appliedFilter.tabs[0]]" [filterMode]="'All'"
                                    [queryService]="queryService" [isOpen]="true" [isFilterExcluded]="false">
                                </facet-container>
                            </div>
                        </div>
                    </div>
                </list-form>
            </div>
            <div class="actions">
                <mat-icon class="action" *ngIf="editMode" [matTooltip]="getAppliedTabs(appliedFilter.tabs)"
                    (click)="editFilter(appliedFilter)">edit</mat-icon>
                <mat-icon class="action" *ngIf="editMode" (click)="removeFilter(filterField)">close</mat-icon>
            </div>
        </div>
    </ng-container>
</div>
