import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import {
    AdvancedFilterService,
    BaseController,
    DATE_OPERATION,
    DATE_OPR_DICTIONARY,
    DiscovererDataService,
    DiscovererQueryService,
    FacetValue,
    FIELDS_TYPES,
    FILTERING_MODES,
    FILTERING_OPERATION,
    FILTERING_OPR_DICTIONARY,
    FILTER_CHANGES,
    IColumnSetting,
    IDisplayFilter,
    FUTURE_FILTERING_OPR_DICTIONARY,
    LESS_FILTERING_OPR_DICTIONARY
} from '@discoverer/core/services';

import { MatSelectChange } from '@angular/material/select';


import { HttpClient } from '@angular/common/http';
import { ITab2, TabSettingsService } from '@discoverer/core/services';

@Component({
    selector: 'broken-down-filter',
    templateUrl: './broken-down-filter.component.html',
    styleUrls: ['./broken-down-filter.component.scss']
})
export class BrokenDownFilterComponent extends BaseController implements OnInit {
    @Input()
    public advFilterMode: string;
    @Input()
    public availableCols: IColumnSetting[];
    @Input()
    public filterIndex: number;
    @Input()
    public filter: IDisplayFilter;
    @Input()
    private advFilterService: AdvancedFilterService;
    @Input()
    public queryService: DiscovererQueryService;
    @Input()
    public serviceUrl: string;
    @Input()
    public readonly: boolean;
    @Input()
    public changeLocation: string;
    @Input()
    public tabSettings: TabSettingsService;
    @Input()
    public filterDepth: number;

    public FILTERING_OPERATION = FILTERING_OPERATION;
    public FILTERING_MODES = FILTERING_MODES;
    public DATE_OPERATION = DATE_OPERATION;
    public DATE_OPR_DICTIONARY = DATE_OPR_DICTIONARY;
    public FILTERING_OPR_DICTIONARY = FILTERING_OPR_DICTIONARY;
    public FIELDS_TYPES = FIELDS_TYPES;
    public FILTER_CHANGES = FILTER_CHANGES;

    public dataService: DiscovererDataService<any>;
    public facetValues: FacetValue[];

    public fieldType: string;
    public fieldTitle: string;
    public subAdvancedFilterMode: string = FILTERING_MODES.WHERE;

    // Filer drop down list options
    public FILTERING_MIN_OPERATION = FILTERING_OPERATION.filter(o => o.allowedTypes.includes("numeric") && o.label.includes('more'));
    public FILTERING_MAX_OPERATION = FILTERING_OPERATION.filter(o => o.allowedTypes.includes("numeric") && o.label.includes('less'));

    public selectedMinFilterExpression: any = this.FILTERING_MIN_OPERATION[0].id;
    public selectedMaxFilterExpression: any = this.FILTERING_MAX_OPERATION[1].id;

    public itemHover = false;
    public invalidValue = false;
    public tab: ITab2;
    @Output()
    public invalidFilterValue: EventEmitter<any> = new EventEmitter<any>();

    public get isDateAfter(): boolean {
        return !!FUTURE_FILTERING_OPR_DICTIONARY.find(dateOp => dateOp === this.filter.operationA);
    }
    public get isActionLess(): boolean {
        return !!LESS_FILTERING_OPR_DICTIONARY.find(dateOp => dateOp === this.filter.operationA);
    }

    constructor(private eHttp: HttpClient) {
        super();
    }
    async ngOnInit(): Promise<void> {
        const colDef = this.advFilterService.getFilterType(this.filterIndex);
        if (!!colDef) {
            this.setFacet();
        } else {
            if (this.advFilterService.isNested(this.filterIndex)) {
                this.subAdvancedFilterMode = this.advFilterService.getSubAdvancedType(this.filterIndex);
                this.updateFilteringMode(true);
            }
        }
        this.tab = await this.tabSettings.getCurrentTab();
    }

    public onKey(event: any, type: string, operation?: string) {
        if ((type === FIELDS_TYPES.NUMERIC || type === FIELDS_TYPES.DATE && !!operation) && isNaN(event.target.value) && !!event.target.value) {
            this.invalidValue = true;
        } else {
            this.invalidValue = false;
        }
        this.advFilterService.validateFilter(this.filterIndex, !this.invalidValue);
    }

    public filterValueChanged(event: FacetValue[]) {
        if (this.filter.isDynamicFacet === false) {
            this.filter.valueA = event.map(f => f.id).join(', ');
            this.filter.displayA = event.map(f => f.title).join(', ');
        }
        else {
            this.filter.valueA = event.map(f => f.title).join(', ');
        }
        this.advFilterService.updateFilter(this.filterIndex, this.filter);
    }

    public dateChanged(event?: any) {
        if (!!event && !!event.value) {
            // we are working with moment date here
            if (this.isDateAfter) {
                this.filter.valueA = event.value.format('YYYY-MM-DD');
            } else {
                this.filter.valueB = event.value.format('YYYY-MM-DD');
            }
        }
        this.advFilterService.updateFilterDate(this.filterIndex, this.filter);
    }

    public columnChange(selectionChange: any) {
        /*this.filter = Object.assign({});*/
        this.filter.fields[0] = this.validateField(selectionChange);
        this.filter.operationA = '';
        this.filter.operationB = undefined;
        this.filter.valueA = '';
        this.advFilterService.updateFilter(this.filterIndex, this.filter);
        this.setFacet();
    }

    private validateField(field: any): string {
        // NOTE: The event leaks on clicking outside the input breaking down the filter setting type to string doesnt help
        if (!!field.isTrusted) {
            if (!!this.availableCols.find(x => x.display === field.target.value)) {
                field = this.availableCols.find(x => x.display === field.target.value).fieldName;
            } else {
                field = '';
            }
        }
        if (!this.availableCols.find(x => x.fieldName === field)) {
            field = '';
        }

        return field + '';
    }

    public operationAChange(selectionChange: MatSelectChange) {
        this.filter.operationA = selectionChange.value;
        this.DATE_OPERATION = DATE_OPERATION.filter(o => o.allowedTypes.includes(selectionChange.value));
        this.filter.valueA = '';
        this.advFilterService.updateFilter(this.filterIndex, this.filter);
    }

    public updateFilterValue() {
        this.advFilterService.updateFilter(this.filterIndex, this.filter);
    }

    public updateIsBetweenValue() {
        this.advFilterService.updateFilter(this.filterIndex, this.filter);
    }

    public updateFromOperator() {
        this.advFilterService.updateFilter(this.filterIndex, this.filter);
    }

    public updateToOperator() {
        this.advFilterService.updateFilter(this.filterIndex, this.filter);
    }

    public removeFilter() {
        this.advFilterService.removeFilter(this.filterIndex);
    }

    public addNewFilter() {
        this.advFilterService.addFiler(this.filterIndex);
    }

    public handleDateOperation(selectionChange: MatSelectChange) {
        this.filter.operationB = selectionChange.value;
        this.filter.valueA = '';
    }

    public updateFilteringMode(filterMode) {
        if (this.subAdvancedFilterMode === FILTERING_MODES.WHERE) {
            this.handleWhereMode();
        } else {
            this.handleNonWhereMode();
        }
        this.advFilterService.updateFilter(this.filterIndex, this.filter);
    }

    private handleWhereMode() {
        if (this.filter.type == FILTERING_MODES.ANY || this.filter.type == FILTERING_MODES.ALL) {
            this.switchFromAdvancedToFacet();
        } else {
            this.filter.type = 'facet';
            this.filter.fields = [];
        }
    }

    private switchFromAdvancedToFacet() {
        if (this.filter.filters[0] && this.filter.filters[0].type == 'facet') {
            this.filter.type = this.filter.filters[0].type
            this.filter.valueA = this.filter.filters[0].valueA
            this.filter.valueB = this.filter.filters[0].valueB
            this.filter.operationA = this.filter.filters[0].operationA
            this.filter.operationB = this.filter.filters[0].operationB
            this.filter.fields = this.filter.filters[0].fields
        } else {
            this.filter.type = 'facet';
            this.filter.fields = [];
            this.filter.operationA = "";
            this.filter.operationB = "";
            this.filter.expression = [];
            this.filter.valueA = "";
            this.filter.valueB = "";

        }
    }

    private handleNonWhereMode() {
            if (this.filter.type == 'facet') {
                this.addNewAdvancedFilter();
            }
        this.filter.type = this.subAdvancedFilterMode;
        this.filter.fields = ['Advanced'];
    }

    private addNewAdvancedFilter() {
        this.filter.filters[0] = {
            expression: this.filter.expression,
            fields: this.filter.fields,
            type: this.filter.type
        }
        this.filter.filters[0].operationA = this.filter.operationA;
        this.filter.filters[0].operationB = this.filter.operationB;
        this.filter.filters[0].valueA = this.filter.valueA;
        this.filter.fields = ['Advanced'];
        this.filter.type = this.subAdvancedFilterMode;
        this.filter.expression = [''];
        this.filter.operationA = '';
        this.filter.operationB = '';
        this.filter.valueA = '';
        this.filter.valueB = '';

    }

    public checkValidateAdvancedFilter(event) {

    }

    public linkWithChildFilters(event) {
        if (!!event) {
            this.filter.filters = event;
            this.advFilterService.updateFilter(this.filterIndex, this.filter);
        }
    }


    private async setFacet() {
        const colDef = await this.advFilterService.getFilterType(this.filterIndex);
        if (!!colDef) {
            this.fieldType = colDef.type;
            this.fieldTitle = colDef.display;
            this.FILTERING_OPERATION = FILTERING_OPERATION.filter(o => o.allowedTypes.includes(colDef.type));
        }

    }
}
