import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { IColumnSetting, TabSettingsService } from '@discoverer/core';
import { FieldArrayType } from '@ngx-formly/core';
import { Keys } from '@progress/kendo-angular-common';
import { AddEvent, CancelEvent, CellClickEvent, CellCloseEvent, EditEvent, GridComponent, RemoveEvent, SaveEvent } from '@progress/kendo-angular-grid';
const ColumnTypeToKendoMapper = {
    'boolean': 'boolean',
    'string': 'text',
    'text': 'text',
    'html': 'text',
    'link': 'text',
    'link_website': 'text',
    'string_array': 'text',
    'string_set': 'text',
    'icon': 'text',
    'phone': 'text',
    'int': 'numeric',
    'integer': 'numeric',
    'double': 'numeric',
    'currency': 'numeric',
    'percent': 'numeric',
    'number': 'numeric',
    'date': 'date',
    'timestamp': 'date',
    'timestamptz': 'date'
};
@Component({
    selector: 'dyn-table',
    templateUrl: './dyn-table.component.html',
    styleUrls: ['./dyn-table.component.scss']
})
export class DynTableComponent extends FieldArrayType implements OnInit {
    nestedColumns: IColumnSetting[] = [];
    tableColumns: { display: string, fieldName: string, type: 'boolean' | 'text' | 'numeric' | 'date' }[] = [];
    formGroup!: FormGroup;
    editedRowIndex: number;

    constructor(
        private tabSettingsService: TabSettingsService,
        private formBuilder: FormBuilder,
        private cdr: ChangeDetectorRef
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        await this.loadColumns();
        this.addEmptyLine();
    }

    private async loadColumns(): Promise<void> {
        //use static columns or tab columns
        if (!!this.field.expressionProperties && !!this.field.expressionProperties.useStaticColumns) {
            this.tableColumns = this.field.fieldGroup[0]?.fieldGroup.map(field => ({
                display: field.templateOptions.label,
                fieldName: field.key.toString(),
                type: this.mapColumnTypeToKenodType(field.templateOptions.type)
            }));
        } else {
            const fieldLabel = this.to.label || this.field.parent.templateOptions.label;
            const columns = await this.tabSettingsService.getAllColumns();
            this.nestedColumns = columns.filter(col => col.fieldName.startsWith(`${this.field.key}.`));
            this.tableColumns = this.nestedColumns.map(col => ({
                display: col.display.replace(`${fieldLabel} - `, ''),
                fieldName: col.fieldName.replace(`${this.field.key}.`, ''),
                type: this.mapColumnTypeToKenodType(col.dataType)
            }));
        }

    }

    public removeHandler(args: RemoveEvent): void {
        const formArray = this.form.controls[this.to.label] as FormArray;
        formArray.removeAt(args.rowIndex);
        this.model.splice(args.rowIndex, 1);
    }


    public cellCloseHandler(args: CellCloseEvent): void {
        const { formGroup, rowIndex } = args;
        if (this.isLastRow(rowIndex)) return;
        if (!formGroup.valid) {
            args.preventDefault();
        } else if (args.originalEvent?.keyCode !== Keys.Escape) {
            this.updateModelRow(args);
            this.addEmptyLine();
            this.cdr.detectChanges();
        }
    }

    private addModelRow(args: any): void {
        const { formGroup, rowIndex } = args;
        const formArray = this.getNestedFormArray(this.form.controls, '.', this.field.key.toString());
        const rowControl = formArray.controls[rowIndex];
        const { value } = formGroup;
        this.model[rowIndex] = value;
        rowControl.setValue(value);
        this.updateModel();
    }
    private updateModelRow(args: any): void {
        const { formGroup, rowIndex } = args;
        const formArray = this.getNestedFormArray(this.form.controls, '.', this.field.key.toString());
        const rowControl = formArray.controls[rowIndex];
        const { value } = formGroup;
        this.model[rowIndex] = value;
        delete this.model[rowIndex][`${this.field.key}`.split('.')[0]]
        var innerRowControl = this.getNestedFormArray((rowControl as FormGroup).controls, '.', this.field.key.toString());
        innerRowControl.setValue(JSON.parse(JSON.stringify(value)));
        delete this.model[rowIndex][`${this.field.key}`.split('.')[0]] // this is a hack innerRowControl.setValue(value); add a nested object to model
        this.updateModel();
    }

    public cellClickHandler(args: CellClickEvent): void {
        if (!args.isEdited) {
            args.sender.editCell(args.rowIndex, args.columnIndex, this.createFormGroup(this.model[args.rowIndex]));
        }
    }

    public addHandler(args: AddEvent): void {
        this.closeEditor(args.sender);
        args.sender.addRow(this.createFormGroup(this.model[args.rowIndex]));
    }

    public editHandler(args: any): void {
        this.closeEditor(args.sender);
        this.editedRowIndex = args.rowIndex;
        args.sender.editRow(args.rowIndex, this.createFormGroup(this.model[args.rowIndex]));
    }

    public saveHandler(args: SaveEvent): void {
        const isLastLine = (args.rowIndex === (this.model.length - 1))  //
        if (isLastLine) {                                               //
            this.addModelRow(args);                                     // This is A hack to fix when editting a row the model object adds a nested object inside
        } else {                                                        //
            this.updateModelRow(args);                                  //
        }                                                               //

        this.addEmptyLine();
        this.cdr.detectChanges();
        args.sender.closeRow(args.rowIndex);
    }

    public createFormGroup(data: any): FormGroup {
        return this.formBuilder.group(data);
    }

    public cancelHandler(args: CancelEvent): void {
        // close the editor for the given row
        this.closeEditor(args.sender, args.rowIndex);
    }

    public trackByIndex(index: number, item: any): any {
        return index;
    }

    private getNestedFormArray(
        rootFormControls: { [key: string]: AbstractControl } | AbstractControl,
        splitKey: string,
        fieldKey: string
    ): FormArray {
        const keys = fieldKey.split(splitKey);
        const currentKey = keys[0];
        const currentControl = rootFormControls[currentKey];
        if (keys.length === 1) {
            return currentControl as FormArray;
        }
        const remainingKey = keys.slice(1).join(splitKey);

        return this.getNestedFormArray(currentControl?.controls, splitKey, remainingKey);
    }

    private addEmptyLine(): void {
        if (!this.model) return;

        const lastRow = this.model.length > 0 ? this.model[this.model.length - 1] : [];
        if (Object.values(lastRow).every(value => value === '')) return;

        const emptyLine = this.createEmptyLine();
        const formArray = this.getNestedFormArray(this.form.controls, '.', this.field.key.toString());
        formArray.push(this.formBuilder.group(emptyLine));
        this.model.push(emptyLine);
    }

    private createEmptyLine(): any {
        return Object.keys(this.model[0]).reduce((acc, key) => {
            acc[key] = '';
            return acc;
        }, {} as any);
    }

    private updateModel(): void {
        this.model = this.model.filter(row => Object.values(row).some(value => value !== ''));
    }

    public isLastRow(rowIndex: number): boolean {
        return rowIndex === this.model.length - 1;
    }
    private mapColumnTypeToKenodType(dataType: string) {
        return ColumnTypeToKendoMapper[dataType] || 'text'
    }

    private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
        grid.closeRow(rowIndex);
        this.editedRowIndex = undefined;
        this.formGroup = undefined;
    }
}
