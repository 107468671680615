import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import {
    DiscovererQueryService, BaseController, IColumnSetting,
    TabSettingsService, ReportPersistService, ITab2, IFacetDefinition, FILTERING_MODES, disLogger, searchForText
} from '@discoverer/core';
import { MatDialog } from '@angular/material/dialog';
import { groupBy, take } from 'rxjs/operators';
import { AdvancedDialog } from '../../dialogs/advanced/advanced.dialog';
import { ColumnSettingsCustomizationDialog } from '../../dialogs/column-settings-customization/column-settings-customization.dialog';
import { KeyValue } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';
import { TableHeaderService } from '@discoverer/core/services/dynamic-reports-services/table-header.service';
//import { filter, map } from 'lodash';
export interface IFacet extends IColumnSetting {
    isOpen: boolean;
    shortDisplay: string;
    nestedPath: string;
    postfix: string;
    excludeRange: boolean;
}
const SLIDE_ANIMATIONS = [
    trigger('slideIn', [transition(':enter', [style({ transform: 'translateX(100%)' }), animate(200)])]),
    trigger('slideOut', [transition(':enter', [style({ transform: 'translateX(-100%)' }), animate(200)])])
];

@Component({
    selector: 'facet-list',
    templateUrl: './facet-list.component.html',
    styleUrls: ['./facet-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: SLIDE_ANIMATIONS
})
export class FacetListComponent extends BaseController implements OnInit {
    @Input() public queryService: DiscovererQueryService;
    @Input() public serviceUrl: string;
    @Input() public hiddenFilters = true;
    @Input() public width: number;
    @Input() public columns: number = 3;
    @Output() tilted = new EventEmitter<boolean>();
    @Output() close = new EventEmitter<boolean>();

    @Input() set searchTerm(value: string) {
        this._searchTerm = value;
        const filtered = this._filterFacetList(this._searchTerm);
        this.goupedFacetList = this.getFacetGroups(filtered);
        this._cdRef.detectChanges();
    }

    get searchTerm(): string {
        return this._searchTerm;
    }
    public _searchTerm: string;

    public clearButton: boolean = true

    public allKey: string;
    public facetList: IFacet[] = [];
    public originalFacetList: IFacet[] = [];

    public goupedFacetList: Map<string, IFacet[]> = new Map<string, IFacet[]>();

    public tab: ITab2;
    public filterModeAllChecked = true;
    public FILTERING_MODES = FILTERING_MODES;
    public filterMode = FILTERING_MODES.ALL;
    public groupedFacetListKeys: Map<number, string> = new Map<number, string>();
    public selectedGroup: { key: number, value: string };
    constructor(private dialog: MatDialog,
        private _cdRef: ChangeDetectorRef,
        private reportPersist: ReportPersistService,
        private tabSettings: TabSettingsService,
            public tableHeaderService: TableHeaderService) {
        super();
    }
    private _filterFacetList(term = ''): IFacet[] {
        return this.originalFacetList.filter(facet => searchForText(term.toLowerCase(), facet.display.toLowerCase()));
    }

    private getFacetGroups(facetList: IFacet[]): Map<string, any[]> {
        let map = new Map();

        var rootFacetCollection = facetList.filter(facet => !facet?.nestedPath);

        this.groupedFacetListKeys.set(1, "");
        map.set("", rootFacetCollection);
        // this.groupedFacetListKeys.set(2, "0");
        // this.groupedFacetListKeys.set(3, "1");
        // map.set("0", rootFacetCollection);
        // map.set("1", rootFacetCollection);

        this.tab?.nestedPathsKeys?.forEach(nestedPathKey => {
            let keyName = Object.keys(nestedPathKey)[0];

            const splitedKeyName = nestedPathKey[keyName].split('-');
            this.groupedFacetListKeys.set(+splitedKeyName[0], splitedKeyName[1]);

            var facetCollection = facetList.filter(facet => facet?.nestedPath?.split('.')[0] === keyName);
            map.set(splitedKeyName[1], facetCollection);

        });

        this.groupedFacetListKeys = new Map([...this.groupedFacetListKeys.entries()].sort((a, b) => {
              if (a > b) return 1;
              if (a == b) return 0;
              if (a < b) return -1;
          }
        ));
        return map;
    }

    public async ngOnInit() {
        this.tab = await this.tabSettings.getCurrentTab();
        const columnsDictionary = await this.tabSettings.getColumnDictionary();

        if (this.reportPersist.mainQueryService?.filters[0]) {
            this.filterMode = this.reportPersist.mainQueryService?.filters[0]?.filterMode;
        }

        this.subscriptions.push(
            this.reportPersist.mainViewState.oState.subscribe(mainViewState => {
                let facets = mainViewState.facets;
                if (!!facets) {
                    this.allKey = this.tab.key;
                    let facetNames = [];
                    if (mainViewState.isDefaultFacets) {
                        facetNames = this.tab.defaultFacets
                    } else if (facets) {
                        facetNames = facets.map(f => f.name);
                    }

                    this.originalFacetList = facetNames.map((f) => columnsDictionary[f] as IFacet).filter(f => f != null);
                    const filtered = this._filterFacetList(this.searchTerm);

                    this.goupedFacetList = this.getFacetGroups(filtered);
                    if (this.goupedFacetList.size === 1) {
                        this.selectedGroup = { key: 1, value: "" }
                    }
                    disLogger("goupedFacetList", this.goupedFacetList);
                    this._cdRef.detectChanges();
                }
            })
        );


    }

    public getNextGroupByKey(item: IFacet, transformer: (item) => string = (x => x)): string {
        const paths = transformer(item?.display)?.split('-');
        return (!!paths && paths.length > 1) ? paths[0].trim() : '';
    }

    public getShortDisplayTransform(key: string): (item) => string {
        return (item: string) => {
            let result = key.length == 0 ? item : item.trim().substring(key.length + 1).trim();

            if (result.startsWith('-')) {
                result = result.substring(1).trim();
            }
            return result;
        }
    }

    public trackByKey<K, V>(i: number, keyValue: KeyValue<K, V>): K {
        return keyValue.key;
    }

    public trackByField(i: number, item: IFacet): string {
        return item.fieldName;
    }


    public trackByLength(i: number, item: IFacet[]): number {
        return item.length;
    }



    // public async openFilterSettingsDialog() {
    //     const obj = await this.openColumnsSettingsDialog(this.originalFacetList as any).pipe(take(1)).toPromise();

    //     if (obj) {
    //         const newColumns: IColumnSetting[] = obj.rightColumns;
    //         if (newColumns && newColumns.length > 0) {
    //             let facets = this.reportPersist.mainViewState.getState().facets;
    //             this.reportPersist.mainViewState.setValue({ facets: facets }, false);
    //             this.reportPersist.mainViewState.setValue({ isDefaultFacets: obj.isDefault }, false);
    //         }
    //     }
    //     this._cdRef.markForCheck();
    // }

    // public hasFacetsOrAdvancedFilters() {
    //     return this.facetList.length !== 0 || this.queryService.filters.findIndex(f => (f.type == 'All' || f.type == 'Any')) > -1;
    // }

    // public openColumnsSettingsDialog(reportColumns: IColumnSetting[], isColumns = false) {
    //     return this.dialog.open(ColumnSettingsCustomizationDialog, {
    //         panelClass: 'column-settings-customization-dialog-container',
    //         data: {
    //             reportColumns,
    //             isColumns,
    //             tabSettings: this.tabSettings,
    //             queryService: this.queryService
    //         },
    //         autoFocus: false
    //     }).afterClosed();
    // }

    // public updateFilteringMode() {
    //     this.filterMode = this.filterModeAllChecked ? FILTERING_MODES.ALL : FILTERING_MODES.ANY;
    //     this.reportPersist.mainQueryService.filters[0].filterMode = this.filterMode;
    //     this.reportPersist.mainQueryService.refresh();
    // }
}

