export interface IMapPoint {
    XCoord: number;
    YCoord: number;
    Title: string;
    Icon: string;
    Color: string;
    Data: any;
}

export interface Point {
    latitude: number;
    longitude: number;
}
