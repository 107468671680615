<div id="column-settings-customization">

    <div class="header color-primary-bg">
        <div class="title"> {{dialogInputSettings.selectedSetting}}</div>
        <mat-icon (click)="close()">close</mat-icon>
    </div>
    <div class="content">
        <ng-container *ngIf="dialogInputSettings.selectedSetting === 'Display Columns' || dialogInputSettings.selectedSetting === 'Side Filters'">
            <left-right-columns-settings class="columns "
                [label]="(dialogInputSettings.isColumns)?'Selected for Report': 'Dynamic filters'"
                [allColumns]="allColumns" [rightColumns]="rightColumns"
                [defaultLabel]="(dialogInputSettings.isColumns)?'' : 'Reset filters'"
                [initialIsDefaultSelected]="(dialogInputSettings.isColumns)? isDefaultColumns : isDefaultFilters"
                [filterType]="dialogInputSettings.isColumns" (rightColumnsOutput)="updateColumnDisplay($event)"
                (setDefaultSettingsEvent)="setDefaultColumns($event)">
            </left-right-columns-settings>
        </ng-container>
        <ng-container *ngIf="dialogInputSettings.isColumns && dialogInputSettings.selectedSetting === 'Sorting'">
            <sorting (sortFieldChanged)="sorts=$event" (defaultSort)="isDefaultSorts=$event"
                [mainViewState]="dialogInputSettings.mainViewState" [queryService]="dialogInputSettings.queryService"
                [tabSettings]="dialogInputSettings.tabSettings">
            </sorting>
        </ng-container>
        <ng-container
            *ngIf="currentApp !== 'obi' && dialogInputSettings.isColumns && dialogInputSettings.selectedSetting === 'Grouping'">
            <div class="slide-toggle-area">
                <mat-slide-toggle [ngModel]="showGroupFooter" (change)="showGroupFooter = !showGroupFooter">
                    Show Groups Footers
                </mat-slide-toggle>
            </div>
            <grouping (groupFieldChanged)="groups=$event" [groups]="dialogInputSettings.groupByColumns"
                [selectedView]="dialogInputSettings.selectedView" [tabSettings]="dialogInputSettings.tabSettings">
            </grouping>
        </ng-container>
        <ng-container *ngIf="columnsToFlattenData.length > 0 && dialogInputSettings.isColumns && dialogInputSettings.selectedSetting === 'Data Flatten By'">
            <div class="dataFlattenBy-container">
                <mat-form-field *ngIf="columnsToFlattenData.length > 0 " class="mat-form-field-column">
                    <mat-select #select placeholder="Select Field" [(value)]="dataFlattenBy">
                        <mat-option *ngFor="let group of columnsToFlattenData" [value]="group"
                            (click)="setFlattenDataBy(group)">
                            {{group}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-icon *ngIf="dataFlattenBy" class="dataFlattenBy-clear-button" (click)="setFlattenDataBy('')">clear</mat-icon>
            </div>
        </ng-container>
    </div>
    <mat-divider></mat-divider>
    <div class="footer">
        <button mat-stroked-button class="cancel rounded" (click)="close()">
            <mat-icon>cancel</mat-icon>
            Cancel
        </button>
        <button mat-flat-button class="create rounded" color="primary" (click)="save()">
            <mat-icon>save</mat-icon>
            Save
        </button>
    </div>
</div>