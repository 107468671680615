<div class="dialog-container">
    <div class="message">
        <span>
            {{data.message}}
        </span>
    </div>
    <div class="buttons-row">
        <button *ngIf="data.isConfirmation" mat-button class="round-button" (click)="close(true)">
            YES
        </button>
        <button mat-button class="round-button" (click)="close()">
            Cancel
        </button>
    </div>
</div>
