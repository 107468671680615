import { Observable, Subscription, Subject, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, auditTime, tap, first, take } from 'rxjs/operators';

import { DiscovererQueryService } from './discoverer-query-service.service';
import { IFilterSortDataService } from './ifilter-sort-data-service.service';
import { FacetFieldAccumulator } from '../classes/facet-field-accumulator.class';

import { DRQuery, DRResult } from '../classes/discoverer.class';

import { IDRFilter, IState } from '../classes';

import { HttpClient } from '@angular/common/http';
import { LoadingState } from '../classes/loading-state';
import { EsQueryService } from './discoverer-esquery-service';
import { disLogger } from '../functions';

export interface Factory<K> {
    create(row: K, dataFlattenBy?: string): K[];
}

export class NoDataRow implements Factory<NoDataRow> {
    create(row: NoDataRow): NoDataRow[] {
        return null;
    }
}

export class ExactDataRow implements Factory<ExactDataRow> {
    create(row: any, dataFlattenBy?: string) {
        return row;
    }
}

export class DiscovererDataService<T> extends IFilterSortDataService<T> {

    public oData: Observable<T[]>;
    public oFacetResults: Observable<FacetFieldAccumulator[]>;
    public oResultLength: Observable<number>;
    public oLoadingStatusResult: Observable<LoadingState>;
    public independent = false;

    protected _enabled = true;
    protected _filterRequests = false;
    protected _queryStatsEnabled = false;
    protected _serviceUrl: string;

    // facets
    protected _dataRequested: { [key: string]: boolean; } = {};
    protected _statRequested: { [key: string]: boolean; } = {};
    protected _statFacets: { [key: string]: any; } = {};
    protected _dataFacets: { [key: string]: any; } = {};

    protected _sData: Subject<T[]>;
    protected _sFacetResults: Subject<FacetFieldAccumulator[]>;
    protected _sResultLength: Subject<number>;
    protected _bsLoadingStatus: BehaviorSubject<LoadingState>;

    protected queryService: DiscovererQueryService;


    private _subscriptions: Subscription[] = [];

    private baseQuery: DRQuery = new DRQuery();
    private http: HttpClient;

    constructor(http: HttpClient, private esQueryService: EsQueryService, private dataRowFactory?: Factory<T>, _independent = false) {
        super();
        this.http = http;
        this._sData = new ReplaySubject(1);
        this._sFacetResults = (new ReplaySubject(1) as Subject<FacetFieldAccumulator[]>);
        this._sResultLength = (new ReplaySubject(1) as Subject<number>);
        this._bsLoadingStatus = new BehaviorSubject<LoadingState>({ status: null });
        this.oData = this._sData.asObservable();
        this.oResultLength = this._sResultLength.asObservable();
        this.oFacetResults = this._sFacetResults.asObservable();
        this.oLoadingStatusResult = this._bsLoadingStatus.asObservable();
        this.independent = _independent;

        // this._sResultLength.subscribe(value => {
        //     console.log('_sResultLength data ========================================== ', value);
        // });
    }

    public setOrderBy(column: string, dir?: 'asc' | 'desc') {
        if (!!this.queryService) {
            this.queryService.setOrderBy(column, dir);
        } else {
            super.setOrderBy(column, dir);
        }
    }

    public unsetOrderBy(column: string) {
        if (!!this.queryService) {
            this.queryService.unsetOrderBy(column);
        } else {
            super.unsetOrderBy(column);
        }
    }

    public setFilter(filterName: string, filter: IDRFilter) {
        if (!!this.queryService) {
            this.queryService.setFilter(filterName, filter);
        } else {
            super.setFilter(filterName, filter);
        }
    }

    public unSetFilter(filterName: string) {
        if (!!this.queryService) {
            this.queryService.unSetFilter(filterName);
        } else {
            super.unSetFilter(filterName);
        }
    }

    get baseQueryService(): DiscovererQueryService {
        return this.queryService;
    }

    get changeId(): string {
        return this.baseQuery.changeId + '-' + this._changeId;
    }

    get enabled(): boolean {
        return this._enabled;
    }

    set enabled(value: boolean) {
        this._enabled = value;
    }

    // filter requests if the fields are not empty
    get filterRequests(): boolean {
        return this._filterRequests;
    }

    set filterRequests(value: boolean) {
        this._filterRequests = value;
    }

    get queryStatsEnabled(): boolean {
        return this._queryStatsEnabled;
    }

    set queryStatsEnabled(value: boolean) {
        this._queryStatsEnabled = value;
    }

    public destroy() {
        this.unsubscribeAll();
    }

    public init(serviceUrl: string, queryService: DiscovererQueryService, name?: string) {
        this._serviceUrl = serviceUrl + '?' + (!!name ? 'data=' + name.replace(/[ ]/g, '-') : '') +
            (!!queryService.name ? '&qname=' + queryService.name.replace(/[ ]/g, '-') : '');

        this.unsubscribeAll();

        this.queryService = queryService.createChildService(queryService.name + '-' + name);

        this._subscriptions.push(this.queryService.updateSubscription);

        this._subscriptions.push(this.queryService.oQuery.subscribe(query => {
            (this.independent) && (query.filters = []);
            this.baseQuery = query; // save base query
            if (this.enabled) {
                this.refresh();
            }
        }));

        this._subscriptions.push(this.queryService.oSelectedStats.subscribe(selectedStats => {
            if (this.queryStatsEnabled) {
                this.resetStatRequested();
                selectedStats.forEach(s => {
                    this.setStatRequested(s.name, true);
                });
                if (this.enabled) {
                    this.refresh();
                }
            }
        }));

        this._subscriptions.push(this.oQuery.pipe(auditTime(60)).subscribe(request => {
            if (this.enabled && request.changeId !== '-1') {
                if (!this._filterRequests || (this._filterRequests && request.fields.length !== 0)) {
                    this.queryData(request);
                }
            }
        }));
    }

    private unsubscribeAll() {
        let sub = null;
        while (this._subscriptions.length > 0) {
            sub = this._subscriptions.pop();
            if (!!sub) {
                sub.unsubscribe();
            }
        }
    }

    public get serviceUrl() {
        return this._serviceUrl;
    }

    public refresh(refreshBase: boolean = false): Promise<void> {
        if (refreshBase) {
            this.queryService.refresh();
            return;
        }
        const query = this.getCurrentQueryCopy();
        query.size = this.pageSize;
        query.start = this.start - 1;
        query.changeId = this.changeId;
        this._sQuery.next(query);
    }

    public getCurrentQueryCopy() {
        const query = new DRQuery();
        query.filters = this.baseQuery.filters;
        query.sorts = this.baseQuery.sorts;

        query.fields = this.fields;
        query.facets = Object.keys(this._dataRequested).filter(x => this._dataRequested[x] === true)
            .map(s => ({ type: 'named', name: s }));
        query.stats = Object.keys(this._statRequested).filter(x => this._statRequested[x] === true)
            .map(s => ({ type: 'named', name: s }));

        query.stats = query.stats.concat(Object.keys(this._statFacets).map(k => this._statFacets[k]));
        query.facets = query.facets.concat(Object.keys(this._dataFacets).map(k => this._dataFacets[k]));
        query.groups = this.groups;
        query.dataFlattenBy = this.baseQuery.dataFlattenBy;
        return query;
    }

    public setDataIsRequested(facetName: string, isRequested: boolean) {
        this._dataRequested[facetName] = isRequested;
    }

    public setFacet(forName: string, facetDef: any) {
        this._dataFacets[forName] = facetDef;
        this._changeId++;
    }

    public resetFacets() {
        this._dataFacets = []
        this._changeId++;
    }

    public resetDataRequested() {
        this._dataRequested = {};
        this._changeId++;
    }

    public resetStatRequested() {
        this._statRequested = {};
        this._changeId++;
    }
    public resetStatFacet() {
        this._statRequested = {};
        this._statFacets = [];
        this._changeId++;
    }

    public setStatRequested(forName: string, isRequested: boolean) {
        this._statRequested[forName] = isRequested;
        this._changeId++;
    }

    public setStat(fieldName: string, functionType: string) {
        const statDef: IState = {
            type: 'stat',
            name: functionType + fieldName,
            field: fieldName,
            functionType: functionType
        };
        this._statFacets[functionType + fieldName] = statDef;
        this._changeId++;
    }
    
    public setResultLength( resultLength: number) {
        this._sResultLength.next(resultLength);
    }

    private async queryData(query: DRQuery) {
        const sendQuery = query;
        const randomSeed = this.queryService.randomSeed;
        if(randomSeed){
            sendQuery.randomSeed = randomSeed;
        }
        disLogger('dataservice ------------ sending query');
        this.esQueryService.updateQuery(sendQuery, this.serviceUrl, this.queryService.name);
        let loadingState: LoadingState = { status: "Busy" };
        this._bsLoadingStatus.next(loadingState);
        const req: Observable<DRResult<T>> = this.http
            .post(this._serviceUrl, sendQuery)
            .pipe(
                map((res: any) => {
                    const obj: DRResult<T> = new DRResult<T>();
                    Object.assign(obj.responseHeader, res.responseHeader);
                    Object.assign(obj.facets, res.facets);
                    obj.queryChangeId = res.queryChangeId;
                    obj.response.numFound = res.response?.numFound;
                    obj.response.start = res.response?.start;
                    obj.esQuery = res.query;
                    obj.response.docs = res.response?.docs.map(
                        (doc: any) => this.dataRowFactory.create(doc, sendQuery.dataFlattenBy)
                    ).reduce((r, e) => (r.push(...e), r), []);
                    return obj;
                }),
                tap({
                    next: (x) => {
                        loadingState = { status: "Success" };
                    },
                    error: (err) => {
                        console.log('tap error', err);
                        loadingState = { status: "Failure", errorDescription: err };
                    },
                    complete: () => {
                        this.reaiseState(loadingState);
                    }
                })
                // finalize(() => { console.log("loadingState : ",loadingState);  })
            );

        const request = await req.toPromise();


        if (request && query.changeId === this.changeId) {
            if (request.response) {
                const hasFlatten = this.queryService.getMergedDataFlattenBy() != '';
                if(!hasFlatten) {
                    this._sResultLength.next(request.response?.numFound);
                } else {
                    if (request.response.docs.length === 0 ) {
                        this._sResultLength.next(0);
                    } else {
                        this._sResultLength.next(-1);
                    }
                }
                this._sData.next(request.response.docs);
            }

            const result: FacetFieldAccumulator[] = [];
            const statsAccum = new FacetFieldAccumulator('stats');
            result.push(statsAccum);
            Object.keys(request.facets).forEach(facet => {
                if (request.facets[facet] != null && request.facets[facet].buckets !== undefined) {
                    const accum = new FacetFieldAccumulator(facet);
                    const buckets = request.facets[facet].buckets;

                    buckets.forEach((b: any) => {
                        // to remove unwanted part of hireDate
                        if (facet === 'hireDates' && b.key) {
                            b.key = b.key.toString().substring(0, 4);
                        }
                        accum.accumValue(b.key, b.value, 'count');
                        accum.accumValue(b.key, b.stat1, 'stat1');
                        accum.accumValue(b.key, b.stat2, 'stat2');
                        accum.accumValue(b.key, b.stat3, 'stat3');
                        accum.accumValue(b.key, b.stat4, 'stat4');
                    });
                    if (!!request.facets[facet].before) {
                        accum.accumValue('before', request.facets[facet].before.value);
                    }
                    if (!!request.facets[facet].after) {
                        accum.accumValue('after', request.facets[facet].after.value);
                    }
                    if (!!request.facets[facet].between) {
                        accum.accumValue('between', request.facets[facet].between.value);
                    }

                    result.push(accum);

                } else if (request.facets[facet] != null && !isNaN(parseFloat(request.facets[facet]))) {
                    statsAccum.accumValue(facet, Number(request.facets[facet]));
                }

            });
            this._sFacetResults.next(result);
        }
    }

    private reaiseState(state: LoadingState) {
        if (state.status == "Busy")
            state = { status: "Failure", errorDescription: "Something went wrong during your request !" };
        this._bsLoadingStatus.next(state);
    }

}
