import { IDRFilter } from './filter.interface';
import { ChartConfig } from './chart-config.class';

export interface Request {
    id: string;
    userId: number;
    requestName: string;
    filters: IDRFilter[];
    customChart: ChartConfig[];
}

export interface Directory {
    id: number;
    userId?: number;
    name: string;
    requests?: Request[];
}
