
import { JsonpClientBackend } from '@angular/common/http';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { max, min } from 'lodash';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FacetFieldAccumulator } from '../classes/facet-field-accumulator.class';
import { LoadingState } from '../classes/loading-state';
import { DiscovererDataService } from './discoverer-data-service.service';
import { DiscovererQueryService } from './discoverer-query-service.service';
import { disLogger } from '../functions';

@Injectable()
export class CachedIgniteDataService<T> implements OnDestroy {

  public oData: Observable<T[]>;
  protected _sData: Subject<T[]>;

  public oFacetResults: Observable<FacetFieldAccumulator[]>;
  public oResultLength: Observable<number>;
  public oLoadingStatusResult: Observable<LoadingState>;

  protected _bsLoadingStatus$: BehaviorSubject<LoadingState>;



  protected _cache: any[] = [];
  protected _cacheRequests: { start: number, fillStart: number, filled: boolean, requestedOn: Date }[] = [];

  protected _currentPage: number = 0;
  protected _fetchSize: number = 0;

  protected _requestStart: number = 0;
  protected _requestSize: number = 20;
  protected _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(public dataService: DiscovererDataService<T>) {
    this._sData = new ReplaySubject(1);
    this.oData = this._sData.asObservable();

    this._bsLoadingStatus$ = new BehaviorSubject<LoadingState>({ status: "Busy" });
    this.oLoadingStatusResult = this._bsLoadingStatus$.asObservable();

    this.oFacetResults = dataService.oFacetResults;
    this.oResultLength = dataService.oResultLength;
    this.dataService.enabled = false;


    this.dataService.oData
   .pipe(takeUntil(this._unsubscribeAll)).subscribe( async data => {
      var resultLength = await this.dataService.oResultLength.pipe(first()).toPromise();

      var matchRequest = this._cacheRequests.find(x => x.start === (this.dataService.start - 1) && x.filled === false);
      if (!!matchRequest) {
        matchRequest.filled = true;
        data.forEach((v, i) => this._cache[i + matchRequest.fillStart] = v);
        if (resultLength === -1) {
          this.dataService.setResultLength(-1 * (data.length + matchRequest.fillStart));
        }
        else if (data.length === 0) {
          this.dataService.setResultLength(data.length + matchRequest.fillStart);
        } else {
          this.dataService.setResultLength(resultLength);
        }

        this.refresh();
      }
      console.log(';;;  found=' + !!matchRequest);

    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
  }

  public init(serviceUrl: string, queryService: DiscovererQueryService, name?: string) {
    this.dataService.init(serviceUrl, queryService, name);
    this.dataService.enabled = false;


    this.dataService.baseQueryService.oQuery.pipe(takeUntil(this._unsubscribeAll)).subscribe(s => {
      this._requestStart = 0;
      this._requestSize = 20;
      this._fetchSize = 40;
      this._currentPage = 0;
      this._cache = Array.apply(null, Array(5000));
      this._cacheRequests = [];
      this.refresh();
    });

    this.dataService.oLoadingStatusResult.pipe(takeUntil(this._unsubscribeAll)).subscribe(state => {
      this._bsLoadingStatus$.next(state);
    });
  }
  public async initCacheLength(length: number) {
    this._cache.length = length || 0;
  }

  public setPageParams(pageNumber: number, pageSize: number) {
    this.setPageStart((pageSize * (pageNumber - 1)), pageSize);
  }

  public setPageStart(start: number, pageSize: number) {
    console.log(';;; start=' + start + ', pageSize=' + pageSize);

    this._requestStart = start;
    this._requestSize = pageSize;
  }
  public refresh() {
    let fillStart = this._requestStart < 0 ? 0 : this._requestStart;

    // Cache hit or partial hit
    let sizeOfItems = this._requestStart + this._requestSize;
    sizeOfItems = Math.min(this._cache.length, sizeOfItems);

    if (sizeOfItems > 0) {
      const cacheData = this._cache.slice(this._requestStart, sizeOfItems);
      const firstNull = cacheData.findIndex(x => x == null);

      if (firstNull < 0) {
        this._sData.next(cacheData);
        return
      } else {
        // Partial cache hit: Push partial data and fetch the remaining
        (firstNull > 0) && this._sData.next(cacheData.slice(0, firstNull));
        fillStart = firstNull + this._requestStart;
      }
    }
    // Cache miss, fetch the next page
    this.fetchNextPage(fillStart);
  }
  private fetchNextPage(fillStart: number) {
    const fetchStart = this._currentPage * this._fetchSize;
    if ( !!this._cacheRequests.find( x=> x.fillStart === fillStart ))
        return;

    this.dataService.setPageStart(
        fetchStart + 1,
        this._fetchSize
    );
    this._currentPage++;
    this._cacheRequests.push({ start: fetchStart, fillStart: fillStart , filled: false, requestedOn: new Date() });
    this.dataService.refresh();
  }


}
