<ng-container *ngIf="loading" class="loading-area">
    <ngx-skeleton-loader *ngIf="loading" count="1" animation="progress-dark" [theme]="{
        height: '100%',
        opacity: '0.8'
      }">
    </ngx-skeleton-loader>
</ng-container>
<ng-container *ngIf="!loading">
    <iframe *ngIf="!isDownloadFileView" width="100%" height="100%" [src]="originalFileUrl"></iframe>
    <!-- ... -->
    <div (click)="downloadFile()" class="download-file-view" *ngIf="isDownloadFileView">
        <div class='title'>
            Download File
        </div>
        <div *ngIf="fileName && fileExtension" class="file-info">
            {{fileName}}.{{fileExtension}}
        </div>
        <button class="download-button">
            <mat-icon>cloud_download</mat-icon>
        </button>
    </div>
</ng-container>