import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PickerInteractionMode } from 'igniteui-angular';
import { BaseCustomController } from '../shared/base-custom-controller';

@Component({
  selector: 'app-dyn-time-picker',
  templateUrl: './dyn-time-picker.component.html',
  styleUrls: ['./dyn-time-picker.component.scss']
})
export class DynTimePickerComponent extends BaseCustomController {
  public mode: PickerInteractionMode = PickerInteractionMode.DropDown;
  public format = 'hh:mm:ss tt';

  get control() {
    return this.field.formControl as FormControl
  }

  returnDate() {
    try {
      const date = new Date(this.field.formControl.value);
      return formatDate(date, 'YYYY-MM-dd hh:mm:ss a', "en-US");
    } catch (e) {
      return '';
    }
  }

}
