import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { buildFacetFilter, DiscovererQueryService, IDRFilter } from '@discoverer/core/services';

@Component({
    selector: 'disco-related-list-view',
    templateUrl: './disco-related-list-view.component.html',
    styleUrls: ['./disco-related-list-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscoRelatedListViewComponent implements OnInit {

    @Input() public appId = '';
    @Input() public tabId = '';
    @Input() public visibleDetailsHeader = false;
    @Input() public requestId = null;

    @Input() public filterField: string = null;
    @Input() public filterValue: any = null;

    @Input() public enableNavigation: boolean = true;
    @Input() public enableMultiSelect: boolean = false;

    filters: { [key: string]: IDRFilter; } = {};

    constructor(
        public queryService: DiscovererQueryService,
        private cdRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        if (!!this.filterField && !!this.filterValue) {
            this.filters[this.filterField] = buildFacetFilter(this.filterField, [this.filterValue]);
            this.cdRef.markForCheck();
        }
    }

    public navigateToReport() {

        window.open(`${this.appId}/reports/${this.tabId}/${this.requestId}?${encodeURI(this.filterField)}=${encodeURI(this.filterValue)}`, this.requestId );
    }

}
