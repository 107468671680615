import {
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    BaseController,
    Permission,
    ReportPersistService,
    TabSettingsService,
    UserPermissionService,
} from '@discoverer/core/services';
import {
    Subject
} from 'rxjs';

@Component({
    selector: 'main-title',
    templateUrl: './main-title.component.html',
    styleUrls: ['./main-title.component.scss']
})
export class MainTitleComponent extends BaseController implements OnInit {
    @Input() enableEdit: boolean = true;
    public canEdit: boolean = false;
    public mainTitle: string;
    public isEditMode = false;
    public hideReportControlFeatures = false;

    @Input() private currentTabKey: string;
    @ViewChild('TITLE') title;
    private reportNameSubject: Subject<string> = new Subject();


    constructor(
        private _reportPersist: ReportPersistService,
        private _cdRef: ChangeDetectorRef,
        private _userPermissionService: UserPermissionService,
        private _tabSettingService: TabSettingsService,
    ) {
        super();
    }

    public handlePaste(event: ClipboardEvent): void {
        event.preventDefault();
        const clipboardData = event.clipboardData;
        const pastedText = clipboardData.getData('text/plain');
        this._titleChange(pastedText)
    }
    async ngOnInit() {
        if (this.currentTabKey !== 'dashboard') {
            const tab = (await this._tabSettingService.getCurrentTab());
            this.currentTabKey = tab?.key;
            this.hideReportControlFeatures = tab?.hideReportControlFeatures;
        }
        this.subscriptions.push(this._reportPersist.mainViewState.oState.subscribe(async (requestLoaded) => {
            this.mainTitle = requestLoaded.mainTitle.trim();
            const requestOwnerUserId = requestLoaded?.userId;
            const dirId = requestLoaded?.dirId;
            const resourceKey = `dirId:${dirId}/requestOwnerUserId:${requestOwnerUserId}/requestId:${this._reportPersist.currentReqId}`;
            this.canEdit = await this._hasPermission(Permission.EditReport, resourceKey);
        }));
        this.subscriptions.push(this.reportNameSubject.subscribe(textValue => {
            this.mainTitle = textValue.trim();
            this._reportPersist.mainViewState.setValue({ mainTitle: this.mainTitle }, false);
        }));
    }
    public updateTitle(savedItem: string) {
        this.isEditMode = false;
        this._titleChange(savedItem);
    }
    public turnEditModeOn() {
        this.isEditMode = true;
        this._cdRef.detectChanges();
        this.title.nativeElement.focus()
    }

    private _titleChange(textValue: string) {
        this.reportNameSubject.next(textValue);
    }
    private async _hasPermission(permissionKey: string, resourceKey?: string): Promise<boolean> {
        return await this._userPermissionService.hasPermission(this.currentTabKey, permissionKey, resourceKey);
    }

}
