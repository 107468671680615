<div class="auto-complete-wrapper">

    <input [tabIndex]="to?.tabindex" [formControl]="searchControl" (blur)="isOpen=false" (focus)="onFocus()"
        (keyup)="onFocus()" (keydown)="onKeyDown($event)" autocomplete="off" class="searchInput" matInput #searchText />
    <mat-select [formControl]="$any(field)?.formControl" style="height: 0px;" [hidden]="field.formControl.value"
        (closed)="isOpen = false" #dropDown disableOptionCentering [formlyAttributes]="field"
        [compareWith]="compareWithFn" [panelClass]="to.panelClass" class="custom-select" [disabled]="field.formControl.value">
        <mat-option *ngFor="let option of filteredOptions" [value]="to.useOption ?   option : option[valueMember]"
            [matTooltip]="showToolTip ? getDisplayValue(option) : null" matTooltipClass="custom-tooltip">
            {{getDisplayValue(option)}}
        </mat-option>
    </mat-select>
    <button mat-button type="button"
        *ngIf="field.formControl.value !== null && field.formControl.value !== undefined && !to.disabled"
        class="clear-button" [ngClass]="{'disabled': to.disabled}" matSuffix mat-icon-button aria-label="Clear"
        (click)="clearSelection()">
        <mat-icon>close</mat-icon>
    </button>
    <mat-spinner *ngIf="isLoading" diameter="30"></mat-spinner>
</div>