<div id="app-root" class="scroll-wrapper">
  <div *ngIf="!!currentTab">
    <div id="main-wrapper" class="main-view">
      <div class="container-fluid main-view-container" [style.height]="height">
        <div class="workspace-container height-100" >
          <div [@fadeInOutO]="!!selectedView?'in':'out'" class="scrollable-area height-100">
            <one-chart-view #chart_view *ngIf="selectedView === 'chart-view'" [visibleDetailsHeader]="visibleDetailsHeader"
              [queryService]="reportPersist.mainQueryService" [chartState]="reportPersist.chartState"
              [chartMaxLabels]="chartMaxLabels">
            </one-chart-view>

            <div class="height-100" *ngIf="selectedView === 'pivot-view'">
              <pivot-chart-wrapper [allowEditMode]="isEditMode" #pivot_view [visibleDetailsHeader]="visibleDetailsHeader"
                *ngIf="!!reportPersist.pivotChartState && !!reportPersist.mainQueryService"
                [pivotState]="reportPersist.pivotChartState" [queryService]="reportPersist.mainQueryService">
              </pivot-chart-wrapper>
            </div>
            <kendo-table-view #table_view [isEditMode]="isEditMode" *ngIf="selectedView === 'table-view'" (cellClickedEvent)="navigateToDetails($event)"
             [isPreview]="isPreview" (numberOfRecordsEvent)="sendTotalNumberOfRecords($event)">
            </kendo-table-view>
            <kanban-view [isPreview]="true" *ngIf="selectedView === 'kanban-view'" [queryService]="reportPersist.mainQueryService" [kanbanViewState]="reportPersist.kanbanViewState"></kanban-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
