import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Card, TabSettingsService } from '@discoverer/core/services';



@Component({
  selector: 'kanban-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KanbanCardComponent implements OnInit {

  @Input() card: Card;
  private requestId
  private appId
  private tabId

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private tabSettings: TabSettingsService,
  ) {
    this.requestId = this.activatedRoute.snapshot.paramMap.get('id');
    this.appId = this.activatedRoute.snapshot.data.appId;


  }

  async ngOnInit() {
    this.tabId =(await this.tabSettings.getCurrentTab()).key;
  }


  public async navigateToDetails() {

    this.router.navigate([{ outlets: { dialog: [this.appId, 'detail', this.tabId, this.card.id ] } }]);
  }
}
