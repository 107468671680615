import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
} from '@angular/core';
import {
    StateService,
    DateGroupBy,
    DiscovererDataService,
    NumberGroupBy,
    FacetFieldAccumulator,
    DiscovererQueryService,
    IPivotConfig,
    ICategories,
    IColumnSetting,
    DefaultPivotLimit,
    roundUpToHighestDigit,
    getNearestValueToGap,
} from '@discoverer/core/services';

import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseChartDetails } from '../base-chart-details';
import { cloneDeep } from '@discoverer/core/services';
import { HttpClient } from '@angular/common/http';

import {
    TabSettingsService,
    IMainViewState,
    ReportPersistService,
} from '@discoverer/core/services/dynamic-reports-services';
import { ChartAxis } from '../../models';
import { NUMBER_OF_ROWS_METRIC } from '@discoverer/core/services';
import { EsQueryService } from '@discoverer/core/services/core-data-services/discoverer-esquery-service';
import { PivotChartComponent, ViewTypes } from '../../common';
import { PivotChartControlService } from '@discoverer/dynamic-reports/common/charts-control/chart-control.service';

@Component({
    selector: 'pivot-chart-details',
    templateUrl: './pivot-chart-details.component.html',
    styleUrls: ['./pivot-chart-details.component.scss'],
    providers: [{ provide: PivotChartControlService }]
})
export class PivotChartDetailsComponent extends BaseChartDetails implements OnInit {

    public displayMode: 'Values' | 'Percentage' = 'Percentage';
    @Input() public pivotState: StateService<IPivotConfig>;
    @Input() public expandRows: boolean;
    @Input() public isEditMode: boolean;
    @Input() public visibleDetailsHeader: boolean = true;
    public metricList = ['sum', 'avg', 'min', 'max', 'count'];
    @Output() public isValid: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('PIVOT_CHART') public pivotChart: PivotChartComponent;
    public chartType: ViewTypes.Pivot = ViewTypes.Pivot;
    public queryService: DiscovererQueryService;

    constructor(
        private reportPersist: ReportPersistService,
        public pivotChartControlService: PivotChartControlService
    ) {
        super();
    }

    public async ngOnInit() {
        this.queryService = this.reportPersist.mainQueryService;
    }

    public checkPivotStatus(e: boolean) {
        this.isValid.emit(e);
    }

    public exportToExcel() {
        this.pivotChart.exportToExcel();
    }

    public changePeriod(previous = false) {
        this.pivotChart.changePeriod(previous);
    }
}
