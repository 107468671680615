import { Injectable } from '@angular/core';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs';


@Injectable()
export class ComponentUtilityService {
    constructor(
        private matSnackBar: MatSnackBar
    ) {
    }

    public openMessageDialog(title: string, message: string, showButton?: boolean) {
        return of(message);
    }

    public openSnackBar(message: string) {
        this.matSnackBar.open(message, '', {
            duration: 3000,
        });
    }



}

