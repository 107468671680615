export class FileInfoDto {
    fileId: string
    taskId?: string
}

export class FileLog {
    stepName: string
    jsonObject: any
    isCopied: boolean
}

export class FileDetailsDto {
    Id: string;
    DatasetKey: string;
    FileTaskId: string;
    Status: FileStatusEnum;
    OriginalFileName: string;
    FileExtension: string;
    CreatedAt: Date;
    IsTest: boolean;

}


export enum FileStatusEnum{
    PROCESSING = 'PROCESSING',
    PROCESSED = 'PROCESSED',
    FAILED = 'FAILED'
}