import { IMetric } from '@discoverer/core/services';
import { ChartAxis, IChartType } from '@discoverer/dynamic-reports';

export class ChartControlState {
    public chartType: IChartType;
    public summarizeBy: SummarizeBy[] = [];
    public groupBys: ChartAxis[];
    public sort: ChartSort;
    public isLogarithmScale: boolean;
    public countSubRecords?: boolean;
}
export class SummarizeBy {
    public colName: string;
    public functionType: 'count' | 'sum' | 'avg' | 'min' | 'max' | 'stddev' | 'sumsq';
    public subTitle: string;
    public overrideField?: string;

}
export class PivotChartControlState extends ChartControlState {
    public summarizeBy: PivotSummarizeBy[] = [];
}

export class KendoChartControlState extends ChartControlState {
    public summarizeBy: KendoSummarizeBy[] = [];
}

export class PivotSummarizeBy implements SummarizeBy {
    public overrideField: string;
    public colName: string;
    public functionType: 'min' | 'max' | 'sum' | 'avg' | 'count' | 'stddev' | 'sumsq';
    public subTitle: string;
    constructor(metric: IMetric) {
        this.colName = metric.colName;
        this.functionType = metric.functionType;
        this.overrideField = metric.overrideField;
    }
}
export class KendoSummarizeBy implements SummarizeBy {
    public overrideField: string;
    public colName: string;
    public functionType: 'min' | 'max' | 'sum' | 'avg' | 'count' | 'stddev' | 'sumsq';
    public subTitle: string;
    constructor(metric: IMetric) {
        this.colName = metric.colName;
        this.functionType = metric.functionType;
        this.overrideField = metric.overrideField;
    }
}

export class ChartSort {
    public sortDir: ('asc' | 'desc');
    public sortType?: ('label' | 'value');
    public seriesSort?: string;
    public seriesSortDir?: ('asc' | 'desc');
    public subTitle?: string;
}

