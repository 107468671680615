import { Clipboard } from '@angular/cdk/clipboard';
import { Location } from "@angular/common";
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TabSettingsService } from "@discoverer/core";
import { combineLatest } from 'rxjs';
import { Field } from '../../helpers/docpipeline-function';
import { DatasetFileService } from '../../services/api/dataset-file.service';
import { EmitterService } from '../../services/emitter.service';
import { DocumentViewDetailsDirective } from "./document-view-details.directive";
import { FileDetailsHandlerService } from './file-details-handler.service';
const NESTED_TYPES = ['document', 'object', 'object_array']

@Component({
    selector: 'app-document-view-details',
    templateUrl: './document-view-details.component.html',
    styleUrls: ['./document-view-details.component.scss']
})
export class DocumentViewDetailsComponent extends DocumentViewDetailsDirective implements OnInit {
    public tabId: any;
    public columnList: any;
    public source: 'records' | 'queue';
    public fileKey: 'File.file_id' | 'Id';
    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        _datasetFileService: DatasetFileService,
        clipboard: Clipboard,
        location: Location,
        cdr: ChangeDetectorRef,
        _router: Router,
        _tabSettingService: TabSettingsService,
        _dialog: MatDialog,
        _snackBar: MatSnackBar,
        private _fileDetailsHandlerService: FileDetailsHandlerService
,
        _emitterService: EmitterService
    ) {
        super(data, _datasetFileService, clipboard, location, cdr, _router, _tabSettingService, _dialog, _snackBar,_emitterService);
        this.tabId = data.routeSnapshot.data['tabId'];
        this.columnList = data.routeSnapshot.data['columnList'];
        this.source = data.routeSnapshot.data['source'];
        this.fileKey = data.routeSnapshot.data['fileKey'];
    }
    public async ngOnInit() {
        await super.ngOnInit();
        this._subscribeToResults();
    }

    private _subscribeToResults(): void {
        this.fieldsLoading = true;

        this.subscriptions.push(combineLatest([
            this._fileDetailsHandlerService.oFileResults,
            this._fileDetailsHandlerService.schemaService.oCurrentSchema
        ]).subscribe(
            ([fileResults, currentSchema]) => this._processFields(fileResults, currentSchema),
            () => this.fieldsLoading = false
        ));
    }

    private _processFields(fileResults: any, currentSchema: any): void {
        try {
            this.fields = [];
            const firstFields = this._getFirstTwoFields(currentSchema);
            const titles = firstFields.map(fld =>
                fld.split('.').reduce((acc, key) => acc[key], fileResults)
            );
            this.fields.push(...titles);
            this.fieldsLoading = false;
        } catch (error) {
            console.error('Error processing fields:', error);
        }
    }

    private _getFirstTwoFields(schema: { [key: string]: Field }): string[] {
        const result: string[] = [];

        const findFields = (fields: Record<string, Field>, path: string): void => {
            for (const key in fields) {
                const field = fields[key];
                if (!NESTED_TYPES.includes(field.type)) {
                    result.push(`${path}.${key}`);
                }
                if (result.length === 2) break;
            }
        };

        for (const key in schema) {
            findFields(schema[key].fields, key);
            if (result.length === 2) break;
        }

        return result;
    }
}
