import { FormlyFieldConfig, FormlyForm } from '@ngx-formly/core';

export class DynFormConfiguration {
    fields: FormlyFieldConfig[];
    options: [];
}
export interface IDynForm {
    Key: string;
    Title: string;
    Configuration: string;
    Directory: string;
    RelativeDirectory: string;
    TargetUrl: string;
    Version: any;
}
export class DynForm {
    key: string;
    title: string;
    configuration: DynFormConfiguration;
    targetUrl: string;
    public constructor(obj: IDynForm) {
        this.key = obj && obj.Key || '';
        this.targetUrl = obj && obj.TargetUrl || '';
        this.title = obj && obj.Title || '';
        this.configuration = obj && obj.Configuration && JSON.parse(obj.Configuration) || new DynFormConfiguration();
        
        // this is a hack to remove the prefix from the labels
        this.configuration.fields.forEach((field: FormlyFieldConfig) => {
            if (field.fieldGroup && field.fieldGroup.length > 0) {
                field.fieldGroup.forEach((subField: FormlyFieldConfig) => {
                    if (subField.fieldGroup && subField.fieldGroup.length > 0) {
                        subField.fieldGroup.forEach((subSubField: FormlyFieldConfig) => {
                            if (subSubField.templateOptions && subSubField.templateOptions.label && subSubField.templateOptions.label.includes('-') )
                                subSubField.templateOptions.label = subSubField.templateOptions.label.split('-').reverse()[0].trim();
                        });
                    }
                    if (subField.templateOptions && subField.templateOptions.label && subField.templateOptions.label.includes('-') )
                        subField.templateOptions.label = subField.templateOptions.label.split('-').reverse()[0].trim();
                });
            }
        });
    }
}

