<div class="header">
    <div class="title"> Kanban Settings</div>
    <mat-icon (click)="close()">close</mat-icon>
</div>

<mat-horizontal-stepper linear #stepper>
    <mat-step label="Group By" [stepControl]="firstStepValidation">
        <div class="tab-content ">
            <div class="tab-body first-step" *ngIf="groups?.length > 0">
                <div class="groupByColumn mat-size">
                    <div class="title"> Group by</div>
                    <grouping (groupFieldChanged)="getFacetValues($event)"
                        [groups]="groups"
                        [selectedView]="'kanban-view'" [tabSettings]="dialogInputSettings.tabSettings">
                    </grouping>
                </div>
                <ng-container *ngIf="loadingState?.status === 'Busy' ">
                    <div style="width: 50px;height: 50px; margin-left: 400px;">
                        <mat-spinner [diameter]="25" class="kanban-customization-dialog-spinner"></mat-spinner>
                    </div>
                </ng-container>
                <br>
                <left-right-columns-settings
                    *ngIf="groups[0].field && allListColumns?.length > 0" class="columns"
                    [label]="'Showing'" [allColumns]="allListColumns" [rightColumns]="rightListColumns"
                    [defaultLabel]="'Set default lists'" [initialIsDefaultSelected]="true" [filterType]="true"
                    (rightColumnsOutput)="updateListColumnDisplay($event)"
                    (setDefaultSettingsEvent)="setDefaultListColumns()">
                </left-right-columns-settings>
            </div>
            <div class="tab-footer">
                <button class="create rounded upper-case" color="primary" mat-flat-button matStepperNext (click)="validateStep1()">Next</button>
            </div>
        </div>
    </mat-step>
    <mat-step label="Fields"  [stepControl]="seconedStepValidation">
        <div class="tab-content">
            <left-right-columns-settings class="columns" [label]="'Showing'" [allColumns]="allListCardsColumns"
                [rightColumns]="rightListCardsColumns" [defaultLabel]="'Set Default List Columns'"
                [initialIsDefaultSelected]="true" [filterType]="true"
                (rightColumnsOutput)="updateListCardsColumnDisplay($event)"
                (setDefaultSettingsEvent)="setDefaultListCardsColumns()">
            </left-right-columns-settings>
            <div class="tab-footer">
                <button mat-stroked-button matStepperPrevious class="cancel rounded upper-case">
                    Back
                </button>
                <button mat-flat-button matStepperNext (click)="validateStep2()" class="create rounded upper-case" color="primary">
                    Next
                </button>
            </div>
        </div>

    </mat-step>
    <mat-step label="Sorts">
        <div class="tab-content">
            <div class="mat-size">
                <sorting (sortFieldChanged)="sorts=$event" (defaultSort)="isDefaultSorts=$event"
                    [mainViewState]="dialogInputSettings.mainViewState"
                    [queryService]="dialogInputSettings.queryService" [tabSettings]="dialogInputSettings.tabSettings">
                </sorting>
            </div>
            <div class="tab-footer">
                <button mat-stroked-button matStepperPrevious class="cancel rounded upper-case">
                    Back
                </button>
                <button mat-flat-button class="create rounded upper-case" color="primary" (click)="save()">
                    save
                </button>
            </div>
        </div>
    </mat-step>
</mat-horizontal-stepper>
