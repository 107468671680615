import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, UserData, UserDataService } from '@discoverer/app-core';
import { AppSettingsService, BaseController, DiscovererQueryService } from '@discoverer/core';
import { EmitterService } from '../../services/emitter.service';
import { GeneralSettingsService } from '../../services/general-settings.service';
import { SchemaService } from '../../services/schema.service';
import { IToolbarSettings, ToolbarService } from '../../services/toolbar.service';
import { environment } from '@env/environment';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseController implements OnInit {
    user: UserData;
    toolbarSettings: IToolbarSettings = { showBackButton: false, title: 'Datasets' };
    isSubTitleCopied: boolean = false;
    isEmailCopied = false;
    datasetId: string;
    totalFileCount: number;

    constructor(
        public queryService: DiscovererQueryService,
        public emitterService: EmitterService,
        private _userService: UserDataService,
        private _toolbarService: ToolbarService,
        private _router: Router,
        private _authService: AuthService,
        private _generalSettingsService: GeneralSettingsService,
        private schemaService: SchemaService,
        private _appSettingsService: AppSettingsService
    ) { super(); }

    async ngOnInit(): Promise<void> {
        await this._appSettingsService.setActiveApp(environment.auth.productKey);
        this.subscriptions.push(this._userService.loggedInUser
            .subscribe((user: UserData) => this.user = user));

        this.subscriptions.push(this._toolbarService.oToolbarSettings
            .subscribe((t: IToolbarSettings) => this.toolbarSettings = t));

        this.subscriptions.push(this.emitterService.oDatasetId
            .subscribe(async (value: string) => {
                this._generalSettingsService.setDatasetId(value);
                await this.schemaService.updateDatasetSchema(value)
                this.datasetId = value;
            }));

    }

    public signOut() {
        const redirectUrl = this._router.url;
        this._authService.goToLogin(redirectUrl);
    }

}
