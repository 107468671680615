import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { DataFormattingService } from '@discoverer/core/services';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'metrics-card',
    templateUrl: './metrics-card.component.html',
    styleUrls: ['./metrics-card.component.scss'],
})
export class MetricsCardComponent {
    @Input() public current: number;
    @Input() public prev: number;
    @Input() public description: string;
    @Input() public type = 'double';
    @Input() public displayMode: 'Percentage' | 'Values' = 'Percentage';
    @Input() public valDiff: number;

    @Output() public dataClicked: EventEmitter<any> = new EventEmitter<any>();

    constructor(public formattingService: DataFormattingService) {

    }
    public openRecords(view, val) {
        this.dataClicked.emit({ view, val })
    }
    // public getPercentage(current, previous) {
    //     if (previous == null) { return 0; }
    //     let val = 0;
    //     val = (current - previous) / (previous <= 0 ? 1 : previous);
    //     return Math.abs((val * 100));
    // }
    // public getDiffrence(current, previous) {
    //     if (previous == null) { return 0; }
    //     return Math.abs(current - previous);
    // }
    getAbs(valDiff) {
        return Math.abs(valDiff);
    }
    public formatMetrics(val) {
        return this.formattingService.getValue(val, this.type);
    }
}
