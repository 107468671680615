import { Injectable } from '@angular/core';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';

@Injectable()
export class ExportDataService {

    private _excelKendoElement: ExcelExportComponent;
    constructor() { }

    public set gridElement(val: ExcelExportComponent) {
        this._excelKendoElement = val;
    }

    public toExcel(data): void {
        this._excelKendoElement.save(data);
    }


}
