import { Subject, Observable, ReplaySubject } from 'rxjs';

export class BlocPatternServiceBase<T, K> {
    protected _$input: Subject<any> = new Subject();
    protected _$output: Subject<{ input: T, output: K}> = new ReplaySubject(1);
    protected _isLive = true;
    public result: Observable<{input: T, output: K}>;

    constructor() {
        this.result = this._$output;
    }

    dispose() {
        this._isLive = false;
        this._$input.complete();
        this._$output.complete();
    }
}
