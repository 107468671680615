import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatasetService } from '../../../services/api/dataset.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

const DUPLICATE_DATASET_TITLE_MESSAGE = 'Dataset with same name already exists';
export const SNACKBAR_OPTIONS: MatSnackBarConfig = { duration: 2000, politeness: 'polite' };
export const ERROR_SNACKBAR_OPTIONS: MatSnackBarConfig = { ...SNACKBAR_OPTIONS, panelClass: 'error-snackbar' };
export const SUCCESS_SNACKBAR_OPTIONS: MatSnackBarConfig = { ...SNACKBAR_OPTIONS, panelClass: 'success-snackbar' };

export const WARN_SNACKBAR_OPTIONS: MatSnackBarConfig = { ...SNACKBAR_OPTIONS, panelClass: 'warning-snackbar' };

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'edit-title-dialog',
    templateUrl: './edit-title-dialog.html',
    styleUrls: ['./edit-title-dialog.scss']
})
export class EditTitleDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<EditTitleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { datasetId: string, templateName: string },
        private _datasetService: DatasetService,
        private _snackBar: MatSnackBar
    ) {
    }
    public close() {
        this.dialogRef.close();
    }
    public async save() {
        const { datasetId, templateName } = this.data;
        const rep = await this._datasetService.updateTemplateName(datasetId, templateName).toPromise();
        if (!rep.isSuccess) {
            const msg = rep.result.includes(DUPLICATE_DATASET_TITLE_MESSAGE) ? DUPLICATE_DATASET_TITLE_MESSAGE : rep.result
            this._snackBar.open(msg, null, ERROR_SNACKBAR_OPTIONS);
            return;
        }
        this._snackBar.open('Name Was Changed Successffully', null, SUCCESS_SNACKBAR_OPTIONS);
        this.dialogRef.close(rep.result);
    }
}
