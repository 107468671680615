<div class="sorting">
    <div class="main-wrapper">
        <div class="form-group">
            <mat-radio-group class="radio-group" [(ngModel)]="isDefaultSorts" (change)="updateDefaultSorts($event.value)">
                <mat-radio-button class="radio-button" [value]="true"> Sort on the default field
                    <span *ngFor="let col of defaultSorts">({{col?.display}})</span>
                </mat-radio-button>
                <mat-radio-button class="radio-button" [value]="false">Sort on other fields</mat-radio-button>
            </mat-radio-group>
        </div>
        <columns-settings *ngIf="!isDefaultSorts" [addFieldLabel]="'Add another sort'" [columns]="sortColumns" [tabSettings]="tabSettings"
            (orderChanged)="setSortField()" (fieldChanged)="setSortField()">
        </columns-settings>

    </div>
</div>
