import {
    Component, Input, ChangeDetectionStrategy, Output,
    EventEmitter, OnInit, ContentChild, ElementRef, TemplateRef, OnDestroy
} from '@angular/core';
import { FacetValue, DiscovererQueryService, FacetFieldAccumulator, DRFacetOption, DRFilter } from '../services';
import { FacetDataSourceBloc } from '../facet-bloc/facet-datasource-bloc';
import { map, switchMap, concatMap } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EsQueryService } from '../services/core-data-services/discoverer-esquery-service';

export class FacetSourceContext {

    public facetValueDictionary: { [key: string]: FacetValue } = {};
    constructor(
        private readonly queryService: DiscovererQueryService, public readonly facetValues: FacetValue[],
        private readonly facetKey: string, public readonly facetData: FacetFieldAccumulator,
        public selectedFacetValues: FacetValue[], private readonly singleMode) {
        if (this.selectedFacetValues && selectedFacetValues.length) {
            this.selectedFacetValues.forEach(f => {
                this.facetValueDictionary[f.facetKey] = f;
            });
        }
    }

    public unselectAllFacets() {
        this.selectedFacetValues = [];
        this.updateQueryService(this.selectedFacetValues);
    }

    public selectFacet(facetVal: FacetValue) {

        if (this.singleMode) {
            this.selectedFacetValues = [];
        }
        this.selectedFacetValues.push(facetVal);
        this.selectedFacetValues.forEach(x => x.isChecked = true);
        this.updateQueryService(this.selectedFacetValues);
    }

    public unselectFacet(facetVal: FacetValue) {
        const index = this.selectedFacetValues.findIndex(x => x.facetKey === facetVal.facetKey);
        this.selectedFacetValues.splice(index, 1);
        this.updateQueryService(this.selectedFacetValues);
    }

    public isChecked(facetVal: FacetValue) {
        return !!this.facetValueDictionary[facetVal.facetKey];
    }

    private updateQueryService(selectedValues: FacetValue[] = null) {
        if (!this.selectedFacetValues || !this.selectedFacetValues.length) {
            this.queryService.unSetFilter(this.facetKey);
        } else {
            this.queryService.setFilter(this.facetKey, new DRFilter('facet', [this.facetKey], selectedValues));
        }
        this.queryService.refresh();
    }
}
interface IProcessResultChanges {
    facetData: FacetFieldAccumulator;
    facetValues: FacetValue[];
    selectedFacetValues: FacetValue[];
}
@Component({
    // tslint:disable-next-line:component-selector
    selector: 'facet-data-source',
    styleUrls: ['./facet-data-source.component.scss'],
    templateUrl: 'facet-data-source.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FacetDataSourceBloc]
})
export class FacetDataSourceComponent implements OnInit, OnDestroy {

    @ContentChild(TemplateRef) template: TemplateRef<ElementRef>;

    @Output()
    public context = new EventEmitter<FacetSourceContext>();

    @Input() set facetName(value) {
        this.dataSourceBloc.setFacetInputs({ facetName: value });
    }
    @Input() set title(value: string) {
        this.dataSourceBloc.setFacetInputs({ title: value });
    }
    @Input() set serviceUrl(value: string) {
        this.dataSourceBloc.setFacetInputs({ serviceUrl: value });
    }
    @Input() set facetKey(value: string) {
        this.dataSourceBloc.setFacetInputs({ facetKey: value });
    }
    @Input() set queryService(value: DiscovererQueryService) {
        this.dataSourceBloc.setFacetInputs({ queryService: value });
    }
    @Input() set enableQuery(value: boolean) {
        this.dataSourceBloc.setFacetInputs({ enable: value });
    }
    @Input() singleMode: boolean;


    private dataSourceBloc: FacetDataSourceBloc;
    private dataSourceBlocSubscription: Subscription;
    constructor(
      private httpClient: HttpClient,
      private esQueryService:EsQueryService) {

      this.dataSourceBloc = new FacetDataSourceBloc(this.httpClient,this.esQueryService);
    }
    ngOnDestroy(): void {
      if (!!this.dataSourceBlocSubscription ) {
        this.dataSourceBloc.dispose();
      }
    }
    ngOnInit(): void {

        this.subscribeToDataSourceBloc();
    }

  private subscribeToDataSourceBloc() {
    const $context = this.dataSourceBloc.result.pipe(switchMap(dataSource => {
      return combineLatest(dataSource.output.oFacetResults, dataSource.input.queryService.oQuery).pipe(
        map((val) => {
          if (val[1] && val[1].filters && val[1].filters.length) {
            val[1].filters.forEach(x => {
              const fieldFilter = x.fields.find(z => z === dataSource.input.facetKey);
              if (fieldFilter) {
                dataSource.input.queryService.filters[fieldFilter] = x;
              }
            });
          }
          const results = this.getProcessResultChanges(val[0], dataSource.input.facetKey, dataSource.input.queryService.filters);
          return new FacetSourceContext(dataSource.input.queryService, results.facetValues, dataSource.input.facetKey,
            results.facetData, results.selectedFacetValues, this.singleMode);
        }));
    }));

    this.dataSourceBlocSubscription = $context.subscribe(context => { this.context.emit(context); });
  }

    private getProcessResultChanges(results: FacetFieldAccumulator[], facetKey: string, filters: any): IProcessResultChanges {
        if (results && results.length) {
            const result = results.find(x => x.field === facetKey);
            const keyMap = result.getKeyMap();
            return {
                facetValues: Object.keys(keyMap).map(name => new FacetValue(new DRFacetOption(keyMap[name], '"' + name + '"', name))),
                facetData: result,
                selectedFacetValues: filters && filters[facetKey] ? filters[facetKey].facetValues : []
            };
        }
        return {} as IProcessResultChanges;
    }
}
