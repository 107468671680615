import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { SIDEBAR_ITEMS, VerticalSidebarService } from './services/vertical-sidebar.service';
import { Subscription, interval } from 'rxjs';



@Component({
  selector: 'app-vertical-sidebar',
  templateUrl: './vertical-sidebar.component.html',
  styleUrls: ['./vertical-sidebar.component.scss']
})
export class VerticalSidebarComponent implements OnInit, OnDestroy {

  isUpdating: boolean = false
  queueLength: number
  resultsLength: number
  subs: Subscription[] = []
  productKey: string = environment.auth.productKey
  datasetId: string;
  sidebarItems = SIDEBAR_ITEMS;
  constructor(
    private _sidebarService: VerticalSidebarService,
    private _activated: ActivatedRoute) { }

  async ngOnInit(): Promise<void> {
    this.datasetId = this._activated.snapshot.params['datasetId'];

    await this._setDatasetCounts();
    this.subs.push(interval(4000).subscribe(
      async () => {
        await this._setDatasetCounts();
      }));
  }

  private async _setDatasetCounts() {
    if (this.datasetId) {
      const result = await this._sidebarService.getDatasetCounts(this.datasetId);
      this.sidebarItems.find(i => i.id == 'pipeline')?.children.forEach(child => {
        if (child.id == 'queue') child.badge = String(result.queueLength);
        else if (child.id == 'records') child.badge = String(result.resultLength);
      });
    } else {
      console.warn("Dataset Id is not configured!")
    }

  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe())
    this.sidebarItems.find(i => i.id == 'pipeline')?.children.forEach(child => {
      child.badge = String(0)
    })
  }
}
