// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
    isDevelopment: false,
    production: true,
    VERSION: require('../../package.json').version,
    DOCPIPELINE: {
        GraphQlURL: "https://docpipeline-graphql.ignite.dev.jo.capella.io/v1/graphql"
    },
    auth: {
        API: 'https://parsd-auth.dev.jo.capella.io/',
        logoutURL: '',
        loginURL: 'https://parsd-auth.dev.jo.capella.io/',
        productKey: 'docpipeline'
    },
    defaultEmptyRequest: '240f0129-e542-4d05-9562-a3f92ceed0f8'
};


