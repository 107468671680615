import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { OptionListComponent } from './components/option-list-config/option-list.component';
import { AuthGuard } from '@discoverer/app-core';


const routes: Routes = [
    // { path: 'dashboard', component: DashboardComponent },
    { path: 'lists', component: OptionListComponent, canActivate: [AuthGuard]  },
    { path: '', loadChildren: () => import('./components/reports/reports.module').then(m => m.ReportsModule), canActivate: [AuthGuard] }
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class IgniteRoutingModule {
}
