import { Subject, Observable, ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

@Injectable()
export class BlocPatternServiceBase<T, K> {
    protected _$input: Subject<T> = new Subject();
    protected _$output: Subject<K> = new ReplaySubject(1);
    protected _isLive = true;
    public result: Observable<K>;

    constructor() {
        this.result = this._$output;
    }

    dispose() {
        this._isLive = false;
        this._$input.complete();
        this._$output.complete();
    }

    public async getCurrentValue() {
      return (await this._$output.pipe( first()).toPromise());
    }
}
