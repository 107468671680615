import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DiscoFormComponent } from './disco-form.component';


@Component({
  selector: 'app-disco-form-control',
  templateUrl: './disco-form-control.component.html',
})
export class DiscoFormControlComponent {
  @Input()
  title: string = 'Edit';

  @Input()
  set isEditMode(value: boolean) {
    this._isEditMode = value;
    if (!!this.form) {
      this.form.isEditMode = value;
    }
  }
  get isEditMode(): boolean {
    return this._isEditMode;
  }
  @Input()
  disableWhenInvalid = false;
  @Input()
  set form(value: DiscoFormComponent) {
    this._form = value;
    if (!!value) {
      this.form.onSubmit.subscribe(s => {
        this.isEditMode = false;
      });
    }
  }
  get form(): DiscoFormComponent {
    return this._form;
  }

  @Input()
  hasCustomSubmission: boolean = false;
  @Output() triggerCustomSubmission: EventEmitter<boolean> = new EventEmitter(false);

  private _form: DiscoFormComponent;
  private _isEditMode: boolean = false;

  public submit() {
    if (this.hasCustomSubmission == false) {
      this.form.dynForm.submit()
    }
    else {
      this.triggerCustomSubmission.emit(true);
    }
  }
}
