import { Input, Component, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';


@Component({
    selector: 'input-row-list',
    templateUrl: './input-row-list.component.html',
    styleUrls: ['./input-row-list.component.css']
})

export class InputRowListComponent {

    @Input('column-name')
    columnName: string;

    @Input('can-add')
    canAdd: boolean = false;

    @Input('can-edit')
    canEdit: boolean = false;

    @Input('can-delete')
    canDelete: boolean = false;

    @Input('material-icon')
    materialIcon: string;

    @Input('icon-color')
    iconColor: string = '#000000';

    @Input('row-list')
    set rowList(values: any[]) {
        this._rowList = Object.assign([], values.sort((a, b) => a.id - b.id));
        if (this.canAdd) {
            this._rowList.push({ id: 0, name: '' });
        }
    }

    @Output('selected-row')
    selectedRowEmit: EventEmitter<any> = new EventEmitter<any>();

    @Output('changed-on-row')
    changedOnRow: EventEmitter<any> = new EventEmitter<any>();

    @Output('delete-row')
    deleteRow: EventEmitter<any> = new EventEmitter<any>();

    get rowList(): any[] {
        return this._rowList;
    }

    get selectedRow(): any {
        return this._selectedRow;
    }

    set selectedRow(row: any) {
        this._selectedRow = row;
    }

    private _rowList: any[] = new Array();
    private _selectedRow: any;

    constructor(public dialog: MatDialog) {
    }

    public selectRow(row: any) {
        this._selectedRow = row;
        this.selectedRowEmit.emit(row);
    }

    public rowChange(value, row: any) {
        row.name = value;
        this.changedOnRow.emit(row);
    }

    public rowDeleteConfirmDialog(row: any) {
        this.deleteRow.emit(row);
    }
}
