import { Component, ViewEncapsulation, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormControl } from '@angular/forms';
import { RequestFilingService } from '@discoverer/core/services';
import { AppSettingsService } from '@discoverer/core/services/dynamic-reports-services';
import { DASHBOARDS } from '@discoverer/dynamic-reports/common/dashboards-list/dashboards-list.component';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'save-report',
    templateUrl: './save-report.dialog.html',
    styleUrls: ['./save-report.dialog.scss'],
    encapsulation: ViewEncapsulation.None
})
// tslint:disable-next-line:component-class-suffix
export class SaveReportDialog implements OnInit {

    public reportName: FormControl;
    public directory: FormControl;
    public showError = false;
    public directoryList;
    public currentDashboard =DASHBOARDS;
    public isValidForm() {
        return this.reportName.valid;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<SaveReportDialog>,
        private requestFilingService: RequestFilingService,
        private appSettings: AppSettingsService
    ) {
        this.createForm();
    }
    async ngOnInit() {
        const appKey = (await this.appSettings.getCurrentApp()).key;
        this.directoryList = await this.requestFilingService.getDirectories(appKey, this.data.tab, true);
        this.directory.setValue(this.directoryList.find(x => x.directoryName.split('|')[1] === 'Personal').id);
    }

    public close() {
        this.dialogRef.close();
    }
    public save() {
        if (this.isValidForm()) {
            const reportdir = this.directoryList.find(x => x.id === this.directory.value);
            const name = reportdir.directoryName.split('|')[1].toLowerCase();
            this.dialogRef.close({ reportName: this.reportName.value, directoryId: this.directory.value, reportDir: name, isSave: true });
        } else {
            this.showError = true;
        }
    }

    private createForm() {
        this.reportName = new FormControl('', [
            Validators.required
        ]);
        this.directory = new FormControl('', [
            Validators.required
        ]);
    }
}
