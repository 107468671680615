<div class="left-list-container vertical-filters-wrapper">
    <div class="noselect filter-column ">

        <div class="filters-container">
            <div class="groups" *ngIf="$displayedFilters | async as appliedFilters">
                <div class="group" *ngFor="let filter of appliedFilters; let i = index;">
                    <ng-container>
                        <facet-container [isCollapsible]="true" [isOpen]="i<4" [facet]="filter.facet" [currentTab]="currentTab"
                            [display]="filter.facet.display" [clearButtonKey]="true" [filterMode]="'all'"
                            [queryService]="queryService" [showPin]="false">
                        </facet-container>
                    </ng-container>

                </div>
            </div>
            <div class="advanced-filter" *ngFor="let filter of $displayedAdvFilters | async;">
                <div class="facet-item">
                    <search-header-advanced-filter-container [isVertical]="true" [filter]="filter"
                        [advFilterService]="advFilterService" [columnDictionary]="columnDictionary">
                    </search-header-advanced-filter-container>
                    <div class="facet-actions">
                        <span class="action" (click)="showAdvancedModal(0)">EDIT</span>
                        <span class="action" (click)="removeAdvancedFilter(0)">CLEAR</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
