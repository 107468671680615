import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { DatasetService } from './api/dataset.service';
import { take } from 'rxjs/operators';
import { Field } from '../helpers/docpipeline-function';
import { DocumentDialogFormData } from '../common/schema-main/schema-form/dataset-document-title/dataset-document-title.dialog';

@Injectable({ providedIn: 'root' })
export class SchemaService {
    private _currentSchema$ = new ReplaySubject<{ [key: string]: Field }>(1);
    public oCurrentSchema = this._currentSchema$.asObservable();

    private _selectedDocument$ = new ReplaySubject<{ key: string, field: Field }>(1);
    public oSelectedDocument = this._selectedDocument$.asObservable();

    private _documentList$ = new ReplaySubject<{ key: string, display: string }[]>(1);
    public oDocumentList = this._documentList$.asObservable();

    constructor(private datsetService: DatasetService) { }

    public async updateDatasetSchema(datasetId: string, documentKey?: string): Promise<any> {
        const fields = (await this.datsetService.getFields(datasetId).toPromise())?.result;
        if (!fields) return null;
        this._currentSchema$.next(fields);
        await this.setCurrentDocuemnt(documentKey, fields);
        this._upadtedocuemntList(fields);
        return fields;
    }

    public async setCurrentDocuemnt(key?: string, fields?: { [key: string]: Field }) {
        (!fields) && (fields = await this.oCurrentSchema.pipe(take(1)).toPromise());
        (!key || !fields[key]) && (key = Object.keys(fields)[0]);
        this._selectedDocument$.next({ key, field: fields[key] });
    }

    public async setCurrentDocumentFields(fields?: { [key: string]: Field }) {
        const currentSchema = await this.oCurrentSchema.pipe(take(1)).toPromise();
        const currentDoc = await this.oSelectedDocument.pipe(take(1)).toPromise();
        currentSchema[currentDoc.key].fields = fields;
        this._currentSchema$.next(currentSchema);
        this.setCurrentDocuemnt(currentDoc.key);
    }

    private _upadtedocuemntList(fields: { [key: string]: Field }) {
        const docLits = Object.keys(fields).map(key => ({ key: key, display: fields[key].display }));
        this._documentList$.next(docLits);
    }

    public async setFields() {
        let fields = await this.oSelectedDocument.pipe(take(1)).toPromise();
        this._selectedDocument$.next(fields);
    }
    public async addNewDocument(documentDetails: DocumentDialogFormData): Promise<boolean> {
        if (this._checkIfDocumentNameExists(documentDetails.title)) return false;
        const key = documentDetails.title?.replace(/\W/g, '');
        if (this._checkIfDocumentKeyExists(key)) return false;
        var currentFields = await this.oCurrentSchema.pipe(take(1)).toPromise();
        currentFields[key] = { display: documentDetails.title, type: 'document', fields: {}, description: documentDetails.description }
        this._currentSchema$.next(currentFields);
        this._selectedDocument$.next({ key, field: currentFields[key] });
        this._upadtedocuemntList(currentFields)
        return true
    }
    public async editSelectedDocumentTitle(documentDetails: DocumentDialogFormData): Promise<boolean> {
        if (this._checkIfDocumentNameExists(documentDetails.title)) return false;
        const key = documentDetails.title?.replace(/\W/g, '');
        if (this._checkIfDocumentKeyExists(key)) return false;
        let currentFields = await this.oCurrentSchema.pipe(take(1)).toPromise();
        let selectedDocument = await this.oSelectedDocument.pipe(take(1)).toPromise();
        currentFields[key] = { display: documentDetails.title, type: 'document', fields: selectedDocument.field.fields, description: documentDetails.description };
        delete currentFields[selectedDocument.key];
        this._currentSchema$.next(currentFields);
        this._selectedDocument$.next({ key, field: currentFields[key] });
        this._upadtedocuemntList(currentFields)
        return true;
    }
    public async editDocumentTitle(oldDocKey: string, documentDetails: DocumentDialogFormData): Promise<boolean> {
        if (this._checkIfDocumentNameExists(documentDetails.title)) return false;
        const key = documentDetails.title?.replace(/\W/g, '');
        if (this._checkIfDocumentKeyExists(key)) return false;
        let currentFields = await this.oCurrentSchema.pipe(take(1)).toPromise();
        let isSelecetdDocTitleEdited = oldDocKey === (await this.oSelectedDocument.pipe(take(1)).toPromise()).key;
        let selectedDocument = currentFields[oldDocKey];
        currentFields[key] = { display: documentDetails.title, type: 'document', fields: selectedDocument.fields, description: documentDetails.description };
        delete currentFields[oldDocKey];
        this._currentSchema$.next(currentFields);
        isSelecetdDocTitleEdited && this._selectedDocument$.next({ key, field: currentFields[key] });
        this._upadtedocuemntList(currentFields)
        return true;
    }

    public async deleteSelectedDocument() {
        let currentFields = await this.oCurrentSchema.pipe(take(1)).toPromise();
        let selectedDocument = await this.oSelectedDocument.pipe(take(1)).toPromise();
        delete currentFields[selectedDocument.key]
        this._currentSchema$.next(currentFields)
        await this.setCurrentDocuemnt();
        this._upadtedocuemntList(currentFields)
    }
    public async clearSelectedDocumentfields(docKey?: string) {
        let currentFields = await this.oCurrentSchema.pipe(take(1)).toPromise();
        let selectedDocumentKey = docKey || (await this.oSelectedDocument.pipe(take(1)).toPromise()).key;
        currentFields[selectedDocumentKey].fields = {};
        this._currentSchema$.next(currentFields)
        await this.setCurrentDocuemnt(selectedDocumentKey, currentFields);
        this._upadtedocuemntList(currentFields)
    }

    public getCurrentSchema() {
        return this.oCurrentSchema.pipe(take(1)).toPromise();
    }
    public getCurrentDocument() {
        return this.oSelectedDocument.pipe(take(1)).toPromise();
    }
    private async _checkIfDocumentKeyExists(newKey: string): Promise<boolean> {
        var keys = (await this.oDocumentList.pipe(take(1)).toPromise()).map(x => x.key)
        return (keys.includes(newKey));
    }
    private async _checkIfDocumentNameExists(newName: string): Promise<boolean> {
        var names = (await this.oDocumentList.pipe(take(1)).toPromise()).map(x => x.display)
        return (names.includes(newName));
    }
}
