<div class="column rating-container">
    <label>{{field.templateOptions.label}}</label>
    <div class="stars-container row">
        <div class="star" *ngFor="let star of stars" (click)="fillStars(star)"
            [ngClass]="{'selected': (star <= selectedValue || star <= hoveredValue)}"
            (mouseenter)="hoveredValue=!isDisabled()?star:0" (mouseleave)="hoveredValue=0">
            <span class="material-icons">
                star_rate
            </span>
        </div>
    </div>
</div>