<div class="filtered-list" scrollTracker (scrollingFinished)="scrollingFinished.emit()">
    <div *ngFor="let request of requests" class="list-items">
        <div (click)="requestClicked(request)">
            <mat-card class="request-card" [ngClass]="{'selected': reportIsSelected(request)}">
                <div class="title-wrapper">
                    <mat-icon class="mr-4 ml-2 icon text-3xl" color="accent">{{request.icon || 'table_chart_outline'}}
                    </mat-icon>
                    <div class="title">
                        <div class="title-text" [innerHtml]="request.requestName | highlighter: searchText">

                        </div>
                    </div>
                </div>
                <div class="info">
                    <div class="section">
                        <div class="info-title">Data Set: </div>
                        <div class="info-sub-title " [matTooltip]="request.directoryName">
                            <span class="ellipsis">{{request.tabName}}</span>
                        </div>
                    </div>
                    <div class="section">
                        <div class="info-title">Directory: </div>
                        <div class="info-sub-title " [matTooltip]="request.directoryName">
                            <mat-icon class="sub-icon"> {{request.isDirCommon ? 'public' : 'folder'}} </mat-icon>
                            <div class="ellipsis" [innerHtml]="request.directoryName | highlighter: searchText">
                            </div>
                        </div>
                    </div>
                    <div class="section">
                        <div class="info-title">Created at: </div>
                        <div class="info-sub-title">{{request.createTime | date}}</div>
                    </div>
                    <div class="section">
                        <div class="info-title">Updated at: </div>
                        <div class="info-sub-title">{{request.updateTime | date}}</div>
                    </div>
                    <div class="section" style="flex: 0.10;">
                        <div class="action">
                            <mat-icon class="add" *ngIf="isOpenReport else IS_SELECT">arrow_forward_ios</mat-icon>
                            <ng-template #IS_SELECT>
                                <mat-icon class="remove" *ngIf="reportIsSelected(request) else ADD"> remove</mat-icon>
                                <ng-template #ADD><mat-icon class="add"> add </mat-icon></ng-template>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
    <div class="list-items">
        <ng-content select="[endOfListCard]"></ng-content>
    </div>
</div>
