import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserSettingsService } from '../services/user-settings.service';
import { SidenavService } from '../services/sidenav.service';
import { UserData, UserDataService } from '@discoverer/app-core';
// import { SidenavService } from '../services/sidenav.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  @Output() toggleSidenav = new EventEmitter<any>();

  showIcon: boolean
  fgInvalid: boolean
  isLoading: boolean
  name: string
  nameInitials: string 
  userEmail: string
  fg: FormGroup
  constructor(
    private _userSettingsService: UserSettingsService,
    private _sidenavService: SidenavService,
    private _userService: UserDataService,

  ) { }

  ngOnInit(): void {
    this._userService.loggedInUser.subscribe((user: UserData) => {
      this.name = user.name;
      this.nameInitials = user.name.split(' ')[0].split('')[0] + user.name.split(' ')[1].split('')[0];
      this.userEmail = user.email
      });

    // TODO populate form control with values from the current user
    this.fg = new FormGroup({
      fullname: new FormControl(this.name, [Validators.required]),
      email: new FormControl(this.userEmail, [Validators.required, Validators.email])
    })

    this._sidenavService.getScreenSize().subscribe(size => {
      if (size.width < 768) {
        this.showIcon = true
      } else {
        this.showIcon = false

      }
    });
  }

  async saveProfile() {
    if (this.fg.invalid) {
      this.fgInvalid = true;
      return;
    }
    this.fgInvalid = false;
    this.isLoading = true;
    await this._userSettingsService.updateProfileDetails(this.fg.value);
    this.isLoading = false;
  }

  toggleOuterSidenav() {
    this._sidenavService.toggleSidenav(true);
  }
}
