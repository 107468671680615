import { Injectable } from '@angular/core';
import { FormsDataServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/services';
import { HttpClient } from '@angular/common/http';
import { FileDetailsHandlerService } from '../../shared-pages/file-details-handler.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FileFormsDataService implements FormsDataServiceInterface {

    public formServicePath: string = '/api/es/form/docpipeline';

    constructor(private http: HttpClient,
        public _fileDetailsHandlerService: FileDetailsHandlerService,
    ) {
    }

    public  getFormByKey(tabId: String) {
        return this._fileDetailsHandlerService.schemaService.oSelectedDocument
            .pipe(switchMap(selectedDoc =>  this.http.get(`${this.formServicePath}/${tabId}/${selectedDoc.key}`)));
    }

    postFormData(object: any) {
        Object.keys(object).forEach(key => {
            if (object[key] instanceof Date) {
                object[key] = new Date(object[key]).toUTCString();
            }
        });
        return this.http.post(`${this.formServicePath}`, object);
    }
}
