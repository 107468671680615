
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { BaseController, buildQueryFromParams, DEFAULT_REPORT_TITLE, RoleChecker } from '@discoverer/core/services';
import { fadeAnimtaion } from '@app/animations/animations';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

import { RolesService } from '@discoverer/app-core';
import { DiscovererQueryService, MultiSelectService } from '@discoverer/core/services';
import { Title } from '@angular/platform-browser';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, first, takeUntil } from 'rxjs/operators';
import { DiscoReportContext } from '../common';

@Component({
    selector: 'disco-list-view',
    templateUrl: 'disco-list-view.component.html',
    animations: [fadeAnimtaion],
    providers: [
        { provide: RoleChecker, useClass: RolesService }
    ],
    styleUrls: ['./disco-list-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscoListViewComponent extends BaseController {
    public appId = '';
    public tabId = '';
    public hideEditColumn = false;
    public requestId = null;
    public enableMultipleSelect = false;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _titleService: Title,
        private _cdRef: ChangeDetectorRef,
        public queryService: DiscovererQueryService,
        public multiSelectService: MultiSelectService,
    ) {
        super();
        this.requestId = this._activatedRoute.snapshot.paramMap.get('id');

        this.appId = this._activatedRoute.snapshot.data.appId;
        this.enableMultipleSelect = this._activatedRoute.snapshot.data.enableSelect;
        if (this._activatedRoute.snapshot.data.tabId != null) {
            this.tabId = this._activatedRoute.snapshot.data.tabId;
        }

        this.hideEditColumn = this._activatedRoute.snapshot.data.hideEditColumn;

        combineLatest([this._activatedRoute.queryParams, this._activatedRoute.params])
            .pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {

                const queryParams = data[0];
                const params = data[1];


                if (queryParams) {
                    this.queryService = this.queryService.createChildService();
                    buildQueryFromParams(this.queryService, queryParams);
                }

                this.tabId = params?.tabId ?? this.tabId;
                this.requestId = queryParams?.requestId ?? params?.id;

                this._cdRef.markForCheck();
            });

        const shouldReuse = this._router.routeReuseStrategy.shouldReuseRoute;


        this._router.routeReuseStrategy.shouldReuseRoute = function (future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot) {
            if (future.paramMap.get('tabId') != curr.paramMap.get('tabId')) {
                return false;
            }
            else
                return shouldReuse(future, curr);
        }
        this._cdRef.markForCheck();
    }

    public setContext(context: DiscoReportContext) {
        if (context != null) {
            //this._location.replaceState( this._location.path().replace('/default', '/' + context.currentReqId ).replace(context.loadReqId, context.currentReqId ));
            this._titleService.setTitle(`${context?.currentReport?.mainView?.mainTitle || DEFAULT_REPORT_TITLE}`)
        }
    }


    public navigateToDetail(data: any, businessKey: string) {
        const x = data?.currentObject[businessKey];
        const bKey = Array.isArray(x) ? x[0] : x;
        this._router.navigate([{ outlets: { dialog: [this.appId, 'detail', this.tabId, bKey] } }]);
    }


    public ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
