import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendResponse } from '../../models/common';
import { Observable } from 'rxjs';
import { IntegrationModel } from '../../models/integration.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BackendRouteEndpoint } from './constants/backend-route-constants';


@Injectable({
    providedIn: 'root'
})
export class IntegrationService {
  // TODO: Add Types instead of any

    constructor(private _httpClient: HttpClient, private _matSnackBar: MatSnackBar) { }

    public create(data: IntegrationModel): Observable<BackendResponse<any>> {

        var uri = `${BackendRouteEndpoint.DATASET_INTEGRATION_BASE_URL(data.datasetId)}`;

        return this._httpClient.post<BackendResponse<any>>(uri, data);
    }

    public update(data: IntegrationModel): Observable<BackendResponse<any>> {

        var uri = `${BackendRouteEndpoint.DATASET_INTEGRATION_BASE_URL(data.datasetId)}/${data.id}`;


        return this._httpClient.put<BackendResponse<any>>(uri, data);
    }
    public getAll(datasetId: string): Observable<BackendResponse<any>> {

        var uri = `${BackendRouteEndpoint.DATASET_INTEGRATION_BASE_URL(datasetId)}`;

        return this._httpClient.get<BackendResponse<any>>(uri);
    }
    public get(id: string, datasetId: string): Observable<BackendResponse<any>> {

        var uri = `${BackendRouteEndpoint.DATASET_INTEGRATION_BASE_URL(datasetId)}/${id}`;

        return this._httpClient.get<BackendResponse<any>>(uri);
    }

    public delete(id: string, datasetId: string): Observable<BackendResponse<any>> {

        var uri = `${BackendRouteEndpoint.DATASET_INTEGRATION_BASE_URL(datasetId)}/${id}`;

        return this._httpClient.delete<BackendResponse<any>>(uri);
    }

    public deleteAll(datasetId: string): Observable<BackendResponse<any>> {

        const uri = `${BackendRouteEndpoint.DATASET_INTEGRATION_BASE_URL(datasetId)}`;

        return this._httpClient.delete<BackendResponse<any>>(uri);
    }
}
