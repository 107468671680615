<div class="wrapper">
    <header>
        <div class="header-content">
            <h1 class="dialog-title">Auto Import Fields</h1>
        </div>
        <div class="header-actions">
            <button mat-icon-button [disabled]="isGenerating" (click)="closeDialog(false)">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </header>

    <mat-divider></mat-divider>

    <main>
        <mat-radio-group [disabled]="isGenerating" class="radio-group" [(ngModel)]="chosenOption" color="primary">
            <mat-radio-button class="radio-button" value="upload">Generate Using Document</mat-radio-button>
            <mat-radio-button class="radio-button" value="template">Pick a Template</mat-radio-button>
        </mat-radio-group>


        <div class="main-content">
            <app-dataset-fields-templates-dialog *ngIf="chosenOption === 'template'; else UPLOAD">
            </app-dataset-fields-templates-dialog>

            <ng-template #UPLOAD>
                <field-from-files-dialog [datasetId]="data.datasetId"
                    (generatingFields)="handleFieldsGenerated($event)"></field-from-files-dialog>
            </ng-template>

        </div>

    </main>
</div>