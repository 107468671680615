import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

import {BaseController, DiscovererQueryService, AdvancedFilterService, IDRFilter, DRFilter, FacetValue, IColumnSetting, DiscovererFacetTranslationService, TabSettingsService, FILTERING_MODES, FILTERING_OPR_DICTIONARY} from '@discoverer/core/services';
import {HttpClient} from '@angular/common/http';
import {Apollo} from 'apollo-angular';
import {IDisplayFilter} from '@discoverer/core/services';
import {P} from '@angular/cdk/keycodes';
import {debug} from 'console';


@Component({
    selector: 'advanced-filters',
    templateUrl: './advanced-filters.component.html',
    styleUrls: ['./advanced-filters.component.scss']
})
export class AdvancedFiltersComponent extends BaseController implements OnInit {

    @Input()
    public mqService: DiscovererQueryService;
    @Input()
    public tabSettings: TabSettingsService;
    @Input()
    public advancedFilterMode: string;
    @Input()
    public isSubFilter: boolean;
    @Input()
    public parentFilter: IDisplayFilter;
    @Input()
    public depth: number = 0;
    @Input()
    public filterIndex: number = 0;
    @Output()
    public invalidFilterValue: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output()
    public filtersValue: EventEmitter<Array<IDRFilter>> = new EventEmitter<Array<IDRFilter>>();

    public filters: Array<IDRFilter> = [];
    public bdFilters: Array<IDisplayFilter> = []; // broken down facet filters
    public bdAdvFilters: Array<IDisplayFilter> = []; // broken down Advanced Filter
    // public bdAdvFiltersCpy: Array<IDisplayFilter> = []; // broken down Advanced Filter
    public availableColumns: IColumnSetting[];
    public FILTERING_MODES = FILTERING_MODES;
    public FILTERING_OPERATION_DICTIONARY = FILTERING_OPR_DICTIONARY;
    //public advancedFilterMode = 'all';
    public filtersList: IDisplayFilter[] = [];
    public advFilterService: AdvancedFilterService;
    public filtersEmit: any[] = [];

    public currentQuery;
    public updateFilters = true;
    public itemHover = false;
    validQuery = true;
    public serviceUrl: string;

    constructor(private facetTranslationService: DiscovererFacetTranslationService, private http: HttpClient, private apollo: Apollo) {
        super();
    }

    async ngOnInit() {
        this.advFilterService = new AdvancedFilterService(this.facetTranslationService, this.http, this.apollo, this.tabSettings);
        this.serviceUrl = (await this.tabSettings.getCurrentTab()).serviceUrl
        this.availableColumns = await this.tabSettings.getAllColumns();
        let subs = null;
        subs = this.mqService.oQuery.subscribe((q) => {
            if (!!q) {
                if (!!subs) {
                    subs.unsubscribe();
                } else {
                    let advFilters = q.filters.filter((f) => f.fields[0] === 'Advanced');
                    this.advancedFilterMode = !!advFilters[0] ? advFilters[0].type : FILTERING_MODES.ALL;
                    if (this.isSubFilter) {
                        advFilters = [this.parentFilter];
                    }
                    this.prepareAdvancedService(advFilters);
                }
            }
        });

    }

    public addNewFilter() {
        this.advFilterService.addFiler(this.filtersList.length);
    }

    private async prepareAdvancedService(advFilters) {
        const columnDictionary = await this.tabSettings.getColumnDictionary();
        this.advFilterService.init(!!advFilters[0] ? advFilters[0] : null, columnDictionary);
        this.subscriptions.push(this.advFilterService.oFilters.subscribe((filters: IDisplayFilter[]) => {
            this.filtersList = this.mapFilters(filters, columnDictionary);
            this.filtersValue.emit(filters);
            const allValid = filters.map((f) => f.isValid);
            this.checkValidateAdvancedFilter(allValid);
            if (filters.filter((f) => AdvancedFilterService.isFilterReady(f)) && this.validQuery) {

                const subFilters = filters.filter((f) => AdvancedFilterService.isFilterReady(f)).map((f) => {
                    return this.getDRFilter(f);
                });
                console.log('SubFilters: ', subFilters);

                const d: DRFilter = new DRFilter(this.advancedFilterMode, ['Advanced'], [], subFilters);
                if (!this.isSubFilter) {
                    console.log('Query Filter: ', d);
                    if (d.filters.length === 0) {
                        this.mqService.unSetFilter('Advanced');
                    } else {
                        this.mqService.setFilter('Advanced', d);
                    }
                }
            }
        }));
    }

    private getDRFilter(f: IDisplayFilter | IDRFilter) {
        if (!f) return [];
        let filter = new DRFilter(f.type, f.fields, (!!f.expression) ? f.expression.map(e => e.trim()).map((e) => {
            return new FacetValue({
                key: e,
                query: e,
                display: e
            });
        }) : [], !!f.filters ? f.filters.map((sf) => {
            return this.getDRFilter(sf);
        }) : []);
        return filter;
    }

    private mapFilters(filters: IDisplayFilter[], columnDictionary: any): IDisplayFilter[] {
        filters.map(async f => {
            if (f.fields && f.fields.length > 0) {
                const column = columnDictionary[f.fields[0]];
                if (column) {
                    f.isDynamicFacet = column.isDynamicFacet;
                    f.tableName = column.tableName;
                    f.optionListId = column?.form?.templateOptions?.optionsListId == null ? 0 : column.form.templateOptions.optionsListId;
                    if (f.isDynamicFacet == false)
                        f.displayA = await this.advFilterService.getDisplayFromExpression(f.expression, column)
                }
            }
            return f;
        });

        return filters;
    }

    public trackAdvancedFilters(index: number, item: IDisplayFilter): string {
        return item.id;
    }

    public updateFilteringMode(isAdvanced) {
        this.advFilterService.updateFilteringMode(this.advancedFilterMode);
    }
    public checkValidateAdvancedFilter(filtersValidityList: boolean[]) {
        let allV = true;
        filtersValidityList.forEach(x => {
            if (x === false) {
                allV = false;
                return;
            }
        });
        this.validQuery = allV;

        setTimeout(() => {
            this.invalidFilterValue.emit(allV);
        }, 50);

    }
}
