import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FacetActionsComponent } from './facet-actions.component'
import { MaterialModule } from '@discoverer/MaterialModule';


@NgModule({
  imports: [
      CommonModule,
      MaterialModule
  ],
  declarations: [
    FacetActionsComponent,
  ],
  exports: [
    FacetActionsComponent
  ]
})
export class FacetActionsModule {
  constructor() {
  }
}
