import {
    Component, Optional, Inject, ViewChild, ElementRef, ContentChild,
    TemplateRef, Input, Output, EventEmitter, OnInit, ChangeDetectorRef, NgZone, ChangeDetectionStrategy
} from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseController, buildQueryFromParams, DiscovererQueryService, TabSettingsService } from '@discoverer/core/services';
import { DataSourceContext } from '@discoverer/core/data-source';
import { HttpClient } from '@angular/common/http';
import { FilesService } from './files-service';
import { DiscoFormComponent } from '../disco-form/disco-form.component';
import { OptionDataServiceInterface, OptionListDataServiceInterface } from '@discoverer/dynamic-forms/option-lists/shared-services/data-service';
import { HasuraOptionListService } from '@discoverer/dynamic-forms/option-lists/shared-services/hasura-option-list-service';
import { HasuraOptionService } from '@discoverer/dynamic-forms/option-lists/shared-services/hasura-option-service';
import { DISCO_FORM_SERVICE_URL, FormsDataServiceInterface, HttpFormsDataService } from '@discoverer/dynamic-forms/dyn-forms/services';
import { FilesServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/dyn-upload/files-service.interface';



@Component({
    // tslint:disable-next-line:component-selector
    selector: 'disco-details',
    templateUrl: './disco-details.component.html',
    styleUrls: ['./disco-details.component.scss'],
    providers: [
        { provide: OptionListDataServiceInterface, useClass: HasuraOptionListService },
        { provide: OptionDataServiceInterface, useClass: HasuraOptionService },
        { provide: FormsDataServiceInterface, useClass: HttpFormsDataService, deps: [HttpClient, DISCO_FORM_SERVICE_URL] },
        { provide: FilesServiceInterface, useClass: FilesService },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscoDetailsComponent extends BaseController implements OnInit {


    @ContentChild('bodyTemplate') bodyTemplate: TemplateRef<ElementRef>;
    @ContentChild('headerTemplate') headerTemplate: TemplateRef<ElementRef>;
    @ContentChild('actionTemplate') actionTemplate: TemplateRef<ElementRef>;

    @ViewChild('pdfContainer') pdfContainer: ElementRef;
    @ViewChild('detailForm') detailForm: DiscoFormComponent;

    @Input() set formServicePath(value) {
        (this.defaultForm as HttpFormsDataService).formServicePath = value;
    }

    @Input() public appId: string;
    @Input() public tabId: string;
    @Input() public isDeletable = true;
    @Input() public requestId = null;
    @Input() public showHeader = true;
    // tslint:disable-next-line:no-output-on-prefix
    @Output() onSubmit = new EventEmitter<DataSourceContext>();
    // tslint:disable-next-line:no-output-on-prefix

    @Output()
    public context = new EventEmitter<DataSourceContext>();

    start = 0;
    returnUrl: string;
    private routeSnapshot: ActivatedRouteSnapshot;


    isLoading = false;
    hasDataChanged = false;
    queryService = new DiscovererQueryService();

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        @Optional() public dialogRef: MatDialogRef<any>,
        private location: Location,
        private tabSettings: TabSettingsService,
        private zone: NgZone,
        private defaultForm: FormsDataServiceInterface,
        route: ActivatedRoute,
    ) {
        super();
        this.routeSnapshot = this.getRouteSnapshot(data, route);
    }

    ngOnInit(): void {

        this.zone.runTask(async t => {
            if (!!this.routeSnapshot) {
                this.requestId = !this.requestId ? 'default' : this.requestId;
                this.returnUrl = this.routeSnapshot.queryParams.returnUrl;
                this.start = 0;
                // tslint:disable-next-line: radix

                if (this.routeSnapshot.data.appId) {
                    this.appId = this.routeSnapshot.data.appId;
                    this.tabId = this.routeSnapshot.paramMap.get('tabId') ?? this.routeSnapshot.data.tabId;

                    await this.tabSettings.setCurrentTab(this.tabId);
                    var params = Object.assign({}, this.routeSnapshot.queryParams);
                    const tab = (await this.tabSettings.getCurrentTab())
                    params[tab.businessKey] = this.routeSnapshot.paramMap.get('recordId');
                    buildQueryFromParams(this.queryService, params);
                }
            }


        });
    }


    private getRouteSnapshot(data: any, route: ActivatedRoute): ActivatedRouteSnapshot {
        // Dialog vs Standard Route.
        if (!!data?.routeSnapshot) {
            return data.routeSnapshot as ActivatedRouteSnapshot;
        } else if (!!route) {
            return route.snapshot;
        }
    }

    public submit(event) {
        this.hasDataChanged = true;
        if (event) {
            this.onSubmit.emit(event);
        }
    }



    public close() {

        switch (this.returnUrl) {
            case 'default': {
                this.location.back();
                return;
            }
            case '__close__': {
                window.close();
                return;
            }
            default: {
                setTimeout(() => {
                    this.dialogRef.close({ refresh: true });
                }, 200);
            }
        }
    }

    public print() {
        this.detailForm.print();
    }
}

