import { AfterViewInit, Directive, OnDestroy, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[tab-directive]' })
export class TabDirective implements AfterViewInit, OnDestroy {
    observable: any;
    constructor(@Optional() private autoTrigger: MatAutocompleteTrigger, @Optional() private control: NgControl) { }
    ngAfterViewInit() {

        if (this.autoTrigger && this.autoTrigger.panelClosingActions) {
            this.observable = this.autoTrigger.panelClosingActions.subscribe(x => {
                if (this.autoTrigger.activeOption) {
                    const value = this.autoTrigger.activeOption.value;
                    if (this.control && this.control.value) {
                        this.control.control.setValue(value, { emit: true });
                        this.autoTrigger.writeValue(this.autoTrigger.activeOption.value);
                    }
                }
            });
        }
    }
    ngOnDestroy() {
        if (this.observable) {
            this.observable.unsubscribe();
        }
    }
}
