import { ChangeDetectorRef, Component, OnInit, Optional } from '@angular/core';
import { IntegrationType } from '../../models/enums';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IntegrationDetailsDialogComponent } from './integration-details-dialog/integration-details-dialog.component'
import { integrationTypeImage, integrationTypeName } from '../../models/constants';
import { IntegrationModel } from '../../models/integration.model';
import { ActivatedRoute } from '@angular/router';
import { DiscovererQueryService } from '@discoverer/core';
import { ToolbarService } from '../../services/toolbar.service';
import { EmitterService } from '../../services/emitter.service';
import { IntegrationService } from '../../services/api/integration.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from '../attoms/confirmation-dialog/confirmation-dialog.component';

@Component({
    selector: 'app-Integration',
    templateUrl: './integrations.component.html',
    styleUrls: ['./integrations.component.scss']
})

export class IntegrationsComponent implements OnInit {
    public availableIntegrations = [
        {
            typeId: IntegrationType.Webhook,
            description: 'Add a webhook to send the parsed data and events to your server or a 3rd-party platform and create any custom integration.',
            imageUrl: 'assets/images/logo/webhook-logo.png', // Update with actual image path
            disabled: false
        },
        {
            typeId: IntegrationType.Make,
            description: 'Make (formerly Integromat) allows you to create, build, and automate workflows. Use the playful drag-and-drop interface to connect apps in a few clicks.',
            imageUrl: 'assets/images/logo/test.png',
            disabled: true
        },
        {
            typeId: IntegrationType.Zapier,
            description: 'Zapier connects you to all the software you rely on. Export parsed data to Google Sheets, Airtable, Slack, Asana, Excel, Zoho CRM and 5000+ apps.',
            imageUrl: 'assets/images/logo/zapier-logo.png', // Update with actual image path
            disabled: true
        }
        // ... other integrations
    ];

    public datasetId: string;
    public integrationList: IntegrationModel[] = [];
    public isLoading = true;

    constructor(
        @Optional() public dialogRef: MatDialogRef<any>,
        protected dialog: MatDialog,
        public queryService: DiscovererQueryService,
        private _integrationService: IntegrationService,
        private _activatedRouter: ActivatedRoute,
        private _toolbarService: ToolbarService,
        private _emitterService: EmitterService,
        private _matSnackBar: MatSnackBar,
        private _cdRef: ChangeDetectorRef,

    ) {

    }
    async ngOnInit() {

        this._activatedRouter.params.subscribe(async params => {
            this.datasetId = params['datasetId'];
            this.loadData();
        });
    }
    createIntegration(typeId: IntegrationType) {

        var data = {
            id: "",
            typeId: typeId,
            datasetId: this.datasetId
        };
        this.callDetailsDialog(data);
    }
    public editIntegration(id: string) {

        this.isLoading = true;

        this._integrationService.get(id, this.datasetId).subscribe(response => {

            this.isLoading = false;


            if (!response.isSuccess || response.result === false) {
                this.showSnackBarError(`integration of id ${id} not found`);
                return;
            }
            else {
                this.callDetailsDialog(response.result);
            }
        })
    }
    public deleteIntegration(id: string) {

        this.dialog.open(ConfirmationDialogComponent, { data: { message: `Are you sure you want to delete this integration ?` } }).afterClosed().subscribe(
            res => {
                this.isLoading = true;
                if (res) {
                    this._integrationService.delete(id, this.datasetId).subscribe(response => {


                        if (!response.isSuccess || response.result === false) {
                            this.showSnackBarError(`Not able to delete integration with id ${id}`);
                            return;
                        }
                        else {
                            this.showSnackBarSuccess(`Integration is Deleted Successfully`);
                            this._refreshData();
                        }
                        this.isLoading = false;
                    });
                } else {
                    this.isLoading = false;
                }
            }
        )

    }
    public getIntegrationName(typeId: IntegrationType): string {
        return integrationTypeName.get(typeId)
    }
    public getIntegrationImage(typeId: IntegrationType): string {
        return integrationTypeImage.get(typeId)
    }
    private callDetailsDialog(data: any) {

        this.isLoading = false;

        this.dialog.open(IntegrationDetailsDialogComponent, {
            data: data,
            maxWidth: '1280px',
            width: '1280px',
            maxHeight: '800px',
            panelClass: 'panel-class-details-dialog',
            disableClose: false
        }).afterClosed().subscribe(res => {
            if (res) {
                if (!res?.isSuccess) {
                    this.showSnackBarError(res.result)
                }
                else {
                    this.showSnackBarSuccess(`Integration is ${!!data.id ? 'Updated' : 'Created'}   Successfully !!`);
                    this.getAllIntegrations();
                }
            }
        });
    }
    private showSnackBarError(message: string) {
        this._matSnackBar.open(message, null, {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: ['error-snackbar']
        });
    }
    private showSnackBarSuccess(message: string) {
        this._matSnackBar.open(message, null, {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: ['success-snackbar']
        });
    }
    private async loadData() {

        this.queryService.resetFilters()
        this.queryService.refresh()
        this._emitterService.setDatasetId(this.datasetId)

        await this._toolbarService.setSettings(this.datasetId, this._activatedRouter.snapshot.routeConfig.data);

        this.getAllIntegrations();
    }
    private getAllIntegrations() {
        if (!this.datasetId)
            return [];

        this._integrationService.getAll(this.datasetId).subscribe(response => {
            this.isLoading = false;
            if (!response.isSuccess) {
                this.showSnackBarError("Something went wrong during fetch data !!!");
                return;
            }
            else {
                this.integrationList = response.result;
                this._cdRef.detectChanges();
            }
        })
    }
    private _refreshData() {
        this.getAllIntegrations();
        this._cdRef.detectChanges();


    }
    // public editIntegration(id: string) {
    //     this.dialog.open(IntegrationDetailsDialogComponent, {
    //         data: {
    //             id: id,
    //             datasetId: this.datasetId
    //         } as IIntegrationDetailsDialogData,
    //         width: '80%',
    //         height: '90vh',
    //         panelClass: 'white-class-details-dialog',
    //     }).afterClosed().subscribe(res => {
    //         if (!!res) {
    //             this.getAllIntegrations();
    //         }
    //     });
    // }
}
