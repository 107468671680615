import { Inject, Injectable, InjectionToken } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { FormsDataServiceInterface } from './forms-data-service.interface';


export const DISCO_FORM_SERVICE_URL = new InjectionToken<string>('/api/es/undefined');

@Injectable()
export class HttpFormsDataService implements FormsDataServiceInterface {

    public formServicePath: string;

    constructor(private http: HttpClient, @Inject(DISCO_FORM_SERVICE_URL) formServicePath: string) {
        this.formServicePath = formServicePath;
    }

    public getFormByKey(key: string) {
        return this.http.get(`${this.formServicePath}/${key}`);
    }

    postFormData(object: any) {
        Object.keys(object).forEach(key => {
            if (object[key] instanceof Date) {
                object[key] = new Date(object[key]).toUTCString();
            }
        });
        return this.http.post(`${this.formServicePath}`, object);
    }
}

