import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { StripeService } from './ignite/components/reports/common/user-settings/services/stripe.service';
import { DatasetService } from './ignite/components/reports/services/api/dataset.service';

@Injectable({ providedIn: 'root' })
export class AppGuardService implements CanActivate {

  constructor(private router: Router, private _stripeService: StripeService) {
  }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    var response = await this._stripeService.verifySubscription().toPromise();
    if (!response?.isSuccess) return false;
    return response.result;
  }
}


@Injectable({ providedIn: 'root' })
export class DatasetGuard implements CanActivate {

  constructor(private _datasetService: DatasetService, private router: Router) {
  }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const { isSuccess, result } = await this._datasetService.getById(route.params.datasetId);
    if (!isSuccess) return false;
    if (result === null) {
      this.router.navigate(['datasets']);
      return false;
    }
    return true;
  };
}
