import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
    BaseController, StateService, IColumnSetting
} from '@discoverer/core/services'
import { ITableViewState, IMainViewState, TabSettingsService } from '@discoverer/core/services/dynamic-reports-services';

@Component({
    selector: 'columns-to-display',
    templateUrl: './columns-to-display.component.html',
    styleUrls: ['./columns-to-display.component.scss']
})
export class ColumnsToDisplayComponent extends BaseController implements OnInit {

    @Input()
    public tableViewState: StateService<ITableViewState>;

    @Input()
    public mainViewState: StateService<IMainViewState>;

    @Output()
    public isValidColumnsEvent?: EventEmitter<boolean>;

    public selectedStatus = true;
    public rightColumns: IColumnSetting[] = [];
    public defaultColumns: IColumnSetting[] = [];
    public allColumns: IColumnSetting[] = [];
    public isDefaultColumns = true;

    constructor(private tabSettings: TabSettingsService) {
        super();
        this.isValidColumnsEvent = new EventEmitter<boolean>();
    }
    public async ngOnInit() {
        this.subscriptions.push(this.tableViewState.oState.subscribe(state => {
            this.rightColumns = state.columnSettings;
        }));
        this.allColumns = await this.tabSettings.getAllColumns();
        this.defaultColumns = await this.tabSettings.getDefaultColumns();

        this.subscriptions.push(this.mainViewState.oState.subscribe((state) => {
            this.isDefaultColumns = state.isDefaultColumns;
        }));
        this.isValidColumnsEvent.emit(true);
    }
    public setDefaultColumns(event: boolean) {
        this.updateColumnDisplay(this.defaultColumns);
    }

    public updateColumnDisplay(columnSettings: IColumnSetting[]) {
        this.isValidColumnsEvent.emit(!!columnSettings && columnSettings.length > 0);
        this.tableViewState.setValue({ columnSettings });
    }

    public updateDefaultColumns(isDefaultColumns: boolean) {
        if (!isDefaultColumns) {
            this.updateColumnDisplay(this.defaultColumns);
        }
        this.mainViewState.setValue({ isDefaultColumns });
    }

}
