import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DatasetService } from '@app/ignite/components/reports/services/api/dataset.service';
import { ERROR_SNACKBAR_OPTIONS, WARN_SNACKBAR_OPTIONS } from '../../../home/edit-title-dialog/edit-title-dialog';
import { Field } from '@app/ignite/components/reports/helpers/docpipeline-function';
import { SchemaService } from '@app/ignite/components/reports/services/schema.service';
import { MatDialogRef } from '@angular/material/dialog';

interface Template {
  key: string;
  display: string;
  fields: { [key: string]: Field }
}

@Component({
  selector: 'app-dataset-fields-templates-dialog',
  templateUrl: './dataset-fields-templates-dialog.component.html',
  styleUrls: ['./dataset-fields-templates-dialog.component.scss']
})
export class DatasetFieldsTemplatesDialogComponent implements OnInit {

  searchText: string;
  templates: Template[] = [];
  originalTemplates: Template[] = [];

  constructor(
    private _datasetService: DatasetService,
    private _snackBar: MatSnackBar,
    private _schemaService: SchemaService,
    private _dialogRef: MatDialogRef<DatasetFieldsTemplatesDialogComponent>
  ) { }

  async ngOnInit(): Promise<void> {
    await this.fetchFieldTemplates();
  }

  async fetchFieldTemplates() {
    const response = await this._datasetService.getAllTemplatesFields().toPromise();
    if (!response.isSuccess) this._showMsg(`Error fetching Templates`, ERROR_SNACKBAR_OPTIONS)
    console.log('templates', response.result)
    Object.entries(response.result).forEach(([templateName, fields]) => {
      this.originalTemplates.push({ key: templateName, display: templateName.replace(/_/g,' ').toLocaleUpperCase(), fields })
    })
    this.templates = this.originalTemplates;
  }

  filterTemplates() {
    console.log('searchText', this.searchText)
    if (!this.searchText) this.templates = this.originalTemplates;
    else this.templates = this.originalTemplates.filter(template => template.display.toLowerCase().includes(this.searchText.toLowerCase()))
  }

  async updateDocumentFields(fields: { [key: string]: Field }) {
    console.log('fields on update', fields)
    await this._schemaService.setCurrentDocumentFields(fields);
    this._dialogRef.close();
    this._showMsg(`Please save fields before making further actions`, WARN_SNACKBAR_OPTIONS)
  }

  private _showMsg(msg: string, config: MatSnackBarConfig) {
    this._snackBar.open(msg, 'Close', config)
  }
}
