import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MonacoEditorComponent, MonacoEditorConstructionOptions, MonacoStandaloneCodeEditor } from '@materia-ui/ngx-monaco-editor';
// import { DEFAULT_SCHEMA_R } from './default-yaml-schema'
import * as yaml from 'js-yaml';

@Component({
    selector: 'app-yaml-editor',
    templateUrl: './yaml-editor.component.html',
    styleUrls: ['./yaml-editor.component.scss']
})
export class YamlEditorComponent implements OnInit {
    @ViewChild(MonacoEditorComponent, { static: false }) monacoComponent: MonacoEditorComponent;
    // @Input() code = DEFAULT_SCHEMA_R;
    @Output() modelChanged: EventEmitter<any> = new EventEmitter<any>();
    error: { mark: any, message: string, name: string, reason: string, stack: string }
    public editorOptions: MonacoEditorConstructionOptions = {
        theme: "vs",
        language: "yaml",
        minimap: { enabled: false },
        lineNumbers: "off",
        automaticLayout: true
    };


    constructor() {

    }
    ngOnInit(): void {

    }
    editorInit(editor: MonacoStandaloneCodeEditor) {
        editor.onDidChangeModelDecorations(() => {
            setTimeout(() => {
                try {
                    this.error = null;
                    // const x = yaml.load(this.code)
                    // this.modelChanged.emit(x)
                } catch (ex) {
                    this.error = ex;
                }
            }, 1);
        });
    }
}