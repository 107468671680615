<ng-container *ngIf="loadingState && loadingState.status === 'Failure'">
    <div class="error-message">
        <label>{{loadingState?.errorDescription}}</label>
    </div>
</ng-container>
<div  id="pivot-chart" class="pivot-main  height-100">
    <div class="card height-100" >
        <kendo-grid style="height: 100% !important" #Grids [data]="gridView" [reorderable]="isEditMode"
            [sortable]="{mode: 'multiple', allowUnsort: true, showIndexes: true }" [sort]="gridState.sort"
            [resizable]="true" scrollable="virtual" [rowHeight]="40" [detailRowHeight]="40" [virtualColumns]="true"
            [pageSize]="pageSize" [skip]="gridState.skip" (pageChange)="pageChange($event)"
            (sortChange)="sortChange($event)" [groupable]="false"
            (columnResize)="onColumnResize($event)"
            [loading]="isBusy || loadingState?.status === 'Busy'">
            <kendo-grid-column *ngFor="let column of pivotColumns; let i = index; trackBy: trackByField"
                [field]="comparasionMode?column?.fieldCompare:column?.fieldVal1" [width]="column.width || 120" [locked]="column.locked" [lockable]="true" >

                <ng-template *ngIf="column" kendoGridHeaderTemplate>
                    <div class="flex">
                        <span>{{column.title.val1}} </span>
                        <span *ngIf="column.title.val2 && ! column.isGroupBy">
                            &nbsp;vs {{column.title.val2}}
                        </span>
                    </div>
                </ng-template>

                <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-columnIndex="columnIndex">
                    <ng-container *ngIf="column.isGroupBy else NotGroupBy">
                        {{dataFormattingService.getValue(dataItem[column.field]?.val1,
                        pivotColumns[columnIndex].dataType)}}
                    </ng-container>
                    <ng-template #NotGroupBy>
                        <ng-container *ngIf="!comparasionMode  else comparasionValues">
                            <div mat-flat-button class="link" [matMenuTriggerFor]="menu">
                                {{dataFormattingService.getValue(dataItem[column.field]?.val1,
                                pivotColumns[columnIndex]?.dataType)}}
                            </div>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="openRecords(column, dataItem, 'table-view')">View
                                    Records</button>
                                <button mat-menu-item (click)="openRecords(column, dataItem, 'pivot-view')">View
                                    Pivot</button>
                                <button mat-menu-item (click)="openRecords(column, dataItem, 'chart-view')">View
                                    Chart</button>
                            </mat-menu>
                        </ng-container>
                        <ng-template #comparasionValues>
                            <metrics-card [current]="dataItem[column.field]?.val1||0"
                                [prev]="dataItem[column.field]?.val2||0" [type]="pivotColumns[columnIndex].dataType"
                                [displayMode]="displayMode" [valDiff]="dataItem[column.field]?.valDiff"
                                (dataClicked)="openRecords(column, dataItem, $event.view,  $event.val)">
                            </metrics-card>
                        </ng-template>
                    </ng-template>

                </ng-template>

                <ng-template kendoGridFooterTemplate let-column let-columnIndex="columnIndex">
                    <ng-container *ngIf="!comparasionMode; else footerComparasionValues">
                        {{footerData[pivotColumns[columnIndex].field].val1 ?
                        dataFormattingService.getValue(footerData[pivotColumns[columnIndex].field].val1,pivotColumns[columnIndex].dataType)
                        : ''}}
                    </ng-container>
                    <ng-template #footerComparasionValues>
                        <div class="compare-value-mode">
                            <span class="first-value">
                                {{footerData[pivotColumns[columnIndex].field]?.val1 ?
                                dataFormattingService.getValue(footerData[pivotColumns[columnIndex].field]?.val1,pivotColumns[columnIndex].dataType)
                                : ''}}
                            </span>
                            <span class="second-value">
                                {{footerData[pivotColumns[columnIndex].field]?.val2 ?
                                dataFormattingService.getValue(footerData[pivotColumns[columnIndex].field]?.val2,pivotColumns[columnIndex].dataType)
                                : ''}}
                            </span>
                        </div>
                    </ng-template>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
</div>
