import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { DiscovererQueryService, BaseController, StateService,
    IChartDataConfig, IChartFormatConfig, IChartState, TabSettingsService  } from '@discoverer/core/services';
import { DialogsManagerService } from '../../dialogs';

@Component({
    selector: 'chart-view',
    templateUrl: './chart-view.component.html',
    styleUrls: ['./chart-view.component.scss']
})
export class ChartViewComponent extends BaseController implements OnInit {
    @Input()
    public serviceUrl: string;
    @Input()
    public queryService: DiscovererQueryService;
    @Input()
    public chartStates: StateService<IChartState[]>;
    @Input()
    public allKey: string;

    public charts: any[];
    public columns: any[];

    constructor(private dialogsManagerService: DialogsManagerService,
                private tabSettings: TabSettingsService) {
        super();
    }

    public async ngOnInit() {
        this.columns = await this.tabSettings.getAllColumns();
        this.subscriptions.push(
            this.chartStates.oState.subscribe((statesObj: IChartState[]) => {
                const cs: any[] = [];
                Object.keys(statesObj).forEach((key) => {
                    const state: IChartState = statesObj[key];
                    cs.push({
                        chartConfigState: new StateService<IChartDataConfig>(state.chartConfig),
                        chartFormatState: new StateService<IChartFormatConfig>(state.chartFormat),
                        isFull: state.isFull
                    });
                });
                this.charts = cs;
            })
        );
    }

    public changeChartSize($event, chart, index: number) {
        const updatedChart: IChartState = {
            chartConfig: chart.chartConfigState.getState(),
            chartFormat: chart.chartFormatState.getState(),
            isFull: $event
        };
        this.saveChartAt(index, updatedChart);
    }
    public editChart(chart, index: number) {
        const updatedChart: IChartState = {
            chartConfig: chart.chartConfigState.getState(),
            chartFormat: chart.chartFormatState.getState(),
            isFull: chart.isFull
        };
        this.addChart(updatedChart, index);
    }
    public addChart(chart: IChartState = null, index: number = -1) {

    }

    public saveChartAt(i: number, chart: IChartState) {
        this.chartStates.setValue({ [i]: chart });
    }
}
