import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UserSettingsService } from '../services/user-settings.service';
import { SidenavService } from '../services/sidenav.service';


export enum PasswordTextStatusEnum {
  SHORT = 'too short',
  WEAK = 'weak',
  GOOD = 'good',
  EXCELLENT = 'excellent',
}

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

  showIcon: boolean
  isPasswordWeak: boolean
  isUnMatch: boolean;
  lettersRegex: RegExp = /[A-Za-z]/;
  numbersRegex: RegExp = /\d/;
  specialCharsRegex: RegExp = /[^A-Za-z0-9]/;
  passwordCheckerTextEnum = PasswordTextStatusEnum
  checkerText: PasswordTextStatusEnum = PasswordTextStatusEnum.SHORT;
  hide: boolean = true;
  newPassword: FormControl = new FormControl(null, [Validators.required]);
  confirmPassword: FormControl = new FormControl(null, [Validators.required]);
  constructor(
    private _userSettingsService: UserSettingsService,
    private _sidenavService: SidenavService
  ) { }

  ngOnInit(): void {
    this._sidenavService.getScreenSize().subscribe(size => {
      if (size.width < 768) {
        this.showIcon = true
      } else {
        this.showIcon = false

      }
    });
  }

  checkPasswordStrength(event) {
    let password: string = event.target.value;

    if (password?.length < 8) {
      this.checkerText = PasswordTextStatusEnum.SHORT;
      return;
    }
    const passwordCondition = {
      isWeak: password.match(this.lettersRegex),
      isGood: password.match(this.numbersRegex),
      isExcellent: password.match(this.specialCharsRegex)
    }

    if (passwordCondition.isWeak) {
      this.checkerText = PasswordTextStatusEnum.WEAK;
    }

    if (passwordCondition.isWeak && passwordCondition.isGood) {
      this.checkerText = PasswordTextStatusEnum.GOOD;
    }

    if (passwordCondition.isWeak && passwordCondition.isGood && passwordCondition.isExcellent) {
      this.checkerText = PasswordTextStatusEnum.EXCELLENT;
    }
  }

  async savePassword() {
    if (this.newPassword.value !== this.confirmPassword.value) {
      this.isUnMatch = true;
      this.isPasswordWeak = false;
      return;
    }
    if (this.checkerText === PasswordTextStatusEnum.WEAK || this.checkerText == PasswordTextStatusEnum.SHORT) {
      this.isPasswordWeak = true;
      this.isUnMatch = false
      return;
    }
    this.isPasswordWeak = false;
    this.isUnMatch = false;
    await this._userSettingsService.updatePassword(this.newPassword.value)
  }


  toggleOuterSidenav() {
    this._sidenavService.toggleSidenav(true);
  }
}
