<div class="height-100" id="one-chart-view">
    <div *ngIf="chartState && serviceUrl" class="one-chart" [ngClass]="{'full-width': true}">
        <div class="section-area height-100" *ngIf="tab">
            <div class="section-main chart-area height-100">
                <chart-details #KENDO_CHART [chartState]="chartState" [queryService]="mainQueryService"
                    [visibleDetailsHeader]="visibleDetailsHeader" [serviceUrl]="serviceUrl"
                    [mainViewState]="mainViewState"></chart-details>
            </div>
        </div>
    </div>
</div>
