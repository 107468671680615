import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { KendoChartItemWidget } from './kendo-chart-item.widget';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';


import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PopupModule } from '@progress/kendo-angular-popup';
@NgModule({
  imports: [
    CommonModule,
    ChartsModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    PopupModule
  ],
  declarations: [
    KendoChartItemWidget
  ],
  entryComponents: [
    KendoChartItemWidget
  ],
  providers: [
    TitleCasePipe
  ],
  exports: [
    ChartsModule,
    KendoChartItemWidget
  ]
})
export class KendoChartItemModule {
  constructor() {

  }
}

export { KendoChartItemWidget };
