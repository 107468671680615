<div class="reports-container full-screen">
    <div class="header">
        <div class="left">
            <mat-form-field class="search-header">
                <input #searchField placeholder="Lookup Report/Directory Name" matInput type="text"
                    [formControl]="searchFieldControl">
                <mat-icon class="icon" matSuffix mat-icon-button *ngIf="searchFieldControl.value"
                    (click)="clearSearch()">close</mat-icon>
            </mat-form-field>
        </div>
        <div class="right">
            <div class="actions">
                <button mat-flat-button [matMenuTriggerFor]="newRequest" color="action-button"
                    color="primary">New</button>
                <mat-menu #newRequest="matMenu">
                    <button mat-menu-item (click)="openNewDashboard()">
                        <mat-icon>dashboard</mat-icon>
                        Dashboard</button>
                    <mat-divider></mat-divider>
                    <button mat-menu-item (click)="openNewReport(viewTypes.Table)">
                        <mat-icon>table_chart</mat-icon>
                        Grid</button>
                    <button mat-menu-item (click)="openNewReport(viewTypes.Chart)">
                        <mat-icon>bar_chart</mat-icon>
                        Chart</button>
                    <button mat-menu-item (click)="openNewReport(viewTypes.Pivot)">
                        <mat-icon>list_alt</mat-icon>
                        Pivot</button>
                    <button mat-menu-item (click)="openNewReport(viewTypes.Kanban)">
                        <mat-icon>view_column</mat-icon>
                        Kanban</button>
                </mat-menu>
            </div>
        </div>
    </div>
    <div class="body">
        <filtered-reports-view *ngIf="!isLoading else IS_LOADING" [isOpenReport]="true" (scrollingFinished)="loadMore()"
            (clickedRequest)="openSearchedReport($event)" [requests]="searchResults" [highlitedRequests]="[]" [searchText]="highliterText">
            <div endOfListCard>
                <div *ngIf="noMoreRequests" class="end-message"> No More Requests to Load </div>
            </div>
        </filtered-reports-view>
        <ng-template #IS_LOADING>
            <div class="spinner">
                <mat-spinner></mat-spinner>
            </div>
        </ng-template>
    </div>
</div>
