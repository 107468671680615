import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';

import {
    IChartDataConfig, IChartFormatConfig, StateService,
    BaseController, NumberGroupBy, DiscovererQueryService
} from '@discoverer/core/services';

import { IChartState, IMainViewState, TabSettingsService, ReportPersistService, ITab2 } from '@discoverer/core/services';
import { filter, first } from 'rxjs/operators';
import { DataSourceContext } from '@discoverer/core/data-source/data-source.component';
import { orderBy } from 'lodash';
import { SortDescriptor } from '@progress/kendo-data-query';
import { ChartDetailsComponent } from '../../customize-report';



@Component({
    // tslint:disable-next-line:component-selector
    selector: 'one-chart-view',
    templateUrl: './one-chart-view.component.html',
    styleUrls: ['./one-chart-view.component.scss']
})
export class OneChartViewComponent extends BaseController implements OnInit {

    @Input() public queryService: DiscovererQueryService;
    @Input() public chartState: StateService<IChartState>;
    @Input() public hideEditChart = true;
    @Input() public mainViewState: StateService<IMainViewState>;
    @Input() public chartMaxLabels: number;
    @Input() public visibleDetailsHeader: boolean = true;

    @Output() public hideEditChartEvent = new EventEmitter<boolean>();
    // tslint:disable-next-line:variable-name
    @ViewChild('KENDO_CHART') public KendoChart: ChartDetailsComponent;
    public tab;
    public chart: any = {};
    public columns: any[];
    public isFull: boolean;
    public serviceUrl: string;
    public mainQueryService: DiscovererQueryService;
    public errorMsg: string;
    // public isError = false;
    public pageTitle: string;
    public filters;
    public gridView;
    public context;
    public sort: SortDescriptor[] = [
        {
            field: 'Status',
            dir: 'desc'
        }
    ];
    sorts = [{
        sortField: 'Status',
        dir: 'desc'
    }];
    // tslint:disable-next-line:max-line-length
    constructor(
        private tabSettings: TabSettingsService,
        private reportPersist: ReportPersistService,
        private _cdref: ChangeDetectorRef
    ) {
        super();
    }

    public async ngOnInit() {
        this.tab = await this.tabSettings.getCurrentTab();
        this.columns = await this.tabSettings.getAllColumns();
        this.pageTitle = this.tab.name + ' ' + this.reportPersist.mainViewState.getState().mainTitle;
        this.mainViewState = this.reportPersist.mainViewState;
        this.mainQueryService = this.reportPersist.mainQueryService;
        this.mainQueryService.oQuery.subscribe(x => { this.filters = x.filters; });
        this.serviceUrl = (await this.tabSettings.getCurrentTab()).serviceUrl;
        this.subscribeToReport();
    }

    public changeChartSize($event) {
        this.chartState.setValue({ isFull: $event });
    }
    public contextChanged(context: DataSourceContext) {
        if (!!context && !!context.data) {
            this.context = context;
            this.columns = Object.keys(context.columnDictionary).map(x => context.columnDictionary[x]);

            this.loadData();
        }
    }
    private loadData(): void {
        this.gridView = {
            data: orderBy(this.context.data, this.sort),
            total: this.context.data.length
        };
    }
    public sortChange(sort: SortDescriptor[]): void {
        this.sort = sort;
        this.loadData();
    }


    private subscribeToReport() {
        this.subscriptions.push(
            this.reportPersist.oRequestLoaded.pipe( filter( r => !!r && !!r.chart)).subscribe(reportLoaded => {
                const state = reportLoaded.chart;
                this.chartState = null;
                this._cdref.detectChanges();
                this.chartState = this.reportPersist.chartState;
                this._cdref.detectChanges();
            }));
    }

    public exportChart(type: string) {
        this.KendoChart.exportChart(type);
    }
}
