import {
    Component,
    Input,
    OnInit,
    OnChanges,
    SimpleChanges,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    OnDestroy,
    Output,
    EventEmitter,
} from "@angular/core";
import {
    DiscovererQueryService,
    BaseController,
    FILTERING_OPERATION,
    IFacetDefinition,
    LoadingState,
} from "../services";
import { format } from "@progress/kendo-angular-intl";

import { NumericRangeFilterService } from "./numeric-range-filter.service";



@Component({
    selector: "numeric-range-filter",
    styleUrls: ["./numeric-range-filter.component.scss"],
    templateUrl: "./numeric-range-filter.component.html",
    providers: [
        { provide: NumericRangeFilterService }
    ]
})
export class SliderFacetComponent
    extends BaseController
    implements OnChanges, OnInit, OnDestroy {

    @Input()
    title: string;

    @Input()
    facetKey: string;

    @Input()
    format = "{0:n2}";

    @Input()
    postfix = "";

    @Input()
    prefix = "";

    @Input()
    isOpen = false;

    @Input()
    min: number = 0;

    @Input()
    max: number = 1266;

    @Input()
    step: number;

    @Input()
    serviceUrl: string;

    @Input()
    excludeRange: boolean;

    @Input()
    queryService: DiscovererQueryService;

    @Input()
    allKey: string;

    @Input()
    filterMode: string;

    @Input() isCollapsible = true;

    @Input()
    clearButtonKey;

    @Input() isPinned = false;

    @Input() showPin: boolean = false;
    @Input() showDoneButton: boolean = false;


    @Output() pinFilter: EventEmitter<IFacetDefinition> = new EventEmitter<IFacetDefinition>();
    @Output() doneButtonClicked: EventEmitter<any> = new EventEmitter<any>();

    loadingState: LoadingState;
    showClearButtonWhenCardIsNotOpen: boolean;

    numericOperations = FILTERING_OPERATION.filter(
        (o) =>
            o.allowedTypes.includes("numeric") ||
            o.label.includes("--- Filter By ---")
    );
    numericMinOperations = FILTERING_OPERATION.filter(
        (o) => o.allowedTypes.includes("numeric") && o.label.includes("more")
    );
    numericMaxOperations = FILTERING_OPERATION.filter(
        (o) => o.allowedTypes.includes("numeric") && o.label.includes("less")
    );



    constructor(
        private _cdRef: ChangeDetectorRef,
        public numericRangeFilterService: NumericRangeFilterService
    ) {
        super();
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            !!changes["queryService"] ||
            !!changes["facetKey"] ||
            !!changes["isOpen"]
        ) {
            this.numericRangeFilterService.setQueryService(this.queryService);
            this.numericRangeFilterService.setFacetKey(this.facetKey);
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.numericRangeFilterService.destroy();
    }

    facetTitleClicked() {
        this.isOpen = !this.isOpen;
        this._cdRef.markForCheck();
    }

    formatNumber(num: number): string {
        return (
            format(this.format ? this.format : "{0:0}", [num], "en") +
            (this.postfix ? this.postfix : "")
        );
    }


    isFilterApplied() {
        return this.queryService?.filters?.find((filter) =>
            filter?.fields?.every((field) => field === this.facetKey)
        );
    }
    getFilterDisplay() {
        return this.queryService?.filters?.find(
            (filter) => filter?.fields?.every((field) => field === this.facetKey)
            )?.facetValues?.map((field) => field.title).join(', ');
    }

    clearFilter() {
        this.numericRangeFilterService.resetState();
    }



    public doubleInputsUICondition(output) {
        return output.masterSelection != 'NUMERIC_BLANK' && output.masterSelection != 'NUMERIC_NOT_BLANK' && output.masterSelection != ''
    }
    public addPinnedFilter() {
        this.pinFilter.emit({ group: "0", isPinned: !this.isPinned, name: this.facetKey });
    }
}
