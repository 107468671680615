import { Component, Directive, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DiscovererDataService } from './core-data-services';

@Directive({})
export class BaseController implements OnDestroy {

    protected subscriptions: Subscription[];
    protected dataServices: DiscovererDataService<any>[];

    constructor() {
        this.subscriptions = [];
        this.dataServices = [];
    }
    public ngOnDestroy(): void {
        this.unsubscribeAll(this.subscriptions, this.dataServices);
    }

    protected unsubscribeAll(subscriptions: Subscription[] = this.subscriptions, dataServices: DiscovererDataService<any>[] = this.dataServices) {
        if (!!dataServices) {
            dataServices.forEach(ds => {
                if (!!ds) {
                    ds.destroy();
                }
            });
        }
        if (!!subscriptions) {
            subscriptions.forEach(sub => {
                if (!!sub && !sub.closed) {
                    sub.unsubscribe();
                }
                this.subscriptions = this.subscriptions.filter( s => s !== sub );
            });
        }
    }

    protected destroyDataServices() {
        if ( !!this.dataServices) {
            this.dataServices.forEach(ds => {
                if (!!ds) {
                    ds.destroy();
                }
            });
        }
    }
}
