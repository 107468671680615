import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { Observable, of } from 'rxjs';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'custom-multicheckbox',
    templateUrl: `custom-multicheckbox.component.html`,
    styleUrls: ['./custom-multicheckbox.component.scss']
})
export class CustomMultiCheckboxComponent extends FieldType {
    constructor() {
        super();
    }

    onChange(optionValue: any, checked: boolean) {
        if (!this.formControl.value) {
            this.formControl.setValue({});
        }
        this.formControl.value[optionValue] = checked;
        this.field.formControl.updateValueAndValidity();
    }

    isChecked(optionValue: any) {
        return this.formControl.value?.[optionValue];
    }

    getOptionIsDisabled(optionValue) {
        return this.to.disabledOptions?.includes(optionValue);
    }

    asObservable(input: any[] | Observable<any[]>): Observable<any[]> {
        return Array.isArray(input) ? of(input) : input;
    }
}


