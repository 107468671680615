import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IColumSetting, ITab2 } from '../classes/report-state';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class JSONDataService {
    constructor(private http: HttpClient) { }

    public getTabsByAppId(appId: string): Observable<any> {
        const url = `/api/colsettings/${appId}`;
        return this.http.get(url);
    }
    public getColumnsByTabId(appId: string, key: string): Observable<any> {
        const url = `/api/colsettings/${appId}/${key}`;
        return this.http.get(url);
    }
    public async saveColumns(appId: string, tabId: string, colsettings: IColumSetting[]): Promise<any> {
        const url = `/api/colsettings/${appId}/${tabId}/save`;
        return this.http.post(url, colsettings).toPromise();
    }
    public async saveTab(appId: string, tabConfig: any): Promise<any> {
        const url = `/api/colsettings/${appId}/savetab`;
        return this.http.post(url, tabConfig).toPromise();
    }
}
