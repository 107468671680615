import { switchMap, map } from 'rxjs/operators';
import { IDynForm } from '../common/models';
import { Injectable } from '@angular/core';
import { BlocPatternServiceBase } from '../services/bloc-pattern-service-base';
import { FormsDataServiceInterface } from '../services';

@Injectable()
export class GetDynFormsBloc extends BlocPatternServiceBase<string, IDynForm[]> {

    public directories: any;

    constructor(private formsDataService: FormsDataServiceInterface) {
        super();
        this._$input.pipe(switchMap(directory => {
            return this.formsDataService.getFormsByDirectory(directory)
                .pipe(map((result: IDynForm[]) => {
                    const mappedResult = result.map(r => {
                        r.RelativeDirectory = r.Directory === directory ? '' : r.Directory.replace(directory + '/', '');
                        return r;
                    });

                    this.directories = this.groupBy(result, 'RelativeDirectory');
                    return mappedResult;
                })
                );
        })).subscribe(s => {
            this._$output.next({ input: '', output: s });
        });
    }

    public setDirectory(directory: string) {
        this._$input.next(directory);
    }


    private groupBy(list: any[], groupKey: string) {
        const groups: { group: string; items: any[] }[] = [];
        list.forEach(item => {
            const group = item[groupKey];
            const i = groups.findIndex(x => x.group === group);
            if (i !== -1) {
                groups[i].items.push(item);
            } else {
                groups.push({ group, items: [item] });
            }
        });
        return groups;
    }
}
