import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendResponse } from '../../models/common';
import { BackendRouteEndpoint } from './constants/backend-route-constants'

@Injectable({
    providedIn: 'root'
})
export class DatasetFileService {

    constructor(
        private httpClient: HttpClient
    ) { }
  // TODO: Add Types instead of BackendResponse<any>

    getAll(datasetId: string) {
        return this.httpClient.get<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}`);
    }
    getTotalNonTestAndNeedsReprocessingFileCount(datasetId: string, needsReprocess: boolean) {
        return this.httpClient.get<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/need-reprocess/${needsReprocess}/total-count`);
    }

    uploadWithProgress(datasetId: string, file) {
        const req = new HttpRequest('POST', `${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/upload`, file, {
            reportProgress: true,
            responseType: 'json'
        });
        return this.httpClient.request(req);
    }
    upload(datasetId: string, formData) {
        return this.httpClient.post<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/upload`, formData);
    }

    getOriginalFile(datasetId: string, fileId: string) {
        return this.httpClient
            .get((`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/${fileId}/download`), {
                observe: 'response',
                responseType: 'blob'
            });
    }
    getResults(datasetId: string, fileId: string) {
        return this.httpClient.get<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/${fileId}/results`)
    }

    updateFileResults(datasetId: string, fileId: string, data) {
        return this.httpClient.put<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/${fileId}/results`, data)
    }

    getLogs(datasetId: string, fileId: string) {
        return this.httpClient.get<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/${fileId}/logs`);
    }

    reprocessAllFiles(datasetId: string, useNeedsReprocessing: boolean) {
        return this.httpClient.post<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/re-process/use-need-reprocess/${useNeedsReprocessing}`, null);
    }

    reprocessById(datasetId: string, fileId: string) {
        return this.httpClient.post<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/${fileId}/re-process`, null);
    }

    deleteById(datasetId: string, fileId: string) {
        return this.httpClient.delete<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/${fileId}`)
    }

    getFileDetails(datasetId: string, fileId: string) {
        return this.httpClient.get<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/${fileId}/details`)
    }
}
