<div id="select-tab">
    <div class="header color-primary-bg">
        <h3 class="title">Select a Data Set </h3>
        <mat-icon class="close-icon" (click)="close()">close</mat-icon>
    </div>
    <div class="main">
        <mat-select class="tab" placeholder="Data Set" [formControl]="tabControl">
            <mat-option *ngFor="let tab of tabs" [value]="tab">{{tab.name}} </mat-option>
        </mat-select>
    </div>

    <div class="footer">
        <button mat-stroked-button class="rounded" (click)="close()">Cancel</button>
        <button mat-flat-button class="rounded" color="accent" [disabled]="tabControl.invalid" (click)="open()">Open</button>
    </div>

</div>