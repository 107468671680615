import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { first, map } from 'rxjs/operators';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { OptionListDataServiceInterface } from './data-service';
import { IOptionList } from '../models/option-list';
import { Observable } from 'rxjs';

@Injectable()
export class HasuraOptionListService implements OptionListDataServiceInterface {
    constructor(@Inject(forwardRef(() => Apollo)) private apollo: Apollo) {

    }

    public add(list: IOptionList): Observable<{ mutationResult: { errors: any } | IOptionList }> {
        return this.apollo.mutate({
            mutation: gql`mutation optionList($description: String!) {
                insert_ignt_core_OptionLists(objects: {Description: $description}) {
                  returning {
                    Description
                    Id
                  }
                }
              }
              `, variables: { description: list.description }
        }).pipe(map((x: any) => {
            return { mutationResult: x.errors ? x : this.mapData(x.data.insert_ignt_core_OptionLists.returning[0]) };
        }));
    }

    public update(list: IOptionList): Observable<{ mutationResult: { errors: any } | IOptionList }> {
        return this.apollo.mutate({
            mutation: gql`mutation optionList($listId: Int!, $description: String!) {
                update_ignt_core_OptionLists(where: {Id: {_eq: $listId}}, _set: {Description: $description}) {
                  returning {
                    Description
                    Id
                  }
                }
              }
              `, variables: { listId: list.id, description: list.description }
        }).pipe(map((x: any) => {
            return {
                mutationResult: x.errors ? x : this.mapData(x.data.update_ignt_core_OptionLists.returning[0])
            };
        }));
    }
    public delete(list): Observable<{ mutationResult: { errors: any } | null }> {
        return this.apollo.mutate({
            mutation: gql`mutation delete_ignt_core_OptionLists($listId: Int!) {
                delete_ignt_core_OptionLists(where: {Id: {_eq: $listId}}) {
                  affected_rows
                  returning {
                    Id
                    Description
                  }
                }
              }
              `, variables: { listId: list.id }
        }).pipe(map((x: any) => {
            return {
                mutationResult: x.errors ? x : null
            };
        }));
    }
    public get(searchText: string) {
        let queryObservable;
        if (searchText) {
            queryObservable = this.apollo.query({
                query: gql`query getOptionLists($search: String!) {
                    ignt_core_OptionLists(where: { Description: { _ilike: $search } }, order_by: {Id: desc}) {
                      Id
                      Description
                    }} `,
                variables: { search: '%' + searchText + '%' }
            });
        } else {
            queryObservable = this.apollo.query({
                query: gql`query OptionLists {
                    ignt_core_OptionLists(order_by: {Id: desc}) {
                      Description
                      Id
                    }
                  }
                  `,
            });
        }
        return queryObservable.pipe(first(), map((x: any) =>
            x.data.ignt_core_OptionLists.map(z => {
                return { id: z.Id, description: z.Description, options: [] };
            })));
    }

    mapData(item): IOptionList {
        return item ? { id: item.Id, description: item.Description } as IOptionList : null
    }
}
