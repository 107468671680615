<app-file-result-displayer  [datasetId]="datasetId" [fileId]="fileId" *ngIf="result && !isLoading && isDataExists"
    (results)="isDataExists = !!$event"></app-file-result-displayer>

<div *ngIf="!isDataExists && !isLoading" class="no-data-label">
    <p>No Data</p>
</div>

<div *ngIf="isLoading" class="spinner">
    <mat-spinner diameter="100"></mat-spinner>
</div>
