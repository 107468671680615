import { Injectable } from '@angular/core';
import { Theme, light, dark, calson, sett } from './theme';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    private active: Theme;
    private availableThemes: Theme[] = [light, dark];

    getAvailableThemes(): Theme[] {
        return this.availableThemes;
    }

    getActiveTheme(): Theme {
        return this.active;
    }

    isDarkTheme(): boolean {
        return this.active.name === dark.name;
    }

    setActiveThemeByName(themeName: string): void {
      this.setActiveTheme( this.availableThemes.find( x => x.name == themeName)  );
    }

    setActiveTheme(theme: Theme): void {
        this.active = theme;
        const element = document.getElementsByTagName('body')[0];
        element.className = theme.name;
    }
}
