import { Component, ViewChild, ChangeDetectorRef, OnInit, ViewEncapsulation, OnDestroy, AfterViewInit } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { MatRadioGroup } from '@angular/material/radio';
import { Subject } from 'rxjs';
import { OptionDataServiceInterface } from '../../option-lists/shared-services/data-service';
import { HasuraOptionService } from '../../option-lists/shared-services/hasura-option-service';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'formly-field-mat-radio',
  templateUrl: './dyn-radio.component.html',
  styleUrls: ['./dyn-radio.component.scss'],
  providers: [{ provide: OptionDataServiceInterface, useClass: HasuraOptionService }],
  encapsulation: ViewEncapsulation.None
})
// tslint:disable-next-line:component-class-suffix
export class DynRadio extends FieldType implements OnInit, OnDestroy {
  onDestroy$ = new Subject<void>();
  @ViewChild(MatRadioGroup, { static: true }) radioGroup!: MatRadioGroup;
  public dynOptions;
  constructor(
    private optionsService: OptionDataServiceInterface,
    private cd: ChangeDetectorRef) {
    super();
  }
  ngOnInit() {
    let filterByGroup = this.field.templateOptions?.filterByGroup;
    if (this.field.templateOptions.optionsListId != null) {
      this.optionsService
        .getByOptionListId(this.field.templateOptions.optionsListId, "")
        .subscribe((response: any) => {
          this.dynOptions = filterByGroup
            ? response.filter((option) => option.group === filterByGroup)
            : response;
          this.cd.markForCheck();
        });
    } else {
      this.optionsService
        .getByQuery(this.field.templateOptions.query)
        .subscribe((response) => {
          this.dynOptions = response;
          this.cd.markForCheck();
        });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getDisplayMember(option) {
    const to = this.field.templateOptions;
    const displayMember = to && to.displayMember ? to.displayMember : 'code';
    return option[displayMember];
  }
}
