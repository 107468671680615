import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewDirectoryDialog } from '../add-directory/new-directory.dialog';

@Component({
  selector: 'edit-title',
  templateUrl: './edit-title.dialog.html',
  styleUrls: ['./edit-title.dialog.scss']
})
export class EditTitleDialog {

    public title: FormControl;
    public showError = false;

    public isValidForm() {
        return this.title.valid;
    }

    constructor(
        private dialogRef: MatDialogRef<NewDirectoryDialog>,
        @Inject(MAT_DIALOG_DATA) public data: {title}
        ) {
        this.createForm(data.title);
    }

    public close() {
        this.dialogRef.close();
    }
    public save() {
        if (this.isValidForm()) {
            this.dialogRef.close(this.title.value);
        } else {
            this.showError = true;
        }
    }

    private createForm(currentName) {
        this.title = new FormControl(currentName, [Validators.required]);
    }
}
