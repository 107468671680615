<div class="dialog">
    <div class="dialog-body" *ngIf="tab?.key ">
        <div style="width: 100%;" *ngIf="reqId">
            <disco-tab [appId]="tab.app.key" [tabId]="tab?.key">
                <ng-template let-tabContext>
                    <disco-report [queryService]="queryService" *ngIf="tabContext" [requestId]="reqId">
                        <ng-template let-reportContext>
                            <div class="inset-0 flex flex-col min-w-0 overflow-hidden noselect">
                                <workspace #WORKSPACE [hideEditColumn]="false" [enableSelect]="false"
                                    [hideActions]="true">
                                </workspace>
                                <div class="dialog-footer">
                                    <button class="foot-btn" mat-stroked-button (click)="close()">Cancel</button>
                                    <button class="foot-btn" mat-flat-button 
                                        (click)="addToDashboard(WORKSPACE.reportPersist)" color="primary">Save</button>
                                </div>
                            </div>
                        </ng-template>
                    </disco-report>
                </ng-template>
            </disco-tab>
        </div>
    </div>
</div>
<div class="overlay-spinner" *ngIf="loading">
    <mat-spinner></mat-spinner>
</div>