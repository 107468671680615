import { SpecificDataType, GeneralDataType, IColumnSetting } from "@discoverer/core/services/classes/discoverer.class";


export class ColumnSetting implements IColumnSetting {
    isRequired?: boolean;
    showInFilter?: boolean;
    width: number;
    format?: string;
    order?: number;
    isSelected?: boolean;
    referenceLink?: IReferenceLink;
    detailColumn?: boolean;
    isDynamicFacet: boolean;
    dynamicFacetFieldName: string;
    constructor(
        public fieldName: string,
        public dataType: SpecificDataType,
        public display: string,
        public type: GeneralDataType,
        public nestedPath?: string,
        public hidden = false,
        public displayOrder = 1,
    ) {
        this.showInFilter = !hidden;
        this.width = 200;
        this.isDynamicFacet = true;
        this.dynamicFacetFieldName = '';
        this.form = new FormSettings(dataType);
    }
    form: FormSettings;

}
export interface IReferenceLink {
    sourceIdColumn: string;
    targetTable: string;
    targetTab: string;
    targetIdColumn: string;
    targetNameColumn: string;
    filterBy: string;
}


export class FormSettings {
    type: string;
    templateOptions: any;
    expressionProperties: any;
    readOnly: boolean;
    exclude: boolean;
    hideExpression: string;
    constructor(_type?: string) {
        if (_type) {
            this.type = this.DefaultFormType(_type);
            this.templateOptions = null;
            this.expressionProperties = null;
            this.readOnly = false;
            this.exclude = false;
            this.hideExpression = null;
        }
        // this.type = obj && obj.type || this.DefaultFormType(dataType);
        // this.readOnly = obj?.readOnly || false;
        // this.exclude = obj?.exclude || false;
        // this.templateOptions = obj && obj.templateOptions || {
        //     type: dataType === 'currency' || dataType === 'int' || dataType === 'double' ? 'number' : 'text',
        //     required: false,
        //     label: display,
        //     floatLabel: 'always',
        //     tabindex: 1,
        // };
        // this.expressionProperties = obj && obj.expressionProperties || null;
    }
    DefaultFormType(type?: string) {
        switch (type) {
            case 'percent':
            case 'currency':
            case 'double':
            case 'string_array':
            case 'text':
            case 'string':
            case 'phone':
            case 'int':
                return 'input';
            case 'timestamp':
            case 'date':
                return 'datepicker';
            case 'boolean':
                return 'checkbox';
            case 'object_array':
                return 'object_array'
            case 'object':
                return 'object'
            default: return 'input';
        }
    }
}

