import { Subscription } from 'rxjs';
import { Component, OnInit, Output, OnDestroy } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { IOptionList } from '../models/option-list';
import { OptionDialogComponent } from '../options-dialog/options-dialog';
import { GetCategoriesBloc } from '../shared-services/categories.bloc';
import { MutateCategoryBloc } from '../shared-services/option-list.bloc';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'category-list',
    templateUrl: './category-list.component.html',
    styleUrls: ['./category-list.component.scss']
})
export class ListContainerComponent implements OnInit, OnDestroy {

    @Output()
    public selectedCategory: IOptionList;
    public categoryDetailsForm: FormGroup;
    public searchControl: FormControl = new FormControl('');
    public filteredLists = new Array<IOptionList>();
    public optionLists = new Array<IOptionList>();
    public OptionListsChangesSubscription: Subscription;
    constructor(
        public getCategoriesBloc: GetCategoriesBloc,
        public mutatOptionListBloc: MutateCategoryBloc,
        public dialog: MatDialog,
        private _formBuilder: FormBuilder,
    ) {
        this.initForm();
    }
    async ngOnInit() {
        this.getOptionLists();
        this.searchCategoies();
        this.subscribeToOptionListChanges();
        this.getCategoriesBloc.setOptionLists('');

    }

    private subscribeToOptionListChanges() {
        this.OptionListsChangesSubscription = this.mutatOptionListBloc.result.subscribe(x => {
            x.result == 'OK' ? this.selectedCategory = x.output : this.displayError(x.message);
            this.getCategoriesBloc.refresh();
        });
    }

    private displayError(message: string) {
        return this.dialog.open(OptionDialogComponent, {
            data: { message },
            autoFocus: false
        });
    }

    public addOptionList() {
        const newList = {
            id: 0,
            description: '',
            options: []
        } as IOptionList;
        this.selectedCategory = newList;
    }
    public saveChanges() {
        if (!this.categoryDetailsForm.invalid) {
            const category: IOptionList = Object.assign(this.selectedCategory, this.categoryDetailsForm.value);
            this.mutatOptionListBloc.addOptionList(category);
        } else {
            this.markAsTouched();
        }
    }

    private initForm() {
        this.categoryDetailsForm = this._formBuilder.group({
            description: ['', Validators.required],
        });
    }

    private markAsTouched() {
        Object.keys(this.categoryDetailsForm.controls).forEach(key => {
            this.categoryDetailsForm.controls[key].markAsTouched();
        });
    }
    private searchCategoies() {
        this.searchControl.valueChanges.subscribe(x => {
            if (x) {
                this.filteredLists = this.optionLists.filter(ol => ol.description.toLowerCase().includes(x.toLowerCase()));
            } else {
                this.filteredLists = this.optionLists;
            }
        });
    }

    private getOptionLists() {
        this.getCategoriesBloc.result.subscribe(x => {
            this.filteredLists = this.optionLists = x.lists;
        });
    }

    ngOnDestroy(): void {
        this.OptionListsChangesSubscription.unsubscribe();
    }
}
