import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
    OnInit,
    ChangeDetectorRef,
    ChangeDetectionStrategy
} from '@angular/core';
import {
    DiscovererQueryService,
    FacetValue,
    DRFilter,
    BaseController,
    DiscovererFacetTranslationService,
    AdvancedFilterService,
    IDRFilter,
    DRFacetOption,
    searchForText
} from '@discoverer/core/services';
import {AdvancedDialog, ColumnSettingsCustomizationDialog, DialogsManagerService} from '../../dialogs';
// tslint:disable-next-line:import-spacing

import {DSearchFilter, IColumnSetting, StateService} from '@discoverer/core/services';
import {disLogger} from '@discoverer/core/services';

import {Observable, Subject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {map, tap, first, debounceTime, take, takeWhile, startWith} from 'rxjs/operators';
import {ITableHeader, TabSettingsService, ReportPersistService, ITableViewState, IMainViewState, IKanbanViewState, Permission, UserPermissionService} from '@discoverer/core/services';

import {HttpClient} from '@angular/common/http';
import {Apollo} from 'apollo-angular';
import {FormControl} from '@angular/forms';


@Component({
    selector: 'search-header',
    styleUrls: ['./search-header.component.scss'],
    templateUrl: './search-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchHeaderComponent extends BaseController implements OnChanges, OnInit {


    @Input() queryService: DiscovererQueryService;
    @Input() tableHeaderConfig: ITableHeader;
    @Input() selectedView: string;
    @Input() hideFilters = false;
    @Input() mainViewState: StateService<IMainViewState>;
    @Output() changeView: EventEmitter<string> = new EventEmitter<string>();
    @Output() openFiltersClick: EventEmitter<boolean> = new EventEmitter<boolean>()

    public oSearchValue: Observable<string>;
    public columnDictionary: {[key: string]: IColumnSetting};
    public tabTitle: string;
    public displayedAdvFilters: Array<{
        filters: Array<any>
        type: string
    }>;
    public advFilterService: AdvancedFilterService
    public showGroupingWarning = false;
    public canChangeColumnSettings;
    public fetchedRecordsLength;
    private $searchUpdate: Subject<boolean> = new Subject<boolean>();

    public filtersListControl = new FormControl('');
    public filteredcolumns: Observable<any[]>;

    currentTab;
    currentQuery;
    availableColumns = [];
    constructor(
        private dialog: MatDialog,
        public tabSettings: TabSettingsService,
        public reportSettings: ReportPersistService,
        public dialogsManagerService: DialogsManagerService,
        private facetTranslationService: DiscovererFacetTranslationService,
        private http: HttpClient,
        private apollo: Apollo,
        private _cdr: ChangeDetectorRef,
        private _userPermissionService: UserPermissionService
    ) {
        super();

    }

    public trackByFields(index: number, filter: DRFilter) {
        return filter.fields.join();
    }
    removeEmptyfacetFilters(event: boolean = false) {
        if (!event) {
            const emptyFilters = this.currentQuery.filters.filter(f => f.type == 'facet' && f.expression.length === 0 && !f.openFacet);
            if (emptyFilters.length > 0) {
                emptyFilters.forEach(fltr => this.queryService.unSetFilter(fltr.fields[0]));
                this.queryService.refresh();
            }
        }
    }
    public async ngOnInit() {
        this.advFilterService = new AdvancedFilterService(this.facetTranslationService, this.http, this.apollo, this.tabSettings);
        this.currentTab = await this.tabSettings.getCurrentTab();
        this.tabTitle = this.currentTab.name;
        this.canChangeColumnSettings = await this._userPermissionService.hasPermission(this.currentTab.key, Permission.ChangeColumnSettings);
        this.oSearchValue = this.queryService.oQuery.pipe(map(s => {
            const searchFilter = s.filters.find(f => f.type === 'text');
            return !!searchFilter ? (searchFilter as DSearchFilter).originalValue : '';
        }));

        this.subscriptions.push(this.$searchUpdate.pipe(debounceTime(250)).subscribe(s => {
            this.queryService.refresh();
        }));

        if (!!this.queryService ) {
            this.subscriptions.push(this.queryService.oQuery.subscribe(s => {
                this.currentQuery = s
                const advFilters = s.filters.filter(f => f.type == 'Any' || f.type == 'All');
                if (advFilters.length) {
                    this.displayedAdvFilters = [];
                    this.displayedAdvFilters.push({
                        filters: advFilters,
                        type: advFilters[0].type
                    });
                } else {
                    this.displayedAdvFilters = null;
                }
                this._cdr.detectChanges();
            }));
        }
        this.filteredcolumns = this.filtersListControl.valueChanges.pipe(
            startWith(''),
            map(v => this.availableColumns
                .sort((a, b) => (a?.display > b?.display) ? 1 : -1)
                .filter(option => (option?.display?.trim() ?? "").length > 0)
                .filter(option => searchForText(v, option?.display?.toLowerCase()))
            ));
    }


    public async addEmptyFilter(value) {
        // this.blurAll();

        const col = this.availableColumns.find(col => col.display === value);
        var facetValues = [];
        switch (col.type) {
            case 'date':
                facetValues = [new FacetValue(new DRFacetOption('NULL TO NULL', '[NULL TO NULL]', 'Is not Blank'))];
                break;
            case 'numeric':
                facetValues = [new FacetValue(new DRFacetOption('NULL TO NULL', '[NULL TO NULL]', 'Is not Blank'))];
                break;
        }
        const newFilter = new DRFilter('facet', [col.fieldName], facetValues, [], '0');
        newFilter.openFacet = true;
        this.queryService.setFilter(col.fieldName, newFilter, false);
        this.queryService.refresh();
        this.filtersListControl.reset();
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.tableHeaderConfig) {
            this.fetchedRecordsLength = this.min(this.tableHeaderConfig?.skip
                +
                this.tableHeaderConfig?.pageSize, this.tableHeaderConfig?.resultLength);
        }
    }
    public isFilterExcluded(filter) {
        return filter.facetValues.some(x => x.isExclusion);
    }
    // TODO: move code to a separate BLOC
    public async searchTextUpdate(searchText: string) {
        const statement = searchText.endsWith(" ") ? searchText : searchText + "*";
        const fields = await this.getSearchTextColumns();
        disLogger('unset search filter');
        this.queryService.unSetFilter('search');
        const keys = Object.keys(this.queryService.filters);
        keys.forEach(k => {
            if (!!this.queryService && !!this.queryService.filters && !!this.queryService.filters[k]
                && this.queryService.filters[k].type === 'text') {
                this.queryService.unSetFilter(k);
            }
        });
        if (!!searchText && searchText.trim() !== '') {
            this.queryService.setFilter('search', new DSearchFilter('text', fields, [statement], searchText));
        }
        this.$searchUpdate.next(true);

    }

    // TODO: move code to a separate BLOC
    private async getSearchTextColumns() {
        console.log(this.reportSettings.tableViewState.getState().columnSettings);
        return (this.reportSettings.tableViewState.getState().columnSettings)
            .filter(s => s.fieldName.split('_')[1] !== 'dt')
            .map(s => {
                const postfix = s.fieldName.split('_')[1];
                if (postfix === 'd' || postfix === 'i' || postfix === 's' || postfix === 'ss') {
                    const fieldName = s.fieldName.split('_')[0] + '_t';
                    return fieldName;
                } else {
                    return s.fieldName;
                }
            }).filter(x => !!x && x !== '');
    }



    public min(v: number, v2: number) {
        return (v < v2) ? v : v2;
    }

    public async openColumnsSettingsDialog(selectedView: string) {
        if(selectedView === 'kanban-view')
        {
            this.dialogsManagerService.openKanbanDialog(this.tabSettings,this.reportSettings.mainQueryService,this.reportSettings.kanbanViewState,this.mainViewState).subscribe(kanbanViewState => {
                if (kanbanViewState) {
                    this.reportSettings.kanbanViewState.setValue(kanbanViewState);
                }
              });
        }
        else
        {
        var tableColumns = this.reportSettings.tableViewState.getState().columnSettings;
        var groupByColumns = this.reportSettings.tableViewState.getState().groups;
        this.dialogsManagerService.openColumnsSettingsDialog(tableColumns, true,
            this.tabSettings, this.queryService, this.mainViewState, selectedView,groupByColumns).subscribe((obj) => {
                if (obj) {
                    disLogger('openColumnsSettingsDialog results', obj);
                    this.reportSettings.tableViewState.setValue({columnSettings: obj.rightColumns, dataFlattenBy: obj.dataFlattenBy, groups: obj.groups, sorts: obj.sorts} as ITableViewState);
                    this.reportSettings.mainViewState.setValue({isDefaultColumns: obj.isDefault});
                }
            });
        }
    }

    public uncheckFilter(filter: IDRFilter) {
        this.queryService.unSetFilter(filter.fields[0]);
        this.queryService.refresh();
    }

    public async uncheck(field: string[], value: FacetValue, refresh: boolean = true) {
        const currentQuery = await this.queryService.oQuery.pipe(first()).toPromise();
        const filter = (currentQuery.filters as DRFilter[]).filter(f => f.fields[0] === field[0])[0];
        const newFilter = new DRFilter(filter.type,
            filter.fields,
            filter.facetValues.filter(v => v.facetKey !== value.facetKey),
            filter.filters);
        if (newFilter.facetValues.length === 0) {
            this.queryService.unSetFilter(filter.fields[0]);
        } else {
            this.queryService.setFilter(field[0], newFilter);
        }
        if (refresh) {
            this.queryService.refresh();
        }
    }

    async removeAdvancedFilter(index: number) {
        console.log("remove filter", index);
        let currentQuery = await this.queryService.oQuery.pipe(first()).toPromise();
        let advFilter = (currentQuery.filters as DRFilter[]).filter(f => f.type == 'Any' || f.type == 'All')[0];
        let fieldName = (advFilter.filters as DRFilter[])[index].fields[0];

        this.queryService.unSetAdvFilter(fieldName);
        this.queryService.refresh();
    }
    log(filter) {
        console.log(filter)
    }

    public async showAdvancedModal(index: number = 0) {
        const currentQuery = await this.queryService.oQuery.pipe(first()).toPromise();
        const advFilter = (currentQuery.filters as DRFilter[]).filter(f => f.type == 'Any' || f.type == 'All')[0];
        const groups = (advFilter?.filters as DRFilter[]);
        const filterMode = advFilter?.type || 'All';

        this.dialog.open(AdvancedDialog, {
            panelClass: 'advanced-dialog-container',
            data: {
                queryService: this.queryService,
                tabSettings: this.tabSettings,
                filterMode
            },
            width: '120vh',
            height: '90vh',
            autoFocus: false
        });
    }
}
