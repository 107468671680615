import { switchMap } from 'rxjs/operators';
import { BlocPatternServiceBase } from './bloc-pattern-service-base';
import { IOptionList } from '../models/option-list';
import { OptionListDataServiceInterface } from './data-service';
import { Injectable } from '@angular/core';

@Injectable()
export class MutateCategoryBloc extends BlocPatternServiceBase<{ list: IOptionList, operation: 'add' | 'update' | 'delete' }, {
    result: 'OK' | 'Error', output?: IOptionList, message?: string
}> {
    constructor(private dataService: OptionListDataServiceInterface) {
        super();
        this._$input.pipe(switchMap(
            data => {
                let list;
                switch (data.operation) {
                    case 'add':
                        list = this.dataService.add(data.list);
                        break;
                    case 'update':
                        list = this.dataService.update(data.list);
                        break;
                    case 'delete':
                        list = this.dataService.delete(data.list);
                        break;
                }
                return list;
            }
        )).subscribe((r: any) => {
            if (r && r.mutationResult?.errors) {
                let message = r.mutationResult.errors[0].message;
                if (message.includes('Uniqueness violation')) {
                    message = "Option list with the same description already exists";
                }
                if (message.includes('Foreign key violation')) {
                    message = 'This option list is related to other records';
                }
                this._$output.next({ result: 'Error', message, output: { description: '', id: 0, options: [] } });
            }
            else {
                this._$output.next({ result: 'OK', output: r.mutationResult });
            }
        })
    }
    public addOptionList(list: IOptionList) {
        this._$input.next({ list, operation: 'add' });
    }
    public updateOptionList(list: IOptionList) {

        this._$input.next({ list, operation: 'update' });
    }
    public deleteOptionList(list: IOptionList) {
        this._$input.next({ list, operation: 'delete' });
    }
}

// @Injectable()
// export class SelectedOptionListBloc extends BlocPatternServiceBase<IOptionList, IOptionList> {

//     constructor() {
//         super();

//         this._$input.subscribe(s => {
//             this._$output.next(s);
//         });


//     }
//     public selectOptionList(list: IOptionList) {
//         this._$input.next(list);
//     }

// }

