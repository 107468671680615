import { Observable, Subject, combineLatest, merge, BehaviorSubject } from 'rxjs';
import {
    DRQuery, DRResult, IPivotConfig,
    IColumn,
    DateGroupBy,
    GroupBy,
    ITab2

} from '../classes';
import { BaseDataFacetService } from './base-data-facet.service';
import { map, distinctUntilChanged, first, tap, filter, distinctUntilKeyChanged, debounceTime } from 'rxjs/operators';
import { ICategories, IMetric, IBucket, IPivotChartResult, IColumnSetting } from '../classes';
import { mapFunctionName, mapAggregateName } from '../functions';
import { DiscovererQueryService } from './discoverer-query-service.service';
import { StateService } from '../state/state-service';
import { HttpClient } from '@angular/common/http';
import { LoadingState } from '../classes/loading-state';
import { DiscovererFacetTranslationService, FacetTranslationInfo } from './discoverer-facet-translation-service.service';
import { EsQueryService } from './discoverer-esquery-service';
import { Apollo } from 'apollo-angular';
export const NUMBER_OF_ROWS_METRIC: IColumnSetting = {
    fieldName: 'number_of_rows_metric',
    display: '# Of Rows',
    width: 200,
    type: 'numeric',
    dataType: 'integer',
    form: ''
};
import { PivotChartCalcs } from './pivot-chart-calc-class';
import { TabSettingsService } from '..';
import { keyframes } from '@angular/animations';

export const DefaultPivotLimit = 50;

export class PivotDataService extends BaseDataFacetService {

    private distinctSubFacetsGroups: any = {};
    public oData: Observable<IPivotChartResult>;
    public oLoadingStatus: Observable<LoadingState>;

    private $OData: Subject<IPivotChartResult>;
    private _bsLoadingStatus$: BehaviorSubject<LoadingState>;

    private _changeId = 0;
    constructor(
        private _http: HttpClient,
        _serviceUrl: string,
        private _queryService: DiscovererQueryService,
        private _stateService: StateService<IPivotConfig>,
        private _tabSettingsService: TabSettingsService,
        private _facetTranslationService: DiscovererFacetTranslationService,
        colArray: IColumn[],
        private esQueryService: EsQueryService) {
        super(colArray, _serviceUrl);
        this._bsLoadingStatus$ = new BehaviorSubject<LoadingState>({ status: "Busy" });
        this.oLoadingStatus = this._bsLoadingStatus$.asObservable();

        this.$OData = new Subject<IPivotChartResult>();
        this.oData = this.$OData.asObservable();
        let config: IPivotConfig;
        const sub = combineLatest([this._stateService.oState, this._queryService.oQuery])
            .pipe(map((result) => ({ state: result[0], query: result[1] })),
                filter((result) => this.isValidState(result.state)),
                map(result => (Object.assign(result, { keyString: JSON.stringify({ state: result.state, filters: result.query.filters }) }))),
                distinctUntilKeyChanged('keyString'),
                debounceTime(100))

            .subscribe(res => {
                this.distinctSubFacetsGroups = {};
                config = res.state;
                if (!!config.metric && (!!config.categories || (!!config.subFacets && config.subFacets.length))) {
                    const newConfig: IPivotConfig = config;
                    if (!config.categories || !config.categories.colName) {
                        newConfig.categories = newConfig.subFacets[0];
                        newConfig.subFacets.splice(0, 1);
                    }
                    this.queryData(res.query, newConfig);
                }
            });
        this.subscriptions.push(sub);
    }

    private isValidState(state: IPivotConfig): boolean {
        if (!!state) {
            return this.isValidMetric(state.metric)
                && this.isValidFacet(state.categories)
                && this.isValidSubFacets(state.subFacets);
        }
        return false;
    }

    private isValidMetric(metric: IMetric): boolean {
        return !!metric && !!metric.colName && !!metric.functionType;
    }

    private isValidSubFacets(subFacets: ICategories[]): boolean {
        let isValid = true;
        if (!!subFacets && subFacets.length) {
            let len = subFacets.length;
            while (len--) {
                if (!this.isValidFacet(subFacets[len])) {
                    isValid = false;
                    break;
                }
            }
        }
        return isValid;
    }

    private isValidFacet(facet: ICategories): boolean {
        if (!!facet && !!facet.colName) {
            const col = this.columns[facet.colName];
            if (!!col && (col.type === 'date' || col.type === 'numeric')) {
                return facet.groupBy !== null;
            }
            return true;
        }
        return false;
    }

    private async queryData(query: DRQuery, config: IPivotConfig) {
        try {
            const sendQuery1 = this.buildQuery(query, config, 'regular');
            this.esQueryService.updateQuery(sendQuery1, this.serviceUrl,  "pivot-chart");
            const response1 = await this.GetResultForQuery(sendQuery1);
            const result1 = await this.processResult(response1, config);
            let result2;
            if (config.categories.groupBy && config.categories.groupBy.comparasionMode) {
                const sendQuery2 = this.buildQuery(query, config, 'compare');
                const response2 = await this.GetResultForQuery(sendQuery2);
                result2 = await this.processResult(response2, config);
            }
            if (result1) {
                const subFacetKeys = result1.result.length ? (Object.keys(this.distinctSubFacetsGroups)).filter(x => x) : [];
                const keys = subFacetKeys.length ? subFacetKeys : ['key'];
                const compareType = config?.categories?.groupBy?.compareType || 'Percentage';
                const newResult = this.MergeResults(result1.result, result2 ? result2.result : [], result1.columns, keys, compareType);
                const newColumns = this.mergeColumns(result1.columns, result2 ? result2.columns : [], keys);
                const newFooter = this.mergeFooters(result1.footer, result2 ? result2.footer : {});
                // const newGroups = result1.groups;
                // const finalData = PivotChartCalcs.groupData(newResult, newGroups, config.metric.functionType, compareType);
                // flat data is still used in export only
                this.$OData.next({ result: newResult, columns: newColumns, footer: newFooter/*, groups: newGroups, flatData: newResult*/ });
            } else {
                this.$OData.next(null);
            }
        } catch {
            this.$OData.next(null);
            this._bsLoadingStatus$.next({status: 'Failure', errorDescription: 'Failed to load data'})
        }

    }

    private async GetResultForQuery(sendQuery: DRQuery) {
            this._bsLoadingStatus$.next({ status: "Busy" } as LoadingState)


        return await this._http

            .post(this.serviceUrl + '?data=pivot&qname=main', sendQuery)
            .pipe(tap({
                next: (x) => {
                    this._bsLoadingStatus$.next({ status: "Success" })
                },
                error: (err) => {
                    this._bsLoadingStatus$.next({ status: "Failure", errorDescription: err });
                }
            }),
            map((res: any) => {
                const obj: DRResult<any> = new DRResult<any>();
                Object.assign(obj.responseHeader, res.responseHeader);
                Object.assign(obj.facets, res.facets);
                obj.response.numFound = res.response.numFound;
                obj.response.start = res.response.start;
                obj.esQuery = res.query;
                return obj;
            }), first()).toPromise();
    }

    private async processResult(x: DRResult<any>, config: IPivotConfig) {
        if (!!x && !!x.facets) {
            const allCells: any[] = [];
            const parentFacetKey = config.categories.colName;
            const parentBuckets: IBucket[] = x.facets[parentFacetKey]?.buckets ?? [];
            let cats = [];
            if (config.categories.sortType === 'label') {
                cats = parentBuckets.map(z => z.key);
                cats = sortArray(cats, this.columns[parentFacetKey].dataType, config.categories.sortDir);
            } else {
                config.categories.sortDir === 'asc' ? parentBuckets.sort((a, b) => a.value - b.value) : parentBuckets.sort((a, b) => b.stat1 - a.stat1);
                cats = parentBuckets.map(z => z.key);
            }

            const catColName = config.categories.colName;
            let preview = [];
            let columns = [];
            const footer = {};
            // const agg: any[] = [];
            // const groups: any[] = [];
            if (!!config.subFacets && config.subFacets.length) {
                config.subFacets.forEach((fInfo, j) => {
                    this.distinctSubFacetsGroups[fInfo.colName] = [];
                    columns.push({
                        field: fInfo.colName,
                        title: this.columns[fInfo.colName].display,
                        format: this.columns[fInfo.colName].format,
                        width: fInfo.width ?? 200,
                        locked: true
                    });
                    // if (j > 0) {
                    //     agg.push({ field: `${fInfo.colName}.val1`, aggregate: 'count' });
                    // }
                });
                parentBuckets.forEach(bucket => {
                    const facetKeyValue = {
                        [parentFacetKey]: bucket.key

                    };
                    if (!!bucket && !!bucket.buckets) {
                        config.subFacets.forEach((fInfo, j) => {
                            facetKeyValue[fInfo.colName] = this.mapGroupedData(fInfo, bucket.key);
                        });
                        const cells = this.prepareRowsForFacetLevel(config.subFacets, bucket, facetKeyValue);
                        allCells.push(...cells);
                    }
                });
                const possibleGroups = this.getPossibleGroups(allCells);

                const fct = mapAggregateName(config.metric.functionType);
                // agg.push({ field: `${totalColumn.field}.val1`, aggregate: fct });
                // agg.push({ field: `${totalColumn.field}.val2`, aggregate: fct });
                preview = this.prepareDataToPreview(allCells, possibleGroups, config, cats);
                const { seriesSort, seriesSortDir } = config?.categories;
                const seriesDir = seriesSortDir === 'asc' ? 1 : -1;
                if (seriesSort === 'value') {
                    preview.sort((a, b) => seriesDir * (a[fct] - b[fct]));
                } else {
                    const colType = this.columns[(config.subFacets || [])[0].colName].dataType;
                    const { colName } = (config.subFacets || [])[0];
                    preview = sortArray(preview, colType, seriesSortDir, colName); //.sort((a, b) => seriesDir * (a[colName] > b[colName] ? -1 : 1));
                }
                cats.forEach((cat, i) => {
                    columns.push({
                        field: `col${i}`,
                        title: this.mapGroupedData(config.categories, cat),
                        format: this.columns[config.metric.colName]?.format,
                        width: config.categories.width ?? 200,
                        dataType: this.columns[config.metric.colName]?.dataType,
                        category: cat,

                    });
                    // agg.push({ field: `col${i}.val1`, aggregate: fct });
                    // agg.push({ field: `col${i}.val2`, aggregate: fct });
                });
                const totalColumn = {
                    field: 'sum',
                    title: `Total (${mapFunctionName(config.metric.functionType)}.)`,
                    format: this.columns[config.metric.colName]?.format,
                    width: 200,
                    isTotalColumn: true,
                    dataType: this.columns[config.metric.colName]?.dataType

                };
                columns.push(totalColumn);
                // if (config.subFacets.length > 1) {
                //     config.subFacets.forEach((facet, index) => {
                // const m = [agg[index], agg[config.subFacets.length - 1]];
                // m.push(...agg.slice(config.subFacets.length, agg.length));
                // if (index < config.subFacets.length - 1) {
                //     groups.push({ field: `${facet.colName}.val1`, aggregates: m });
                // }
                //     });
                // }
            } else {
                const fn: string = (config.metric.colName === NUMBER_OF_ROWS_METRIC.fieldName) ? 'value' : 'stat1';
                cats.forEach(cat => {
                    const formatedG = this.mapGroupedData(config.categories, cat);
                    const bucket = parentBuckets.find(z => z.key === cat);
                    const previewItem = {
                        key: formatedG,
                        value: bucket[fn],
                        count: bucket.value

                    };
                    preview.push(previewItem);
                });
                columns = [
                    {
                        field: 'key',
                        title: this.columns[parentFacetKey].display,
                        format: this.columns[parentFacetKey].format,
                        width: 200, // this.columns[perantFacetKey].width,
                    }, {
                        field: 'value',
                        title: `${this.columns[config.metric.colName]?.display} (${mapFunctionName(config.metric.functionType)}.)`,
                        format: this.columns[config.metric.colName]?.format,
                        isTotalColumn: true,
                        width: 200,
                        dataType: this.columns[config.metric.colName]?.dataType
                    }
                ];
            }
            let once = false;
            columns.forEach((col, i) => {
                if (col.dataType !== 'number' && !once) {
                    footer[col.field] = `Total(${mapFunctionName(config.metric.functionType)})`;
                    once = true;
                } else {
                    footer[col.field] = this.calculateTotalColumn(preview, config.metric.functionType, col.field);
                }
            });
            if (config?.subFacets?.length > 0) {

                for (const subFacet of config?.subFacets) {
                    preview = await this.getResultTranslationValues(preview, subFacet.colName);
                }
            } else {
                preview = await this.getResultTranslationValues(preview, config.categories.colName);

            }

            columns = await this.getResultTranslationValuesForColumns(columns);

            return { result: preview, columns, footer/*, groups*/ };

        }

    }
    private async _getGeneralInfo(colName?: string) {
        const pivotState = this._stateService.getState();
        const currentTab = await this._tabSettingsService.getCurrentTab();
        const tabColumns = await this._tabSettingsService.getAllColumns();
        const column = tabColumns.find(c => c.fieldName === (colName || pivotState.categories.colName));
        return { pivotState, currentTab, column };
    }

    private async _getFacetInfo(url: string, optionsListId): Promise<FacetTranslationInfo[]> {
        await this._facetTranslationService.init(`${url}`, optionsListId);
        const facetInfo = this._facetTranslationService.facetTranslationInfo;
        return facetInfo;
    }

    private async getResultTranslationValues(results: any[], colName: string): Promise<any[]> {

        const { pivotState, currentTab, column } = await this._getGeneralInfo(colName);
        if (column != undefined && column.isDynamicFacet === false) {

            const facetTranslationUrl = `${currentTab.facetTranslationUrl}/${pivotState.categories.colName}/${column.tableName}`;
            const facetInfo = await this._getFacetInfo(facetTranslationUrl, column.form.templateOptions.optionsListId);
            results = results.map(result => {
                const keys = Object.keys(result);
                const key = keys.includes("key") ? 'key' : colName;
                const info = facetInfo.find(i => i.Id === result[key]);
                if (info) {
                    result[key] = info.Display;
                    result['id'] = info.Id;
                }
                else {
                    result['id'] = '';
                }
                return result;
            });
        }
        return results;
    }

    private async getResultTranslationValuesForColumns(result: any[]): Promise<any[]> {
        const { pivotState, currentTab, column } = await this._getGeneralInfo();
        if (column != undefined && column.isDynamicFacet === false) {
            const facetTranslationUrl = `${currentTab.facetTranslationUrl}/${pivotState.categories.colName}/${column.tableName}`;
            const facetInfo = await this._getFacetInfo(facetTranslationUrl, column.form.templateOptions.optionsListId)
            result = result.map(d => {
                const keys = Object.keys(d);
                if (keys.includes("title")) {
                    const info = facetInfo.find(i => i.Id === d.title);
                    if (info) {
                        d.title = info.Display;
                        d['id'] = info.Id;
                    }
                    else {
                        d['id'] = '';
                    }
                }
                return d;
            });
        }
        return result;
    }

    private buildQuery(query: DRQuery, config: IPivotConfig, queryType: 'regular' | 'compare') {
        var mainFacet = this.getFacetDef(config, queryType);
        var facetArray = mainFacet['facets'] as any[];
        delete mainFacet['facets'];
        var currentFacet = mainFacet;
        if (facetArray != null) {
            facetArray.forEach(f => {
                currentFacet.subFacet = f;
                currentFacet = f;
            });
        }
        const sendQuery = new DRQuery();
        sendQuery.size = 0;
        sendQuery.start = 0;
        sendQuery.sorts = [];
        query.filters.forEach(x => x.includeAllFields = true);
        sendQuery.filters = query.filters;
        sendQuery.stats = [
            {
                name: 'stat1',
                type: 'stat',
                functionType: config.metric.functionType,
                field: (!!config.metric.overrideField) ? config.metric.overrideField : config.metric.colName

            }
        ];
        sendQuery.facets = [
            mainFacet
        ];
        return sendQuery;
    }

    private prepareDataToPreview(result: any[], possibleGroups: any[], config: IPivotConfig, cats: string[]) {
        const preview = [];
        possibleGroups.forEach(pg => {


            const rowArray = result.filter(x => {
                return x['pgKey'] == pg['pgKey'];
            });
            const previewItem = pg;
            rowArray.forEach(ra => {
                const colName = `col${cats.indexOf(ra[config.categories.colName])}`;
                const stat = (!!ra['stat'] && ra['stat'] !== 0) ? +ra['stat'] : null;
                previewItem[colName] = stat;
            });
            previewItem['sum'] = this.calculateTotalColumn(rowArray, config.metric.functionType);
            preview.push(previewItem);
        });
        return preview;
    }

    private calculateTotalColumn(rowArray: any[], functionType: string, fieldName: string = 'stat'): any {
        let result = 0;
        switch (functionType) {
            case 'avg':
                let count = 0;
                rowArray.forEach(ra => {
                    if (!!ra[fieldName] && ra[fieldName] !== 0) {
                        count++;
                        result += +ra[fieldName];
                    }
                });
                count = (count) ? count : 1;
                result = result / count;
                break;
            case 'min':
                rowArray.forEach((ra, i) => {
                    const stat = (!!ra[fieldName] && ra[fieldName] !== 0) ? +ra[fieldName] : null;
                    if (!!stat && (stat < result || result === 0)) {
                        result = stat;
                    }
                });
                break;
            case 'max':
                rowArray.forEach((ra, i) => {
                    const stat = (!!ra[fieldName] && ra[fieldName] !== 0) ? +ra[fieldName] : null;
                    if (!!stat && (stat > result || result === 0)) {
                        result = stat;
                    }
                });
                break;
            case 'sum':
            default:
                rowArray.forEach(ra => {
                    const stat = (!!ra[fieldName] && ra[fieldName] !== 0) ? +ra[fieldName] : null;
                    result += (!!stat) ? +ra[fieldName] : 0;
                });
                break;
        }
        return result;
    }

    private getPossibleGroups(result: any[]) {
        const keys = Object.keys(this.distinctSubFacetsGroups);
        const possibleGroups = [];
        const possibleGroupDictionary = {};
        result.forEach(x => {
            let pgKey = '';
            const pg = {};
            keys.forEach(k => {
                pgKey = x[k] + '|' + pgKey;
                pg[k] = x[k];
            });
            x['pgKey'] = pgKey;

            if (!possibleGroupDictionary[pgKey]) {
                pg['pgKey'] = pgKey;
                possibleGroups.push(pg);
                possibleGroupDictionary[pgKey] = true;
            }
        });
        return possibleGroups;
    }

    private isEqual(obj1, obj2) {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    }

    private prepareRowsForFacetLevel(subFacets: ICategories[], bucket: IBucket, facetKeyValue: any, level: number = -1,
        cells: any[] = []) {
        if (!bucket) {
            return cells;
        }
        if (!!bucket.buckets && bucket.buckets.length > 0) {
            level++;
            bucket.buckets.forEach(bucket1 => {
                const facetKey1 = subFacets[level].colName;
                facetKeyValue[facetKey1] = this.mapGroupedData(subFacets[level], bucket1.key);
                const l = this.prepareRowsForFacetLevel(subFacets, bucket1, Object.assign({}, facetKeyValue), level);
                this.updateSubFacetsGroups(facetKey1, bucket1.key);
                cells.push(...l);
            });
        }
        if (!!subFacets[level] && !!bucket.key) {
            const facetKey = subFacets[level].colName;
            facetKeyValue[facetKey] = this.mapGroupedData(subFacets[level], bucket.key);
        }
        if (!bucket.buckets) {
            const cell = this.prepareCell(bucket, facetKeyValue);
            cells.push(cell);
        }
        return cells;
    }

    private updateSubFacetsGroups(facetName: string, facetValue: string) {
        const values: string[] = this.distinctSubFacetsGroups[facetName];
        if (!values.includes(facetValue) || values.length === 0) {
            values.push(facetValue);
        }
        this.distinctSubFacetsGroups[facetName] = values;
    }

    private prepareCell(bucket: IBucket, facetKeyValue: any) {
        const cell = {
            stat: bucket.value ? bucket.stat1 : bucket.value
        };
        Object.keys(facetKeyValue).forEach(k => {
            cell[k] = facetKeyValue[k];
        });
        return cell;
    }

    private getFacetDef(config: IPivotConfig, queryType: 'regular' | 'compare') {
        const groupBy = this.getGroupByForQueryType(config.categories.groupBy, queryType, config.categories.colName);
        const main = this.getSingleFacetDef(config.categories.colName, groupBy, config.categories.limit, config.categories.minCount, 0, true);
        if (!!config.subFacets && config.subFacets.length) {
            main['facets'] = [];
            config.subFacets.forEach(sf => {
                const subGroupBy = this.getGroupByForQueryType(sf.groupBy, queryType, config.categories.colName);
                const sub1 = this.getSingleFacetDef(sf.colName, subGroupBy, (!!sf.limit) ? sf.limit : DefaultPivotLimit, sf.minCount, 0, true);
                main['facets'].push(sub1);
            });
        }
        return main;
    }

    private getGroupByForQueryType(groupBy: GroupBy, queryType: 'regular' | 'compare', colName: string) {
        if (groupBy) {
            if ((this.columns[colName].type === 'date') || groupBy.comparasionMode) {
                const dateGroupBy = groupBy as DateGroupBy;
                const newGroupBy = new DateGroupBy(dateGroupBy.orginalGap, dateGroupBy.numberOfGroups, dateGroupBy.offset, dateGroupBy.compareOffset, dateGroupBy.displayOffSet);
                newGroupBy.setQueryType(queryType);
                return newGroupBy;
            } else {
                return groupBy;
            }
        } else {
            return null;
        }
    }

    private MergeResults(results1: any[], results2: any[], columns: any[], keys: string[], compareType = 'Percentage') {
        const resultMap = results1.concat(results2);
        const hashMap = new Map();
        resultMap.forEach((item) => {
            const key = keys.length === 1 ? (item[keys[0]] ? item[keys[0]] : '') : keys.map(x => item[x] ? item[x] : '').join(',');
            const collection = hashMap.get(key);
            if (!collection) {
                const result = {};
                columns.forEach(z => {
                    if (Object.keys(item).includes('id')) {
                        result[z.field] = { val1: (!!item[z.field] || !z.type) ? item[z.field] : 0, id: item.id };
                    } else {
                        const val1 = (!!item[z.field] || !z.type) ? item[z.field] : 0
                        result[z.field] = {
                            val1,
                            val2: 0,
                            valDiff: PivotChartCalcs.calcCompareTotal(val1, 0, compareType)
                        };
                    }
                });
                hashMap.set(key, result);
            } else {
                columns.forEach(z => {
                    if (!keys.find(x => x === z.field)) {
                        collection[z.field].val2 = (!!item[z.field] || !z.type) ? item[z.field] : 0;
                        collection[z.field].valDiff = PivotChartCalcs.calcCompareTotal(collection[z.field].val1 || 0, collection[z.field].val2 || 0, compareType)
                    }
                });
                hashMap.set(key, collection);
            }
        });
        return [...hashMap.values()];
    }

    private mergeColumns(columns1: any[], columns2: any[], groupingKeys: string[] | '') {
        const column1Map = new Map(columns1.map(i => [i.field, i]));
        const column2Map = new Map(columns2.map(i => [i.field, i]));
        const returnedColumns = [];
        column1Map.forEach((value, key) => {
            returnedColumns.push({
                field: key,
                title: {
                    val1: value.title,
                    val2: column2Map.get(key) ? column2Map.get(key).title : '',
                },
                format: value.format,
                width: value.width,
                dataType: value.dataType ? value.dataType : '',
                category: {
                    val1: value.category,
                    val2: column2Map.get(key) ? column2Map.get(key).category : '',
                },
                isTotalColumn: value.isTotalColumn || false,
                locked: value.locked
            });
        });
        return returnedColumns;
    }

    private mergeFooters(footer1: any, footer2: any) {
        const newFooter = {};
        Object.keys(footer1).forEach(x => {
            newFooter[x] = { val1: footer1[x], val2: footer2[x] };
        });
        return newFooter;
    }
}

export function sortArray(arr: any[], dataType: string, dir: 'asc' | 'desc' = 'asc', key?: string): any[] {
    const isAsc = dir === 'asc' ? 1 : -1;

    const compare = (a: any, b: any): number => {
        const sortFieldA = key ? a[key] : a;
        const sortFieldB = key ? b[key] : b;
        switch (dataType) {
            case 'string':
            case 'string_array':
            case 'text':
                return isAsc * (sortFieldA < sortFieldB ? -1 : 1);

            case 'timestamp':
            case 'date':
                const dateA = key === 'count' ? sortFieldA  : _getStartOfRangeDate(sortFieldA)
                const dateB = key === 'count' ? sortFieldB : _getStartOfRangeDate(sortFieldB);
                return isAsc * (dateA < dateB ? -1 : 1);

            case 'int':
            case 'numeric':
            case 'currency':
                const numA = _getStartOfRangeNumber(sortFieldA);
                const numB = _getStartOfRangeNumber(sortFieldB);
                return isAsc * (numA - numB);

            default:
                return 0;
        }
    };

    arr.sort(compare);
    return arr;
}



function _getStartOfRangeNumber(range: string): number { 
    if (!range.slice) return +range;
    return Number(String(range).split('-')[0]); 
}

function _getStartOfRangeDate(range: string): Date { 
    if (!range.slice) return new Date(range);
    return new Date(range.slice(0, range.length / 2)); 
}
