import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseCustomController } from '../shared/base-custom-controller';
import { FloatNumnberPipe } from '../shared/comma-pipe';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'float',
    template: `<input  matInput type="text" (blur)="onBlur()" [formControl]="floatControl"  [tabIndex]="to?.tabindex" />`,
    styleUrls: ['./float-component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [FloatNumnberPipe]
})
// tslint:disable-next-line:component-class-suffix
export class FloatFieldType extends BaseCustomController implements OnInit {
    constructor(private pipe: FloatNumnberPipe) {
        super();
    }
    floatControl = new FormControl('');
    ngOnInit() {
        this.floatControl.setValue(this.pipe.transform(this.field.formControl.value));
        this.setControlStatus();
        this.field.formControl.valueChanges.subscribe(x => {
            this.floatControl.setValue(this.pipe.transform(x), { emitEvent: false });
        });
        this.field.formControl.statusChanges.subscribe(x => {
            this.setControlStatus();
        });
    }
    public onBlur() {
        const inputVal = this.floatControl.value;
        const value = Number(inputVal.replace(/[^0-9.-]+/g, ''));
        this.updateValueAndValidity(this.field, value, { emitEvent: false });
        const key = Array.isArray(this.field.key) ? this.field.key[0] : this.field.key;
        this.model[key] = value;
    }
    private setControlStatus() {
        if (this.to && this.to.disabled) {
            this.floatControl.disable();
        } else {
            this.floatControl.enable();
        }
    }

}
