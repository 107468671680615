import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { DataSourceComponent } from './data-source.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@NgModule({
    imports: [
    CommonModule,
      MatProgressSpinnerModule
    ],
    declarations: [
      DataSourceComponent
    ],
    entryComponents: [],
    providers: [],
    exports: [
      DataSourceComponent
    ]
})
export class DataSourceModule {
    constructor() {
    }
}
