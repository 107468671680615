import { Component, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { formatDate } from '@angular/common';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'formly-datetimepicker',
    templateUrl: './date-time-picker.html',
    styleUrls: ['./date-time-picker.scss'],
    encapsulation: ViewEncapsulation.None
})
// tslint:disable-next-line:component-class-suffix
export class DatetimepickerFieldType extends FieldType {

    returnDate() {
        try {
          const date = new Date(this.field.formControl.value);
          return formatDate(date, 'MM/dd/yyyy hh:mm:ss ', "en-US");
        } catch (e) {
          return '';
        }
    }
}
