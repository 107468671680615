import { Component, OnInit, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import * as _ from 'lodash';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { GraphQlOptionDataServiceInterface } from '../../option-lists/shared-services/data-service';
import { GraphQlController } from '../shared/base-graphql-controller';

//  Custom text field with extra classes and all

@Component({
    selector: 'app-formly-field-graphql-auto-complete',
    templateUrl: './graphql-auto-complete.component.html',
    styleUrls: ['./graphql-auto-complete.component.scss']
})
export class GraphQlDynAutoCompleteComponent extends GraphQlController implements OnInit, OnDestroy {
    @ViewChild(MatAutocompleteTrigger, { static: true }) trigger: MatAutocompleteTrigger;
    @ViewChild('dropDown', { static: false }) dropDown: MatSelect;

    public dynOptions = [];
    public filteredOptions = [];
    public isLoading = false;
    public searchControl = new FormControl()
    public isOpen = false;
    public showToolTip = false;
    public optionsLimit = 40;

    constructor(
        public optionsService: GraphQlOptionDataServiceInterface,
        private cdRef: ChangeDetectorRef
    ) {
        super(optionsService);

    }

    async ngOnInit() {
        this.showToolTip = this.field.templateOptions.showToolTip;
        if(this.field.templateOptions.optionsLimit && this.field.templateOptions.optionsLimit > 0){
            this.optionsLimit = this.field.templateOptions.optionsLimit;
        }
        this.subscribeTofilter();
        this.subscribeToOptions();
        this.subscribeToStatus();
        let defaultValue = this.field.formControl.value;
        this.field.formControl.valueChanges.pipe(takeUntil(this._unsubscribeAll), distinctUntilChanged((x, y) => x === y)).subscribe(
            s => {
                const displayValue = this.getDisplayValue(this.findOption(s));
                this.searchControl.setValue( displayValue || s);
            }
        )
        this.searchControl.valueChanges.pipe(takeUntil(this._unsubscribeAll), distinctUntilChanged((x, y) => x === y)).subscribe(
            s => {
                this.filter(s);
                if (s != null) {
                    if (this.valueMember === this.displayMember) { // allows adding a new option
                        this.field.formControl.setValue(s)
                    } else {
                        this.checkForInvalidSelection(s);
                    }
                }
            }
        );
        if (defaultValue) {
            this.field.formControl.setValue(defaultValue);
        }

    }

    ngOnDestroy() {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    public findOption(item: any) {
        if (this.dynOptions && !this.to.useOption) {
            return this.dynOptions.find(z => z[this.valueMember] == item);
        } else if (this.to.useOption && this.valueMember && item) {
            var option = this.dynOptions.find(z => z[this.valueMember] == item[this.valueMember]);
            return {
                ...item,
                ...option
            }
        } else {
            return item;
        }
    }

    compareWithFn = (o1: any, o2: any) => {
        return (!!o1 && !!o2 && o1[this.valueMember] && o2[this.valueMember]) ? (o1[this.valueMember] === o2[this.valueMember]) : o1 === o2;

    }

    public onFocus() {
        if (this.filteredOptions?.length > (this.to.selectFirstOption ? 1 : 0) && !this.field.formControl.value) {
            this.dropDown.open();
            this.isOpen = true;
        }
    }

    public filter(value: string) {
        
        if (value) {
            this.filteredOptions = this.dynOptions.filter(option => this.getDisplayValue(option)?.toLowerCase()?.includes(value?.toLowerCase())).slice(0, this.optionsLimit);
        } else {
            this.filteredOptions = this.dynOptions.slice(0, this.optionsLimit);
        }
    }

    private subscribeToStatus() {
        this.field.formControl.statusChanges.subscribe(x => {
            if (this.to && this.to.disabled) {
                this.searchControl.disable();
            } else {
                this.searchControl.enable();
            }
        });
    }

    private async subscribeToOptions() {
        this.isLoading = true;
        this.$gqOptions
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(options => {
                this.isLoading = false;
                this.dynOptions = this.filteredOptions = options;

                if (!!this.dynOptions && this.dynOptions.length) {
                    const currentOption = this.findOption(this.formControl.value) ?? this.formControl.value;
                    this.searchControl.setValue(this.getDisplayValue(currentOption) ?? currentOption);
                    if (!currentOption) {
                        this.updateValueAndValidity(this.field, undefined);
                    } else {
                        const key = Array.isArray(this.field.key) ? this.field.key[0] : this.field.key;
                        this.updateValueAndValidity(this.field, this.to.useOption ? currentOption ? currentOption : this.field.model[key] : this.field.model[key]);
                    }
                    if (!!this.to && !!this.to.selectFirstOption) {
                        this.field.formControl.setValue(this.dynOptions[0][this.valueMember]);
                    }
                }
            });
        this.dynOptions = this.filteredOptions = [];
        if (!this.cdRef['destroyed']) {
            this.cdRef.markForCheck();
        }
    }

    private checkForInvalidSelection(searchValue) {
        if (searchValue && !this.field.formControl.value) {
            this.field.formControl.setErrors({ invalidSelection: true });
        } else if (this.field.formControl.hasError('invalidSelection')) {
            delete this.field.formControl.errors.invalidSelection;
            this.field.formControl.updateValueAndValidity();
        }
    }

    public clearSelection() {
        this.field.formControl.setValue(null);
        this.searchControl.setValue(null);

        // Using setTimeout to ensure the digest cycle is complete
        setTimeout(() => {
            if (this.dropDown) {
                this.dropDown.open();
            }
        });
    }

    onKeyDown(event: KeyboardEvent) {
        if (event.key === 'Backspace' || event.key === 'Delete') {
            this.clearSelection();
        }
    }
}
