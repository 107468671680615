import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'input-widget',
    styleUrls: ['./input.widget.scss'],
    templateUrl: './input.widget.html',

})
export class InputWidgetComponent {
    @Input()
    public label = '';

    @Input()
    public panelClass = '';

    @Output()
    public change: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    public set value(v : string) {
        this._value = v;
        this.inputControl.setValue(this._value);
    }

    @Input()
    public inputControl: FormControl = new FormControl();

    private _value: string;

    constructor() {

    }

    public onInputChange() {
        this._value = this.inputControl.value;
        this.change.emit(this._value);
        return true;
    }
}
