<div class="wrapper">

    <disco-data [appId]="'docpipeline'" [tabId]="tabId === 'datasetId' ? datasetId : tabId" [requestId]="currentReqId"
        class="flex-1" [columnList]="columnList" [start]="start" [size]="1" [queryService]="queryService"
        (context)="setContext($event)">
        <ng-template let-context>
            <div class="flex h-full overflow-auto">
                <div class="flex h-full w-full">
                    <div class="result-viewer flex flex-col" appResizable (widthAreaChanging)="checkHeaderWidth($event)"
                        (isDragging)="dragging = $event">
                        <div class="header flex">
                            <div class="close-icon">
                                <button mat-icon-button (click)="closeDialog()">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </div>
                            <div class="content">
                                <div class="info-actions">
                                    <div class="record-info">
                                        <ng-container>
                                            <ngx-skeleton-loader *ngIf="fieldsLoading" count="3"
                                                animation="progress-dark" [theme]="{
                                                height: '15px',
                                                opacity: '0.8',
                                                'margin-bottom': '4px'
                                              }">
                                            </ngx-skeleton-loader>
                                        </ng-container>
                                        <ng-container *ngIf="!fieldsLoading">
                                            <ng-container *ngIf="titles.length else defaultView;">
                                                <ng-container *ngFor="let field of titles; index as i">
                                                    <p *ngIf="i == 0" class="first">{{field || '-'}}</p>
                                                    <p *ngIf="i == 1" class="second">{{field || '-'}}</p>
                                                </ng-container>
                                                <p class="third">{{fileName}}.{{fileExtension}} ({{dateReceived | date :
                                                    'medium'}})</p>
                                            </ng-container>
                                            <ng-template #defaultView>
                                                <p class="file-name">{{fileName}}.{{fileExtension}}</p>
                                                <p class="date-received">Received {{dateReceived | date : 'medium'}}</p>
                                            </ng-template>
                                        </ng-container>
                                    </div>

                                    <div class="action">
                                        <ng-container *ngIf="!isEditMode && !showLogs">
                                            <ng-container *ngIf="result && headerWidth > 925">
                                                <button mat-flat-button color="basic" (click)="isEditMode = true"
                                                    class="basic-button">
                                                    <span>Edit</span>
                                                </button>
                                                <button mat-stroked-button color="basic" *ngIf="!isCopied"
                                                    (click)="copyResult(result)" class="basic-button">
                                                    <span>Copy</span>
                                                </button>
                                                <button mat-stroked-button color="basic" *ngIf="isCopied"
                                                    class="basic-button">
                                                    <mat-icon class="copy-icon">check</mat-icon>
                                                </button>
                                            </ng-container>
                                            <button class="more_vert" mat-icon-button color="basic"
                                                [matMenuTriggerFor]="menu">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu">
                                                <ng-container *ngIf="headerWidth <= 925">
                                                    <button (click)="isEditMode = true" mat-menu-item>
                                                        <span>Edit</span>
                                                    </button>
                                                    <button (click)="copyResult(result)" mat-menu-item>
                                                        <span *ngIf="!isCopied">Copy</span>
                                                        <mat-icon *ngIf="isCopied" class="copy-icon">check</mat-icon>
                                                    </button>
                                                </ng-container>
                                                <button (click)="showLogs = !showLogs" mat-menu-item>
                                                    <span>{{showLogs ? 'Hide ' : 'Show '}}Logs</span>
                                                </button>
                                                <button (click)="reprocessFile()" mat-menu-item>
                                                    <span>Reprocess File</span>
                                                </button>
                                                <mat-divider></mat-divider>
                                                <button class="warn" (click)="deleteRow()" mat-menu-item>
                                                    <span>Delete</span>
                                                </button>
                                            </mat-menu>
                                        </ng-container>

                                        <ng-container *ngIf="isEditMode">
                                            <button class="save-btn" [disabled]="saveLoading" mat-flat-button
                                                color="basic" (click)="save()">
                                                <mat-spinner *ngIf="saveLoading" diameter="20"></mat-spinner>
                                                {{saveLoading ? 'Saving' : 'Save'}}
                                            </button>
                                            <button *ngIf="!saveLoading" mat-stroked-button color="basic"
                                                (click)="cancelEditMode()">Cancel</button>
                                        </ng-container>

                                        <ng-container *ngIf="showLogs">
                                            <button mat-flat-button color="basic" (click)="showLogs = !showLogs">Hide
                                                Logs</button>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="navigation flex align-center" *ngIf="!isEditMode else EditModePlaceHolder">
                                    <button mat-icon-button (click)="move('prev')"><i
                                            class="material-icons">keyboard_arrow_left</i></button>
                                    <p>Record {{start+1}} of {{dataSourceContext?.total}}</p>
                                    <button mat-icon-button (click)="move('next')"><i
                                            class="material-icons">keyboard_arrow_right</i></button>
                                </div>

                                <ng-template #EditModePlaceHolder>
                                    <div class="flex gap-2">

                                    </div>
                                </ng-template>
                            </div>

                        </div>
                        <div *ngIf="!showLogs else LOGS" class="main">
                            <ng-container *ngIf="!isEditMode else EditForm">
                                <app-file-result-displayer [fileId]="fileId" [datasetId]="datasetId"
                                    [resultIndex]="resultIndex" (dataFetched)="dataFetched($event)"
                                    *ngIf="fileId && !loading"></app-file-result-displayer>
                            </ng-container>
                            <ng-template #EditForm>
                                <div *ngIf="resultIndex >=0 else EDIT_ALL">
                                    <app-file-result-editor [fileId]="fileId" [data]="result[resultIndex]"
                                        [datasetId]="datasetId" [isSaving]="saveLoading"></app-file-result-editor>
                                </div>
                                <ng-template #EDIT_ALL>
                                    <div *ngFor="let res of result; let i = index">
                                        <app-file-result-editor [fileId]="fileId" [data]="result[i]"
                                            [datasetId]="datasetId" [isSaving]="saveLoading"></app-file-result-editor>
                                        <mat-divider *ngIf="result?.length - 1 !== i" class="divider" ></mat-divider>

                                    </div>
                                </ng-template>
                            </ng-template>
                        </div>
                        <ng-template #LOGS>
                            <div class="flex logs w-full">
                                <div class="overflow-auto main px-11 py-5 flex-col">
                                    <div class="flex align-center mb-5 gap-2">
                                        <button mat-icon-button (click)="showLogs = !showLogs">
                                            <mat-icon>arrow_back</mat-icon>
                                        </button>
                                        <p class="text-xl font-medium">Logs</p>
                                    </div>
                                    <app-file-log-displayer *ngIf="fileId && !loading" [datasetId]="datasetId"
                                        [fileId]="fileId"></app-file-log-displayer>
                                </div>

                            </div>
                        </ng-template>
                    </div>
                    <div class="pdf-viewer">
                        <app-original-file-viewer [ngClass]="{'hidden': dragging}" *ngIf="fileId && !loading"
                            [datasetId]="datasetId" [fileId]="fileId" [fileName]="fileName"
                            [fileExtension]="fileExtension">
                        </app-original-file-viewer>
                    </div>



                </div>
            </div>
        </ng-template>
    </disco-data>
</div>
