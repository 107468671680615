import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-toggle',
    template: `
        <mat-slide-toggle style="margin-bottom:8px"
            [formControl]="$any(formControl)"
            [formlyAttributes]="field"
            [color]="to.color"
        >
        {{ to.label }}
        </mat-slide-toggle>

`,
})
export class FormlyFieldToggle extends FieldType { }
