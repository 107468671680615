import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFacetDefinition, ReportPersistService } from '../services';

@Component({
  selector: 'facet-container',
  templateUrl: './facet-container.component.html',
  styleUrls: ['./facet-container.component.scss']
})
export class FacetContainerComponent  {
    @Input() facet;
    @Input() filterMode = 'all';
    @Input() currentTab;
    @Input() queryService;
    @Input() isFilterExcluded = false;
    @Input() isCollapsible = true;
    @Input() isOpen = false;
    @Input() clearButtonKey = true;
    @Input() display;
    @Input() independent: boolean = false;
    @Input() showCount: boolean = true;
    @Input() showPin: boolean = true;
    @Input() showDoneButton: boolean = false;
    @Output() doneButtonClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _reportPersistService: ReportPersistService) { }


  newSelected(event){

  }
  public isPinned() {
      const pinnedFilters: IFacetDefinition[] = this._reportPersistService.mainViewState.getState().facets || [];
      return !!pinnedFilters.find(pf => pf.name === this.facet?.fieldName && pf.isPinned);
  }
  public pinFilter(pinnedFilter) {
      const facets: IFacetDefinition[] = this._reportPersistService.mainViewState.getState().facets || [];
      const facetIndex: number = facets.findIndex(f => f.name === this.facet?.fieldName);
      if (facetIndex >= 0) {
          facets.splice(facetIndex, 1, pinnedFilter)
      } else {
          facets.push(pinnedFilter);
      }
      this._reportPersistService.mainViewState.setField('facets', facets);
  }
}
