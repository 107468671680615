import { Component, OnInit, ChangeDetectorRef, AfterViewChecked, ViewEncapsulation } from '@angular/core';
import { IOptionList } from '../models/option-list';
import { IOption } from '../models/option';

import { MutateCategoryBloc } from '../shared-services/option-list.bloc';
import { GetOptionsBloc, MutateOptionBloc } from '../shared-services/options.bloc';
import { GetCategoriesBloc } from '../shared-services/categories.bloc';


@Component({
    // tslint:disable-next-line: component-selector
    selector: 'options-configuration',
    templateUrl: './configuration.component.html',
    styleUrls: ['./configuration.component.scss'],
    providers: [
        GetCategoriesBloc, MutateCategoryBloc, GetOptionsBloc, MutateOptionBloc]
})
export class OptionsConfigurationComponent implements OnInit, AfterViewChecked {
    public pageTitle = 'Configure Options';
    public selectedList: IOptionList;
    public selectedOption: IOption;

    constructor(

        private ref: ChangeDetectorRef) { }
    ngOnInit() {

    }
    ngAfterViewChecked() {
        this.ref.markForCheck();
    }
}
