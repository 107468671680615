<div class="dialog-container">
    <div class="title">
        {{data?.isNew ? 'Add' : 'Edit' }} Document
    </div>
    <mat-divider></mat-divider>
    <main>
        <mat-form-field class="w-full">
            <input matInput placeholder="Please enter document name" [(ngModel)]="data.formData.title" />
        </mat-form-field>

        <mat-form-field class="w-full">
            <input matInput placeholder="Descripe your document" [(ngModel)]="data.formData.description" />
        </mat-form-field>
    </main>

    <div class="buttons-row flex justify-end gap-2">
        <button mat-raised-button color="primary" class="round-button" [disabled]="!data.formData.title"
            (click)="save()">
            Save
        </button>
        <button mat-raised-button class="round-button" (click)="close()">
            Cancel
        </button>
    </div>
</div>