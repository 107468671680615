<mat-card (mouseover)="showClearButtonWhenCardIsNotOpen = true" (mouseleave)="showClearButtonWhenCardIsNotOpen = false"
    class="card" color="primary" *ngIf="isCollapsible; else List">
    <mat-card-content>
        <ng-container *ngTemplateOutlet="facetList"></ng-container>
    </mat-card-content>
</mat-card>
<ng-template #List>
    <div *ngIf="title" class="uncollapsible-title">
        <div>{{ title }}</div>
    </div>
    <div>
        <ng-container *ngTemplateOutlet="facetList"></ng-container>
    </div>
</ng-template>

<ng-template #facetList>
    <div class="facet-header">
        <div class="right-side">
            <div *ngIf="isCollapsible" style="cursor: pointer">
                <div class="progress-bar-area">
                    <mat-progress-bar *ngIf="loadingState?.status == 'Busy'" mode="indeterminate"></mat-progress-bar>
                </div>
                <div (click)="facetTitleClicked()" layoutDir="row" class="title-container">
                    <button mat-icon-button mat-button>
                        <mat-icon>{{
                            isOpen ? "keyboard_arrow_down" : "keyboard_arrow_right"
                            }}</mat-icon>
                    </button>
                    <div flex="4" class="facet-title">
                        <div>{{ title }}</div>
                    </div>
                </div>
                <div *ngIf="(countFiltersApplied() || countAdvancedFiltersApplied()) && !isOpen" flex="1"
                    layoutDir="row" class="collapsed-message">
                    <span *ngIf="countFiltersApplied()">{{ countFiltersApplied() }} Selected</span>
                    <ng-container *ngIf="countAdvancedFiltersApplied()">
                        <span class="capitalize">{{ getAdvancedQueryTypeName(filtersList[0].operationA) }} &nbsp;</span>
                        <span>{{ filtersList[0]?.valueA }}</span>
                    </ng-container>
                    <span
                        *ngIf="(countFiltersApplied() > 0 || countAdvancedFiltersApplied() > 0) && showClearButtonWhenCardIsNotOpen"
                        class="clear-button" (click)="clearFilter()">
                        CLEAR
                    </span>
                </div>
            </div>
        </div>
    </div>
    <mat-list *ngIf="isOpen" class="filterList" multi>
        <div class="flex flex-row justify-between align-center">
            <mat-form-field class="query-type" *ngIf="facetType != 'boolean'">
                <mat-select [value]="queryType" (valueChange)="switchQueryType($event)">
                    <mat-option [value]="'include'">Include Any</mat-option>
                    <mat-option *ngIf="isStringArrayFacetType" [value]="'and'">Include All</mat-option>
                    <mat-option [value]="'exclude'">Exclude</mat-option>
                    <mat-option [value]="'contains'">Contains</mat-option>
                    <mat-option [value]="'starts_with'">Starts With</mat-option>
                    <mat-option [value]="'regex_match'">Regex Match</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="flex flex-row align-center">
                <div *ngIf="showPin" class="action-icon" (click)="addPinnedFilter()">
                    <mat-icon [fontSet]="isPinned ? 'material-icons' : 'material-icons-outlined'">push_pin</mat-icon>
                    <span class="pointer">{{isPinned ? 'Pinned' : 'Pin'}}</span>
                </div>
                <button *ngIf="!isDynamic" class="flex align-center justify-center" [matMenuTriggerFor]="menu">
                    <mat-icon class="more_vert">more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button (click)="toggleDynamicTranslation()" mat-menu-item>
                        <span>{{showTranslation ? 'Show' : 'Hide'}} Display Values</span>
                    </button>
                </mat-menu>
            </div>

        </div>

        <div class="include-exclude-divider">
            <mat-divider></mat-divider>
        </div>
        <ng-container *ngIf="queryType === 'contains' || queryType === 'starts_with' || queryType === 'regex_match'">
            <mat-form-field class="advanced-value">
                <input placeholder="Enter Value" matInput [(ngModel)]="filtersList[0].valueA"
                    (change)="updateFilterValue()">
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="queryType === 'include' || queryType === 'exclude' || queryType === 'and'">
            <div class="top-row">
                <mat-form-field class="search" *ngIf="isSearchable || !!searchFieldControl.value">
                    <input #searchField [autoFieldFocus]="searchField" placeholder="Search Value" matInput type="text"
                        [formControl]="searchFieldControl">
                    <mat-icon class="icon" *ngIf="!!searchFieldControl.value" matSuffix mat-icon-button
                        (click)="searchFieldControl.setValue('')">close</mat-icon>
                    <mat-icon matSuffix mat-icon-button *ngIf="!searchFieldControl.value" class="icon">search</mat-icon>
                </mat-form-field>
                <div class="facet-total" *ngIf="sortedFacetValues.length < totalFiltered && isOpen">
                    <div style="height: 20px">
                        {{ pageFrom + 1 }} - {{ pageTo }} of {{ totalFiltered }}
                    </div>
                    <div class="pagination-btn" (click)="prevPage($event)">
                        <mat-icon [ngClass]="{ disabled: pageNumber >= 1 }">
                            keyboard_arrow_left
                        </mat-icon>
                    </div>
                    <div class="pagination-btn" (click)="nextPage($event)">
                        <mat-icon [ngClass]="{ disabled: pageTo < totalFiltered }">
                            keyboard_arrow_right
                        </mat-icon>
                    </div>
                </div>
            </div>
            <div class="filter-icons-div-container">
                <div>
                    <mat-checkbox matTooltip="Select All" color="primary" class="checkbox select-all-checkbox"
                        (change)="selectAllFacetValues($event)" [(ngModel)]="selectAll">
                        Select All
                    </mat-checkbox>
                </div>
                <div class="filter-icons-div">
                    <div (click)="setAlphaSortingValue()" class="sorting-button"
                        [ngClass]="{'active': checkIfTypeOfSortIsBoolean(chooseAlphaSorting)}">
                        <app-alpha matTooltip="Ascending Alphabetic" *ngIf="!chooseAlphaSorting"></app-alpha>
                        <app-alpha-revers matTooltip="Descending Alphabetic"
                            *ngIf="chooseAlphaSorting"></app-alpha-revers>
                    </div>
                    <div (click)="setSumSortingValue()" class="sorting-button"
                        [ngClass]="{'active': checkIfTypeOfSortIsBoolean(chooseSumSorting)}">
                        <app-number matTooltip="Ascending Number" *ngIf="chooseSumSorting"></app-number>
                        <app-number-revers matTooltip="Descending Number" *ngIf="!chooseSumSorting"></app-number-revers>
                    </div>
                </div>
            </div>
            <div class="checkbox-wrapper flex flex-col gap-1">
                <div layoutDir="row" class="short" *ngFor="let facetVal of sortedFacetValues">
                    <mat-checkbox color="primary" class="checkbox" matTooltip="{{
                isEmpty(facetVal.title) ? '(Blank)' : facetVal.title
              }}" [ngClass]="{
                excluded: facetVal.isExclusion,
                empty: facetData?.getValue(facetVal?.facetKey) === 0
              }" (change)="selectFacetValue($event.checked, facetVal)" [ngModel]="facetVal.isChecked">{{
                        isEmpty(facetVal.title) ?
                        "(Blank)" : facetVal.title }}
                    </mat-checkbox>
                    <div class="selection-container">
                        <div class="pick-only-button">
                            <span (click)="pickOnly(facetVal.title)">Pick Only</span>
                        </div>

                        <div *ngIf="facetData?.getValue(facetVal?.facetKey) && showCount"
                            class="facet-value color-warn">
                            {{ facetData?.getValue(facetVal?.facetKey) | formattedNumber }}
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <app-facet-actions *ngIf="isOpen"
            [showClearButton]="(isCollapsible && clearButtonKey && isOpen && ((countFiltersApplied() > 0) || countAdvancedFiltersApplied() > 0)) || (!clearButtonKey && ((countFiltersApplied() > 0) || countAdvancedFiltersApplied() > 0))"
            (clearButtonClicked)="clearFilter()" [showDoneButton]="showDoneButton"
            (doneButtonClicked)="doneButtonClicked.emit()">
        </app-facet-actions>
    </mat-list>
</ng-template>