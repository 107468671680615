<div class="dashboard-wrapper" *ngIf="(states | async) as state">

    <div class="header" *ngIf="showHeader">

        <div class="control-bar">
            <div class="dashboard-actions">
                <!-- <mat-icon class="icon" *ngIf="editMode" (click)="reset();" matTooltip="Reset">refresh</mat-icon> -->
                <mat-icon class="icon" [popoverTrigger]="dashboardListPopover" [overlayX]="'center'"
                    [closeOnClickOutside]="true" matTooltip="Open Dashboard" matListIcon>folder_open</mat-icon>

                <!-- <mat-icon class="icon" (click)="shareDashboard()" matTooltip="Share">link</mat-icon> -->
                <!-- <mat-icon class="icon" *ngIf="canEditReport" (click)="saveDashboard()" matTooltip="Save">save</mat-icon> -->
                <mat-icon class="icon" (click)="saveAsDashboard()" matTooltip="Duplicate" c>file_copy</mat-icon>
                <mat-icon class="icon" *ngIf="canDeleteReport && editMode" (click)="deleteDashboard()"
                    matTooltip="Delete">delete</mat-icon>
                <ng-template #dashboardListPopover>
                    <dashboards-list [currentAppKey]="appId" [reportType]="'dashboard-view'" [canEdit]="canEditReport"
                        (mainTitleChanged)="titleChange($event)" (deleteReport)="deleteDashboard()"
                        (hideReportsEvent)="closePopover()" (currentReqDirId)="changeRequest($event)">
                    </dashboards-list>
                </ng-template>
            </div>
            <div layoutDir="row" class="workspace-header">

                <main-title [enableEdit]="editMode" [currentTabKey]="'dashboard'"></main-title>
                <div class="actions">
                    <div class="dashboard-control">
                        <div *ngIf="!editMode else EditMode" class="edit-mode">
                            <div class="applied-filters" *ngIf="dashboardFiltersService.mainQueryService">
                                <applied-dashboard-filters
                                    [queryService]="dashboardFiltersService.mainQueryService"></applied-dashboard-filters>
                            </div>
                            <button matTooltip="Edit Dashboard" mat-flat-button
                                color="primary" (click)="editMode=!editMode" class="self-center">
                                <mat-icon class="icon">edit</mat-icon> Edit
                            </button>
                        </div>
                        <ng-template #EditMode>
                            <button class="action" matTooltip="Add" mat-stroked-button
                                [matMenuTriggerFor]="NewChart" (click)="$event.preventDefault()">
                                Add
                            </button>
                            <mat-menu #NewChart="matMenu">
                                <button ml-2 mat-menu-item color="primary" (click)="openFiltersSelector()">
                                    Filters
                                </button>
                                <mat-divider></mat-divider>
                                <button [popoverTrigger]="popover" [overlayX]="'end'" [closeOnClickOutside]="true"
                                    matTooltip="Add Chart" mat-menu-item color="primary">
                                    Existing Chart
                                </button>
                                <button matTooltip="Reset" [matMenuTriggerFor]="TABS" mat-menu-item>
                                    New Chart
                                </button>
                                <mat-menu #TABS="matMenu">
                                    <button mat-menu-item *ngFor="let tab of tabs"
                                        (click)="openNewReportDialog(tab.key)">{{tab.name}}</button>
                                </mat-menu>
                            </mat-menu>
                            <ng-template #popover>
                                <dashboards [selectedRequests]="state[0].reports" (hideReportsEvent)="closePopover()"
                                    [originalTabs]="tabs" (onRemove)="onRemove($event)"
                                    (selectedRequest)="addNewReport($event)">
                                </dashboards>
                            </ng-template>
                            <div class="applied-filters" *ngIf="dashboardFiltersService.mainQueryService">
                                <applied-dashboard-filters
                                [editMode]="editMode"
                                    [queryService]="dashboardFiltersService.mainQueryService"></applied-dashboard-filters>
                            </div>
                            <div class="flex">
                                <button class="action" matTooltip="Done" mat-flat-button
                                    (click)="editMode=!editMode; reset()">
                                    <mat-icon class="icon">refresh</mat-icon> Cancel
                                </button>
                                <button class="action" matTooltip="Done" mat-flat-button color="primary"
                                    (click)="saveDashboardAction()">
                                    <mat-icon class="icon">save</mat-icon> Done
                                </button>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="dashboard-workspace main-view-container">
        <div class="body" *ngIf="dashboardFiltersService.initialized">
            <dashboard-view [dashboardFiltersService]="dashboardFiltersService" [editMode]="editMode" [app]="appId" [queryService]="queryService"
                (onResize)="onResize($event)" [dashboardViewState]="reportPersist.dashboardViewState"
                [selectedReports]="state[0].reports" (onRemove)="onRemove($event)">
            </dashboard-view>
        </div>
        <div class="footer">
        </div>
    </div>
    <ng-template #loading>
        loading..
    </ng-template>
</div>
