import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDynamicReport, ReportPersistService, RequestFilingService, TabSettingsService } from '@discoverer/core';
import { RequestCacheService } from '@discoverer/core/services/dynamic-reports-services/request-cache.service';
import { ViewTypes } from '@discoverer/dynamic-reports';
import { environment } from '@env/environment';
import { v4 as uuidv4 } from 'uuid';
import { Field, mapColumns, mapConnectionSettings, mapTabConfig, Section, toBase64String } from '../helpers/docpipeline-function';
import { ColumnSetting } from '../models/column-settings';


@Injectable({
    providedIn: 'root'
})
export class IgniteTabService {

    constructor(
        private httpClient: HttpClient,
        private _reportPersistService: ReportPersistService,
        private _requestFillingService: RequestFilingService,
        private _requestCacheService: RequestCacheService,
        private _tabSettingService: TabSettingsService,
    ) { }

    public async publishColumns(datasetId: string, fields: { [key: string]: Field }) {
        const files = [
            {
                name: `${datasetId}.columns.json`,
                payload: toBase64String(mapColumns(fields, false))
            }
        ]
        await this.publishAssets(files)
    }
    public async initTab(pipelineTitle: string, fields: { [key: string]: Field }, datasetId: string) {
        const columns = mapColumns(fields, true)
        var requestId = await this._createRequestAndUseSameDirectoryId(datasetId, columns);
        const nestedCols = [...new Set(columns.filter(c => c?.nestedPath && !c?.nestedPath?.includes('.')).map(c => c.nestedPath))]
            .map((nestedPath, i) => {
                const { display } = columns.find(c => c.nestedPath === nestedPath);
                { return { [nestedPath]: `${i + 2}-${display}` } }
            });
        const files = [
            {
                name: `${datasetId}.columns.json`,
                payload: toBase64String(columns)
            },
            {
                name: `${datasetId}.connection.json`,
                payload: toBase64String(mapConnectionSettings(datasetId, RESULTS_BUSINESS_KEY))
            },
            {
                name: `${datasetId}.forms.json`,
                payload: toBase64String({
                    "Details": this._mapFormSections(fields)
                })
            },
            {
                name: `${datasetId}.tab-config.json`,
                payload: toBase64String(mapTabConfig(datasetId, pipelineTitle, requestId, nestedCols)),
            }
        ]
        this._requestCacheService.removeAllCachedReuests();
        return files;
        // await this.publishAssets(files)
    }
    public async initDefaultTab(pipelineTitle: string, datasetId: string) {
        const columns = standardCols
        const dirRes = await this.createDir(datasetId);
        const requestId = await this._copyDefaultRequest(dirRes.id, columns)
        const nestedCols = [{ "File": "2-File" }]

        const files = [
            {
                name: `${datasetId}.columns.json`,
                payload: toBase64String(columns)
            },
            {
                name: `${datasetId}.connection.json`,
                payload: toBase64String(mapConnectionSettings(datasetId, RESULTS_BUSINESS_KEY))
            },
            {
                name: `${datasetId}.forms.json`,
                payload: toBase64String([]),
            },
            {
                name: `${datasetId}.tab-config.json`,
                payload: toBase64String(mapTabConfig(datasetId, pipelineTitle, requestId, nestedCols)),
            }
        ]
        this._requestCacheService.removeAllCachedReuests();
        return files;
        // await this.publishAssets(files)
    }
    public async getTab(tabId: string) {
        return await this.httpClient
            .get<any>(`api/es/apps/${environment.auth.productKey}/tabs/${tabId}`).toPromise();
    }
    public async getColumns(tabId: string): Promise<ColumnSetting[]> {
        var response = await this.httpClient.get<ColumnSetting[]>(`/api/es/apps/${environment.auth.productKey}/tabs/${tabId}/columns`).toPromise();
        return response
    }
    public getGuid() {
        return uuidv4();
    }

    // files is array of name,payload
    // name like time.tab-config.json
    // payload is a base64 like this const base64Token = Buffer.from(encoded).toString('base64');
    private async publishAssets(files: { name: string, payload: string }[]): Promise<any> {
        const body = JSON.stringify({ "files": files });
        const headers = { 'Content-Type': 'application/json' };
        return await this.httpClient
            .post<any>(`api/es/publish/assets/${environment.auth.productKey}`, body, { headers }).toPromise();
    }
    private async createDir(tabId: string) {
        return await this._requestFillingService.addCommonDirectroyForTab(environment.auth.productKey, tabId, `Common`)
    }
    private async _copyDefaultRequest(defaultdirId: number, columns: ColumnSetting[]): Promise<string> {
        const defaultReq = await this._reportPersistService.getRequest(environment.defaultEmptyRequest, environment.auth.productKey) as IDynamicReport;
        this._updateReportToNewSchema(defaultReq, columns);
        const newRequestId = await this._reportPersistService.createDefaultRequest(environment.auth.productKey, defaultReq, defaultdirId);
        return newRequestId;
    }
    private _updateReportToNewSchema(report: IDynamicReport, columns: ColumnSetting[]) {
        report.mainView.type = ViewTypes.Table;
        report.mainView.mainTitle = `All Records`;
        report.tableView.columnSettings = columns.filter(col => col.dataType !== 'object_array' && col.dataType !== 'object');
        report.mainView.isDefaultFacets = false;
        report.mainView.facets = columns.filter(col => col.dataType !== 'object_array' && col.dataType !== 'object').map(col => ({ name: col.fieldName, group: '0', isPinned: false }));
    }

    private _mapFormSections(fields: { [key: string]: Field }): Array<Section> {
        let keys = Object.keys(fields);
        let sections = Array<Section>();
        let currentSection: Section;

        addSection();
        for (let i = 0; i < keys.length;) {
            if (fieldIsObjectOrObjectArray(i)) {
                const title = fields[keys[i]].display || keys[i];
                const subFields = Object.keys(fields[keys[i]].fields).map(f => `${keys[i]}.${f}`);
                if (fields[keys[i]].type === 'object_array'){
                    addSection(title, 'table', keys[i], divideArray(subFields, 1));
                }
                else {
                    addSection(title, 'form', null, divideArray(subFields, 2));
                }
                addSection();
                i++;
                continue;
            }

            if (i + 1 >= keys.length || (fieldIsObjectOrObjectArray(i + 1))) {
                currentSection.rows.push([keys[i]]);
                i += 1;
                continue;
            }
            else {
                currentSection.rows.push([keys[i], keys[i + 1]]);
                i += 2;
                continue;
            }
        }
        return sections.filter(s => s.rows.length > 0);

        function fieldIsObjectOrObjectArray(i: number) {
            return fields[keys[i]].type === 'object_array' || fields[keys[i]].type === 'object';
        }

        function addSection(title: string = null,  type: 'form' | 'table' = 'form', nested: string = null, rows: string[][] = []) {
            currentSection = {
                title: title,
                nested: nested,
                type: type,
                rows: rows
            };
            sections.push(currentSection);
        }
    }

    private async _createRequestAndUseSameDirectoryId(datasetId: string, columns: ColumnSetting[]): Promise<string> {
        var currentTab = await this._tabSettingService.fetchTab(environment.auth.productKey, datasetId);
        var currentReport = await this._reportPersistService.getRequest(currentTab.defaultRequestId) as IDynamicReport;
        this._updateReportToNewSchema(currentReport, columns)
        await this._reportPersistService.updateRequest(currentTab.defaultRequestId, currentReport, false, false)

        return currentTab.defaultRequestId;
    }
}

function divideArray(array, size) {
    return array.reduce((acc, _, index) => {
        if (index % size === 0) {
            acc.push(array.slice(index, index + size));
        }
        return acc;
    }, []);
}

export const standardCols = [
    new ColumnSetting('File.uploaded_on', 'timestamptz', 'File - Uploaded On', 'date', 'File', false, 1),
    new ColumnSetting('File.original_file_name', 'string', 'File - Name', 'text', 'File', false, 2),
    new ColumnSetting('File.file_id', 'string', 'File - Id', 'text', 'File', false, 3),
    new ColumnSetting('File.file_extension', 'string', 'File - Extension', 'text', 'File', false, 4),
    new ColumnSetting('File.result_index', 'int', 'File - Result Index', 'numeric', 'File', false, 5),
    new ColumnSetting('ModifiedBy', 'string', 'Modified By', 'text', '', false, 6),
    new ColumnSetting('ModifiedByEmail', 'string', 'Modified By Email', 'text', '', false, 7),
    new ColumnSetting('ModifiedOn', 'timestamptz', 'Modified On', 'date', '', false, 8)
];

export const RESULTS_BUSINESS_KEY: string = "File.file_id"
