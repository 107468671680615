import { ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { debounceTime } from 'rxjs/operators';
import { GraphQlOptionDataServiceInterface } from '../../option-lists/shared-services/data-service';
import { BaseCustomController } from '../shared/base-custom-controller';
import { GraphQlController } from '../shared/base-graphql-controller';

@Component({
    selector: 'app-formly-field-check-box-list',
    templateUrl: './dyn-checkbox-list.component.html',
    styleUrls: ['./dyn-checkbox-list.component.scss']
})
export class DynCheckboxListComponent extends GraphQlController implements OnInit, OnDestroy {
    public dynOptions: any[] = [];
    public isLoading = false;
    @ViewChildren(MatCheckbox) checkboxes!: QueryList<MatCheckbox>;
    constructor(
        public optionsService: GraphQlOptionDataServiceInterface,
        private cdRef: ChangeDetectorRef
    ) {
        super(optionsService);
    }
    ngOnInit() {
        this.subscribeTofilter();
        this.subscribreToOptions();

    }

    ngOnDestroy() {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    onChange(value: any, checked: boolean) {
        this.formControl.setErrors({ others: null });
        this.updateValueAndValidity(this.field, checked
            ? [...(this.formControl.value || []), value]
            : [...(this.formControl.value || [])].filter((o) => o !== value));
        this.formControl.markAsTouched();
    }

    isChecked(option: any) {
        const value = this.formControl.value;
        return value && (value.indexOf(option[this.valueMember]) !== -1);
    }
    private findOptions(items: any[]) {
        return items && items.every(v => this.dynOptions.findIndex(z => z[this.valueMember] === v) !== -1);
    }

    private subscribreToOptions() {
        this.isLoading = true;
        this.$gqOptions.subscribe(options => {
            this.dynOptions = options;
            this.isLoading = false;
            if (options.length) {
                if (!this.findOptions(this.formControl.value)) {
                    this.updateValueAndValidity(this.field, []);
                } else {
                    const key = Array.isArray(this.field.key) ? this.field.key[0] : this.field.key;
                    this.updateValueAndValidity(this.field, this.field.model[key]);
                }
            }
            if (!this.cdRef['destroyed']) {
                this.cdRef.markForCheck();
            }
        });
    }

    displayOptionLabels(options) {
        if (!!options) {
            let labels = options.filter(opt => this.isChecked(opt)).map(op => this.to.displayMember ? op[this.to.displayMember] : op.Description);
            return labels.join(' , ');
        }
    }
}

