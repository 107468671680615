<div id="save-report">
    <div class="header color-primary-bg">
        <h3 class="title">Save report as </h3>
        <mat-icon class="close-icon" (click)="close()">close</mat-icon>
    </div>
    <div class="main">
        <form class="">
            <mat-select class="directory" placeholder="Directories" [formControl]="directory">
                <mat-option *ngFor="let dir of directoryList" [value]="dir.id">{{dir.directoryName.split('|')[1]}}
                </mat-option>
            </mat-select>
        </form>
    </div>

    <div class="footer">
        <button mat-flat-button class="create rounded" color="accent" (click)="save()">OK</button>
        <button mat-stroked-button class="cancel rounded" (click)="close()">Cancel</button>
    </div>

</div>
