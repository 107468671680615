<div class="columns-settings" *ngIf="(reportPersist.oLastRequestData | async) as lastrequest">
    <ng-container *ngIf="canChangeColumnSettings && isAllowView(lastrequest?.mainView?.type)">
        <mat-icon *ngIf="lastrequest?.mainView?.type === 'kanban-view' else NotKanbanView;"
            (click)="openColumnsSettingsDialog(lastrequest?.mainView?.type)" color="accent">
            settings
        </mat-icon>
        <ng-template #NotKanbanView>
            <mat-icon [matMenuTriggerFor]="menu" class="more_vert" color="accent">
                more_vert
            </mat-icon>
            <mat-menu #menu="matMenu">
                <button (click)="openColumnsSettingsDialog(lastrequest?.mainView?.type, 'Display Columns')" mat-menu-item>
                    <span>Display Columns</span>
                </button>
                <button (click)="openColumnsSettingsDialog(lastrequest?.mainView?.type, 'Sorting')" mat-menu-item>
                    <span>Sorting</span>
                </button>
                <button (click)="openColumnsSettingsDialog(lastrequest?.mainView?.type, 'Grouping')" mat-menu-item>
                    <span>Grouping</span>
                </button>
                <button *ngIf="columnsToFlattenData.length > 0" (click)="openColumnsSettingsDialog(lastrequest?.mainView?.type, 'Data Flatten By')" mat-menu-item>
                    <span>Data Flatten By</span>
                </button>
            </mat-menu>
        </ng-template>
    </ng-container>
</div>