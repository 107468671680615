import { Component, Input, OnChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { SIDENAV_COMPONENT_TEMPLATES, SidenavComponents } from "../models";
import { MatSidenav } from '@angular/material/sidenav';

export const sidenavTitle = new Map<string, string>([
    [SIDENAV_COMPONENT_TEMPLATES.CHANGE_DOCUMENT, "Change Document"],
    [SIDENAV_COMPONENT_TEMPLATES.DOCUMENT_RESULT, "Test Results"],
])

@Component({
    selector: 'app-sidenav-component-wrapper',
    templateUrl: './sidenav-component-wrapper.component.html',
    styleUrls: ['./sidenav-component-wrapper.component.scss']
})
export class SidenavComponentWrapperComponent implements OnChanges, OnInit {
    @Input() datasetId: string
    @Input() fileId: string
    // @Input() componentToRender: SidenavComponents
    @Input() sidenavRef: MatSidenav

    @Output() fileUploaded = new EventEmitter<boolean>();

    sidenavTitle: string
    sidenavComponentTemplate = SIDENAV_COMPONENT_TEMPLATES
    sidenavOpened: boolean
    lastComponent: SidenavComponents
    constructor() { }

    ngOnInit(): void {
        // this.componentToRender = SIDENAV_COMPONENT_TEMPLATES.CHANGE_DOCUMENT;
        this.sidenavTitle = 'Test Results'
        this.sidenavRef.closedStart.subscribe(
            async () => {
                await this.handleSidenavClosed();
            }
            )
    }

    ngOnChanges(): void {
        // this.sidenavTitle = sidenavTitle.get(this.componentToRender)
        this.fileId = !!localStorage.getItem(this.datasetId) ? localStorage.getItem(this.datasetId) : null;
    }

    handleFileUploaded(fileId: string) {
        localStorage.setItem(this.datasetId, fileId);
        // this.componentToRender = SIDENAV_COMPONENT_TEMPLATES.DOCUMENT_RESULT
        // this.lastComponent = this.componentToRender
        // this.sidenavTitle = sidenavTitle.get(this.componentToRender)
        this.fileUploaded.emit(true);
    }

    handleSidenavClosed() {
        // if (this.lastComponent === SIDENAV_COMPONENT_TEMPLATES.DOCUMENT_RESULT) {
        //     this.componentToRender = SIDENAV_COMPONENT_TEMPLATES.CHANGE_DOCUMENT;
        //     this.lastComponent = null;
        //     this.sidenavTitle = sidenavTitle.get(this.componentToRender);
        // }
    }

}
