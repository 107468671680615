<ng-container *ngIf="loadingState">
    <!-- <ng-container style="margin-left: 50%;" *ngIf="loadingState.status == 'Busy'">
        <mat-spinner class="spinner-position" [diameter]="20"></mat-spinner>
    </ng-container> -->
    <ng-container *ngIf="loadingState.status == 'Failure'">
        <div class="error-message">{{loadingState.errorDescription}}</div>
    </ng-container>
</ng-container>
<div id="discov-table-view" class="{{(tabSettings.oCurrentTab | async)?.key}}">
    <span [id]="rootDomID" ></span>
</div>
