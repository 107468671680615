<div class="boxes-container" *ngIf="dynOptions && dynOptions.length">

    <ng-container *ngTemplateOutlet="disabled ? disabledTemplate : notDisabled">

    </ng-container>
    
    <ng-template #disabledTemplate>
        <mat-label class="disabled-label">{{displayOptionLabels(dynOptions)}}</mat-label>
    </ng-template>

    <ng-template #notDisabled>
        <ng-container *ngFor="let option of dynOptions ; let i = index">
            <mat-checkbox [id]="id + '_' + i" [formlyAttributes]="field" [checked]="isChecked(option)"
                [tabIndex]="to?.tabindex" [disabled]="formControl.disabled"
                (change)="onChange(option[valueMember], $event.checked)">
                {{ getDisplayValue(option) }}
            </mat-checkbox>
            <mat-spinner *ngIf="isLoading" diameter="30"></mat-spinner>
        </ng-container>
    </ng-template>
    
</div>