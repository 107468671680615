<div>
    <div style="margin-top:12px; margin-bottom:3px; font-size:11px;text-transform:uppercase; color: #666">{{title}}</div>

    <div [style.width.%]="length">
        <div class="bar-row" layoutDir="row">
            <div flex="{{value1/( value2 >0 ? value2 : 1) * 100 }}" class="filled-bar-portion"  [style.background-color]="barColor"></div>
            <div flex="{{100 - ( value1/( value2 >0 ? value2 : 1)  * 100)}}" class="empty-bar-portion" ></div>
        </div>
    </div>

    <div layoutDir="row">
        <div >
            <div class="count">{{value1}} </div>
            <div class="label">{{value1Title}}</div>
        </div>
        <div>
            <div class="slash">/</div>
        </div>
        <div  style="">
            <div class="count" >{{value2}} </div>
            <div class="label">{{value2Title}}</div>
        </div>
    </div>
</div>
