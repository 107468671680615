import {
    Directive,
    Output,
    EventEmitter,
    HostListener
} from '@angular/core';

@Directive({
    selector: '[scrollTracker]'
})
export class ScrollTrackerDirective {

    @Output() scrollingFinished = new EventEmitter<void>();

    @HostListener('scroll', ['$event.target'])
    onScroll(target: HTMLElement): void {
        if (Math.ceil(target.offsetHeight + target.scrollTop) >= target.scrollHeight) {
            this.scrollingFinished.emit();
        }
    }
}
