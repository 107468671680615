<div id="sorting-item" *ngFor="let item of sortColumns ; let pi=index">
    <div class="order">
        <mat-form-field class="mat-form-field-column">
            <mat-select placeholder="sort from" [(value)]="item.dir">
                <mat-option *ngFor="let vector of sortOrderList; let i=index" [value]="vector.dir" (change)="sortOrderChange(vector)">
                    {{vector.title}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="field-name">
        <mat-form-field>
            <mat-select placeholder="select field" [(value)]="item.column.fieldName">
                <mat-option *ngFor="let col of allColumns; let i=index" [value]="col.fieldName" (change)="setSortColumn(col)" >{{col.display}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="controls">
        <mat-icon class="add-circle circle" (click)="addSort()">add</mat-icon>
        <mat-icon class="remove-circle circle" (click)="removeSort(pi)">remove</mat-icon>
    </div>
</div>
