
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { TabSettingsService, searchForText } from '@discoverer/core/services';
import { BaseController, StateService, DiscovererQueryService, IColumnSetting } from '@discoverer/core/services';
import { forEach } from 'lodash';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

class BaseColumnControl {
    formControl: FormControl;
    filteredList?: Observable<IColumnSetting[]>;
}

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'columns-settings',
    templateUrl: './base-columns-settings.component.html',
    styleUrls: ['./base-columns-settings.component.scss']
})
export class BaseColumnSettingsComponent extends BaseController implements OnInit {

    @Input() public tabSettings: TabSettingsService;
    @Input() public selectedView: string;
    @Input() public addFieldLabel: string;
    @Input() public columns: {
        fieldName: string,
        dir: 'asc' | 'desc'
    }[];
    @Output() public orderChanged = new EventEmitter<number>();
    @Output() public fieldChanged = new EventEmitter<boolean>();
    public defaultSorts: IColumnSetting[];
    public isDefaultSorts = true;

    private _allColumns: IColumnSetting[] = [];

    public controlsArray: BaseColumnControl[] = [];

    constructor() { super(); }

    public async ngOnInit() {
        this._allColumns = (await this.tabSettings.getAllColumns()).sort((a, b) => a.fieldName.localeCompare(b.fieldName));
        (this.columns || []).forEach((element) => this.controlsArray.push(this._addNewColumn(element.fieldName)));
    }

    public addField() {
        this.columns.push({ fieldName: '', dir: 'asc' });
        this.controlsArray.push(this._addNewColumn());
    }

    public drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
        moveItemInArray(this.controlsArray, event.previousIndex, event.currentIndex);
        this.fieldChanged.emit(true);
    }

    public removeField(i: number) {
        this.columns.splice(i, 1);
        this.controlsArray.splice(i, 1);
        this.fieldChanged.emit(true);
        if (!this.columns.length) {
            this.addField();
        }
    }

    public orderChange(item, dir) {
        item.dir = dir;
        this.orderChanged.emit(item);
    }

    public onFacetChanged(event: MatAutocompleteSelectedEvent, index: number) {
        this.columns[index].fieldName = event.option.value.fieldName;
        this.fieldChanged.emit(true);
    }

    public displayFn(selectedoption) {
        return selectedoption?.display;
    }

    private _filter(value: string): IColumnSetting[] {
        const filterValue = value.toLowerCase();
        return (this._allColumns || []).filter(option => searchForText(filterValue, option?.display));
    }

    private _addNewColumn(fieldName?: string): BaseColumnControl {
        const col = this._allColumns.find(col => col.fieldName === fieldName);

        const newElement:BaseColumnControl = { formControl: new FormControl(col) };
        newElement.filteredList = newElement.formControl.valueChanges
            .pipe(startWith(''),
                map(value => typeof value === 'string' ? value : value.display),
                map(display => this._filter(display || '')));
        return newElement;
    }
}
