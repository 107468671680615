import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { IOption } from '../../option-lists/models/option';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BaseCustomController } from '../shared/base-custom-controller';
import { DataServiceInterface } from '../shared/data-interface';
import { MatChipInputEvent } from '@angular/material/chips';
import { DataServiceFactory } from '../services/data-service-factory';


@Component({
    selector: 'app-formly-field-data-chip-list',
    templateUrl: './data-chip-list.component.html',
    styleUrls: ['./data-chip-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DataChipListComponent extends BaseCustomController implements OnInit {
    public dynOptionsById: { [id: string]: IOption } = {};
    public dynOptionsByCode: { [id: string]: IOption } = {};
    public dynOptions: IOption[] = [];
    public selectedOptions = [];
    public filteredOptions;
    public displayName: string;
    public allowNewOptions: string;
    optionControl = new FormControl('');
    @ViewChild('optionInput') optionInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    private _usedProp = 'id';
    public useOption;
    public optionsLimit = 30;
    private dataService: DataServiceInterface;

    constructor(
        private dataServiceFactory: DataServiceFactory,
        private cdRef: ChangeDetectorRef,
        private defaultDataService: DataServiceInterface
    ) {
        super();
    }
    ngOnInit() {
        this.displayName = this.field.templateOptions.displayName;
        this.allowNewOptions = this.field.templateOptions.allowNewOptions;
        this.useOption = this.field.templateOptions.useOption;
        const formChanges = new BehaviorSubject(this.field.formControl.value);
        this.field.formControl.valueChanges.subscribe(formChanges);
        const datasourceKey = this.field.templateOptions.dataSourceKey;
        this.dataService = datasourceKey ? this.dataServiceFactory.getService(datasourceKey) : this.defaultDataService;
        combineLatest([this.dataService.get(), formChanges])
            .pipe(filter(f => !!f[0]))
            .subscribe(data => {
                this.dynOptions = data[0];
                const selectedOptions = data[1] !== null && data[1] !== undefined && data[1] !== '-' ? data[1] as string[] | number[] : null;
                this.dynOptions.forEach(o => {
                    this.dynOptionsById[o[this._usedProp]] = o;
                });
                this.selectedOptions = selectedOptions ? this.useOption ? selectedOptions : this.getSelectedOptions(selectedOptions) : [];
                this.optionControl.setValue(null);
                this.optionInput.nativeElement.value = '';
                this.cdRef.markForCheck();
            });

        this.optionControl.valueChanges.subscribe(text => {
            if (this.dynOptions) {
                this.filteredOptions = (text ? this._filter(text) : this.dynOptions).slice(0, this.optionsLimit);
                if (this.selectedOptions && this.selectedOptions.length) {
                    this.filteredOptions = this.filteredOptions.filter(option => this.selectedOptions.findIndex(op => op[this._usedProp] === option[this._usedProp]) === -1).slice(0, this.optionsLimit);
                }
            }
        });
    }

    private getSelectedOptions(ids: (number | string)[]) {
        if (typeof ids === 'string') {
            ids = String(ids).split(',')
        }
        return ids.map(id => {
            if (typeof id === 'string') {
                return this.dynOptionsById[id.trim()]
            }
            return this.dynOptionsById[id]
        });
    }


    remove(option: string): void {
        const index = this.useOption ? this.selectedOptions.findIndex((x) => x[this._usedProp] == option[this._usedProp]) : this.selectedOptions.indexOf(option);
        if (index >= 0) {
            this.selectedOptions.splice(index, 1);
            this.updateValueAndValidity(this.field, this.useOption ? this.selectedOptions : this.selectedOptions.map(op => op[this._usedProp]));
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.optionControl.setValue(null);
        const value = event.option.value;
        if (!this.allowNewOptions && !value)
            return
        this.selectedOptions.push({
            id: value.id,
            [this.displayName]: value[this.displayName]
        });
        this.optionInput.nativeElement.value = '';
        this.updateValueAndValidity(this.field, this.useOption ? this.selectedOptions : this.selectedOptions.map(op => op[this._usedProp]));
    }

    private _filter(value): any[] {
        const filterValue = value[this.displayName] ? value[this.displayName].toLowerCase() : value.toLowerCase();
        return this.dynOptions.filter(option => option[this.displayName].toLowerCase().includes(filterValue));
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if (this.allowNewOptions && (value || '').trim()) {
            const newOption = { id: 0, description: value.trim() };
            this.dataService.add(newOption).subscribe((result: any) => {
                const savedOption = result.mutationResult;
                this.dynOptions.push(savedOption);
                this.selectedOptions.push(savedOption);
                this.updateValueAndValidity(this.field, this.selectedOptions.map(op => op[this._usedProp]));
            });
        }
        if (input) {
            input.value = '';
        }
        this.optionControl.setValue(null);
    }
}

