<div class="dataset-wrapper">
    <div class="spinner-wrapper" *ngIf="!datasetList">
        <mat-spinner color="primary"></mat-spinner>
    </div>
    <div class="main" *ngIf="datasetList">
        <div class="flex-between">
            <mat-form-field class="main-template-search">
                <input class="inner-search-bar" [(ngModel)]="searchText" (input)="filterDatasets()" matInput type="text" placeholder="Search for dataset" />
                <mat-icon (click)="closeSearch()" class="close-button">{{(!searchText)?'search':'close'}}</mat-icon>
            </mat-form-field>

            <div class="create-btn">
                <button mat-flat-button color="primary" (click)="openCreateDialog()">
                    <mat-icon>add</mat-icon> Add Dataset
                </button>
            </div>
        </div>

        <div class="dataset-container">
            <p class="flex justify-center items-center w-full text-2xl font-bold" *ngIf="!datasetList.length">No
                Datasets Found</p>
            <div *ngFor="let dataset of datasetList" class="strech">
                <mat-card (click)="navigateToSchema(dataset?.Id)">
                    <mat-card-title>
                        <b class="text-xl font-medium">{{ dataset?.Title }}</b>
                    </mat-card-title>
                    <mat-card-subtitle>
                        <p>{{ dataset?.EmailAlias }}
                            <mat-icon class="copy-icon" *ngIf="!dataset.IsCopied" (click)="copyEmail(dataset ,$event)"
                                matTooltip="Copy">content_copy</mat-icon>
                            <mat-icon class="copy-icon" *ngIf="dataset.IsCopied">check</mat-icon>
                        </p>

                    </mat-card-subtitle>
                    <mat-card-footer>
                        <div class="bottom">
                            <!-- <div>
                                <p>RECIEVED</p>
                                <span class="badge-received" #tooltip="matTooltip" matTooltip="Total Documents"
                                    matTooltipPosition="above">
                                    {{ dataset?.RecievedDocs || "0" }}
                                </span>
                            </div> -->
                            <div>
                                <p>PROCESSED</p>
                                <span class="badge-proccessed" #tooltip="matTooltip" matTooltip="Documents Processed"
                                    matTooltipPosition="above">
                                    {{ dataset?.ProcessedDocs || "0" }}
                                </span>
                            </div>
                            <div>
                                <p>QUEUED</p>
                                <span class="badge-proccessing" #tooltip="matTooltip" matTooltip="Documents Processing"
                                    matTooltipPosition="above">
                                    <p class="parsed">
                                        {{ dataset?.ProcessingDocs || "0" }}
                                    </p>
                                </span>
                            </div>
                        </div>
                    </mat-card-footer>
                </mat-card>
            </div>
        </div>
    </div>
</div>