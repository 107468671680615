import {
  Component,
  forwardRef,
  Injectable,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { CronOptions } from "ngx-cron-editor";
import { FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { BaseCustomController } from "../shared/base-custom-controller";

@Injectable({
  providedIn: "root",
})
@Component({
  selector: "dyn-cron",
  templateUrl: "./cron-exp-generator.component.html",
  styleUrls: ["./cron-exp-generator.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CronExpGeneratorComponent),
    },
  ],
})
export class CronExpGeneratorComponent
  extends BaseCustomController
  implements OnInit
{
  public panelOpenState = false;
  public cronExpression: string = "* * * * *";
  public isCronDisabled = false;
  public cronOptions: CronOptions = {
    formInputClass: "form-control cron-editor-input",
    formSelectClass: "form-control cron-editor-select",
    formRadioClass: "cron-editor-radio",
    formCheckboxClass: "cron-editor-checkbox",
    defaultTime: "00:00:00",
    hideMinutesTab: false,
    hideHourlyTab: false,
    hideDailyTab: false,
    hideWeeklyTab: false,
    hideMonthlyTab: false,
    hideYearlyTab: false,
    hideAdvancedTab: true,
    hideSpecificWeekDayTab: false,
    hideSpecificMonthWeekTab: false,
    use24HourTime: true,
    hideSeconds: false,
    cronFlavor: "standard",
  };

  cronForm = new FormControl(this.cronExpression);

  onChange = (cronExpression: string) => {};

  onTouched = () => {};

  touched = false;

  constructor() {
    super();
  }

  public ngOnInit() {
    this.onValuesChange();
    this.field.formControl.setValue(this.cronExpression);
  }

  public markFormGroupTouched() {
    Object.values(this.cronForm).forEach((control) => {
      control.markAsTouched();
    });
  }
  public dropDown() {
    this.panelOpenState = !this.panelOpenState;
  }

  public onValuesChange() {
    this.cronForm.valueChanges.subscribe((data) => {
      this.cronExpression = data;
      this.field.formControl.setValue(data);
    });
  }

  writeValue(cronExpression: string) {
    this.cronExpression = cronExpression;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
