import { NgModule } from '@angular/core';
import { HistogramComponent } from './histogram/histogram.component';
import { NumericHistogramComponent } from './numeric-histogram/numeric-histogram.component';
import { DateHistogramComponent } from './date-histogram/date-histogram.component';
import { HistogramService } from './service/histogram.service';
import { CommonModule } from '@angular/common';
import { ChartsModule } from "@progress/kendo-angular-charts";
import { MaterialModule } from '../../MaterialModule';

@NgModule({
    declarations: [
        HistogramComponent,
        NumericHistogramComponent,
        DateHistogramComponent

    ],
    exports: [
        NumericHistogramComponent,
        DateHistogramComponent
    ],
    imports: [
        CommonModule,
        ChartsModule,
        MaterialModule
    ],
    providers: [
        HistogramService
    ]
})
export class DiscoHistogramModule { }
