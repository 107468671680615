import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { format } from '@progress/kendo-angular-intl';
import { BaseController, IColumnSetting } from '@discoverer/core/services'
import { fixTimeZone, disLogger } from '@discoverer/core/services/functions';

@Component({
  selector: 'details-label-value',
  styleUrls: ['./details-label-value.component.scss'],
  templateUrl: './details-label-value.component.html'
})
// tslint:disable-next-line:component-class-suffix
export class DetailsLabelValueWidget extends BaseController implements OnInit, OnChanges {

  @Input() public field: string;
  @Input() public columnDictionary: { [key:string]: IColumnSetting };
  @Input() public data: any = {};

  @Input() public hideLabel = false;

  public displayedLabel: string;
  public displayedValue: string;
  public column: IColumnSetting;

  constructor(
  ) {
    super();

  }

  async ngOnInit() {

  }

  async ngOnChanges() {
    if (!!this.field && !!this.columnDictionary) {
      this.column = this.columnDictionary[this.field];

      if (!!this.column ) {
        this.displayedLabel =  this.column.display;

        if (!!this.column.format) {
          this.displayedValue = format(this.column.format, this.data[this.field], 'en');
        } else {
          this.displayedValue = this.data[this.field];
        }

        if (this.column.type === 'date') {
          fixTimeZone(this.data[this.field]);
        }
      }
    }
  }

}
