import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OptionDataServiceInterface } from './data-service';
import { IdbOption, IInsertDBOptionDTO, IOption } from '../models/option';


@Injectable()
export class HasuraOptionService implements OptionDataServiceInterface {
  constructor(private apollo: Apollo) {

  }

  public getByOptionListId(listId: number, searchText?: string): Observable<IOption[]> {
    if (listId) {
      const queryObservable = this.apollo.query<{ Options: any[] }>({
        query: gql`query getOptions($OptionListId: Int!, $searchText: String) {
          Options: ignt_core_Options(where: {OptionListId: {_eq: $OptionListId}, _and: {Description: {_ilike: $searchText}}}, 
            order_by: {Order: asc, Group: asc}) {
            Id
            Description
            OptionListId
            Code
            Value
            Group
            Order
          }
        }
        `, variables: { OptionListId: listId, searchText: (!!searchText ? searchText + '%' : '%') }
      });
      return queryObservable.pipe(map((x) => (x?.data?.Options).map(z => { return this.mapData(z); })));
    }
  }

  public getOptionsByListName(listName: string): Observable<IOption[]> {
    const queryObservable = this.apollo.query<{ Options: any[] }>({
      query: gql`query getOptions($listName: String!) {
        Options: ignt_core_Options(where: {OptionList: {Description: {_eq: $listName}}}) {
          Id
          Description
          OptionListId
          Code
          Value
          Group
          Order
        }
      }

      `, variables: { listName }
    });
    return queryObservable.pipe(map((x) => (x?.data?.Options).map(z => { return this.mapData(z); })));
  }

  public getOptionIdByName(optionName: string): Promise<number> {
    const queryObservable = this.apollo.query<{ Options: any[] }>({
      query: gql`query optionQuery {
        Options: ignt_core_Options(where: {Description: {_eq: "${optionName}"}}) {
          Id
        }
      }
      `
    });
    return queryObservable.pipe(map(z => z.data && z.data.Options?.length ? z.data.Options[0].Id : 0)).toPromise();
  }

  public getOptionById(id: number): Observable<IOption> {
    const queryObservable = this.apollo.query<{ Options: any[] }>({
      query: gql`query ($id: Int) {
        Options: ignt_core_Options(where: {Id: {_eq: $id}}) {
          OptionListId
          Order
          Value
          Code
          Description
          Group
          Id
        }
      }

      `, variables: { id }
    });
    return queryObservable.pipe(map((x) => (x?.data?.Options).map(z => { return this.mapData(z) })[0]));
  }

  public getOptionByOrder(order: number, optionListId: number): Observable<IOption> {
    const queryObservable = this.apollo.query<{ Options: any[] }>({
      query: gql`query ($optionListId: Int, $order: Int) {
        Options: ignt_core_Options(where: {OptionListId: {_eq: $optionListId}, Order: {_eq: $order}}) {
          OptionListId
          Order
          Value
          Code
          Description
          Group
          Id
        }
      }
      `, variables: {
        order,
        optionListId
      }
    });
    return queryObservable.pipe(map((x) => (x?.data?.Options).map(z => { return this.mapData(z); })[0]));
  }

  public add(option: IOption): Observable<{ mutationResult: { errors: any } | IOption }> {
    return this.apollo.mutate({
      mutation: gql`mutation addOption($description: String!, $code: String, $value: String, $optionListId: Int!, $group: String, $order: Int) {
        Options: insert_ignt_core_Options(objects: {Code: $code, Description: $description, OptionListId: $optionListId, Value: $value, Group: $group, Order: $order}) {
          returning {
            Description
            Id
            Code
            Value
            OptionListId
            Group
            Order
          }
        }
      }      `, variables: {
        description: option.description, code: option.code,
        value: option.value, optionListId: option.optionListId, group: option.group, order: option.order
      }
    }).pipe(map((x: any) => {
      return { mutationResult: x.errors ? x : this.mapData(x.data.Options.returning[0]) };
    }));
  }

  public update(option: IOption): Observable<{ mutationResult: { errors: any } | IOption }> {
    return this.apollo.mutate({
      mutation: gql`mutation updateOption($optionId: Int!, $description: String!, $code: String, $value: String, $group: String) {
        Options: update_ignt_core_Options(where: {Id: {_eq: $optionId}}, _set: {Description: $description, Code: $code, Value: $value, Group: $group}) {
          returning {
            Description
            Id
            Code
            Value
            OptionListId
            Group
            Order
          }
        }
      }
      `, variables: {
        optionId: option.id, description: option.description, code: option.code,
        value: option.value, group: option.group
      }
    }).pipe(map((x: any) => {
      return { mutationResult: x.errors ? x : this.mapData(x.data.Options.returning[0]) };
    }));
  }

  public updateOptionOrder(option: IOption): Observable<IOption> {
    return this.apollo.mutate({
      mutation: gql`mutation updateOptionOrder($optionId: Int!, $order: Int) {
        Options: update_ignt_core_Options(where: {Id: {_eq: $optionId}}, _set: {Order: $order}) {
          returning {
            Description
            Id
            Code
            Value
            OptionListId
            Group
            Order
          }
        }
      }
      `, variables: {
        optionId: option.id, order: option.order
      }
    }).pipe(map((x: any) => this.mapData(x.data.Options.returning[0])));
  }

  public delete(option: IOption): Observable<{ mutationResult: { errors: any } | IOption }> {
    return this.apollo.mutate({
      mutation: gql`mutation deleteOptions($optionId: Int!) {
        Options: delete_ignt_core_Options(where: {Id: {_eq: $optionId}}) {
          affected_rows
          returning {
            Id
            Description
            Code
            OptionListId
            Value
            Group
            Order
          }
        }
      }`, variables: { optionId: option.id }
    }).pipe(map((x: any) => {
      return { mutationResult: x.errors ? x : this.mapData(x.data.Options.returning[0]) };
    }));
  }

  public getByQuery(query: string): Observable<any[]> {
    const queryObservable = this.apollo.query<{ Options: any[] }>({
      query: gql`${query}`
    });

    return queryObservable.pipe(map((x: any) =>
      (x.data ? x.data[Object.keys(x.data)[0]] : []).map(z => {
        return {
          id: z.Id, description: z.Name, optionListId: 0
          , value: null, code: null, group: null, order: null
        };
      })));
  }

  public addMultiple(
    options: IInsertDBOptionDTO[]
  ): Observable<{ mutationResult: { errors: any } | IdbOption[] }> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation ($objects: [ignt_core_Options_insert_input!] = {}) {
            insert_ignt_core_Options(objects: $objects) {
              returning {
                Code
                Description
                Group
                Id
                OptionListId
                Order
                Value
              }
            }
          }
        `,
        variables: {
          objects: options,
        },
      })
      .pipe(
        map((x: any) => {
          return {
            mutationResult: x.errors
              ? x
              : this.mapData(x.data.insert_ignt_core_Options.returning),
          };
        })
      );
  }

  private mapData(item): IOption {
    return item ? {
      code: item.Code, description: item.Description, id: item.Id, optionListId: item.OptionListId,
      value: item.Value, group: item.Group, order: item.Order
    } as IOption : null;
  }

}
