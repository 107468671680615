<div *ngIf="chartControlService.oState | async as state" class="header">
    <div class="edit-buttons">
        <div class="edit-button hover-pointer align-center" *ngIf="chartType === viewTypes.Chart">
            <div [matMenuTriggerFor]="chartTypesMenu" color="accent" class="title" #chartTypesTrigger="matMenuTrigger">
                <div class="menu-button-content">
                    <!-- <div class="title">
                        Chart As
                    </div> -->
                    <div class="subtitle">
                        <div class="subtitle-content">
                            <mat-icon class="chart-icon">{{state?.chartType?.icon}}</mat-icon>
                            <div class="chart-name">{{state?.chartType?.title}}</div>
                        </div>
                    </div>
                    <mat-icon class="title-icon">
                        {{chartTypesTrigger.menuOpen ? 'arrow_drop_up' : 'arrow_drop_down'}}
                    </mat-icon>
                </div>
            </div>
            <mat-menu #chartTypesMenu="matMenu">
                <div class="chart-types">
                    <button mat-menu-item class="chart-type" *ngFor="let type of chartTypes"
                        (click)="setChartType(type)" [ngClass]="{'selected':(type.id == state?.chartType?.id)}">
                        <mat-icon class="icon">{{type.icon}}</mat-icon>
                        <div class="type-title">{{type.title}}</div>
                    </button>
                </div>
            </mat-menu>
        </div>

        <!-- Summarize by -->
        <div class="edit-button ">
            <div [popoverTrigger]="summarizeByPopOver" [closeOnClickOutside]="true" [overlayX]="'start'"
                matTooltip="{{state?.summarizeBy? state?.summarizeBy[0]?.subTitle : '--'}}"
                class="menu-button-content hover-pointer align-center">
                <div class="title">
                    Metric
                </div>
                <div *ngIf="(state?.summarizeBy && state?.summarizeBy[0]?.subTitle)" class="subtitle">
                    <div class="subtitle-content">
                        <div class="name">{{state?.summarizeBy? state?.summarizeBy[0]?.subTitle : '--'}}</div>
                    </div>
                </div>
                <span class="subtitle"></span>
                <mat-icon class="small-icon">arrow_drop_down</mat-icon>

            </div>
            <ng-template #summarizeByPopOver>
                <div class="popup-container">
                    <div *ngFor="let summarizeBy of state?.summarizeBy; let i = index;">
                        <div style="display:flex;">

                            <div class="tab-content">
                                <auto-complete-search [label]="'Field'" class="select-field"
                                    [fieldName]="summarizeBy?.colName" [availableColumns]="metricColumns"
                                    [readonly]="false"
                                    (changeFieldName)="changeSummarizeBy($event, summarizeBy?.functionType || 'count', i)">
                                </auto-complete-search>
                                <mat-form-field class="secound-list"
                                    *ngIf="summarizeBy?.colName && summarizeBy?.colName !== numberOfRowsMetric?.fieldName">
                                    <mat-label>Function</mat-label>
                                    <mat-select placeholder="Function type"
                                        (selectionChange)="changeSummarizeBy(summarizeBy?.colName, $event.value, i)"
                                        [value]="summarizeBy?.functionType">
                                        <mat-option
                                            *ngFor="let metricfcn of currentMetricList(columnDictionary[summarizeBy?.colName]?.type)"
                                            [value]="metricfcn">
                                            {{metricfcn}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="metrics-control" *ngIf="chartType === viewTypes.Chart">
                                <mat-icon class="red-color" (click)="removeMetric(i)"
                                    *ngIf="i > 0 || chartType === viewTypes.Chart">
                                    remove_circle_outline</mat-icon>
                            </div>
                        </div>
                        <mat-divider></mat-divider>

                    </div>
                    <mat-checkbox style="padding-top: 16px" *ngIf="chartType === viewTypes.Chart"
                        [(ngModel)]="state.isLogarithmScale">Plot axis on a logarithmic scale</mat-checkbox>
                    <div style="display: flex; justify-content: space-between; margin-top: 16px;">
                        <button style="border-radius: 0px !important;"
                            *ngIf="chartType === viewTypes.Chart && state?.summarizeBy?.length < 2" mat-raised-button
                            (click)="addSummarizeBy(state?.summarizeBy?.length)">Add
                            Metric</button>
                        &nbsp;
                        <button style="border-radius: 0px !important;" color="primary" mat-raised-button
                            [disabled]="chartControlService.isLoading$ | async"
                            (click)="applyCurrentState()">Done</button>
                    </div>

                </div>
            </ng-template>
        </div>

        <!-- GROUP BYs -->
        <div class="edit-button">
            <div [popoverTrigger]="groupByPopOver" [closeOnClickOutside]="true" [overlayX]="'start'"
                matTooltip="{{getGroupsSubTitle(state?.groupBys)}}"
                class="menu-button-content hover-pointer align-center">
                <div class="title">
                    Group By
                </div>
                <div *ngIf="state?.groupBys?.length > 0" class="subtitle">
                    <div class="subtitle-content">
                        <div class="name">{{getGroupsSubTitle(state?.groupBys)}}</div>
                    </div>
                </div>
                <mat-icon class="small-icon">arrow_drop_down</mat-icon>

            </div>
            <ng-template #groupByPopOver>
                <div class="popup-container">
                    <mat-accordion>
                        <div class="tab-content" *ngFor="let group of state?.groupBys; let jj = index;">

                            <div class="series-clear">
                                <div class="flex flex-col item">
                                    <auto-complete-search
                                        label="{{  ((chartType === viewTypes.Pivot) ? (jj === 0 ?  'Column' : 'Row') : 'Field')  }}"
                                        class="select-field" [fieldName]="group.colName"
                                        [availableColumns]="availableColumns" [readonly]="false"
                                        (changeFieldName)="prepareGroups($event, jj)">
                                    </auto-complete-search>

                                    <mat-expansion-panel class="expansion-panel">
                                        <mat-expansion-panel-header class="expansion-panel-header">
                                            <mat-panel-title class="expansion-panel-title"> More Settings
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div *ngIf="group?.colName && group?.availableGroups?.length else SeriesLimit">
                                            <div *ngIf="group.colType == 'date' || group.colType == 'timestamp' || group.colType == 'timestamptz' else Numeric"
                                                style="display: flex; justify-content: space-between;">
                                                <mat-form-field class="secound-list" style="width: 45% !important;">
                                                    <mat-label>Group By</mat-label>
                                                    <mat-select placeholder="Group By" [(value)]="group.groupByName">
                                                        <mat-option *ngFor="let name of group.availableGroups"
                                                            [value]="name" (click)="setGroupby(group, name, jj)">
                                                            {{name}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <mat-form-field *ngIf="group?.groupByName"
                                                    style="width: 45% !important; margin-top: 8px;">
                                                    <mat-label>{{(group?.groupByName || '-')+' No.'}}</mat-label>
                                                    <input type="number" matInput [(ngModel)]="group.numberOfGroups"
                                                        (change)="onNumberOfGroupsChange(group, group.numberOfGroups, jj)">
                                                </mat-form-field>

                                            </div>
                                            <ng-template #Numeric>
                                                <div *ngIf="group?.groupBy">
                                                    <div style="display: flex;justify-content: space-between;">
                                                        <mat-form-field style="width: 45%;">
                                                            <mat-label>Min</mat-label>
                                                            <input matInput [(ngModel)]="group.groupBy.start"
                                                                (change)="onGroupChange(group, jj)" />
                                                        </mat-form-field>
                                                        <mat-form-field style="width: 45%;">
                                                            <mat-label>Max</mat-label>
                                                            <input matInput [(ngModel)]="group.groupBy.end"
                                                                (change)="onGroupChange(group, jj)" />
                                                        </mat-form-field>
                                                    </div>
                                                    <mat-form-field style="width: 100%;">
                                                        <mat-label>Step</mat-label>
                                                        <input matInput [(ngModel)]="group.groupBy.stepsNo"
                                                            (change)="onGroupChange(group, jj)" />
                                                    </mat-form-field>
                                                </div>
                                            </ng-template>
                                        </div>
                                        <div *ngIf="group?.colType == 'date' && !!group?.groupByName && jj === 0"
                                            class="group-by-date">
                                            <input-widget style="width: 45% !important;" [label]="'Offset'"
                                                [value]="group?.offset?.toString()"
                                                (change)="onOffsetChange(group, $event, jj)">
                                            </input-widget>
                                            <input-widget *ngIf="chartType === viewTypes.Pivot"
                                                [label]="'Compare Offset'" style="width: 45% !important;"
                                                [value]="group?.compareOffset?.toString()"
                                                (change)="onCompareOffsetChange(group, $event, jj)">
                                            </input-widget>
                                        </div>
                                        <ng-template #SeriesLimit>

                                            <div class="limit-chart"
                                                style="display:flex; justify-content: space-between">
                                                <input-widget style="width: 45%" class="number" [label]="'Limit'"
                                                    [value]="group?.limit?.toString()"
                                                    (change)="changeLimit(group, $event, jj)">
                                                </input-widget>
                                                <input-widget style="width: 45%" class="number" [label]="'Min Count'"
                                                    [value]="group?.minCount?.toString()"
                                                    (change)="changeMinCount(group, $event, jj)">
                                                </input-widget>
                                            </div>
                                        </ng-template>

                                        <mat-slide-toggle *ngIf="jj === 0 && chartType === viewTypes.Chart"
                                            [(ngModel)]="state.countSubRecords">Count Sub
                                            Records </mat-slide-toggle>
                                    </mat-expansion-panel>
                                </div>
                                <div class="series-control">
                                    <div class="series-buttons">
                                        <div *ngIf="jj > 0">
                                            <mat-icon class="nav-button" (click)="moveSeriesUp(jj)">
                                                arrow_circle_up
                                            </mat-icon>
                                        </div>
                                        <div class="series-remove button1">
                                            <mat-icon class="red-color" (click)="clearSeries(jj)">
                                                remove_circle_outline
                                            </mat-icon>
                                        </div>
                                        <div *ngIf="jj < state?.groupBys?.length-1">
                                            <mat-icon class="nav-button" (click)="moveSeriesDown(jj)">
                                                arrow_circle_down
                                            </mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="seperator">
                        </div>
                    </mat-accordion>

                    <div style="display: flex; justify-content: space-between; margin-top: 16px;">
                        <button style="border-radius: 0px !important;" mat-raised-button
                            *ngIf="chartType === viewTypes.Pivot || (chartType === viewTypes.Chart && state?.groupBys?.length<2)"
                            (click)="addSeries(state?.groupBys?.length)">Add Group By</button>
                        &nbsp;
                        <button style="border-radius: 0px !important;" color="primary" mat-raised-button
                            [disabled]="chartControlService.isLoading$ | async"
                            (click)="applyCurrentState()">Done</button>
                    </div>
                </div>
            </ng-template>
        </div>

        <!-- Sorting -->
        <div class="edit-button">
            <div [popoverTrigger]="sortingPopOver" [closeOnClickOutside]="true" [overlayX]="'start'"
                matTooltip="{{state?.sort?.subTitle}}" class="menu-button-content hover-pointer align-center">
                <div class="title">
                    Sort By
                </div>
                <div class="subtitle">
                    <div class="subtitle-content">
                        <div class="name">{{state?.sort?.subTitle}}</div>
                    </div>
                </div>
                <mat-icon class="small-icon">arrow_drop_down</mat-icon>
            </div>
            <ng-template #sortingPopOver>
                <div class="popup-container">
                    <div class="sort-container">
                        <div *ngIf="state?.groupBys?.length > 0 && !!state?.sort">
                            <div class="sort-col">
                                <div class="label first">Sort by</div>
                                <mat-radio-group [(ngModel)]="state?.sort.sortType" (change)="setSort(state?.sort)">
                                    <mat-radio-button [value]="'label'" class="left-radio">Labels -
                                        {{chartCategoryLabel}}
                                    </mat-radio-button>
                                    <mat-radio-button [value]="'value'">
                                        Values -
                                        {{state?.summarizeBy? state?.summarizeBy[0]?.subTitle :
                                        '--'}}</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="sort-col">
                                <div class="label">Categories Direction</div>
                                <mat-radio-group [(ngModel)]="state?.sort.sortDir" (change)="setSort(state?.sort)">
                                    <mat-radio-button class="left-radio" [value]="'asc'">Low to High
                                    </mat-radio-button>
                                    <mat-radio-button [value]="'desc'">High to Low</mat-radio-button>
                                </mat-radio-group>
                            </div>

                        </div>
                        <div *ngIf="state?.groupBys?.length > 1 && state?.groupBys[1]?.colName">
                            <div class="sort-col">
                                <div class="label first">Series Sort</div>
                                <mat-radio-group [(ngModel)]="state?.sort.seriesSort" (change)="setSort(state?.sort)">
                                    <mat-radio-button [value]="'series'" class="left-radio"> Series Name
                                    </mat-radio-button>
                                    <mat-radio-button [value]="'value'">
                                        Values </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="sort-col">
                                <div class="label">Series direction</div>
                                <mat-radio-group [(ngModel)]="state?.sort.seriesSortDir"
                                    (change)="setSort(state?.sort)">
                                    <mat-radio-button class="left-radio" [value]="'asc'">Low to High
                                    </mat-radio-button>
                                    <mat-radio-button [value]="'desc'">High to Low</mat-radio-button>
                                </mat-radio-group>
                            </div>

                        </div>
                    </div>
                    <div style="display: flex; justify-content: space-between; margin-top: 16px;">
                        &nbsp;
                        <button style="border-radius: 0px !important;" color="primary" mat-raised-button
                            [disabled]="chartControlService.isLoading$ | async"
                            (click)="applyCurrentState()">Done</button>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <div *ngIf="chartType === viewTypes.Pivot && (state?.groupBys && state?.groupBys[0]?.colType == 'date' || seriesListContainsDateField(state?.groupBys))"
        class="navigation-arrows">
        <button mat-button (click)="changePeriod()">
            <span class="material-icons">
                navigate_before
            </span>
        </button>
        <mat-select *ngIf="chartType === viewTypes.Pivot && state?.groupBys && (state?.groupBys[0]?.groupByName === 'DAILY MONTH' || state?.groupBys[0]?.groupByName === 'DAILY WEEK')"
             placeholder="Select Period" [ngModel]="initSelectedPeriod(state?.groupBys[0]?.groupByName)" (selectionChange)="onPeriodChange($event)" class="custom-select">
            <mat-option *ngFor="let period of periodOptions" [value]="period.value">{{ period.label }}</mat-option>
        </mat-select>
        <button mat-button (click)="changePeriod(true)">
            <span class="material-icons">
                navigate_next
            </span>
        </button>
    </div>
    <div *ngIf="chartType === viewTypes.Pivot && state?.groupBys && !!state?.groupBys[0]?.groupBy?.compareType && state?.groupBys[0].compareOffset > 0"
        class="display-mode-wrapper">
        <div class="display-mode-button" mat-button [matMenuTriggerFor]="displayModeMenu"
            #DisplayModeTrigger="matMenuTrigger">
            <span class="link">Display Mode:</span> <span
                class="title-value">({{state?.groupBys[0]?.groupBy?.compareType}})</span>
            <mat-icon>{{DisplayModeTrigger.menuOpen ? 'arrow_drop_up' : 'arrow_drop_down'}}
            </mat-icon>
        </div>
        <mat-menu #displayModeMenu="matMenu">
            <mat-option *ngFor="let mode of modes" (click)="onCompareTypeChange(state?.groupBys[0], mode)"
                [ngClass]="state?.groupBys[0]?.groupBy?.compareType==mode ? 'mat-selected' : ''">
                {{mode}} </mat-option>
        </mat-menu>
    </div>
</div>
