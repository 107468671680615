<div class="dashboard-filters">
    <div class="body">
        <mat-form-field class="filter-selector">
            <mat-label>Select Filter</mat-label>
            <input type="text" placeholder="Pick Filter" matInput [formControl]="myControl" [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
                (optionSelected)="onFacetChanged($event)">
                <mat-option *ngFor="let facet of filteredfacets | async" [value]="facet">
                    {{facet.display}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <div class="tab-list" *ngIf="tabs.length > 0">
            <mat-checkbox [ngClass]="tabsDic[tab.key].isValid ? 'valid' : 'invalid'" *ngFor="let tab of tabs" [checked]="tabsDic[tab.key].isSelected"
                class="item" (change)="selectionChanged($event, tab)">{{tab.name}}</mat-checkbox>
        </div>
    </div>
    <div class="footer">
        <button class="foot-btn" mat-stroked-button (click)="close()">Cancel</button>
        <button class="foot-btn" mat-flat-button color="primary" [disabled]="!selectedFacet?.tabs || selectedFacet?.tabs?.length === 0" (click)="addFilter()">Save</button>
    </div>
</div>
