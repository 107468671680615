<div class="schema-form-wrapper " cdkDropListGroup>

    <div class="flex w-full items-start gap-2">
        <!-- Main content -->
        <div [ngClass]="hideFileViewer ? 'w-full' : 'w-7/12'">
            <div class="page-header-wrapper">
                <div class="page-header">
                    <ng-container *ngIf="((schemaService.oDocumentList | async) || []) as documentList">
                        <div class="flex gap-1 cursor-pointer items-center">
                            <b class="whitespace-nowrap"
                                *ngIf="schemaService.oSelectedDocument | async as selcetedDoc">{{selcetedDoc?.field?.display}}</b>
                            <button [matMenuTriggerFor]="menu" mat-icon-button>
                                <mat-icon>keyboard_arrow_down</mat-icon></button>

                        </div>
                        <mat-menu #menu="matMenu" xPosition="before">

                            <div *ngFor="let doc of documentList" class="flex gap-2">
                                <button (click)="schemaService.setCurrentDocuemnt(doc.key)" mat-menu-item>
                                    <span>{{doc.display}}</span>
                                </button>
                                <button mat-icon-button (click)="openEditNameDialog(doc);">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </div>
                            <mat-divider></mat-divider>
                            <button (click)="openAddNewDialog()" mat-menu-item>
                                <mat-icon>add</mat-icon>
                                <span>Add Document</span>
                            </button>
                        </mat-menu>
                        <!-- <button mat-icon-button *ngIf="this.isEditMode || this.isLoading" (click)="openEditNameDialog()"
                        aria-label="Example icon-button with a menu">
                        <mat-icon>edit</mat-icon>
                    </button> -->
                        <div class="pl-10 space-x-4 page-header-actions">
                            <div class="left-btn-actions">
                                <!-- Edit Button -->
                                <button mat-flat-button color="primary" *ngIf="!this.isEditMode && !this.isLoading"
                                    (click)="toggleEdit()">
                                    <mat-icon class="material-icons-outlined">edit</mat-icon>
                                    Edit
                                </button>
                                <!-- Save and Cancel Buttons -->
                                <ng-container *ngIf="(this.isEditMode || this.isLoading)">
                                    <button mat-flat-button color="primary" (click)="save()">
                                        <mat-icon *ngIf="!isLoading" class="material-icons-outlined">save</mat-icon>
                                        <mat-spinner *ngIf="isLoading" [diameter]="24"></mat-spinner>
                                        Save
                                    </button>
                                    <button mat-stroked-button class="basic-button" color="basic" (click)="cancel()"
                                        [disabled]="isLoading">
                                        <mat-icon class="material-icons-outlined close-but">close</mat-icon>
                                        Cancel
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                        <button mat-icon-button [matMenuTriggerFor]="OPTIONS_MENU">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #OPTIONS_MENU>
                            <button *ngIf="isGenerateFieldsVisible" mat-menu-item
                                (click)="openAutoGeneratedFieldsDialog()" aria-label="Example icon-button with a menu">
                                <mat-icon>description</mat-icon> Auto Generate Fields
                            </button>
                            <button mat-menu-item (click)="clearSelectedDocumentFields()"
                                aria-label="Example icon-button with a menu">
                                <mat-icon>clear</mat-icon> Clear Fields
                            </button>
                            <button mat-menu-item *ngIf="documentList.length > 1" (click)="deleteSelectedDocument()"
                                aria-label="Example icon-button with a menu">
                                <mat-icon>delete</mat-icon> Delete
                            </button>
                        </mat-menu>
                    </ng-container>
                </div>
            </div>
            <div class="main-fields">
                <div *ngIf="!isDataLoaded">
                    <mat-spinner [diameter]="24"></mat-spinner>
                </div>

                <div class="titles flex">
                    <div class="column-0 drag"></div>
                    <div class="column-1 title">Type</div>
                    <div class="column-2 title">Field Name</div>
                    <div class="column-3 title">Description</div>
                </div>


                <div class="main-schema-form w-full" *ngIf="isDataLoaded" cdkDropList
                    (cdkDropListDropped)="drop($event)">
                    <div *ngFor="let field of getMainFieldsControls(); let i = index">
                        <field-detail *ngIf="field" [formGroup]="field" [index]="i" [isEditMode]="isEditMode"
                            [additionalItem]="isEditMode && i == (originalFields.length - 1)"
                            [objectFormGroup]="objectFormGroup"
                            (inputChange)="handleItemChange(originalFields, i, $event)"
                            (delete)="deleteField(originalFields, i)">
                        </field-detail>


                        <div class="nested-table"
                            *ngIf="field.controls.fields && !(isEditMode && i == (originalFields.length - 1))"
                            cdkDropList (cdkDropListDropped)="dropNested($event,field)">


                            <div class="titles-sub flex">
                                <div class="column-0 drag"></div>
                                <div class="column-1 title">Type</div>
                                <div class="column-2 title">Field Name</div>
                                <div class="column-3 title">Description</div>
                            </div>
                            <field-detail
                                *ngFor="let subField of getNestedFieldsControls(field); let nestedIndex = index"
                                [formGroup]="subField" [index]="nestedIndex" [isNested]="true" [isEditMode]="isEditMode"
                                [additionalItem]="isEditMode && nestedIndex == (getNestedFieldsControls(field).length - 1)"
                                [objectFormGroup]="objectFormGroup"
                                (inputChange)="addItem(field.controls.fields, nestedIndex)"
                                (delete)="deleteField(field.controls.fields, nestedIndex)"></field-detail>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div [ngClass]="(hideFileViewer) ? '' : 'w-5/12'" class="flex">
            <div class="flex flex-col w-full">
                <ng-content *ngIf="!isEditMode" select="[extraActions]"></ng-content>
                <div *ngIf="isEditMode" class="extra-actions-area"></div>
                <ng-content select="[fileActions]"></ng-content>
            </div>

        </div>

    </div>

</div>
