import {
    Pipe,
    PipeTransform
} from '@angular/core';
import { IDirRequest } from '@discoverer/core/services';
import { FlattenedDirRequests } from '@discoverer/dynamic-reports/common/filtered-reports-view/filtered-reports-view.component';


@Pipe({
    name: 'flattenTabsReq',
    pure: true
})
export class FlattenTabsRequestsPipe implements PipeTransform {
    transform(items: { key: string; name: string; dirs: IDirRequest[]; }[]): FlattenedDirRequests[] {
        if (!items) {
            return [] ;
        }
        const reqs = [];
        items.forEach(tab =>
            tab.dirs.forEach(dir =>
                dir.requests.forEach(req => reqs.push({
                    ...req,
                    tabName: tab.name,
                    tabKey: tab.key,
                    directoryName: dir.directoryName.split('|')[1],
                    isDirCommon: dir.userId === 0
                }))));
        return reqs;
    }
}
