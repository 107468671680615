import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IntlModule } from '@progress/kendo-angular-intl';
import { KendoExcelExportComponent } from './kendo-excel-export.component';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';

@NgModule({
    imports: [
        CommonModule,
        IntlModule,
        ExcelExportModule
    ],
    declarations: [
        KendoExcelExportComponent
    ],
    entryComponents: [
    ],
    providers: [
    ],
    exports: [
        KendoExcelExportComponent
    ]
})
export class KendoExcelExportModule {
    constructor() {

    }
}
