import { coerceNumberProperty } from '@angular/cdk/coercion';
import { DOCUMENT } from '@angular/common';
import {
    Directive,
    ElementRef,
    Inject,
    OnDestroy
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { KtdGridLayout, KtdDragStart, KtdResizeStart, KtdDragEnd, KtdResizeEnd, KtdGridLayoutItem } from '@katoid/angular-grid-layout';
import { CompactType } from '@katoid/angular-grid-layout/lib/utils/react-grid-layout.utils';
import { Subscription, merge, fromEvent } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
const DASHBOARD_TRANSITIONS = [
    { name: 'fast', value: 'transform 200ms ease, width 200ms linear, height 200ms linear' },
    { name: 'ease', value: 'transform 500ms ease, width 500ms ease, height 500ms ease' },
    { name: 'ease-out', value: 'transform 500ms ease-out, width 500ms ease-out, height 500ms ease-out' },
    { name: 'linear', value: 'transform 500ms linear, width 500ms linear, height 500ms linear' },
    {
        name: 'overflowing',
        value: 'transform 500ms cubic-bezier(.28,.49,.79,1.35), width 500ms cubic-bezier(.28,.49,.79,1.35), height 500ms cubic-bezier(.28,.49,.79,1.35)'
    },
    { name: 'slow-motion', value: 'transform 1000ms linear, width 1000ms linear, height 1000ms linear' },
    { name: 'transform-only', value: 'transform 500ms ease' }
]
@Directive({})
export class DashboardController implements OnDestroy{
    cols = 80;
    rowHeight = 30;
    compactType: CompactType = 'vertical';
    layout: KtdGridLayout = [ ];
    transitions: { name: string, value: string }[] = DASHBOARD_TRANSITIONS;
    currentTransition: string = this.transitions[0].value;

    dragStartThreshold = 0;
    autoScroll = true;
    disableDrag = false;
    disableResize = false;
    disableRemove = false;
    autoResize = true;
    preventCollision = false;
    isDragging = false;
    isResizing = false;
    resizeSubscription: Subscription;
    grid: any;

    constructor(public elementRef: ElementRef, @Inject(DOCUMENT) public document: Document) {
    }

    ngOnInit() {
        this.resizeSubscription = merge(
            fromEvent(window, 'resize'),
            fromEvent(window, 'orientationchange')
        ).pipe(
            debounceTime(50),
            filter(() => this.autoResize)
        ).subscribe(() => {
            this.grid.resize();
        });
    }

    ngOnDestroy() {
        this.resizeSubscription?.unsubscribe();
    }

    onDragStarted(event: KtdDragStart) {
        this.isDragging = true;
    }

    onResizeStarted(event: KtdResizeStart) {
        this.isResizing = true;
    }

    onDragEnded(event: KtdDragEnd) {
        this.isDragging = false;
    }

    onResizeEnded(event: KtdResizeEnd) {
        this.isResizing = false;
    }

    onLayoutUpdated(layout: KtdGridLayout) {
        this.layout = layout;
    }

    onCompactTypeChange(change: MatSelectChange) {
        this.compactType = change.value;
    }

    onTransitionChange(change: MatSelectChange) {
        this.currentTransition = change.value;
    }

    onAutoScrollChange(checked: boolean) {
        this.autoScroll = checked;
    }

    onDisableDragChange(checked: boolean) {
        this.disableDrag = checked;
    }

    onDisableResizeChange(checked: boolean) {
        this.disableResize = checked;
    }

    onDisableRemoveChange(checked: boolean) {
        this.disableRemove = checked;
    }

    onAutoResizeChange(checked: boolean) {
        this.autoResize = checked;
    }

    onPreventCollisionChange(checked: boolean) {
        this.preventCollision = checked;
    }

    onColsChange(event: Event) {
        this.cols = coerceNumberProperty((event.target as HTMLInputElement).value);
    }

    onRowHeightChange(event: Event) {
        this.rowHeight = coerceNumberProperty((event.target as HTMLInputElement).value);
    }

    onDragStartThresholdChange(event: Event) {
        this.dragStartThreshold = coerceNumberProperty((event.target as HTMLInputElement).value);
    }

    generateLayout() {
        const layout: KtdGridLayout = [];
        for (let i = 0; i < this.cols; i++) {
            const y = Math.ceil(Math.random() * 4) + 1;
            layout.push({
                x: Math.round(Math.random() * (Math.floor((this.cols / 2) - 1))) * 2,
                y: Math.floor(i / 6) * y,
                w: 2,
                h: y,
                id: i.toString()
            });
        }
        console.log('layout', layout);
        this.layout = layout;
    }

    /** Adds a grid item to the layout */
    addItemToLayout(newLayoutItem: KtdGridLayoutItem) {
        this.layout = [
            newLayoutItem,
            ...this.layout
        ];
    }

    /**
     * Fired when a mousedown happens on the remove grid item button.
     * Stops the event from propagating an causing the drag to start.
     * We don't want to drag when mousedown is fired on remove icon button.
     */
    stopEventPropagation(event: Event) {
        event.preventDefault();
        event.stopPropagation();
    }

    /** Removes the item from the layout */
    removeItem(id: string) {
        this.layout = ktdArrayRemoveItem(this.layout, (item) => item.id === id);
    }
}

export function ktdArrayRemoveItem<T>(array: T[], condition: (item: T) => boolean) {
    const arrayCopy = [...array];
    const index = array.findIndex((item) => condition(item));
    if (index > -1) {
        arrayCopy.splice(index, 1);
    }
    return arrayCopy;
}
