<div class="overlay" *ngIf="isUploading">
    <mat-spinner diameter="100"></mat-spinner>
    <p class="upload-hint">Generating your schema from file. Please wait</p>
</div>

<div class="main-wrapper" *ngIf="!isUploading">
    <div class="page-header">
        <b> Fields </b>
    </div>

    <div class="main">
        <div class="main-header">
            <p>Upload a document to start</p>
        </div>

        <!-- <div class="main-upload">
            <custom-file-input [datasetId]="datasetId" [uploadFiles]="false" [isUploading]="isUploading"
                [inputMsg]="'Generating your schema from file'" (fileList)="handleFileList($event)"></custom-file-input>
        </div> -->

        <div class="main-text-template">
            <p>Or Pick from the following templates</p>
        </div>

        <div class="main-template-search">
            <mat-icon>search</mat-icon>
            <input [(ngModel)]="searchText" type="text" placeholder="Search for template" />
        </div>

        <div class="main-template-cards">
            <div *ngIf="isLoading" class="flex justify-center items-center w-full">
                <mat-spinner diameter="80" *ngIf="true"></mat-spinner>
            </div>
            <div *ngIf="!isLoading" class="flex flex-wrap gap-6">
                <div class="template-card" (click)="schemaUpdated.emit(true)">
                    <p class="template-name">Blank Schema</p>
                </div>
                <ng-container *ngFor="let card of templateCards">
                    <ng-container *ngIf="
                            searchText === '' ||
                            card?.label.toLowerCase().includes(searchText.toLowerCase())
                        ">
                        <div class="template-card" (click)="updateSchemaToTemplate(card?.value)">
                            <p class="template-name">{{ card?.label }}</p>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>
