<div class="playground-container">
    <div class="grid-container">
        <ktd-grid [cols]="cols" [rowHeight]="rowHeight" [layout]="layout" [compactType]="compactType"
            [preventCollision]="preventCollision" [scrollableParent]="autoScroll ? document : null" scrollSpeed="4"
            (dragStarted)="onDragStarted($event)" (resizeStarted)="onResizeStarted($event)"
            (dragEnded)="onDragEnded($event)" (resizeEnded)="onResizeEnded($event)"
            (layoutUpdated)="onLayoutUpdated($event)">
            <div *ngFor="let item of selectedReports">
                <disco-tab *ngIf="item.key" [appId]="app" [tabId]="item.key">
                    <ng-template let-tabContext>

                        <ktd-grid-item [id]="item.id" class="grid-item" [transition]="currentTransition"
                            [dragStartThreshold]="dragStartThreshold" [draggable]="!disableDrag"
                            [resizable]="!disableResize">
                            <div class="card">

                                <div class="grid-item-content">
                                    <div class="item-header">
                                        <div class="title">
                                            <h4>{{contextCollection[item.id]?.currentReport?.mainView?.mainTitle ||
                                                '...'}}
                                            </h4>
                                        </div>
                                        <div class="actions">
                                            <mat-icon *ngIf="!disableEdit" class="icon"
                                                (mousedown)="stopEventPropagation($event)"
                                                (click)="edit(item.id, item.key)">edit</mat-icon>
                                            <mat-icon class="icon" (mousedown)="stopEventPropagation($event)"
                                                (click)="openReport(item.id, item.key)">open_in_new</mat-icon>
                                            <mat-icon *ngIf="!disableRemove" class="icon"
                                                (mousedown)="stopEventPropagation($event)"
                                                (click)="removeItem(item.id)">
                                                close</mat-icon>
                                        </div>
                                    </div>

                                    <div class="item-body" *ngIf="dashboardFiltersService.tabsQueryServices[item.key] as query">
                                        <disco-report *ngIf="tabContext else SPINNER"
                                            (context)="contextCollection[item.id] = $event"
                                            [queryService]="query" [requestId]="item.id">
                                            <div class="report-container" *ngIf="!!contextCollection[item.id]">
                                                <reports-view [isEditMode]="editMode"
                                                    *ngIf="!isMissingReport(contextCollection[item.id]) else MissingReport"
                                                    [height]="(item.h*rowHeight)-48 + 'px !important'"
                                                    [visibleDetailsHeader]="false">
                                                </reports-view>
                                                <ng-template #MissingReport>
                                                    <div class="deleted-report">
                                                        <div class="warning-message">This Report is deleted/doesn't
                                                            exists</div>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </disco-report>
                                        <ng-template #SPINNER>
                                            <div class="loading">
                                                <mat-spinner></mat-spinner>
                                            </div>
                                        </ng-template>
                                    </div>
                                    <mat-icon *ngIf="!disableResize" ktdGridResizeHandle
                                        class="resize-handle-1">keyboard_arrow_down
                                    </mat-icon>
                                </div>
                            </div>
                        </ktd-grid-item>
                    </ng-template>
                </disco-tab>

            </div>
        </ktd-grid>
    </div>
</div>
