import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Validators, FormControl } from '@angular/forms';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'new-diretory',
    templateUrl: './new-directory.dialog.html',
    styleUrls: ['./new-directory.dialog.scss'],
    encapsulation: ViewEncapsulation.None
})
// tslint:disable-next-line:component-class-suffix
export class NewDirectoryDialog {

    public newDirectory: FormControl;
    public showError = false;

    public isValidForm() {
        return this.newDirectory.valid;
    }

    constructor(
        private dialogRef: MatDialogRef<NewDirectoryDialog>,
        ) {
        this.createForm();
    }

    public close() {
        this.dialogRef.close();
    }
    public save() {
        if (this.isValidForm()) {
            this.dialogRef.close(this.newDirectory.value);
        } else {
            this.showError = true;
        }
    }

    private createForm() {
        this.newDirectory = new FormControl('', [
            Validators.required
        ]);
    }
}
