import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DatasetFileService } from '../../../services/api/dataset-file.service';


const WORD_DOCS_TYPES = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword'
];

const DOWNLOAD_FILE_TYPES = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
    "application/vnd.ms-excel"
];

@Component({
    selector: 'app-original-file-viewer',
    templateUrl: './original-file-viewer.component.html',
    styleUrls: ['./original-file-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
    @Input() fileExtension: string;
    @Input() fileName: string;
    @Input() datasetId: string;
    @Input() fileId: string;

    originalFileUrl: SafeResourceUrl;
    isDownloadFileView: boolean;
    loading: boolean;

    constructor(
        private _datasetFileService: DatasetFileService,
        private sanitizer: DomSanitizer
    ) {
        this.isDownloadFileView = false;
    }

    async ngOnInit(): Promise<void> {
        this.loading = true;
        await this.fetchAndSetOriginalFile();
        this.loading = false;
    }

    async downloadFile() {
        await this.fetchAndSetOriginalFile();
        const url = this.sanitizer.sanitize(SecurityContext.URL, this.originalFileUrl);
        if (url) {
            this.downloadFileFromUrl(url, this.fileName + '.' + this.fileExtension);
        }
    }

    private downloadFileFromUrl(fileUrl: string, fileName: string) {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    private async fetchAndSetOriginalFile(): Promise<void> {
        try {
            const response = await this.retryFetchFile();

            if (response) {
                this.processFileResponse(response);
            } else {
                console.error('Failed to fetch the file after maximum retries.');
            }
        } catch (error) {
            console.error('Error while fetching the file:', error);
        }
    }

    private async retryFetchFile(): Promise<any | null> {
        const maxRetries = 5, retryDelay = 2000;
        let response;
        for (let retryCount = 0; retryCount < maxRetries; retryCount++) {
            response = await this._datasetFileService.getOriginalFile(this.datasetId, this.fileId).toPromise();

            if (response.body.size !== 0 && !WORD_DOCS_TYPES.includes(response.body.type)) {
                return response;
            }

            await this.delay(retryDelay);
        }

        return response;
    }

    private processFileResponse(response: any): void {
        const blob = new Blob([response.body], { type: response.body.type });
        this.isDownloadFileView = DOWNLOAD_FILE_TYPES.includes(response.body.type);
        this.originalFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    }

    private delay(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

}
