export interface IOption {
  optionListId?: number;
  description: string;
  id: number;
  code?: string;
  value?: any;
  group?: string;
  order?: number;
}

export interface IInsertDBOptionDTO {
  OptionListId: number;
  Description: string;
  Code: string;
  Value?: any;
  Group?: string;
  Order?: number;
}

export interface IdbOption extends IInsertDBOptionDTO {
  Id: number;
}

export class Option {
  optionListId: number;
  description: string;
  id: number;
  code: string;
  value?: any;
  group?: string;
  order?: number;
  constructor(obj: IdbOption) {
    this.code = obj && obj.Code;
    this.optionListId = obj && obj.OptionListId;
    this.description = obj && obj.Description;
    this.value = obj && obj.Value;
    this.id = obj && obj.Id;
    this.group = obj && obj.Group;
    this.order = obj && obj.Order;
  }
}
