<div class="wrapper">
    <div class="header flex justify-between">
        <div class="left-wrapper">
            <div class="close-icon">
                <button mat-icon-button (click)="closeDialog()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <div class="record-info">
                <ng-container>
                    <ngx-skeleton-loader *ngIf="fieldsLoading" count="3" animation="progress-dark" [theme]="{
                        height: '15px',
                        opacity: '0.8',
                        'margin-bottom': '4px'
                      }">
                    </ngx-skeleton-loader>
                </ng-container>
                <ng-container *ngIf="!fieldsLoading">
                    <ng-container *ngIf="fields.length else defaultView;">
                        <ng-container *ngFor="let field of fields; index as i">
                            <p *ngIf="i == 0" class="first">{{field || '-'}}</p>
                            <p *ngIf="i == 1" class="second">{{field || '-'}}</p>
                        </ng-container>
                        <p class="third">{{fileName}}.{{fileExtension}} ({{dateReceived | date : 'medium'}})</p>
                    </ng-container>
                    <ng-template #defaultView>
                        <p class="file-name">{{fileName}}.{{fileExtension}}</p>
                        <p class="date-received">Received {{dateReceived | date : 'medium'}}</p>
                    </ng-template>
                </ng-container>
            </div>

            <div class="action">
                <ng-container *ngIf="!isEditMode && !showLogs">
                    <ng-container *ngIf="result">
                        <button mat-flat-button color="basic" (click)="isEditMode = true" class="basic-button">
                            <span>Edit</span>
                        </button>
                        <button mat-stroked-button color="basic" *ngIf="!isCopied" (click)="copyResult(result)"
                            class="basic-button">
                            <span>Copy</span>
                        </button>
                        <button mat-stroked-button color="basic" *ngIf="isCopied" class="basic-button">
                            <mat-icon class="copy-icon">check</mat-icon>
                        </button>
                    </ng-container>
                    <button mat-icon-button color="basic" [matMenuTriggerFor]="menu">
                        <mat-icon class="more_vert">more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button class="warn" (click)="deleteRow()" mat-menu-item>
                            <span>Delete</span>
                        </button>
                        <button (click)="showLogs = !showLogs" mat-menu-item>
                            <span>{{showLogs ? 'Hide ' : 'Show '}}Logs</span>
                        </button>
                        <button (click)="reprocessFile()" mat-menu-item>
                            <span>Reprocess File</span>
                        </button>
                    </mat-menu>
                </ng-container>

                <ng-container *ngIf="isEditMode">
                    <button class="save-btn" [disabled]="saveLoading" mat-flat-button color="basic" (click)="save()">
                        <mat-spinner *ngIf="saveLoading" diameter="20"></mat-spinner>
                        {{saveLoading ? 'Saving' : 'Save'}}
                    </button>
                    <button *ngIf="!saveLoading" mat-stroked-button color="basic"
                        (click)="cancelEditMode()">Cancel</button>
                </ng-container>

                <ng-container *ngIf="showLogs">
                    <button mat-flat-button color="basic" (click)="showLogs = !showLogs">Hide Logs</button>
                </ng-container>
            </div>
        </div>
        <div class="right-wrapper">
            <div class="navigation flex align-center" *ngIf="!isEditMode else EditModePlaceHolder">
                <button mat-icon-button (click)="move('prev')"><i
                        class="material-icons">keyboard_arrow_left</i></button>
                <p>Record {{start+1}} of {{dataSourceContext?.total}}</p>
                <button mat-icon-button (click)="move('next')"><i
                        class="material-icons">keyboard_arrow_right</i></button>
            </div>
        </div>

        <ng-template #EditModePlaceHolder>
            <div class="flex gap-2">

            </div>
        </ng-template>
    </div>

    <disco-data [appId]="'docpipeline'" [tabId]="tabId === 'datasetId' ? datasetId : tabId" [requestId]="currentReqId"
        class="flex-1" [columnList]="columnList" [start]="start" [size]="1" [queryService]="queryService"
        (context)="setContext($event)">
        <ng-template let-context>
            <div class="flex h-full overflow-auto">
                <div *ngIf="!showLogs else LOGS" class="flex h-full w-full">
                    <div class="result-viewer" appResizable (isDragging)="dragging = $event">
                        <div class="main">
                            <ng-container *ngIf="!isEditMode else EditForm">
                                <app-file-result-displayer [fileId]="fileId" [datasetId]="datasetId"
                                    (dataFetched)="dataFetched($event)"
                                    *ngIf="fileId && !loading"></app-file-result-displayer>
                            </ng-container>
                            <ng-template #EditForm>
                                <app-file-result-editor [fileId]="fileId" [data]="result" [datasetId]="datasetId"
                                    [isSaving]="saveLoading"></app-file-result-editor>
                            </ng-template>
                        </div>
                    </div>
                    <div class="pdf-viewer">
                        <app-original-file-viewer [ngClass]="{'hidden': dragging}" *ngIf="fileId && !loading"
                            [datasetId]="datasetId" [fileId]="fileId" [fileName]="fileName"
                            [fileExtension]="fileExtension">
                        </app-original-file-viewer>
                    </div>



                </div>
                <ng-template #LOGS>
                    <div class="flex logs w-full">
                        <div class="overflow-auto main px-11 py-5 flex-col">
                            <div class="flex align-center mb-5 gap-2">
                                <button mat-icon-button (click)="showLogs = !showLogs">
                                    <mat-icon>arrow_back</mat-icon>
                                </button>
                                <p class="text-xl font-medium">Logs</p>
                            </div>
                            <app-file-log-displayer *ngIf="fileId && !loading" [datasetId]="datasetId"
                                [fileId]="fileId"></app-file-log-displayer>
                        </div>

                    </div>
                </ng-template>
            </div>
        </ng-template>
    </disco-data>
</div>
