import { NgModule } from '@angular/core';
import { DynamicReportsModule } from '@discoverer/dynamic-reports';
import { OptionListComponent } from './components/option-list-config/option-list.component';
import { IgniteRoutingModule } from './ignite-routing.module';
import { ReportsModule } from './components/reports/reports.module';
import { environment } from '@env/environment';
import { ReportsRoutingModule } from './components/reports/reports-routing.module';
import { CommonModule } from '@angular/common';
import { OptionListModule } from '@discoverer/dynamic-forms/option-lists/option-list.module';
import { MatIconModule } from '@angular/material/icon';
import { AppSettingsService } from '@discoverer/core/services/dynamic-reports-services/app-settings.service';
import { MultiSelectService } from '@discoverer/core/services/dynamic-reports-services/multi-select.service';
import { GraphQLModule } from '@app/graphql.module';
import { GraphQlOptionDataServiceInterface } from '@discoverer/dynamic-forms/option-lists/shared-services/data-service';
import { GraphQueryOptionService } from '@discoverer/dynamic-forms';




@NgModule({
  declarations: [
    OptionListComponent,
  ],
  imports: [
    IgniteRoutingModule,
    CommonModule,
    GraphQLModule.forRoot(environment.DOCPIPELINE.GraphQlURL, [environment.auth.productKey]),
    OptionListModule,
    ReportsModule,
    ReportsRoutingModule,
    DynamicReportsModule,
    MatIconModule,
  ],
  providers: [
    { provide: GraphQlOptionDataServiceInterface, useClass: GraphQueryOptionService },
    AppSettingsService,
    MultiSelectService
  ],
  entryComponents: [
  ]
})
export class IgniteModule {
}
