export interface IState {
    type: string;
    display?: string;
    functionType: string;
    field: string;
    name: string;
    label?: string;
}


export interface IStat{
    colName: string;
    functionType: "count" | "sum" | "avg" | "min" | "max" | "stddev" | "sumsq";
    field: string;
}
