import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgModule } from '@angular/core';
import { GridModule } from '@progress/kendo-angular-grid';
import { IntlModule } from '@progress/kendo-angular-intl';
import { KendoTableViewComponent } from './kendo-table-view.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    GridModule,
    IntlModule,
    RouterModule
  ],
  declarations: [
    KendoTableViewComponent
  ],
  entryComponents: [
  ],
  providers: [
  ],
  exports: [
    KendoTableViewComponent
  ]
})
export class KendoTableViewModule {
  constructor() {

  }
}
