export interface ICard {
    id: string;
    fieldNameAndValue: FieldNameAndValue[];
    header: string;
  }
  export class Card implements ICard {

    constructor(public id: string, public header: string, public fieldNameAndValue: FieldNameAndValue[]) {
    }

  }

  export class FieldNameAndValue {
    fieldName : string ;
    fieldValue :string;
  }

export interface IList {
  id: string;
  name: string;
  order: number;
  cardsCount: number;
  isShowing: boolean;
  cards: ICard[];
}


export class List implements IList {
  constructor(public id: string,
    public name: string,
    public order: number,
    public cardsCount: number,
    public isShowing: boolean,
    public cards: ICard[]) {
  }

}
