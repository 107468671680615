import { Component, ViewEncapsulation, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BaseController, StateService, IPivotConfig, DiscovererQueryService } from '@discoverer/core/services'
import { ReportPersistService } from '@discoverer/core/services/dynamic-reports-services';
import { PivotChartComponent } from '../../common/pivot-chart/pivot-chart.component';
import { distinctUntilChanged, filter, first, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
    selector: 'pivot-chart-wrapper',
    templateUrl: './pivot-chart-wrapper.component.html',
    styleUrls: ['./pivot-chart-wrapper.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class PivotChartWrapperComponent extends BaseController implements OnInit {


    @Input() public pivotState: StateService<IPivotConfig>;
    @Input() public queryService: DiscovererQueryService;
    @Input() public pageSize = 30;
    @Input() public visibleDetailsHeader = true;
    @Input() public set exportData(value: boolean) {
        if (value && this.pivotChart) {
            this.pivotChart.exportToExcel();
        }
    }
    @ViewChild('PIVOT_CHART') public pivotChart: PivotChartComponent;

    @Input() public isEditMode = false;
    @Input() public allowEditMode;


    public isLoaded = false;
    public isError: boolean;
    public errorMsg: string;

    constructor(
        private _cdr: ChangeDetectorRef,
        public reportPersist: ReportPersistService) {
        super();
    }

    async ngOnInit() {
        this.subscribeToReport()
    }

    public checkPivotStatus(e: boolean) {
        this.isError = !e;
    }

    public exportToExcel() {
        this.pivotChart.exportToExcel();
    }

    private subscribeToReport() {
        this.subscriptions.push(
            this.reportPersist.oRequestLoaded.pipe(filter(r => !!r && !!r.pivotChart)).subscribe(() => {
                this.isLoaded = false;
                this._cdr.detectChanges();
                this.isEditMode = true;
                this.isLoaded = true;
                this._cdr.detectChanges();
            })
        );
    }

}
