import { Injectable } from "@angular/core";
import { switchMap } from "rxjs/operators";
import { IOptionList } from "../models/option-list";
import { BlocPatternServiceBase } from "./bloc-pattern-service-base";
import { OptionListDataServiceInterface } from "./data-service";

@Injectable()
export class GetCategoriesBloc extends BlocPatternServiceBase<string, { lists: IOptionList[] }> {

    constructor(private dataService: OptionListDataServiceInterface) {
        super();

        this.getAllLists();
    }
    _searchText: string;
    public setOptionLists(searchText: string) {
        this._searchText = searchText;
        this._$input.next(searchText);
    }
    public refresh() {
        this._$input.next(this._searchText);
    }
    private getAllLists() {
        this._$input.pipe(switchMap(q =>
            this.dataService.get(q))).subscribe((s: any) => {
                this._$output.next({ lists: s });
            });
    }
}
