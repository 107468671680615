import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseController, DRQuery, DiscovererQueryService, IColumnSetting, ITab2, ITableHeader, ReportPersistService, TabSettingsService, disLogger } from "@discoverer/core";
import { TableHeaderService } from '@discoverer/core/services/dynamic-reports-services/table-header.service';
import { ExportReportComponent, MAX_EXCEL_SIZE } from '@discoverer/dynamic-reports/common/export-to-excel/export.component';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { SchemaService } from '../../../services/schema.service';

@Component({
  selector: 'app-results-actions',
  templateUrl: './results-actions.component.html',
  styleUrls: ['./results-actions.component.scss']
})
export class ResultsActionsComponent extends BaseController implements OnInit {
  @ViewChild('exportReport', { static: false }) exportReport: ExportReportComponent;
  @Input() tableHeaderConfig: ITableHeader;

  dataFlattenBy: string
  queryService: DiscovererQueryService;
  currentQuery: DRQuery
  MAX_EXCEL_SIZE = MAX_EXCEL_SIZE;
  currentTab: ITab2
  isLoading: boolean = false;
  columnsToFlattenData: {key: string, display: string}[] = [{key:'File', display: 'File'}];

  constructor(
    public reportPersist: ReportPersistService,
    public tableHeaderService: TableHeaderService,
    public dialog: MatDialog,
    private _tabSettingsSer: TabSettingsService,
    private http: HttpClient,
    private _schemaService: SchemaService,
  ) { super(); }

  async ngOnInit(): Promise<void> {
    this.queryService = this.reportPersist.mainQueryService.defaultService()
    const schema = (await this._schemaService.getCurrentSchema())
    const objectArrayFields = Object.keys(schema).filter(key => schema[key].type === 'object_array')
    if (objectArrayFields.length > 0) {
        const allColumns = (await this._tabSettingsSer.getAllColumns());
        this.columnsToFlattenData.push(...allColumns.filter(col => objectArrayFields.includes(col.fieldName)).map(c => ({display: c.display, key:c.fieldName})));
    }
    this.dataFlattenBy = "File";
    this.subscribeToQueryChanges();
    this.currentTab = await this._tabSettingsSer.getCurrentTab();
    this.subscriptions.push(this.tableHeaderService?.tableHeader.subscribe((val) => this.tableHeaderConfig = val));

  }

  async exportTableData(count: number) {
    await this.exportReport.exportToExcel(count);
  }

  private _getReportTitle(): string {
    const date = new Date().toLocaleDateString().replace('/', '_').replace('/', '_');
    const time = `${new Date().getHours()}_${new Date().getMinutes()}`;
    const reportTitle = `${this.currentTab.name} Report - ${date} ${time}`;
    return reportTitle;
  }

  async exportToJson() {
    const state = await (this.reportPersist.oLastRequestData.pipe(first()).toPromise());
    const body = Object.assign(this.currentQuery, { fields: state.tableView.columnSettings.map(x => x.fieldName) }, { size: this.tableHeaderConfig?.resultLength });
    this.http.post(`${this.currentTab.serviceUrl}`, body)
      .subscribe(
        (res) => {
          const data = res['response']['docs']
          const modelJsonString = JSON.stringify(data?.map((_, i) => (data[i]?.source))) // This heavily depends on the response from ignite search data strucutre
          const blob = new Blob([modelJsonString], { type: 'application/json' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = this._getReportTitle()
          link.click();
          URL.revokeObjectURL(url);
        }
      );
  }

  private subscribeToQueryChanges() {
    this.subscriptions.push(this.queryService.oQuery.subscribe(query => {
      disLogger('I received a query', query);
      this.currentQuery = query;
      this.dataFlattenBy = query.dataFlattenBy;
    }));
  }

  public setFlattenDataBy(groupName: string) {
    this.queryService.resetDataFlattenBy();
    this.queryService.setDataFlattenBy(groupName);
    this.queryService.refresh();
    this.dataFlattenBy = groupName;
  }

}
