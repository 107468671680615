<div id="save-report">
    <div class="header color-primary-bg">
        <div>Export Data</div>
        <mat-icon (click)="close(false)">close</mat-icon>
    </div>
    <div class="footer">
        <button mat-flat-button class="submit rounded" color="accent" (click)="save(10000)">Excel <= {{excelNumber / 1000}}K</button>
        <button mat-flat-button class="submit rounded" color="accent" (click)="save(100000)">CSV <= {{csvNumber / 1000}}K</button>
    </div>

</div>