<div id="columns-to-display">
        <div class="main-wrapper">
                <div class="form-group">
                        <mat-radio-group [ngModel]="isDefaultColumns" (change)="updateDefaultColumns($event.value)">
                                <mat-radio-button class="radio-button first" [value]="true">Default columns</mat-radio-button>
                                <mat-radio-button class="radio-button" [value]="false">Custom columns</mat-radio-button>
                        </mat-radio-group>
                </div>
                <div class="default-columns" layoutDir="column" *ngIf="isDefaultColumns">
                        <div class="default-area">
                                <div class="item" *ngFor="let col of defaultColumns; let i=index">{{col.display}}</div>
                        </div>
                </div>
                <div class="custom-columns" *ngIf="!isDefaultColumns">
                        <left-right-columns-settings [label]="'Report Columns'" [allColumns]="allColumns"
                                [rightColumns]="rightColumns" (rightColumnsOutput)="updateColumnDisplay($event)" [defaultLabel]="'Set to current default columns'"
                                (setDefaultSettingsEvent)="setDefaultColumns($event)"></left-right-columns-settings>

                </div>
        </div>

</div>
