import { IDynamicReport, ReportSubType } from "..";

export interface IRequest {
    id: string;
    createTime: string;
    requestName: string;
    requestOrder: number;
    updateTime: string;
    description: string;
    type: string;
    subType: ReportSubType;
    request: IDynamicReport;
    selected: boolean;
    icon?: string;
    directoryId?: number;
  }

  export interface IDirRequest {
    directoryName: string;
    id: number;
    requests: IRequest[];
    expanded: boolean;
    width?: number;
    userId: number;
  }
  export interface IUser {
    id: string;
    email: string;
  }
