import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { AngularSplitModule } from 'angular-split';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { RouterModule } from '@angular/router';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';

import {
  ChartViewComponent,
  ControlBarComponent,
  FacetListComponent,
  ReportsComponent,
  SearchHeaderComponent,
  OneChartViewComponent,
  PivotChartComponent,
  DiscoTabComponent,
  StatActionComponent,
} from './common';
import {
  DetailsLabelValueWidget,
  InputWidgetComponent,
  SearchInputWidget,
  LeftRightColumnsSettingsWidget,
  SortingContainerComponent,
  AutoCompleteSearchWidget,
  EllipsifyTextDirective
} from './widgets';
import {
  ColumnSettingsCustomizationDialog,
  CustomConfirmDialog,
  DialogsManagerService,
  SaveReportDialog,
  ImageFullScreenDialog,
  NewDirectoryDialog,
  AdvancedDialog,
  RequestDirectoryDialog
} from './dialogs';
import { WorkspaceComponent } from './workspace/workspace.component';

import {
  ChartDetailsComponent,
  ColumnsToDisplayComponent,
  AdvancedFiltersComponent,
  BrokenDownFilterComponent,
  DynamicFilterComponent, AdvancedFilterFacetComponent, PivotChartDetailsComponent
} from './customize-report';

import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS, MatMomentDateModule,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import { MatDialogModule } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';

import { PivotChartWrapperComponent } from './workspace/pivot-chart-wrapper/pivot-chart-wrapper.component';
import { DiscoReportComponent } from './common/disco-report/disco-report.component';

import { DiscoDataComponent } from './common/disco-data/disco-data.component';
import { DiscoListViewComponent } from './disco-list-view/disco-list-view.component';
import { KendoChartItemModule } from '@discoverer/core/kendo-chart-item';
import { MaterialModule } from "@discoverer/MaterialModule";
import { DiscovererWidgetsModule } from "@discoverer/core/discoverer.module";
import { MetricsCardComponent } from './metrics-card/metrics-card.component';

import { WorkspaceMobileComponent } from './workspace-mobile/workspace-mobile.component';
import { ReportsView } from './reports-view/reports-view.component';
import { ExportReportComponent } from './common/export-to-excel/export.component';
import { ChartDataComponent } from './common/one-chart-view/chart-data/chart-data.component';
import { KanbanViewModule } from './common/kanban-view/kanban-view.module';

import { BaseColumnSettingsComponent } from './customize-report/sorting-settings/base-columns-settings/base-columns-settings.component';
import { GroupingComponent } from './customize-report/sorting-settings/grouping/grouping.component';
import { SortingComponent } from './customize-report/sorting-settings/sorting/sorting.component';
import { KanbanCustomizationDialog } from './dialogs/kanban-customization/kanban-customization.dialog';
import { FacetListContainerComponent } from './common/facet-list-container/facet-list-container.component';
import { DiscoRelatedListViewComponent } from './disco-related-list-view/disco-related-list-view.component';
import { ListFormComponent } from './widgets/list-form/list-form.component';


import { DateRangeFacetModule } from '@discoverer/core/date-range-facet/date-range-facet.module';
import {
  DiscovererQueryInfoService,
  RequestFilingService,
  ExportDataTableService,
  DiscovererFacetTranslationService,
  TabSettingsService,
  ChartRoutingService,
  DataFormattingService,
  AppSettingsService,
  ControlBarDataService,
  ExportDataService,
  MultiSelectService,
  ReportPersistService,
  AdvancedFilterService
} from '@discoverer/core/services';

import { DataSourceModule } from '@discoverer/core/data-source/data-source.module';
import { ChartViewErrorHandler } from '@discoverer/core/kendo-chart-item/models';
import { KendoTableViewComponent } from '@discoverer/core/kendo-table-view/kendo-table-view.component';
import { ChunkListPipe } from '@discoverer/core/pipes/chunk-list';
import { GroupByPipe } from '@discoverer/core/pipes/group-by.pipe';
import { TransformByPipe } from '@discoverer/core/pipes/transform-by.pipe';
import { PhonePipe } from '@discoverer/core/services/dynamic-reports-services/phone-pipe';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EditTitleDialog } from './dialogs/edit-title/edit-title.dialog';
import { StatDialog } from './common/stat/stat-dialog/stat.dialog';
import { DashboardViewComponent } from './common/dashboard-view/dashboard-view.component';
import { KtdGridModule } from '@katoid/angular-grid-layout';
import { DiscoDashboardComponent } from './disco-dashboard/disco-dashboard.component';
import { DashboardsComponent } from './common/dashboards/dashboards.component';
import { FilterRequestsPipe } from "../core/pipes/FilterRequestsPipe";
import { FlattenTabsRequestsPipe } from '../core/pipes/FlattenTabsRequestsPipe';
import { FlattenDirsRequestsPipe } from '../core/pipes/FlattenDirsRequestsPipe';
import { DashboardsListComponent } from './common/dashboards-list/dashboards-list.component';
import { CopyTextDialog } from './dialogs/copy-text-dialog/copy-text.dialog';
import { PopoverModule } from './popover/popover.module';
import { ReportActionsComponent } from './common/report-actions/report-actions.component';
import { MainTitleComponent } from './common/main-title/main-title.component';
import { ViewsTypeControlComponent } from './common/views-type-control/views-type-control.component';
import { DiscoSearchComponent } from './common/disco-search/disco-search.component';
import { FilterListControlComponent } from './common/filter-list-control/filter-list-control.component';
import { MainViewControlComponent } from './common/main-view-control/main-view-control.component';
import { AppliedFiltersListComponent } from './common/applied-filters-list/applied-filters-list.component';
import { ColumnSettingsControlComponent } from './common/column-settings-control/column-settings-control.component';
import {  PinnedFiltersListComponent } from './common/pinned-filters-list/pinned-filters-list.component';
import { FilterPinnedFiltersPipe } from './common/pinned-filters-list/filter-pinned-filter.pipe';
import { ChartControlComponent } from './common/charts-control/chart-control.component';
import { CategoryPickerDialog } from './dialogs/category-picker-dialog/category-picker.dialog';
import { FilteredReportsViewComponent } from './common/filtered-reports-view/filtered-reports-view.component';
import { AllReportsComponent } from './all-reports/all-reports.component';
import { ScrollTrackerDirective } from './scroll-tracker/scroll-tracker.directive';
import { TabSelectorComponent } from './dialogs/tab-selector/tab-selector.dialog';
import { ExportDialogComponent } from './dialogs/export-dialog/export-dialog.component';
import { NewReportComponent } from './new-report/new-report.component';
import { DashboardFiltersComponent } from './common/dashboard-filters/dashboard-filters.component';
import { AppliedDashboardFiltersComponent } from './common/applied-dashboard-filters/applied-dashboard-filters.component';
import { HighlighterPipe } from './all-reports/highlighter.pipe';
import { VerticalAppliedFiltersComponent } from './common/vertical-applied-filters/vertical-applied-filters.component';
import { TypeofPipe } from '../core/pipes/typeof.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FacetActionsModule } from '@discoverer/core/facet-actions/facet-actions.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    MatMomentDateModule,
    GridModule,
    ExcelModule,
    KendoChartItemModule,
    DiscovererWidgetsModule,
    DateInputsModule,
    DateRangeFacetModule,
    FacetActionsModule,
    AngularSplitModule,
    ExcelExportModule,
    DropDownListModule,
    DragulaModule.forRoot(),
    MatDialogModule,
    KanbanViewModule,
    DataSourceModule,
    KtdGridModule,
    PopoverModule,
    DragDropModule
  ],
  declarations: [
    // common
    DiscoDataComponent,
    DiscoTabComponent,
    DiscoReportComponent,
    DiscoListViewComponent,
    SearchHeaderComponent,
    ReportsComponent,
    FacetListComponent,
    ControlBarComponent,
    ChartViewComponent,
    OneChartViewComponent,
    ChartDataComponent,
    DashboardComponent,
    StatActionComponent,
    // dialogs
    SaveReportDialog,
    NewDirectoryDialog,
    ColumnSettingsCustomizationDialog,
    CustomConfirmDialog,
    ImageFullScreenDialog,
    AdvancedDialog,
    KanbanCustomizationDialog,
    EditTitleDialog,
    StatDialog,
    RequestDirectoryDialog,
    // widgets
    InputWidgetComponent,
    SearchInputWidget,
    DetailsLabelValueWidget,
    ExportDialogComponent,
    LeftRightColumnsSettingsWidget,
    AutoCompleteSearchWidget,
    SortingContainerComponent,
    ChartDetailsComponent,
    ColumnsToDisplayComponent,
    AdvancedFiltersComponent,
    AdvancedFilterFacetComponent,
    BrokenDownFilterComponent,
    DynamicFilterComponent,
    SortingComponent,
    GroupingComponent,
    BaseColumnSettingsComponent,
    PivotChartDetailsComponent,
    PivotChartComponent,
    PivotChartWrapperComponent,
    DashboardViewComponent,
    DiscoDashboardComponent,
    DashboardsComponent,
    DashboardsListComponent,
    ListFormComponent,
    // reports types
    WorkspaceComponent,
    WorkspaceMobileComponent,
    ReportsView,
    // details
    MetricsCardComponent,
    ExportReportComponent,
    PhonePipe,
    FacetListContainerComponent,
    DiscoRelatedListViewComponent,
    //pipes
    ChunkListPipe,
    GroupByPipe,
    TransformByPipe,
    FilterRequestsPipe,
    FlattenTabsRequestsPipe,
    FlattenDirsRequestsPipe,
    FilterPinnedFiltersPipe,
    CopyTextDialog,
    ReportActionsComponent,
    MainTitleComponent,
    ViewsTypeControlComponent,
    DiscoSearchComponent,
    FilterListControlComponent,
    MainViewControlComponent,
    AppliedFiltersListComponent,
    ColumnSettingsControlComponent,
    PinnedFiltersListComponent,
    ChartControlComponent,
    CategoryPickerDialog,
    FilteredReportsViewComponent,
    AllReportsComponent,
    ScrollTrackerDirective,
    TabSelectorComponent,
    NewReportComponent,
    DashboardFiltersComponent,
    AppliedDashboardFiltersComponent,
    ExportDialogComponent,
    EllipsifyTextDirective,
    HighlighterPipe,
    TypeofPipe,
    VerticalAppliedFiltersComponent,
  ],
  exports: [
    WorkspaceComponent,
    WorkspaceMobileComponent,
    ReportsView,
    DiscoDataComponent,
    DiscoTabComponent,
    DiscoReportComponent,
    DiscoListViewComponent,
    DetailsLabelValueWidget,
    AutoCompleteSearchWidget,
    DataSourceModule,
    ReportsComponent,
    FacetListComponent,
    ControlBarComponent,
    OneChartViewComponent,
    ChartDataComponent,
    ExportReportComponent,
    PivotChartComponent,
    PivotChartWrapperComponent,
    SearchHeaderComponent,
    KendoTableViewComponent,
    ExportReportComponent,
    PivotChartComponent,
    LeftRightColumnsSettingsWidget,
    DiscoRelatedListViewComponent,
    StatActionComponent,
    DiscoSearchComponent,
    ScrollTrackerDirective
  ],
  entryComponents: [
    // dialogs
    SaveReportDialog,
    NewDirectoryDialog,
    ColumnSettingsCustomizationDialog,
    CustomConfirmDialog,
    ImageFullScreenDialog,
    AdvancedDialog,
    KanbanCustomizationDialog,
    EditTitleDialog,
    StatDialog,
    RequestDirectoryDialog,
    CategoryPickerDialog,
    // widgets
    InputWidgetComponent,
    SearchInputWidget,
    LeftRightColumnsSettingsWidget,
    SortingContainerComponent,
    ExportDialogComponent
  ],
  providers: [
    RequestFilingService,
    ExportDataTableService,
    ExportDataService,
    DialogsManagerService,
    DragulaService,
    AdvancedFilterService,
    DataFormattingService,

    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    DiscovererQueryInfoService,
    ControlBarDataService,
    ChartViewErrorHandler,
    TabSettingsService,
    ReportPersistService,
    ChartRoutingService,
    AppSettingsService,
    DiscovererFacetTranslationService,
    MultiSelectService
  ]
})
export class DynamicReportsModule {
  constructor() {
  }
}
