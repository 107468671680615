<mat-form-field appearance="outline">
    <mat-label>MM-YY</mat-label>
    <input matInput [matDatepicker]="dp" [formControl]="dateControl">
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker #dp
                    startView="multi-year"
                    (yearSelected)="chosenYearHandler($event)"
                    (monthSelected)="chosenMonthHandler($event, dp)"
                    panelClass="example-month-picker">
    </mat-datepicker>
  </mat-form-field>