import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxPageScrollModule } from "ngx-page-scroll";
import { FlexDirective, LayoutDirective } from "./flex/flex.directive";
import { PercentBarComponent } from "./percent-bar/percent-bar.component";
import { ContainsFilterPipe } from "./pipes/contains.pipe";
import { FormattedNumberPipe } from "./pipes/formatted-number.pipe";
import { CapitalizeFirstPipe } from "./pipes/capitalize-first.pipe";
import { PaginationComponent } from "./pagination/pagination.component";
import { NumberCardComponent } from "./number-card/number-card.component";
import { ComponentUtilityService } from "./services/utility-services/component-utitlty";
import { SliderFacetComponent } from "./numeric-range-filter/numeric-range-filter.component";

import { MatSnackBar } from "@angular/material/snack-bar";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { DateAdapter } from "@angular/material/core";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_FORMATS,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import { MinutesToHoursPipe } from "./pipes/minutes-to-hours.pipe";
import { InputRowListComponent } from "./input-row-list";
import { DropdownListFacetComponent } from "./dropdown-list-facet/dropdown-list-facet.component";
import { DateRangeFacetModule } from "./date-range-facet/date-range-facet.module";
import { FacetActionsModule } from "./facet-actions/facet-actions.module";
import { KendoTableViewModule } from "./kendo-table-view/kendo-table-view.module";
import { KendoExcelExportModule } from "./kendo-excel-export/kendo-excel-export.module";

import { JSONDataService } from "./services/dynamic-reports-services/json-data.service";
import { EsearchSettingsService } from "./services/dynamic-reports-services/esearch-settings.service";
import { KendoReactWrapperModule } from "./kendo-react-wrapper/kendo-react-wrapper.module";
import { FacetDataSourceComponent } from "./facet-data-source";
import { HorizontalFacetComponent } from "./horizontal-facet";
import { FacetComponent } from "./facet/facet.component";
import { Facet1Component } from "./facet/facet1.component";
import { SelectFacetComponent } from "./select-facet/select-facet.component";
import {
  DiscovererQueryService,
  QUERY_NAME,
} from "./services/core-data-services/discoverer-query-service.service";
import { MaterialModule } from "@discoverer/MaterialModule";
import { IonRangeSliderModule } from "./ion-range-slider/ion-range-slider.module";
import { IonRangeSliderComponent } from "./ion-range-slider/ion-range-slider.component";
import { DiscoHistogramModule } from "./histogram/histogram.module";
import {
  PERMISSION_HANDLERS,
  UserPermissionService,
} from "./services/dynamic-reports-services/user-permission.service";
import {
  TableHeaderService
} from "./services/dynamic-reports-services/table-header.service";
import { FacetContainerComponent } from "./facet-container/facet-container.component";
import {
  SHAFComponent,
  SHAFContainerComponent,
} from "./search-header-advanced-filter";

import {NumberComponent} from './sortingIcon/number/number.component';
import {NumberReversComponent} from './sortingIcon/number-revers/number-revers.component';
import {AlphaComponent}from './sortingIcon/alpha/alpha.component';
import {AlphaReversComponent}from './sortingIcon/alpha-revers/alpha-revers.component';
import { PopoverModule } from "@discoverer/dynamic-reports/popover/popover.module";
// import { ResetCacheComponent } from './reset-cache/reset-cache.component';
import { AutoFocus } from "@discoverer/dynamic-reports/auto-focus-directive/AutoFocus.directive";
import { TimedRefreshComponent } from './timed-refresh/timed-refresh.component';


@NgModule({
  declarations: [
    FlexDirective,
    LayoutDirective,
    FacetComponent,
    DropdownListFacetComponent,
    SelectFacetComponent,
    PercentBarComponent,
    ContainsFilterPipe,
    FormattedNumberPipe,
    CapitalizeFirstPipe,
    PaginationComponent,
    NumberCardComponent,
    SliderFacetComponent,
    FacetDataSourceComponent,
    MinutesToHoursPipe,
    InputRowListComponent,
    HorizontalFacetComponent,
    Facet1Component,
    FacetContainerComponent,
    SHAFComponent,
    SHAFContainerComponent,
    NumberComponent,
    NumberReversComponent,
    AlphaComponent,
    AlphaReversComponent,
    AutoFocus,
    TimedRefreshComponent
    // ResetCacheComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxPageScrollModule,
    ReactiveFormsModule,
    MaterialModule,
    IonRangeSliderModule,
    DateRangeFacetModule,
    FacetActionsModule,
    KendoTableViewModule,
    KendoExcelExportModule,
    KendoReactWrapperModule,
    DiscoHistogramModule,
    PopoverModule,

  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    FlexDirective,
    LayoutDirective,
    FacetComponent,
    HorizontalFacetComponent,
    SelectFacetComponent,
    FacetDataSourceComponent,
    PercentBarComponent,
    ContainsFilterPipe,
    FormattedNumberPipe,
    CapitalizeFirstPipe,
    PaginationComponent,
    SliderFacetComponent,
    MinutesToHoursPipe,
    DropdownListFacetComponent,
    KendoTableViewModule,
    KendoExcelExportModule,
    KendoReactWrapperModule,
    Facet1Component,
    FacetContainerComponent,
    SHAFComponent,
    SHAFContainerComponent,
    // ResetCacheComponent
    AutoFocus,
    TimedRefreshComponent

  ],
  entryComponents: [IonRangeSliderComponent],
  providers: [
    MatSnackBar,
    ComponentUtilityService,
    JSONDataService,
    EsearchSettingsService,
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    UserPermissionService,
    { provide: PERMISSION_HANDLERS, useValue: [] },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: QUERY_NAME, useValue: "default" },
    DiscovererQueryService,
    TableHeaderService
  ],
  // schemas: [NO_ERRORS_SCHEMA]
})
export class DiscovererWidgetsModule {}
