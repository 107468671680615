import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatasetFileService } from '../../services/api/dataset-file.service';
import { DatasetService } from '../../services/api/dataset.service';
import { EmitterService } from '../../services/emitter.service';
import { SchemaService } from '../../services/schema.service';
import { ToolbarService } from '../../services/toolbar.service';

export enum SchemDocumentViewTypes {
    pdfView = 'PDF_VIEW',
    uploadView = 'UPLOAD_VIEW',
    resultView = 'RESULT_VIEW'
}

@Component({
    selector: 'app-schema-main',
    templateUrl: './schema-main.component.html',
    styleUrls: ['./schema-main.component.scss']
})
export class SchemaMainComponent implements OnInit, OnDestroy, AfterViewInit {

    viewTypes  = SchemDocumentViewTypes;
    public activeComponent: string = SchemDocumentViewTypes.pdfView;
    public hideFileViewer = false
    public isLoading: boolean = true;
    public datasetId: string;
    public fileId: string
    public dragging = false;
    public fileName: string;
    public fileExtension: string;

    schema: any
    constructor(
        private _activated: ActivatedRoute,
        private _datasetService: DatasetService,
        private _datasetFileService: DatasetFileService,
        private _emitterService: EmitterService,
        private _toolbarService: ToolbarService,
        private _schemaService: SchemaService,
    ) { }

    async ngOnInit(): Promise<void> {
        this.datasetId = this._activated.snapshot.params['datasetId'];
        this._emitterService.setDatasetId(this.datasetId);
        await this._toolbarService.setSettings(this.datasetId, this._activated.snapshot.routeConfig.data)
        this.fileId = !!this.datasetId ? localStorage.getItem(this.datasetId) : null;
        this.activeComponent = this.fileId ? this.viewTypes.pdfView : this.viewTypes.uploadView;
        await this._getFields();
        await this._getDetails();
        this.isLoading = false
    }
    ngAfterViewInit(): void {
    }

    processUploadedFiles(fileId: string) {
        localStorage.setItem(this.datasetId, fileId);
    }

    ngOnDestroy(): void {
        this._schemaService.setFields();
    }

    setFileId() {
        this.fileId = !!this.datasetId ? localStorage.getItem(this.datasetId) : null;
    }
    async handleFileUploaded(fileId: string) {
        localStorage.setItem(this.datasetId, fileId);
        this.setFileId();
        await this._getDetails();
        this.activeComponent = this.viewTypes.pdfView;
    }

    getFileId() {
        this.setFileId();
        return this.fileId;
    }

    private async _getFields() {
        const response = await this._datasetService.getFields(this.datasetId).toPromise();
        if (response.isSuccess && !!response.result) {
            this.schema = response?.result;
            this._schemaService.setFields()
        } else {
        }
        this.isLoading = false;
    }

    private async _getDetails() {
        try {
            const detailsResponse = await this._datasetFileService.getFileDetails(this.datasetId, this.fileId).toPromise();
            if (detailsResponse?.isSuccess && detailsResponse?.result) {
                this.fileName = detailsResponse.result.OriginalFileName;
                this.fileExtension = detailsResponse.result.FileExtension;
            }
        } catch (error) {
            console.error('Error fetching file details:', error);
        }
    }
}
