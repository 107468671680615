<mat-accordion>
    <mat-expansion-panel *ngFor="let log of logSteps">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="panel-header">
                    {{log.stepName}}
                    <button (click)="copy($event, log)" matTooltip="Copy">
                        <mat-icon *ngIf="!log.isCopied">content_copy</mat-icon>
                        <mat-icon *ngIf="log.isCopied">check</mat-icon>
                    </button>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content">
            <ngx-json-viewer [json]="log.jsonObject"></ngx-json-viewer>
        </div>
    </mat-expansion-panel>
</mat-accordion>