import { HttpClient } from '@angular/common/http';
import {
    Component
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
    DiscovererFacetTranslationService,
    ReportPersistService,
    TabSettingsService
} from '@discoverer/core/services';
import { Apollo } from 'apollo-angular';
import { AppliedFiltersDirective } from './applied-filters.directive';

@Component({
    selector: 'applied-filters-list',
    templateUrl: './applied-filters-list.component.html',
    styleUrls: ['./applied-filters-list.component.scss']
})
export class AppliedFiltersListComponent extends AppliedFiltersDirective {

    constructor(dialog: MatDialog,
        facetTranslationService: DiscovererFacetTranslationService,
        http: HttpClient,
        apollo: Apollo,
        _tabSettings: TabSettingsService,
        _reportPersistService: ReportPersistService) {
        super(
            dialog,
            facetTranslationService,
            http,
            apollo,
            _tabSettings,
            _reportPersistService);
    }
}
