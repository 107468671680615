import { OnInit, ChangeDetectorRef, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { OptionDataServiceInterface } from '../../option-lists/shared-services/data-service';
import { IOption } from '../../option-lists/models/option';
import { HasuraOptionService } from '../../option-lists/shared-services/hasura-option-service';
import { Component, Output, EventEmitter, Input } from '@angular/core';
import { BaseCustomController } from '../shared/base-custom-controller';
import { BaseController } from '@discoverer/core';



@Component({
    selector: 'simple-chip-list',
    templateUrl: './simple-chip-list.component.html',
    styleUrls: ['./simple-chip-list.component.scss'],
    providers: [{ provide: OptionDataServiceInterface, useClass: HasuraOptionService }],
    encapsulation: ViewEncapsulation.None
})
export class SimpleChipListComponent extends BaseController implements OnInit {
    public dynOptionsById: { [id: string]: IOption } = {};
    public dynOptionsByCode: { [id: string]: IOption } = {};
    public dynOptions: IOption[] = [];
    public selectedOptions: any[] = [];
    public filteredOptions;

    optionControl = new FormControl('');
    @ViewChild('optionInput') optionInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    @Input() selectedIds: number[] = [];
    @Input() optionsListId: number;
    @Input() editButtonText: string;

    @Output()
    isEditClicked = new EventEmitter<boolean>();
    constructor(
        private optionsService: OptionDataServiceInterface,
        private cdRef: ChangeDetectorRef
    ) {
        super();
    }
    ngOnInit() {
        if (this.optionsListId > 0) {
            this.optionsService.getByOptionListId(this.optionsListId).subscribe(options => {
                if (options) {
                    this.dynOptions = options;
                    const selectedOptions = this.selectedIds as string[] | number[];
                    this.dynOptions.forEach(o => {
                        this.dynOptionsById[o.id] = o;
                        this.dynOptionsByCode[o.code] = o;
                    });
                    this.selectedOptions = selectedOptions ? this.getSelectedOptions(selectedOptions) : [];
                    this.optionControl.setValue(null);
                    this.cdRef.markForCheck();
                }
            });

        }
    }
    private getSelectedOptions(ids: (number | string)[]) {
        const dynOption = this.dynOptionsById
        if (typeof ids === 'string') {
            ids = String(ids).split(',')
        }
        return ids.map(id => {
            if (typeof id === 'string') {
                return dynOption[id.trim()]
            }
            return dynOption[id]
        });
    }
    edit() {
        this.isEditClicked.emit(true);
    }
}

