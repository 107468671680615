export enum StatusTypes {
    NEW = 'new',
    PROCESSING = 'processing',
    PROCESSED = 'processed',
    FAILED = 'failed'
}

export interface TempFile {
    id: string
    name: string
    fileId: string,
    status: StatusTypes
    url: string
}


export interface BackendResponse<T> {
    isSuccess: boolean
    result: T
}
