<disco-tab [appId]="appId" [tabId]="tabId">
  <ng-template let-tabContext>
    <disco-report [queryService]="queryService" *ngIf="tabContext" [requestId]="requestId">
      <ng-template let-reportContext>
          <data-source *ngIf="reportContext"
            [queryService]="reportContext.mainQueryService"
            [columnSettings]="tabContext.columnSettings"
            [filters]="filters"
            [sorts]="sorts"
            [serviceUrl]="tabContext.currentTab.serviceUrl"
            [columnList]="columnList" [start]="start" [size]="size" (context)="context.emit($event)" >

            <ng-template let-context>
                <ng-container  *ngTemplateOutlet="template; context: {$implicit: context}"></ng-container>
            </ng-template>
          </data-source>
          <!-- <div *ngIf="reportContext">
            <textarea rows="20" cols="60">{{tabContext.columnSettings  | json}}</textarea>
            <textarea rows="20" cols="60">{{reportContext.currentReport | json}}</textarea>
          </div> -->

      </ng-template>
    </disco-report>
  </ng-template>
</disco-tab>

