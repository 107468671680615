<div id="reports" *ngIf="!mobileView" style="height: 100%;">
    <div class="flex" style="justify-content: space-between;overflow: auto;">
        <div class="header">
            <mat-form-field class="report-search-bar" floatLabel="never">
                <input #Input [autoFieldFocus]="Input" placeholder="Search Reports" matInput autocomplete="off"
                    [(ngModel)]="term" />
                <mat-icon mat-icon-button matSuffix (click)="term=''" class="close-button">close</mat-icon>
            </mat-form-field>

        </div>
        <div class="row justify-content-end align-center">
            <mat-icon (click)=close()>close</mat-icon>
        </div>
    </div>
    <hr style="margin: 16px 0;" />
    <div class="main" *ngIf="!term else FILTERED_LIST">
        <div class="wrapper">
            <div class="folders">
                <div style="display: flex; margin-bottom: 0.5rem;">
                    <h3 style="align-self: flex-start; font-weight: 600; display: flex; align-items: center;">
                        Directories</h3>
                    <div matTooltip="Create New Directory" (click)="openAddDirectoryDialog()" class="add"
                        color="primary" mat-raised-button>
                        <mat-icon class="icon mr-4">create_new_folder</mat-icon>
                    </div>
                </div>
                <div (click)="selectedDirectory = dir" class="folder" [ngClass]="{'active': selectedDirectory == dir}"
                    *ngFor="let dir of reportList; index as i;">
                    <div class="dir-title">
                        <mat-icon class="mr-4">{{+dir.userId === 0 ? 'public' : 'folder'}}</mat-icon>
                        <span class="title" matTooltip="{{dir.directoryName}}"> {{dir.directoryName}}
                            ({{dir?.requests?.length}})</span>
                    </div>
                    <div class="ml-1 control-buttons" *ngIf="dir.userId">
                        <mat-icon [matMenuTriggerFor]="personalActionsMenu" class="action-icon"
                            aria-label="Directory Actions">
                            more_vert</mat-icon>
                        <mat-menu #personalActionsMenu="matMenu">
                            <button (click)="openEditDirectoryDialog(dir)" mat-menu-item>
                                <mat-icon>edit</mat-icon> <span>Edit Name</span>
                            </button>
                            <button (click)="removeDirectory(dir)" mat-menu-item>
                                <mat-icon>folder_delete</mat-icon> <span>Delete Folder</span>
                            </button>
                            <button *ngIf="canCreatePublicDirectory" (click)="createPublicDirectory(dir)" mat-menu-item>
                                <mat-icon>publish</mat-icon> <span>Make Public</span>
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <div class="reports ml-4" *ngIf="selectedDirectory">
                <div class="flex">
                    <h3 style="align-self: flex-start;font-weight: 600;margin-bottom: 14px;">Reports</h3>
                    <mat-icon *ngIf="selectedDirectory?.requests && selectedDirectory?.requests.length"
                        style="margin-left: 16px;" svgIcon="dashboard" matTooltip="View As Dashboard"
                        (click)="viewAsDashboard()"></mat-icon>
                </div>
                <div [ngClass]="{'active': selectedRequest == request}" class="report"
                    *ngFor="let request of selectedDirectory.requests">
                    <div (click)="setReport(request,selectedDirectory)" style="display: flex; align-items: center;">
                        <mat-icon class="mr-4 report-icon" color="accent">{{(request.icon)? request.icon :
                            'table_chart_outline'}} </mat-icon>
                        <span class="item-title">{{request.requestName}}</span>
                    </div>
                    <mat-icon
                        *ngIf="requestsPermission[request.id]?.canEditReq || requestsPermission[request.id]?.canDelete || requestsPermission[request.id]?.canMove"
                        [matMenuTriggerFor]="ReportActionsMenu" class="action-icon" aria-label="Directory Actions">
                        more_vert</mat-icon>
                    <mat-menu #ReportActionsMenu="matMenu">
                        <button *ngIf="requestsPermission[request.id]?.canEditReq" (click)="openEditReportName(request)"
                            mat-menu-item>
                            <mat-icon>edit</mat-icon> <span>Edit Name</span>
                        </button>
                        <button *ngIf="requestsPermission[request.id]?.canDelete" (click)="removeReport(request)"
                            mat-menu-item>
                            <mat-icon>delete_forever</mat-icon> <span>Delete Report</span>
                        </button>
                        <button *ngIf="requestsPermission[request.id]?.canMove" (click)="moveReportFolder(request)"
                            mat-menu-item>
                            <mat-icon>drive_file_move_rtl</mat-icon> <span>Change Folder</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
    <ng-template #FILTERED_LIST>
        <div class="filtered-list">
            <filtered-reports-view [requests]="(reportList | flattenDirsReq: currentTab.name) | filterReq: term "
                (clickedRequest)="setSearchedReport($event)"></filtered-reports-view>
        </div>
    </ng-template>
</div>

<div id="reports-mobile" *ngIf="mobileView">
    <div class="folder-mobile" *ngFor="let dir of reportList; index as i;">
        <div *ngIf="dir.requests.length" class="folder-wrapper-mobile">
            <div class="folder-header-mobile">
                <span class="title">{{dir.directoryName}}</span>
            </div>
            <div class="folder-main-mobile">
                <div class="item" (click)="setReport(request,dir)" *ngFor="let request of dir.requests">
                    <mat-icon class="outline-table_chart" color="accent">{{(request.icon)? request.icon :
                        'table_chart_outline'}} </mat-icon>
                    <span class="item-title">{{request.requestName}}</span>
                </div>
            </div>
        </div>
    </div>
</div>