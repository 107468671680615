import { FormlyFieldConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';

export abstract class BaseCustomController extends FieldType {

    public updateValueAndValidity(field: FormlyFieldConfig, value: any, options?: Object) {
        field.formControl.setValue(value, options);
        field.formControl.markAsDirty();
        field.formControl.updateValueAndValidity();
    }
}

