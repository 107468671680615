import {
    Component,
    Input,
    OnInit
} from '@angular/core';
import {
    DiscovererQueryService,
    disLogger,
    IColumnSetting,
    IKanbanViewState,
    ITab2,
    ITableViewState,
    Permission,
    ReportPersistService,
    TabSettingsService,
    UserPermissionService
} from '@discoverer/core/services';
import { DialogsManagerService } from '@discoverer/dynamic-reports/dialogs';
import { ViewTypes } from '../control-bar';

@Component({
    selector: 'column-settings-control',
    templateUrl: './column-settings-control.component.html',
    styleUrls: ['./column-settings-control.component.scss']
})
export class ColumnSettingsControlComponent implements OnInit {
    @Input() queryService: DiscovererQueryService;
    public canChangeColumnSettings: boolean;
    public allColumns: IColumnSetting[] = [];
    public columnsToFlattenData: string[] = [];

    allowedViews: ViewTypes[] = [ViewTypes.Kanban, ViewTypes.Table];
    private _currentTab: ITab2

    constructor(
        public reportPersist: ReportPersistService,
        private _tabSettingService: TabSettingsService,
        private _userPermissionService: UserPermissionService,
        private dialogsManagerService: DialogsManagerService
    ) { }

    async ngOnInit() {
        this._currentTab = (await this._tabSettingService.getCurrentTab())
        this.canChangeColumnSettings = await this._userPermissionService.hasPermission(this._currentTab.key, Permission.ChangeColumnSettings);
        this.allColumns = (await this._tabSettingService.getAllColumns()).filter(c => !c.hidden);
        this.columnsToFlattenData = this.allColumns.filter(c => c.nestedPath).filter(c => c.nestedPath.split('.').length == 1).map(c => c.nestedPath).filter((value, index, groupName) => groupName.indexOf(value) === index);
    }

    public async openColumnsSettingsDialog(selectedView: string, selectedSetting: string = null) {
        if (selectedView === 'kanban-view') {
            this.dialogsManagerService.openKanbanDialog(
                this._tabSettingService,
                this.reportPersist.mainQueryService,
                this.reportPersist.kanbanViewState,
                this.reportPersist.mainViewState
                ).subscribe(kanbanViewState => {
                if (kanbanViewState) {
                    this.reportPersist.kanbanViewState.setValue(kanbanViewState);
                }
            });
        }
        else {
            const { columnSettings, groups, showGroupFooter} = this.reportPersist.tableViewState.getState();
            this.dialogsManagerService.openColumnsSettingsDialog(
                columnSettings,
                true,
                this._tabSettingService,
                this.queryService,
                this.reportPersist.mainViewState,
                selectedView,
                groups,
                selectedSetting,
                showGroupFooter
                ).subscribe((obj) => {
                    if (obj) {
                        disLogger('openColumnsSettingsDialog results', obj);
                        this.reportPersist.tableViewState.setValue({ showGroupFooter: obj.showGroupFooter, columnSettings: obj.rightColumns, dataFlattenBy: obj.dataFlattenBy, groups: obj.groups, sorts: obj.sorts, randomSeed: obj.randomSeed } as ITableViewState);
                        this.reportPersist.mainViewState.setValue({ isDefaultColumns: obj.isDefault });
                    }
                });
        }
    }

    public isAllowView(type): boolean {
        return !!this.allowedViews.find(view => view === type);
    }
}
