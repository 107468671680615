import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BaseController, DRSortCriteria, IDRFilter, RequestFilingService, StateService } from '@discoverer/core/services';
import { DataSourceContext } from '@discoverer/core/data-source/data-source.component';
import { IChartState, ITab2, ITableHeader, ReportPersistService } from '@discoverer/core/services/dynamic-reports-services';
import { first } from 'rxjs/operators';
import _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { TableHeaderService } from '@discoverer/core/services/dynamic-reports-services/table-header.service';



@Component({
    // tslint:disable-next-line:component-selector
    selector: 'chart-data',
    templateUrl: './chart-data.component.html',
    styleUrls: ['./chart-data.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChartDataComponent extends BaseController implements OnInit {
    @Input() appId: string;
    @Input() tab: ITab2;
    public columns: string[];
    public filters: IDRFilter[];
    public context: DataSourceContext;
    public currentState: IChartState;
    public isLoading = false;
    @Input() public chartState: StateService<IChartState>;
    public drSort: DRSortCriteria[];
    public tableHeaderConfig: ITableHeader;
    public originalFilters: IDRFilter[];
    public originalSorts: DRSortCriteria[];
    public originalState;
    public showSave = false;

    // tslint:disable-next-line:max-line-length
    constructor(public tableHeaderService: TableHeaderService, public reportPersist: ReportPersistService, private _router: Router,    private _activatedRoute: ActivatedRoute,private requestFilingService: RequestFilingService, private cdRef: ChangeDetectorRef) {
        super();
    }

    public async ngOnInit() {
        this.originalState = {
            chart: this.reportPersist.chartState.getState(),
            chartList: this.reportPersist.chartListState.getState(),
            mainView: this.reportPersist.mainViewState.getState(),
            pivotChart: this.reportPersist.pivotChartState.getState(),
            tableView: this.reportPersist.tableViewState.getState()
        };
        this.originalFilters = (await this.reportPersist.mainQueryService.oQuery.pipe(first()).toPromise()).filters;
        this.originalSorts = (await this.reportPersist.mainQueryService.oQuery.pipe(first()).toPromise()).sorts;
        this.subscribeToQueryChanges();
        this.subscribeToTableChanges();

    }
    public contextChanged(context: DataSourceContext) {
        if (!!context && !!context.data) {
            this.context = context;
        }
    }

    private resetState() {
        this.reportPersist.chartState.setValue(this.originalState.chart, true);
        this.reportPersist.chartListState.setValue(this.originalState.chartList, true);
        this.reportPersist.mainViewState.setValue(this.originalState.mainView, true);
        this.reportPersist.mainQueryService.resetFilters();
        this.originalFilters.forEach(key => {
            this.reportPersist.mainQueryService.setFilter(key.fields[0], key);
        });
        this.reportPersist.mainQueryService.refresh();
    }

    private subscribeToTableChanges() {
        this.reportPersist.tableViewState.oState.subscribe(() => {
            this.showSave = true;
            this.cdRef.markForCheck();
        });
    }

    private subscribeToQueryChanges() {
        this.reportPersist.mainQueryService.oQuery.subscribe(x => {
            if (!_.isEqual(this.originalSorts, x.sorts)) {
                this.showSave = true;
            }
            this.cdRef.markForCheck();
        });
    }

    public navigateToDetail(data: any) {
      this._router.navigate(['detail', this.reportPersist.loadReqId, data.rowIndex], { relativeTo: this._activatedRoute, });
    }
}
