import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppGuardService, DatasetGuard } from './app.guard.service';
import { LandingComponent } from '@discoverer/app-core';
import { HomeComponent } from './ignite/components/reports/common/home/home.component';
import { DatasetListComponent } from './ignite/components/reports/common/dataset-list/dataset-list.component';
import { UserSettingsComponent } from './ignite/components/reports/common/user-settings/user-settings.component';
import { ProfileComponent } from './ignite/components/reports/common/user-settings/profile/profile.component';
import { PasswordComponent } from './ignite/components/reports/common/user-settings/password/password.component';
import { PlansComponent } from './ignite/components/reports/common/user-settings/plans/plans.component';
import { StripePlansResolver } from './ignite/components/reports/common/user-settings/plans/stripe-plans-resolver';
import { AiLayoutComponent } from './ignite/components/reports/common/ai-layout/ai-layout.component';

const routes: Routes = [
    { path: 'auth/landing', component: LandingComponent },
    {
        path: '',
        component: HomeComponent,
        canActivate: [AppGuardService],
        data: {
            title: 'Datasets',
            showBackButton: false
        },
        children: [
            { path: '', redirectTo: 'datasets', pathMatch: 'full' },
            {
                path: 'datasets',
                component: DatasetListComponent,
            },
            {
                path: 'datasets/:datasetId',
                canActivate: [DatasetGuard],
                component: AiLayoutComponent,
                loadChildren: () => import('./ignite/ignite.module').then(m => m.IgniteModule),
            },
            {
                path: 'user-settings',
                component: UserSettingsComponent,
                children: [
                    { path: '', redirectTo: 'profile', pathMatch: 'full' },
                    { path: 'profile', component: ProfileComponent },
                    { path: 'password', component: PasswordComponent },
                    { path: 'plans', component: PlansComponent, resolve: { initialData: StripePlansResolver } },
                ]
            }
        ]
    },


];

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    useHash: false,
    relativeLinkResolution: 'legacy'
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerConfig)],
    exports: [RouterModule],
    providers: [AppGuardService, DatasetGuard]
})
export class AppRoutingModule {
}
