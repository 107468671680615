<div class="filter-card main-wrapper" [ngClass]="{'color-warn-b':itemHover}" (mouseenter)="itemHover=true"
    (mouseleave)="itemHover=false">
    <div class="filter-card section-row selections ">
        <div class="filter-row">

            <mat-icon *ngIf="!readonly" (click)="removeFilter()" class="remove-icon">close</mat-icon>

            <mat-form-field style="width: 100px;margin-right: 5px;"
                [ngClass]="{'force-newline': subAdvancedFilterMode !== FILTERING_MODES.WHERE}">
                <!-- Depth: {{filterDepth}} | Index: {{filterIndex}} -->
                <mat-select placeholder="Show where" [(value)]="subAdvancedFilterMode"
                    (selectionChange)="updateFilteringMode(true)">
                    <mat-option [value]="FILTERING_MODES.ALL">{{FILTERING_MODES.ALL}}</mat-option>
                    <mat-option [value]="FILTERING_MODES.ANY">{{FILTERING_MODES.ANY}}</mat-option>
                    <mat-option [value]="FILTERING_MODES.WHERE">{{FILTERING_MODES.WHERE}}</mat-option>
                </mat-select>
            </mat-form-field>
            <ng-container *ngIf="subAdvancedFilterMode === FILTERING_MODES.WHERE">
                <auto-complete-search class="select-field" [fieldName]="filter.fields[0]"
                    [availableColumns]="availableCols" [readonly]="readonly" (changeFieldName)="columnChange($event)">
                </auto-complete-search>

                <!--first filter operation-->
                <mat-form-field style="width:150px" class="select-field" *ngIf="!!filter?.fields[0]">
                    <mat-select [value]="filter.operationA" (selectionChange)="operationAChange($event)">
                        <mat-option [value]="operation.id" [disabled]="readonly"
                            *ngFor="let operation of FILTERING_OPERATION">
                            {{operation.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <!--first value-->
                <ng-container *ngIf="fieldType === FIELDS_TYPES.TEXT && !!filter?.fields[0] && !!filter?.operationA
            && (filter?.operationA === FILTERING_OPR_DICTIONARY.EQUALS ||
            filter?.operationA === FILTERING_OPR_DICTIONARY.NOT_EQUALS)">
                    <advanced-filter-facet [filter]="filter" [title]="fieldTitle" [facetKey]="filter?.fields[0]"
                        [isDynamic]="filter?.isDynamicFacet == null ? true : filter?.isDynamicFacet"
                        (selectedValueChange)="filterValueChanged($event)" [maxShow]="10"
                        [selectedValuesText]="filter?.isDynamicFacet == null ? filter?.valueA : !filter?.isDynamicFacet? filter?.displayA : filter?.valueA"
                        [queryInfoServiceUrl]="(tab.facetTranslationUrl +'/'+ filter?.fields[0] + '/'+filter?.tableName )"
                        [optionListId]="filter?.optionListId" [queryService]="queryService" [serviceUrl]="serviceUrl">
                    </advanced-filter-facet>
                </ng-container>
                <ng-container *ngIf="fieldType === FIELDS_TYPES.DATE && !!filter?.fields[0] && !!filter?.operationA   ">
                    <mat-form-field class="select-field"
                        *ngIf="!filter.operationA.includes('BLANK') && !filter.operationA.includes('HIGH_VALUE')">
                        <mat-select [value]="filter.operationB" (selectionChange)="handleDateOperation($event)">
                            <mat-option *ngFor="let opr of DATE_OPERATION" [value]="opr.id">{{opr.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <ng-container
                        *ngIf="filter.operationB === DATE_OPR_DICTIONARY.THE_DATE && !filter.operationA.includes('BLANK') && !filter.operationA.includes('HIGH_VALUE')">
                        <input [matDatepicker]="myDatepicker" (dateChange)="dateChanged($event)"
                            [ngModel]="isDateAfter ? filter.valueA : filter.valueB">
                        <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #myDatepicker></mat-datepicker>
                    </ng-container>
                    <ng-container
                        *ngIf="filter.operationB !== DATE_OPR_DICTIONARY.THE_DATE && !filter.operationA.includes('HIGH_VALUE')">
                        <mat-form-field class="select-field" *ngIf="!filter.operationA.includes('BLANK')">
                            <input placeholder="the value" matInput (keyup)="onKey($event,fieldType, filter.operationB)"
                                [(ngModel)]="filter.valueA" [readonly]="readonly" (change)="dateChanged()">
                        </mat-form-field>
                    </ng-container>
                </ng-container>
                <mat-form-field *ngIf="!!filter?.fields[0] && !!filter?.operationA&&filter.operationA !=FILTERING_OPR_DICTIONARY.IS_BETWEEN&&
            ((fieldType !== FIELDS_TYPES.TEXT && fieldType !== FIELDS_TYPES.DATE)
               || filter.operationA === FILTERING_OPR_DICTIONARY.CONTAINS
               || filter.operationA === FILTERING_OPR_DICTIONARY.REGEX_MATCH
               || filter.operationA === FILTERING_OPR_DICTIONARY.STARTS_WITH) else isBetweenSection
            " class="select-field value-field">
                    <input *ngIf="!isActionLess" placeholder="the value" (keyup)="onKey($event,fieldType)" matInput
                        [(ngModel)]="filter.valueA" [readonly]="readonly"
                        (change)="updateFilterValue()">
                    <input *ngIf="isActionLess" placeholder="the value" (keyup)="onKey($event,fieldType)" matInput
                        [(ngModel)]="filter.valueB" [readonly]="readonly"
                        (change)="updateFilterValue()">
                </mat-form-field>


                <ng-template #isBetweenSection>
                    <!-- <p *ngIf="filter.operationA === FILTERING_OPR_DICTIONARY.IS_BETWEEN" class="label-text">From</p> -->

                    <!-- Filter Symbols -->
                    <mat-form-field class="filter-field-between"
                        *ngIf="filter.operationA === FILTERING_OPR_DICTIONARY.IS_BETWEEN">
                        <mat-select [(ngModel)]="filter.fromOperatorValue" (selectionChange)="updateFromOperator()">
                            <mat-option [value]="operation.id" *ngFor="let operation of FILTERING_MIN_OPERATION">
                                {{operation.character}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field *ngIf="filter.operationA === FILTERING_OPR_DICTIONARY.IS_BETWEEN"
                        class="select-field value-field-between">
                        <input placeholder="Min Value" (keyup)="onKey($event,fieldType)" matInput
                            [(ngModel)]="filter.valueA" [readonly]="readonly" (change)="updateFilterValue()">
                    </mat-form-field>

                    <!-- <p *ngIf="filter.operationA === FILTERING_OPR_DICTIONARY.IS_BETWEEN" class="label-text">To</p> -->

                    <!-- Filter Symbols -->
                    <mat-form-field class="filter-field-between"
                        *ngIf="filter.operationA === FILTERING_OPR_DICTIONARY.IS_BETWEEN">
                        <mat-select [(ngModel)]="filter.toOperatorValue" (selectionChange)="updateToOperator()">
                            <mat-option [value]="operation.id" *ngFor="let operation of FILTERING_MAX_OPERATION">
                                {{operation.character}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field *ngIf="filter.operationA === FILTERING_OPR_DICTIONARY.IS_BETWEEN"
                        class="select-field value-field-between">
                        <input placeholder="Max Value" (keyup)="onKey($event,fieldType)" matInput
                            [(ngModel)]="filter.valueB" [readonly]="readonly" (change)="updateIsBetweenValue()">
                    </mat-form-field>
                </ng-template>

                <!--to remove later just to confirm the expression is correct-->
                <mat-form-field class="select-field" *ngIf="false">
                    <input placeholder="the query" matInput [value]="filter.expression">
                    || filter.operationA === FILTERING_OPR_DICTIONARY.STARTS_WITH)
                    " class="select-field value-field">
                    <input placeholder="the value" (keyup)="onKey($event,fieldType)" matInput
                        [(ngModel)]="filter.valueA" [readonly]="readonly" (change)="updateFilterValue()">

                </mat-form-field>

                <!--to remove later just to confirm the expression is correct-->
                <mat-form-field class="select-field" *ngIf="false">
                    <input placeholder="the query" matInput [value]="filter.expression">>

                </mat-form-field>


                <div class="error-msg" *ngIf="invalidValue">Invalid numeric value</div>
            </ng-container>
            <ng-container *ngIf="subAdvancedFilterMode !== FILTERING_MODES.WHERE">
                <!-- TODO: Set groupIndex Value-->
                <advanced-filters *ngIf="tabSettings && queryService && !!filter.filters" [mqService]="queryService"
                    [isSubFilter]="true" [tabSettings]="tabSettings" [advancedFilterMode]="subAdvancedFilterMode"
                    [parentFilter]="filter" [depth]="filterDepth +1" [filterIndex]="filterIndex"
                    (invalidFilterValue)="checkValidateAdvancedFilter($event)"
                    (filtersValue)="linkWithChildFilters($event)">
                </advanced-filters>
                <!-- <div class="control-style" (click)="addNewFilter()">
                    <mat-icon class="add-icon">add</mat-icon>
                    <span> Add filter </span>
                </div> -->
            </ng-container>
            <!-- <div class="filter-label" *ngIf="filterIndex !== 0">{{filterIndex == 0 ? '' : advFilterMode ==
                FILTERING_MODES.ALL ? 'And' : 'Or'}}</div> -->
        </div>
        <br>
    </div>
    <br>
</div>
