import { Component, OnInit, Input, Output, ViewEncapsulation, ViewChild, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { IPivotConfig, BaseController, StateService, IPivotChartResult, ExportDataTableService, ICategories, mapAggregateName, DateGroupBy, DiscovererFacetTranslationService, DRFilter, DRFacetOption, FacetValue, IPivotChartResultColumn } from '@discoverer/core/services';
import { AppSettingsService, IDynamicReport, TabSettingsService } from '@discoverer/core/services/dynamic-reports-services';
import { DiscovererQueryService, IColumnSetting } from '@discoverer/core/services';
import { GridDataResult, PageChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { State, SortDescriptor, process, orderBy, GroupDescriptor } from '@progress/kendo-data-query';
import { mapFunctionName } from '@discoverer/core/services';
import { ReportPersistService } from '@discoverer/core/services/dynamic-reports-services';
import { HttpClient } from '@angular/common/http';
import { NUMBER_OF_ROWS_METRIC, PivotDataService } from '@discoverer/core/services/core-data-services/pivot-chart.service';
import { DataFormattingService } from '@discoverer/core/services/dynamic-reports-services/data-formatting-service';
import { ChartFilter, ChartRoutingService } from '@discoverer/core/services/dynamic-reports-services/chart-routing-service';
import { disLogger } from '@discoverer/core/services';
import { LoadingState } from '@discoverer/core/services/classes/loading-state';
import { EsQueryService } from '@discoverer/core/services/core-data-services/discoverer-esquery-service';
import { catchError, debounceTime, first } from 'rxjs/operators';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { combineLatest, of } from 'rxjs';
import { DialogsManagerService } from '@discoverer/dynamic-reports/dialogs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'pivot-chart',
    templateUrl: './pivot-chart.component.html',
    styleUrls: ['./pivot-chart.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PivotChartComponent extends BaseController implements OnInit {

    @Input() public pivotState: StateService<IPivotConfig>;
    @Input() public queryService: DiscovererQueryService;
    @Input() public pageSize;
    @Input() public isEditMode;
    @Output() public isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public pivotHeaderChanged: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('Grids') private grid: GridComponent;
    public gridView: GridDataResult;
    public gridState: State;
    public pivotColumns: IPivotChartResultColumn[];
    public pivotHeader: any;
    public originalPivotData = [];
    public footerData: any;
    public config: IPivotConfig;
    private subFacetDictionary: { [key: string]: ICategories } = {};
    @Input() public displayMode: 'Values' | 'Percentage' = 'Percentage';
    protected pivotDataService: PivotDataService;
    public loadingState: LoadingState;
    public isBusy = false
    public flatPivotData: any[];
    // groups: GroupDescriptor[] = [];

    private currentApp: string;
    private _columns: IColumnSetting[];

    constructor(
        private _snackBar: MatSnackBar,
        public dataFormattingService: DataFormattingService,
        protected reportPersist: ReportPersistService,
        protected http: HttpClient,
        protected excelService: ExportDataTableService,
        protected cdRef: ChangeDetectorRef,
        protected tabSettings: TabSettingsService,
        private facetTranslationService: DiscovererFacetTranslationService,
        protected appSetting: AppSettingsService,
        private _dialogsManagerService: DialogsManagerService,
        private _chartRoutingService: ChartRoutingService,
        private esQueryService: EsQueryService) {
        super();
        this.gridState = {
            skip: 0,
            take: this.pageSize,
            sort: [{
                field: '',
                dir: 'asc'
            }]
        };
    }

    async ngOnInit() {
        const currentTab = await this.tabSettings.getCurrentTab();
        this.currentApp = (await this.appSetting.getCurrentApp()).key;
        this._columns = (await this.tabSettings.getAllColumns()).concat([NUMBER_OF_ROWS_METRIC]).map(s => Object.assign({}, { name: s.fieldName }, s));
        this.pivotHeader = {};
        disLogger('###COLUMNS##', this._columns);

        this.pivotDataService = new PivotDataService(
            this.http,
            (currentTab).serviceUrl,
            this.queryService,
            this.pivotState,
            this.tabSettings,
            this.facetTranslationService,
            this._columns,
            this.esQueryService
        );
        this.subscriptions.push(this.reportPersist.oRequestLoaded.subscribe(reportLoaded => {
            if (!!reportLoaded && !this.pivotState.getState().categories) {
                this.originalPivotData = [];
                this.pivotColumns = [];
                this.pivotHeader = {};
            }
        }));
        this.dataServices.push(this.pivotDataService as any);
        this.subscriptions.push(this.pivotDataService.oLoadingStatus.subscribe(loadingState => {
            this.loadingState = loadingState;
            if (this.loadingState?.status === 'Failure') {
                this._snackBar.open(`Error: ${this.loadingState.errorDescription}`, 'Close', {
                    duration: 5000
                });
            }
            this.cdRef.detectChanges();
        }))
        this.subscriptions.push(combineLatest([this.pivotState.oState, this.pivotDataService.oData])
            .pipe(debounceTime(10)).subscribe(async res => {
                const config = res[0];
                const data = res[1];
                if (!!config?.subFacets) {
                    this.gridState.sort = [];
                }
                this.fillPivotInfo(data);
            }));

    }

    private fillPivotInfo(x: IPivotChartResult) {
        // this.groups = x.groups;
        this.config = this.pivotState.getState();
        (this.config.subFacets || []).forEach(f => this.subFacetDictionary[f.colName] = f);
        this.gridState.skip = 0;
        this.gridState.take = this.pageSize;
        if (x) {
            this.footerData = x.footer;
            if (!!this.config.categories) {
                this.originalPivotData = x.result;
                this.flatPivotData = x.result //(x.flatData || []);
                this.pivotColumns = x.columns.map(item => {
                    const res = Object.assign({}, item) as any;
                    res.isGroupBy = item.field === 'key' || (this.config && this.config.subFacets && this.config.subFacets.length &&
                        this.config?.subFacets.findIndex(z => z?.colName === item.field) !== -1);
                    res.fieldVal1 = `${item.field}.val1`;
                    res.fieldVal2 = `${item.field}.val2`;
                    res.fieldCompare = `${item.field}.valDiff`;
                    res.fieldName = item.field;
                    return res;
                });
                this.isValid.emit(true);

            } else {
                this.clearData();
            }
        } else {
            this.originalPivotData = this.flatPivotData = [];
        }
        this.loadDataToTable();
        this.cdRef.markForCheck();
    }

    public trackByField(index: number, column: IPivotChartResultColumn) {
        return index;
    }

    public exportToExcel() {
        let response;
        this.excelService.ExportPivotDataToExcel(this.flatPivotData, this.pivotColumns).then(x => {
            response = x;
            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.download = `Pivot Report`;
            anchor.href = url;
            anchor.click();
        }).catch(x => {

        });
    }

    public sortChange(sort: SortDescriptor[]): void {
        this.gridState.sort = sort;
        this.loadDataToTable();
    }

    public pageChange(event: PageChangeEvent) {
        this.gridState.skip = event.skip;
        this.loadDataToTable();
    }

    public changePeriod(previous = false) {
        const groupBy = (this.config.categories.groupBy as DateGroupBy);
        if (groupBy != null) {
            groupBy.displayOffSet = ((previous ? -1 : 1) * groupBy.numberOfGroups) + (groupBy.displayOffSet ? groupBy.displayOffSet : 0);
        }
        this.config.categories.groupBy = groupBy;
        this.pivotState.setValue(this.config, true);
    }



    public get comparasionMode() {
        return this.config?.categories?.groupBy?.comparasionMode;
    }

    private clearData() {
        this.originalPivotData = [];
        this.pivotColumns = [];
        this.pivotHeader = {};
    }

    public async openRecords(column: IPivotChartResultColumn, dataItem: { key: any, value: any }, view: 'table-view' | 'pivot-view' | 'chart-view', val = 'val1') {
        this.isBusy = true;
        const req = this.reportPersist.oLastRequestData.getValue();
        const filters: { categoriesFilter: ChartFilter, seriesFilters: ChartFilter[] } = {
            categoriesFilter: this._prepareCategoriesParam(column, dataItem, val),
            seriesFilters: this._prepareSeriesParam(dataItem)
        };
        const requestCopy = JSON.parse(JSON.stringify(req)) as IDynamicReport; // Copy object without refrences

        if (view === "table-view") {
            await this._chartRoutingService.openSelectedRecords(this.reportPersist, this.currentApp, this.tabSettings.tabKey, filters, requestCopy, view);
        } else {
            const serviceUrl = (await this.tabSettings.getCurrentTab()).serviceUrl;
            const dialogRef = await this._dialogsManagerService.openCategoryPickerDialog(this._columns, serviceUrl, this.reportPersist.mainQueryService)
            const col = await dialogRef.toPromise();
            if (col) {
                await this._chartRoutingService.openSelectedRecords(this.reportPersist, this.currentApp, this.tabSettings.tabKey, filters, requestCopy, view, col);
            }
        }
        this.isBusy = false;
        this.cdRef.markForCheck();

    }
    private _prepareCategoriesParam(column: IPivotChartResultColumn, dataItem: { key: any, value: any }, val = 'val1'): ChartFilter {
        if (this.config.categories.colName === 'DefaultColumn') return;
        let values;
        if (this.config.subFacets?.length) {
            const type = this._columns.find(col => col.fieldName === this.config.categories.colName)?.type;
            const pathFromColumn = column?.category?.val1 && type !== 'date' ? column.category : column.title;
            values = column.isTotalColumn
                ? this.pivotColumns
                    .filter(col => !col.isTotalColumn)
                    .slice(this.config.subFacets.length)
                    .map(col =>col?.category?.val1 && type !== 'date' ? col.category[val] : col.title[val])
                : [pathFromColumn[val]];
        } else {
            values = Object.keys(dataItem.key).includes('id') ? [dataItem.key.id] : [dataItem.key[val]];
        }
        const { dataType, type } = this._columns.find(x => x.fieldName === this.config.categories.colName);
        const { colName, groupBy } = this.config?.categories;
        return {
            fieldName: colName,
            fieldType: dataType,
            values,
            gap: type === 'numeric' ? groupBy?.gap : (groupBy?.orginalGap || '')
        };
    }

    private _prepareSeriesParam(dataItem: { key: any, value: any }): ChartFilter[] {
        return (this.config.subFacets || []).map(facet => {
            const dataVal = dataItem[facet.colName]?.id ? dataItem[facet.colName].id : dataItem[facet.colName].val1;
            return {
                fieldName: facet.colName,
                fieldType: this._columns.find(x => x.fieldName === facet.colName)?.dataType,
                values: [dataVal],
                gap: facet.groupBy ? facet.groupBy.orginalGap : ''
            };
        });
    }

    private loadDataToTable() {
        this.gridView = {
            data: orderBy(this.flatPivotData, this.gridState.sort).slice(this.gridState.skip, this.gridState.skip + this.pageSize),
            total: this.flatPivotData.length
        };
        this.cdRef.markForCheck();
    }
    sortGroupedData(data) {
        const sort = this.gridState.sort;
        (data || []).forEach(d => {
            if (d.aggregates) {
                d.items = this.sortGroupedData(d.items)
            }
        });
        const dir = (sort[0]?.dir === 'desc' ? -1 : 1);
        if (data[0]?.aggregates) {
            const sortField = `${sort[0]?.field.replace('.val1', '.valDiff')}`;
            return data.sort((a, b) => dir * (a.aggregates[sortField] - b.aggregates[sortField]));
        } else {
            const sortField = sort[0]?.field.replace('.val1', '');
            return data.sort((a, b) => dir * (a[sortField]?.valDiff - b[sortField]?.valDiff));
        }
    }

    onColumnResize(event: any): void {
        const getColumnField = () => event?.[0]?.column?.field || '';
        this.config.subFacets?.map((v) => {
            if(getColumnField().includes(v.colName)) {
                v.width = event[0].newWidth
            }
        });
        if(getColumnField().includes("col")) {
            this.config.categories.width = event[0].newWidth;
            this.pivotColumns.map((v) => {
                if(v.field.includes("col")) {
                    v.width = event[0].newWidth
                }
            });
        }
        this.cdRef.markForCheck();
    }
}

