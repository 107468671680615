import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'toDate'
})
export class ToDatePipePipe implements PipeTransform {

  transform(value: any): Date | string {
    if(value instanceof moment) {
      return new Date(value.toString());
    } else if(value === "string") {
      return new Date(value);
    }

    return value;
  }

}
