<div [ngClass]="{'report-open':!hideReports,'report-view': (selectedView=='report-view') }" id="app-root"
  class="scroll-wrapper" [class.fix-sticky]="false">

  <div *ngIf="!!currentTab ">
    <div id="main-wrapper" class="main-view">
          <div class="container-fluid main-view-container">
            <div [ngClass]="(expandRightSide)?'right-side-area-tilted':'right-side-area'">
              <div class="workspace-container">

                <search-header id="search-header" *ngIf="selectedView === 'table-view'" [queryService]="reportPersist.mainQueryService"
                  [tableHeaderConfig]="tableHeaderService?.tableHeader | async" [selectedView]="selectedView">
                </search-header>
                <div [@fadeInOutO]="!!selectedView?'in':'out'" class="scrollable-area">
                  <one-chart-view *ngIf="selectedView === 'chart-view'" [queryService]="reportPersist.mainQueryService"
                    [chartState]="reportPersist.chartState">
                  </one-chart-view>

                  <div *ngIf="selectedView === 'pivot-view' ">
                    <pivot-chart-wrapper *ngIf="!!reportPersist.pivotChartState && !!reportPersist.mainQueryService"
                      [pivotState]="reportPersist.pivotChartState" [queryService]="reportPersist.mainQueryService">
                    </pivot-chart-wrapper>
                  </div>

                  <kendo-table-view *ngIf="selectedView === 'table-view'" (cellClickedEvent)="rowClick.emit($event)"
                  [tableHeaderService]="tableHeaderService">
                  </kendo-table-view>
                </div>

              </div>
            </div>
          </div>
    </div>
  </div>
