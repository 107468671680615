<div class="wrapper">
    <header>
        <div class="header-content">
            <h1 class="dialog-title">Auto Generated Fields</h1>
            <p class="dialog-subtitle" *ngIf="chosenOption === 'template'; else UPLOAD_TEXT">Choose fields from already
                defined templates.</p>
            <ng-template #UPLOAD_TEXT>
                <p class="dialog-subtitle">Auto generated fields by uploading a sample file.</p>
            </ng-template>
        </div>
        <div class="header-actions">
            <button mat-icon-button [disabled]="isGenerating" (click)="closeDialog()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </header>

    <mat-divider></mat-divider>

    <main>
        <mat-radio-group [disabled]="isGenerating" class="radio-group" [(ngModel)]="chosenOption" color="primary">
            <mat-radio-button class="radio-button" value="upload">From File</mat-radio-button>
            <mat-radio-button class="radio-button" value="template">From Template</mat-radio-button>
        </mat-radio-group>

        <div class="main-content">
            <app-dataset-fields-templates-dialog *ngIf="chosenOption === 'template'; else UPLOAD">
            </app-dataset-fields-templates-dialog>

            <ng-template #UPLOAD>
                <field-from-files-dialog [datasetId]="data.datasetId" (fieldsGenerated)="handleFieldsGenerated($event)"
                    (fieldsStartedGenerating)="handleFieldsStartedGenerating($event)"></field-from-files-dialog>
            </ng-template>

        </div>

    </main>
</div>