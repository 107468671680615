<div class="main-body-wrapper main-height">
    <div class="main-body">
        <div *ngIf="isLoading" class="center-spinner">
            <mat-spinner color="primary" diameter="100"></mat-spinner>
        </div>
        <ng-container *ngIf="!isLoading">
            <div class="flex main-height">
                <app-schema-form class="overflow-auto w-full" [datasetId]="datasetId" [hideFileViewer]="hideFileViewer"
                    (testFileReprocessed)="activeComponent=viewTypes.pdfView">
                    <div extraActions [ngClass]="!hideFileViewer ? 'justify-between' : 'justify-end'"
                        class="extra-actions flex align-center gap-2 w-full">
                        <div *ngIf="!hideFileViewer" class="flex gap-2 items-center">
                            <ng-container *ngIf="!!fileId">
                                <mat-button-toggle-group [(ngModel)]="activeComponent" name="fontStyle" aria-label="Font Style"
                                    class="parsd-toggle-button-group" [value]="viewTypes.pdfView">
                                    <mat-button-toggle class="parsd-toggle-button"
                                        (click)="activeComponent = viewTypes.resultView" [value]="viewTypes.resultView" [matTooltip]="'Test Document Results'">
                                        <mat-icon class="material-icons-outlined">assignment</mat-icon>Results
                                    </mat-button-toggle>

                                    <mat-button-toggle class="parsd-toggle-button"
                                        (click)="activeComponent = viewTypes.pdfView" [value]="viewTypes.pdfView" [matTooltip]="'View Test Document'">
                                        <mat-icon class="material-icons-outlined">insert_drive_file</mat-icon>View
                                    </mat-button-toggle>

                                    <mat-button-toggle class="parsd-toggle-button"
                                        (click)="activeComponent = viewTypes.uploadView" [value]="viewTypes.uploadView" [matTooltip]="'Change Test Document'">
                                        <mat-icon class="material-icons-outlined">file_upload</mat-icon>Change
                                    </mat-button-toggle>

                                </mat-button-toggle-group>
                            </ng-container>
                        </div>
                        <button class="file-viewer" (click)="hideFileViewer = !hideFileViewer" mat-stroked-button
                            color="basic" [matTooltip]="hideFileViewer? 'Show Document': 'Hide Document'">
                            <mat-icon class="material-icons-outlined">menu</mat-icon>
                        </button>
                    </div>
                    <div *ngIf="!hideFileViewer" fileActions class="main-viewer flex w-full overflow-auto">
                        <div class="flex flex-1 h-full w-full" [ngSwitch]="activeComponent">
                            <ng-container *ngSwitchCase="viewTypes.uploadView">
                                <custom-file-input [datasetId]="datasetId" class="file-input w-full" [isTest]="true"
                                    (backendResult)="handleFileUploaded($event);"></custom-file-input>
                            </ng-container>

                            <ng-container *ngSwitchCase="viewTypes.pdfView">
                                <app-original-file-viewer class="w-full" [datasetId]="datasetId" [fileId]="fileId" [fileName]="fileName"
                                    [fileExtension]="fileExtension"></app-original-file-viewer>
                            </ng-container>

                            <ng-container *ngSwitchCase="viewTypes.resultView">
                                <app-document-result class="w-full" [datasetId]="datasetId" [fileId]="getFileId()"></app-document-result>
                            </ng-container>

                            <ng-container *ngSwitchDefault>
                              <p>No view selected</p>
                            </ng-container>
                        </div>
                    </div>
                </app-schema-form>
            </div>
        </ng-container>
    </div>
</div>

