import {
    DOCUMENT
} from '@angular/common';
import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    Input,
    ElementRef,
    Inject,
    Output,
    EventEmitter,
    ChangeDetectorRef
} from '@angular/core';
import {
    KtdGridComponent,
    KtdGridLayout,
    KtdResizeEnd,
    ktdTrackById
} from '@katoid/angular-grid-layout';
import {
    StateService,
    IDashboardViewState,
    DiscovererQueryService,
    DashboardController,
    IDashboardReportConfig,
    DashboardFiltersService
} from '@discoverer/core/services';
import {
    Router
} from '@angular/router';
import { DiscoReportContext } from '../disco-report/disco-report.component';
import { DialogsManagerService } from '@discoverer/dynamic-reports/dialogs';

@Component({
    selector: 'dashboard-view',
    templateUrl: './dashboard-view.component.html',
    styleUrls: ['./dashboard-view.component.scss']
})
export class DashboardViewComponent extends DashboardController implements OnDestroy {
    @Input() public dashboardViewState: StateService<IDashboardViewState>;
    @Input() public queryService: DiscovererQueryService;
    @Input() public app: string;
    public disableEdit: boolean;
    _editMode = false
    @Input() public set editMode(isEditMode: boolean) {
        this.disableDrag = this.disableResize = this.disableRemove = this.disableEdit = !isEditMode;
        this._editMode  = isEditMode;
    }
    get editMode() {
        return this._editMode;
    }
    @Input() set selectedReports(value: IDashboardReportConfig[]) {
        this.mapReportsToLayout(value);
        this._selectedReports = value;
    }
    get selectedReports(): IDashboardReportConfig[] {
        return this._selectedReports;
    }
    @Input() dashboardFiltersService: DashboardFiltersService;

    @Output() public onResize: EventEmitter<KtdResizeEnd> = new EventEmitter<KtdResizeEnd>();
    @Output() public onRemove: EventEmitter<string> = new EventEmitter<string>();
    @ViewChild(KtdGridComponent, { static: true }) grid: KtdGridComponent;
    private _selectedReports: IDashboardReportConfig[];
    trackById = ktdTrackById;
    contextCollection: DiscoReportContext[] = [];
    layouts: KtdGridLayout = [];

    constructor(
        @Inject(DOCUMENT) public document: Document,
        public elementRef: ElementRef,
        private router: Router,
        private _cdr: ChangeDetectorRef,
        private _dialogsManagerService: DialogsManagerService
    ) {
        super(elementRef, document);
    }


    public mapReportsToLayout(dashboards: IDashboardReportConfig[]) {
        this.layout = [];
        dashboards.forEach(val => {
            if (!this.layout.includes(val)) {
                this.layout.push(val)
            }
        });
    }

    public openReport(reportId: string, tabId: string) {
        const url = this.router.serializeUrl(this.router.createUrlTree([this.app, 'reports', tabId, reportId]));
        window.open(url, '_blank');
    }
    public async edit(reportId: string, tabId: string) {
        const x = await this._dialogsManagerService.openNewReportDialog(tabId, reportId).toPromise()
        this.contextCollection[reportId]?.reportPersistService?.resetRequest();
        this._cdr.detectChanges()
    }
    public addNewReport(report: IDashboardReportConfig) {
        this.selectedReports.push(report);
    }
    public onDragEnded(event: KtdResizeEnd) {
        this.isDragging = false;
        this.onResize.emit(event);
    }
    public onResizeEnded(event: KtdResizeEnd) {
        this.isResizing = false;
        this.onResize.emit(event);
    }

    public removeItem(reportId: string): void {
        this.onRemove.emit(reportId);
        delete this.contextCollection[reportId];
    }
    public isMissingReport(context: DiscoReportContext) {
        return context?.reportPersistService?.isMissingReport
    }
}

