import { Component, ViewEncapsulation, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    BaseController
} from '@discoverer/core/services'



@Component({
    selector: 'image-full-screen',
    templateUrl: './image-full-screen.dialog.html',
    styleUrls: ['./image-full-screen.dialog.scss'],
    encapsulation: ViewEncapsulation.None
})
// tslint:disable-next-line:component-class-suffix
export class ImageFullScreenDialog extends BaseController implements OnInit {

    public imageUrl: string;
    public imageName: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: any = {},
        private dialogRef: MatDialogRef<ImageFullScreenDialog>
    ) {
        super();
    }

    public ngOnInit() {
        this.imageUrl = this.dialogData['imageUrl'];
        this.imageName = this.dialogData['imageName'];
    }

    public close() {
        this.dialogRef.close();
    }
}
