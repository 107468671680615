import { animate, group, keyframes, state, style, transition, trigger } from '@angular/animations';

// tslint:disable-next-line:variable-name
export const SlideInOutAnimation = [trigger('slideInOut', [
  state('in', style({
    'max-height': '500px', opacity: '1', visibility: 'visible'
  })),
  state('out', style({
    'max-height': '0px', opacity: '0', visibility: 'hidden'
  })),
  transition('in => out', [group([
      animate('400ms ease-in-out', style({
        opacity: '0'
      })),
      animate('600ms ease-in-out', style({
        'max-height': '0px'
      })),
      animate('700ms ease-in-out', style({
        visibility: 'hidden'
      }))
    ]
  )]),
  transition('out => in', [group([
      animate('100ms ease-in-out', style({
        visibility: 'visible'
      })),
      animate('600ms ease-in-out', style({
        'max-height': '500px'
      })),
      animate('200ms ease-in-out', style({
        opacity: '1'
      }))
    ]
  )])
])];

// works on ngIf only
// tslint:disable-next-line:variable-name
export const FadeInOutAnimation = [
  trigger('fadeInOut', [
    state('in', style({})),
    transition('void => *', [
      animate(500, keyframes([
        style({opacity: 0, offset: 0}),
        style({opacity: 1, offset: 0.7}),
        style({opacity: 1, offset: 1.0})
      ]))
    ]),
    transition('* => void', [
      animate(0, keyframes([
        style({opacity: 1, offset: 0}),
        style({opacity: 0, offset: 1})
      ]))
    ])
  ])
];

// works on hidden / ngIf
// tslint:disable-next-line:variable-name
export const FadeInOutOptimizedAnimation = [trigger('fadeInOutO', [
  state('in', style({
    opacity: '1', visibility: 'visible'
  })),
  state('out', style({
    opacity: '0', visibility: 'hidden'
  })),
  transition('in => out', [group([
      animate('400ms ease-in-out', style({
        opacity: '0'
      })),
      animate('700ms ease-in-out', style({
        visibility: 'hidden'
      }))
    ]
  )]),
  transition('out => in', [group([
      animate('100ms ease-in-out', style({
        visibility: 'visible'
      })),
      animate('200ms ease-in-out', style({
        opacity: '1'
      }))
    ]
  )])
])];

export const ShakeAnimation = [trigger('scaleAnimate', [
  state('small', style({
    transform: 'scale(1)',
  })),
  state('large', style({
    transform: 'scale(1)',
  })),
  transition('small => large', animate('400ms ease-in', keyframes([
    style({opacity: 0, transform: 'translateX(-10px)', offset: 0}),
    style({opacity: 1, transform: 'translateX(-8px)', offset: 0.25}),
    style({opacity: 1, transform: 'translateX(0)', offset: 1.0})
  ])))
])];

export const heightExpand = [trigger('heightExpand', [
  state('in', style({
    overflow: 'hidden',
    height: '*',
    width: '300px'
  })),
  state('out', style({
    opacity: '0',
    overflow: 'hidden',
    height: '0px',
    width: '0px'
  })),
  transition('in => out', animate('400ms ease-in-out')),
  transition('out => in', animate('400ms ease-in-out'))
])];
