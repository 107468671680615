import { Injectable } from '@angular/core';

import { DiscovererQueryService } from './discoverer-query-service.service';
import { IFilterSortDataService } from './ifilter-sort-data-service.service';
import { FacetFieldAccumulator } from '../classes/facet-field-accumulator.class';
import { WebWorkerService } from '../web-worker-service';
import { DRQuery, DRResult, DRFilter } from '../classes/discoverer.class';

import { Observable, Subject } from 'rxjs';
import { IDRFilter } from '../classes';

@Injectable()
export class ClientSideDataService<ROW> extends IFilterSortDataService<ROW> {

  private webWorkerService: WebWorkerService = new WebWorkerService();

  public oData: Observable<ROW[]>;
  public oFacetResults: Observable<FacetFieldAccumulator[]>;
  public oResultLength: Observable<number>;

  protected _enabled = true;
  protected _sData: Subject<ROW[]>;
  protected _sFacetResults: Subject<FacetFieldAccumulator[]>;
  protected _sResultLength: Subject<number>;

  protected queryService: DiscovererQueryService;

  private baseQuery: DRQuery = new DRQuery();
  private _dataStore: {
    data: ROW[],
    filtered: ROW[]
  };


  // facets
  private _facetFields: string[] = [];

  constructor() {
    super();
    this._facetFields = ['Result'];
    this._dataStore = { data: [], filtered: [] };
    this._sData = new Subject<ROW[]>();
    this._sFacetResults = new Subject<FacetFieldAccumulator[]>();
    this._sResultLength = <Subject<number>>new Subject();
    this.oData = this._sData.asObservable();
    this.oFacetResults = this._sFacetResults.asObservable();
    this.oResultLength = this._sResultLength.asObservable();
  }

  get enabled(): boolean {
    return this._enabled;
  }
  set enabled(value: boolean) {
    this._enabled = value;
  }

  public init(queryService: DiscovererQueryService) {
    this.oFacetResults.subscribe(results => {
      queryService.results(results);
    });

    this.queryService = queryService;
    this.queryService.oQuery.subscribe(query => {
      this.baseQuery = query; // save base query
      if (this.enabled) {
        this.queryData();
      }
    });

  }

  // recieves data from subclass
  public updateData(data: ROW[]) {
    this._dataStore = {
      data: [],
      filtered: []
    };
    this._dataStore.data = data.map((row, i) => this.flattenObject(row, i));
    this._filterChanged = true;
    this._pageChanged = true;
    this._sortChanged = true;
    this._groupChanged = true;
    this.refresh();
  }

  public refresh() {
    if (this._sortChanged) {
      this.sortData()
        .then(result => {
          this._sortChanged = false;
          this._dataStore.data = result;
          this.queryData();
        });
    } else if (this._filterChanged) {
      this.queryData();
    } else {
      this.pageData();
    }
  }


  public setFacetFields(fields: string[]) {
    this._facetFields = ['Result', ...fields];
  }

  public addFacetField(field: string) {
    let index = this._facetFields.findIndex((val) => val === field);
    if (index < 0) {
      index = this._facetFields.length;
      this._facetFields.push(field);
    }
  }
  public removeFacetField(field: string) {
    const index = this._facetFields.findIndex((val) => val === field);
    if (index >= 0) {
      this._facetFields = this._facetFields.splice(index, 1);
    }
  }

  private buildFilterExpression(exclude = '', filters: { [key: string]: DRFilter }): (req: ROW) => boolean {
    const filterExpression = function (row: ROW) {
      let result = true;
      Object.keys(filters).forEach(key => {
        if (filters[key].fields[0] !== exclude) {
          result = result && filters[key].filter(row);
        }
      });

      return result;
    };

    return filterExpression;
  }

  private facetData() {
    setTimeout(function () {
      const dataArray: ROW[] = this._dataStore.data;
      const results: FacetFieldAccumulator[] = [];
      this._facetFields.forEach((field: string) => {
        const accum = new FacetFieldAccumulator(field);
        const filterExpression: (row: ROW) => boolean = this.buildFilterExpression(field, this.combinedFilters);
        dataArray.forEach((row: ROW) => {
          if (filterExpression(row)) {
            const val: any = <any>row[field];
            if (Array.isArray(val)) {
              accum.accumValues(val, 1);
            } else {
              accum.accumValue(val, 1);
            }
          }
        });
        results.push(accum);
      });
      this._sFacetResults.next(results);
    }.bind(this), 30);
  }

  private flattenObject(object: any, index: number): any {
    const flat = {};
    flat['$index'] = index;
    const prototype = Object.getPrototypeOf(object);
    if (!!prototype) {
      Object.keys(prototype).forEach(function (key) {
        if (!key.startsWith('_')) {
          flat[key] = object[key];
        }
      });
    }
    return flat;
  }

  private sortData(): Promise<any> {

    const orderObject: any = {};
    orderObject.sorts = Object.keys(this._sorts).map((field) => {
      return { orderByColumn: field, orderAscending: this._sorts[field] === 'asc' };
    });

    orderObject.data = this._dataStore.data;

    const promise = this.webWorkerService.run(order => {
      return order.data.sort((a: any, b: any) => ((order.sorts[0].orderAscending ? 1 : -1) *
        (a[order.sorts[0].orderByColumn] - b[order.sorts[0].orderByColumn]))
      );
    }, orderObject);

    return promise;
  }

  private pageData() {
    const dataArray: ROW[] = this._dataStore.filtered;
    this._sData.next(dataArray.slice(this._start - 1, this._start - 1 + this._pageSize));
    this._pageChanged = false;
  }

  private get combinedFilters() {
    let combined = Object.keys(this._filters)
      .map(k => this._filters[k]);
    if (this.baseQuery != null && this.baseQuery.filters != null) {
      combined = (this.baseQuery.filters.concat(combined));
    }
    return combined;
  }

  private queryData() {
    setTimeout(function () {
      const dataArray: ROW[] = this._dataStore.data;
      const query = new DRQuery();
      const filterExpression: (row: ROW) => boolean = this.buildFilterExpression('', this.combinedFilters);
      this._dataStore.filtered = dataArray.filter(x => filterExpression(x));
      this._sResultLength.next(this._dataStore.filtered.length);
      this.pageData();
      this._filterChanged = false;
    }.bind(this), 0);

    this.facetData();
  }


}

