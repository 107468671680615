import {
    Component,
    OnInit,
    AfterViewInit,
    OnDestroy,
    OnChanges,
    ChangeDetectionStrategy,
} from "@angular/core";
import * as React from "react";
import * as ReactDOM from "react-dom";
import {
    Grid,
    GridCell,
    GridColumn,
    GridColumnProps,
} from "@progress/kendo-react-grid";
import {
    AppSettingsService,
    BaseController,
    DataFormattingService,
    DiscovererFacetTranslationService,
    FacetTranslationInfo,
    IColumnSetting,
    MultiSelectService,
    TabSettingsService,
} from "../services";
import { disLogger } from "../services/functions";
import { GroupDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import { v4 as uuidv4 } from "uuid";
import { HttpClient } from "@angular/common/http";

import { Router } from "@angular/router";
import { Apollo } from "apollo-angular";
import { EventEmitter, Output, Input } from '@angular/core';


const stringArrayStyle = {
    padding: '8px',
    backgroundColor: '#e2e8f0',
    display: 'flex',
    gap: '4px',
    borderRadius: '8px'
};

@Component({
    selector: "kendo-react-wrapper",
    templateUrl: "./kendo-react-wrapper.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KendoReactWrapperComponent
    extends BaseController
    implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    public isBusy = false;
    public isEditMode = true;
    public showGroupFooter = true;
    public state = {
        data: [],
        originalData: [],
        width: 0,
        total: 0,
        totalIsComplete: false,
        skip: 0,
        take: 0,
        title: "",
        style: {
            height: "100%",
        },
        rowHeight: 50,
        scrollable: "scrollable",
        fixedScroll: true,
        sortable: {
            allowUnsort: true,
            mode: "single",
        },
        sort: [] as SortDescriptor[],
        resizable: true,
        group: [] as GroupDescriptor[],
        groupable: { enabled: false, footer: "none" },
        onPageChange: async (event) => {
            disLogger("onPageChange ", event);
            await this.pageChange(event.page);
        },
        cellRender: (tdElement, props) => {
            return this.cellRender(tdElement, props);
        },
        onGroupChange: (e) => {
            this.groupChange(e.group);
        },
        reorderable: true,
        onRowClick: (event) => {
            this.rowClick(event);
        },
        onSortChange: (e) => {
            disLogger("onSortChange ", e);
            this.sortChange(e.sort);
        },
        onColumnReorder: async (event) => {
            disLogger("onColumnReorder ", event);
            await this.columnStateChange(event.columns);
        },
        onColumnResize: async (event) => {
            if (event.nativeEvent.type === "pointerup") {
                disLogger("onColumnResize ", event);
                await this.columnStateChange(event.columns);
            }
        },
        onScroll: (event) => {
            //disLogger('scrolling ', event);
            this.scrollChange(event);
        },
        onExpandChange: (event) => {
            this.expandChange(event);
        },
    };

    public columnSettings: IColumnSetting[] = [];

    public currentReqId = "";

    public rootDomID: string;

    constructor(
        public http: HttpClient,
        public tabSettings: TabSettingsService,
        public appSettings: AppSettingsService,
        public router: Router,
        private dataFormattingService: DataFormattingService,
        public multiSelecteService: MultiSelectService,
        public facetTranslationService: DiscovererFacetTranslationService,
        public apollo: Apollo
    ) {
        super();
    }

    protected getRootDomNode() {
        const node = document.getElementById(this.rootDomID);
        // invariant(node, `Node '${this.rootDomID} not found!`);
        return node;
    }

    private isMounted(): boolean {
        return !!this.rootDomID;
    }

    protected renderValueOrArray(
        value: string | string[],
        column: IColumnSetting
    ): React.ReactElement | string {
        const valAsArray = Array.isArray(value) ? value : [value];

        return valAsArray
            .map((x) => this.dataFormattingService.getValue(x, column.dataType))
            .join(" | ");
    }

    protected async render(enableMultiSelect?) {
        console.log("*****RENDER***");
        const self = this;
        const displayedColumnSettings = this.columnSettings.sort(
            (a, b) => a.order - b.order
        );
        const app = await this.appSettings.getCurrentApp();
        const tab = await this.tabSettings.getCurrentTab();
        const appKey = app.key;
        const columns = Promise.all(
            displayedColumnSettings.map(async (column, index) => {
                switch (column.dataType) {
                    case "icon":
                        const iconColumn = (props) => {
                            const isGroupFooter = props.rowType === "groupFooter";
                            const detailColumn = React.createElement(
                              "td", {}, isGroupFooter ? '' : React.createElement(
                                "div", {}, React.createElement(
                                  "span",
                                  {
                                      className: "material-icons open-record-icon",
                                      onClick: () => {
                                          const current = (this.state?.group?.length && this.state?.originalData?.length)
                                            ? this.state.originalData.findIndex((dataItem) => dataItem === props.dataItem)
                                            : this.state.data.findIndex((dataItem) => dataItem === props.dataItem);
                                          this.cellClicked(current, props.dataItem);
                                      },
                                  },
                                  "open_in_new"
                                )
                              )
                            );
                            const other = React.createElement(
                              "td", { id: props.dataItem[column.fieldName] }, isGroupFooter ? '' : React.createElement(
                                "div", {}, React.createElement(
                                  "span",
                                  {
                                      className: "material-icons",
                                  },
                                  `${!!props.dataItem[column.fieldName] ? props.dataItem[column.fieldName] : ''}`
                                )
                              )
                            );
                            return column.detailColumn ? detailColumn : other;
                        };
                        return enableMultiSelect
                          ? this.createCellElementForMultiSelect(column, index)
                          : self.createCellElement(column, index, iconColumn);                    case "html":
                        return self.createCellElement(column, index, (props) => {
                            return !!props.dataItem[column.fieldName] &&
                                props.dataItem[column.fieldName] !== 0
                                ? React.createElement(
                                    "td",
                                    {},
                                    React.createElement(
                                        "div",
                                        { className: "html-text" },
                                        React.createElement(
                                            "span",
                                            {},
                                            `${props.dataItem[column.fieldName]}`
                                        )
                                    )
                                )
                                : React.createElement("td", {});
                        });

                    case "link":
                        return self.createCellElement(column, index, (props) => {
                            const encodedId = encodeURIComponent(
                                props.dataItem[column.referenceLink.sourceIdColumn]
                            );
                            let tree = this.router.createUrlTree([
                                {
                                    outlets: {
                                        dialog: [
                                            appKey,
                                            "detail",
                                            column.referenceLink.targetTab,
                                            encodedId,
                                        ],
                                    },
                                },
                            ]);
                            let url = tree.toString();
                            return !!props.dataItem[column.fieldName] &&
                                !!column.referenceLink
                                ? React.createElement(
                                    "td",
                                    {},
                                    React.createElement(
                                        "a",
                                        {
                                            href: `${url}?returnUrl=__close__`,
                                            onClick: (event) => {
                                                this.router.navigateByUrl(url);
                                                event.preventDefault();
                                                return false;
                                            },
                                        },
                                        React.createElement(
                                            "u",
                                            {},
                                            `${!!props.dataItem[column.fieldName]
                                                ? props.dataItem[column.fieldName]
                                                : ""
                                            }`
                                        )
                                    )
                                )
                                : React.createElement("td", {});
                        });
                    case "percent":
                    case "double":
                    case "date":
                    case "phone":
                    case "currency":
                    case "timestamp":
                    case "timestamptz":
                    case "int":
                        return self.createCellElement(column, index, (props) => {


                            if (this.state?.group?.length) {
                                let fromattedData = props;
                                if (fromattedData?.dataItem?.items?.length && fromattedData?.dataItem?.items[0]) {
                                    fromattedData.dataItem.items.forEach(item => {
                                        const value = item[column.fieldName];
                                        const formattedValue = this.dataFormattingService.getValue(value, column.dataType);
                                        item[column.fieldName] = formattedValue === '$NaNE' ? value : formattedValue;
                                    });
                                }
                                return React.createElement(GridCell, fromattedData);
                            } else {
                                let formatted = this.dataFormattingService.getValue(
                                    props.dataItem[column.fieldName],
                                    column.dataType
                                );
                                return React.createElement(
                                    "td",
                                    {},
                                    React.createElement("div", {}, `${formatted}`)
                                );
                            }
                        });

                    case "img":
                        return self.createCellElement(column, index, (props) => {
                            return !!props.dataItem[column.fieldName]
                                ? React.createElement(
                                    "td",
                                    {},
                                    React.createElement("img", {
                                        src: `${props.dataItem[column.fieldName]}`,
                                        height: "100px",
                                    })
                                )
                                : React.createElement("td", {});
                        });
                    case "link_website":
                        return self.createCellElement(column, index, (props) => {
                            return !!props.dataItem[column.fieldName]
                                ? React.createElement(
                                    "td",
                                    {},
                                    React.createElement(
                                        "a",
                                        {
                                            href: `${props.dataItem[column.fieldName]}`,
                                            target: "_blank",
                                        },
                                        React.createElement(
                                            "u",
                                            {},
                                            `${!!props.dataItem[column.fieldName]
                                                ? props.dataItem[column.fieldName]
                                                : ""
                                            }`
                                        )
                                    )
                                )
                                : React.createElement("td", {});
                        });
                    case "boolean":
                        return self.createCellElement(column, index, (props) => {
                            const data1 =  `${props.dataItem[column.fieldName] == undefined
                                ? ""
                                : props.dataItem[column.fieldName]
                            }`
                            const { style, display } = this._getColumnStyles(column, data1)
                            const data = display || data1;
                            return React.createElement(
                                "td",
                                {},
                                React.createElement(
                                    "div",
                                    { style },
                                    data
                                )
                            );
                        });

                    case "string_array":
                        return self.createCellElement(column, index, (props) => {
                            const spans = props?.dataItem[column?.fieldName]?.map((item, index) => {
                                return item ? React.createElement('span', {
                                    style: stringArrayStyle,
                                    key: props?.columnIndex + props?.columnsCount + index,
                                }, item) : "";
                            });
                            return React.createElement('td', null, React.createElement(
                                "div",
                                { className: "string-array-cell" },
                                spans
                            )
                            );
                        });
                    default:
                        var facetInfo = null;
                        if (column.isDynamicFacet == false) {
                            facetInfo = await this.getFacetTranslation(column);
                        }
                        if (facetInfo == null) {
                            return self.createCellElement(column, index, (props) => {
                                const data1 = `${!!props.dataItem[column.fieldName]
                                    ? props.dataItem[column.fieldName]
                                    : ""
                                    }`
                                const { style, display } = this._getColumnStyles(column, data1)
                                const data = display || data1;
                                return React.createElement(
                                    "td",
                                    { id: data1 },
                                    React.createElement(
                                        "div",
                                        { style },
                                        data
                                    )
                                );
                            });
                        } else {
                            return self.createCellElement(column, index, (props) => {
                                var value = !!props.dataItem[column.fieldName]
                                    ? props.dataItem[column.fieldName]
                                    : "";
                                value = this.getTranslationValue(facetInfo, value);
                                return React.createElement(
                                    "td",
                                    {},
                                    React.createElement("div", {}, `${value}`)
                                );
                            });
                        }
                }
            })
        ).then((columns) => {
            const rootNode = this.getRootDomNode();
            if (!!rootNode && !!rootNode.firstChild) {
                let w = 20; // eye icon width
                displayedColumnSettings.forEach((c) => {
                    w += !!c.width ? c.width : 200;
                });
                const gridNode = rootNode.firstChild as any;
                gridNode.style.width = w + "px";
            }

            if (this.isMounted()) {
                var element = this.getRootDomNode();
                if (element) {
                    ReactDOM.render(
                        React.createElement(Grid as any, this.state, columns),
                        element
                    );
                }
            }
        });
    }
    private _getColumnStyles(column: IColumnSetting, data1: string) {
        let style: any = {};
        let display
        if (column?.customStyle) {
            const deff = column?.customStyle["DEFAULT_STYLE"] || {}
            style = {
                padding: '6px 16px',
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                width: "fit-content",
                gap: '4px'
            };
            const { color: backgroundColor, textColor: color } = column?.customStyle[data1] || column?.customStyle["DEFAULT_STYLE"];
            const { display: dis } = column?.customStyle[data1] ? column?.customStyle[data1] : column?.customStyle["DEFAULT_STYLE"];
            display = dis;
            if (backgroundColor) {
                style = { ...style, backgroundColor };
            }
            if (color) {
                style = { ...style, color };
            } else {
                backgroundColor && (style = { ...style, color: this._contrastingColor(backgroundColor) });

            }
        }
        return { style, display };
    }
    private _contrastingColor(hexColor) {
        if (!hexColor) return 'black'
        var r = parseInt(hexColor.substr(1, 2), 16) || 0;
        var g = parseInt(hexColor.substr(3, 2), 16) || 0;
        var b = parseInt(hexColor.substr(5, 2), 16) || 0;

        var luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

        var blackContrast = (luminance + 0.05) / 0.05;
        var whiteContrast = (1.05) / (luminance + 0.05);

        // Determine which contrast is higher
        return (blackContrast > whiteContrast) ? 'black' : 'white';
    }

    private async getFacetTranslation(
        column: IColumnSetting
    ): Promise<FacetTranslationInfo[]> {
        this.facetTranslationService = new DiscovererFacetTranslationService(
            this.http,
            this.apollo
        );
        const queryInfoServiceUrl = (await this.tabSettings.getCurrentTab())
            .facetTranslationUrl;
        await this.facetTranslationService.init(
            `${queryInfoServiceUrl}/${column.fieldName}/${column.tableName}`,
            column.form.templateOptions.optionsListId
        );
        const facetInfo = this.facetTranslationService.facetTranslationInfo;
        return facetInfo;
    }

    private getTranslationValue(facetInfo: FacetTranslationInfo[], value) {
        if (Array.isArray(value)) {
            const display = value?.map((e) => {
                const info = facetInfo?.find((fi) => fi.Id === e);
                return info?.Display;
            });
            if (display) {
                return display.join(", ");
            } else {
                return "";
            }
        } else {
            const info = facetInfo?.find((fi) => fi.Id === value);
            if (info) return info.Display;
            else return "";
        }
    }
    private createCellElement(column: IColumnSetting, index, customCell) {
        if (!column.width) {
            column.width = 150;
        }
        const props = {
            field: column.fieldName,
            orderIndex: column.order,
            width: column.width,
            title: column.display,
            format: column.format,
            key: index, // not unique?
            cell: this.isBusy
                ? (props) =>
                    React.createElement(
                        "td",
                        {},
                        React.createElement("div", { className: "busy" }, ``)
                    )
                : customCell,
            className: this.isBusy ? "busy" : "",
            sortable: true,
            reorderable: this.isEditMode,
        } as GridColumnProps;

        if (!customCell) {
            delete props.cell;
        }

        return React.createElement(GridColumn, props);
    }

    private createCellElementForMultiSelect(column: IColumnSetting, index) {
        const props = {
            field: column.fieldName,
            orderIndex: column.order,
            width: 75,
            format: column.format,
            key: index,
            cell: (props) => {
                const shouldRenderIcon = column.dataType === 'icon';

                return React.createElement(
                    "td",
                    {},
                    React.createElement(
                        "div",
                        { style: { display: 'flex', alignItems: 'center', justifyContent: shouldRenderIcon ? 'space-between' : 'flex-start' } },
                        React.createElement("input", {
                            type: "checkbox",
                            defaultChecked: this.multiSelecteService.isItemChecked(props.dataItem),
                            onClick: (e) => {
                                this.cellChecked(props.dataItem);
                            },
                        }),
                        shouldRenderIcon && React.createElement(
                      "span",
                      {
                          className: "material-icons open-record-icon",
                          onClick: (e) => {
                              const current = (this.state?.group?.length && this.state?.originalData?.length)
                                ? this.state.originalData.findIndex((dataItem) => dataItem === props.dataItem)
                                : this.state.data.findIndex((dataItem) => dataItem === props.dataItem);
                              this.cellClicked(current, props.dataItem);
                          },
                          style: { cursor: 'pointer' }
                      },
                      "open_in_new"
                    )
                    )
                );
            },
            className: this.isBusy ? "busy" : "",
            sortable: false,
            reorderable: this.isEditMode,
            locked: false,
            headerCell: (props) =>
                React.createElement(
                    "div",
                    { className: "multiple-select" },
                    React.createElement(
                        "div",
                        {},
                        React.createElement("input", {
                            type: "checkbox",
                            defaultChecked:
                                this.multiSelecteService.isMultiSelectHeaderCellEnabled.value,
                            className: "multiple-select",
                            onClick: () => {
                                this.multiSelecteService.isMultiSelectHeaderCellEnabled.next(
                                    !this.multiSelecteService.isMultiSelectHeaderCellEnabled.value
                                );
                                if (
                                    !this.multiSelecteService.isMultiSelectHeaderCellEnabled.value
                                ) {
                                    this.multiSelecteService.markListEmpty();
                                    this.render(true);
                                } else this.multipleSelectHeaderCellChecker();
                            },
                        })
                    )
                ),
        } as GridColumnProps;

        return React.createElement(GridColumn, props);
    }

    public groupChange(group: GroupDescriptor[]): void {
        // overridden
    }

    public sortChange(sort: SortDescriptor[]): void {
        // overridden
    }

    public pageChange(pageChange) {
        // overriden
    }

    public expandChange(event) {
        // overriden
    }
    public async columnStateChange(columnsState: any) {
        console.log("columnsState ", columnsState);
        // overridden
    }

    public scrollChange(event: any) {
        //overridden
    }

    public rowClick(event) {
        // this.cellClicked(current);
    }

    public cellClicked(rowIndex: number, currentObject: any) {
        // CellClickEvent
        // Custom click event on cells
    }

    public cellChecked(currentObject: any) { }

    public multipleSelectHeaderCellChecker() { }

    public cellRender(tdElement, props) {
        if (props.rowType === 'groupFooter' && this.showGroupFooter) {
            const numericField = this.columnSettings.find(x => x.fieldName === props.field);
            if (!!numericField && (numericField.type === 'numeric')) {
                return React.createElement('td', {}, React.createElement('div', {}, props.dataItem.aggregates[props.field] && props.dataItem.aggregates[props.field].sum ? `Sum : ${this.dataFormattingService.getValue(props.dataItem.aggregates[props.field].sum, numericField.dataType)}` : ''));
            }
            // return tdElement
        }
        if (props.rowType === "groupHeader") {
            const field = this.columnSettings.find(
                (x) => x.fieldName === props.dataItem.field
            );
            if (tdElement != null) {
                const formattedValue = this.dataFormattingService.getValue(
                    props.dataItem.value,
                    field?.dataType || "text"
                );
                return React.createElement(
                    "td",
                    { colSpan: "18" },
                    React.createElement("p", {}, formattedValue)
                );
            }
        }
        return tdElement;
    }

    ngOnInit() {
        this.rootDomID = uuidv4();
    }

    ngOnChanges() {
        //this.render();
    }

    ngAfterViewInit() {
        //this.render();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
