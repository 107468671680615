import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormsDataServiceInterface, OptionDataServiceInterface } from '@discoverer/dynamic-forms';
import { HttpClient } from '@angular/common/http';
import { IntegrationFormService } from '../../../services/integration-form.service';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HasuraOptionService } from '../../../../../../discoverer/dynamic-forms/option-lists/shared-services/hasura-option-service';
import { IntegrationModel } from '../../../models/integration.model';
import { IntegrationService } from '../../../services/api/integration.service';
import { integrationTypeName } from '../../../models/constants';
import { DynFormComponent } from '@discoverer/dynamic-forms/dyn-forms/dyn-form/dyn-form.component';


@Component({
    selector: 'integration-details-dialog',
    templateUrl: './integration-details-dialog.component.html',
    styleUrls: ['./integration-details-dialog.component.scss'],
    providers: [
        { provide: FormsDataServiceInterface, useClass: IntegrationFormService, deps: [HttpClient, IntegrationService] },
        { provide: OptionDataServiceInterface, useClass: HasuraOptionService },
    ]
})

export class IntegrationDetailsDialogComponent implements OnInit {

    @ViewChild('dynForm') dynForm: DynFormComponent;
    public isLoading = false;
    public isEditMode: boolean = false;
    public form = new FormGroup({});
    public model: IntegrationModel = new IntegrationModel();
    constructor(public dialogRef: MatDialogRef<IntegrationDetailsDialogComponent>,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: IntegrationModel) {
    }
    ngOnInit() {
        this.loadData();
    }


    public close(value = null) {
        
        this.dialogRef.close(value);
    }

    public save() {
        
        this.isLoading = true;

        if (!this.dynForm.form.valid)
            this.isLoading = false;

        this.dynForm.submit();
    }
    public onSubmit(event) {
        
        try {
            
            this.isLoading = true;
            this.model.datasetId = this.data.datasetId;

            

            if (event) {
                
                this.isLoading = false;
                if (event.error) {
                    this.snackBar.open(event.error);
                }
                this.close(event);
            }

        } catch (error) {
            
            this.isLoading = false;
        }
    }


    public getIntegrationName(): string {
        return integrationTypeName.get(this.data.typeId)
    }

    private loadData() {
        try {
            this.isEditMode = !!this.data.id;
            this.model.id = this.data.id;
            this.model.datasetId = this.data.datasetId;
            this.model.typeId = this.data.typeId;
            this.model.configuration = this.data.configuration;
            if(!this.model.configuration['headers'].length) {
                this.model.configuration['headers'] = [{"key": "", "value": ""}];
            }

        } catch (error) {
            this.isLoading = false;
        }

    }
}
