import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export class HistogramTag {
    min: string;
    max: string
}
export class HistogramData {
    tag: string ;
    value: string;
}

@Component({
  selector: 'disco-histogram',
  templateUrl: './histogram.component.html',
  styleUrls: ['./histogram.component.scss']
})
export class HistogramComponent implements OnInit {
    @Input() public range: {min,max};
    @Output() seriesClicked: EventEmitter<string> = new EventEmitter<string>();

    @Input() public histogramData: HistogramData[];
    constructor() { }

    ngOnInit(): void {

    }

    seriesClick(e) {
        this.seriesClicked.emit(e.category);
    }

}
