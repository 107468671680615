<div *ngIf="!loading" class="wrapper">
    <header>
        Dataset Settings
    </header>

    <main>
        <section>
            <div class="setting-wrapper">
                <div class="header">
                    General Settings
                </div>
                <form [formGroup]="generalSettingsForm" (ngSubmit)="onGeneralSettingsSubmit()">
                    <mat-checkbox formControlName="extract_signatures">Extract Signatures</mat-checkbox>
                    <div class="checkbox-description">
                        This allow you to extract the signatures from the documents
                    </div>
                    <div class="row-actions">
                        <button [disabled]="isGeneralSettingsLoading" color="primary" mat-raised-button class="save-btn" type="submit">
                            <mat-spinner *ngIf="isGeneralSettingsLoading" diameter="20"></mat-spinner>
                            <span>{{isGeneralSettingsLoading ? 'Saving' : 'Save'}}</span>
                        </button>
                    </div>
                </form>
            </div>
            <div class="setting-wrapper">
                <div class="header">
                    Danger Zone
                </div>

                <p class="alert-msg">
                    Once you delete the dataset, all documents and parsed data will be lost. If you have <b>email
                        auto-forwarding</b> enabled, <b>turn it off before </b> deleting this inbox!
                </p>

                <div class="row-actions">
                    <button [disabled]="isDeleteLoading" class="delete-btn" mat-stroked-button (click)="openConfirmDialog()">
                        <mat-spinner *ngIf="isDeleteLoading" diameter="20"></mat-spinner>
                        <span>{{isDeleteLoading ? 'Deleting' : 'Delete'}} dataset</span>
                    </button>
                </div>
            </div>
        </section>
    </main>
</div>