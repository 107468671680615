import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, fromEvent } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Injectable()
export class SidenavService {
  private resize$: Observable<Event>;

  private _sidenavToggle$ = new Subject<any>();
  oSidenavToggle = this._sidenavToggle$.asObservable()

  constructor() {
    this.resize$ = fromEvent(window, 'resize');
  }


  toggleSidenav(value: any) {
    this._sidenavToggle$.next(value);
  }

  getScreenSize(): Observable<{ width: number, height: number }> {
    return this.resize$.pipe(
      startWith(null),
      map(() => ({
        width: window.innerWidth,
        height: window.innerHeight
      }))
    );
  }
}
