<div class="kanban" *ngIf="kanbanViewDataService?.oLoadingStatusResult | async as loadingState ">
    <ng-container *ngIf="(loadingState?.status === 'Busy')  || (loadingState?.status === null) ">
        <mat-spinner></mat-spinner>
    </ng-container>
    <div cdkDropList cdkDropListOrientation="horizontal" class="kanban-lists" [cdkDropListData]="lists"
        (cdkDropListDropped)="dropList($event)" *ngIf="loadingState?.status === 'Success' ">
        <kanban-list *ngFor="let list of lists;let i = index" cdkDrag [cdkDragDisabled]="isPreview" [list]="list"
            [kanbanViewState]="kanbanViewState" (listScroll)="addDataToList($event)">
        </kanban-list>
    </div>
    <ng-container *ngIf="loadingState?.status === 'Failure'">
        <div>
            <label>{{loadingState?.errorDescription}}</label>
        </div>
    </ng-container>
</div>