import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DatasetFileService } from '../../services/api/dataset-file.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StatusTypes, TempFile } from '../../models/common'

@Component({
    selector: 'custom-file-input',
    templateUrl: './custom-file-input.component.html',
    styleUrls: ['./custom-file-input.component.scss']
})
export class CustomFileInputComponent {
    @Input() isTest: boolean = false;
    @Input() datasetId: string;
    @Input() uploadFiles: boolean = true;
    @Input() isMultipleUpload: boolean = false;
    @Input() isUploading: boolean;
    @Input() inputMsg: string

    @Output() backendResult: EventEmitter<string> = new EventEmitter<string>();
    @Output() fileList: EventEmitter<FileList> = new EventEmitter<FileList>();

    tempFiles: TempFile[] = [];
    isDragOver = false;
    selectedFiles: FileList;
    constructor(
      private _datasetFileService: DatasetFileService,
      private _snackBar: MatSnackBar,
    ) { }

    onDragOver(event: DragEvent): void {
        if (this.isUploading) return;
        event.preventDefault();
        this.isDragOver = true;
    }

    onDragLeave(): void {
        if (this.isUploading) return;
        this.isDragOver = false;
    }

    onDrop(event: DragEvent): void {
        if (this.isUploading) return;
        event.preventDefault();
        this.isDragOver = false;
        this.selectedFiles = (event.dataTransfer?.files || []) as FileList;
        this._handleFileUpload();
    }

    onFileSelected(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        this.selectedFiles = (inputElement.files || []) as FileList;
        this._handleFileUpload();
    }

    private _handleFileUpload() {
        this.fileList.emit(this.selectedFiles);
        if (this.uploadFiles) this.onFileChange(this.selectedFiles);
    }

    public async onFileChange(files: FileList) {
        this.isUploading = true
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            this.tempFiles.push({
                id: null,
                name: file.name,
                fileId: null,
                status: StatusTypes.PROCESSING,
                url: ''
            });
            let formData = new FormData();
            formData.append(`inputFile`, file);
            formData.append('isTest', String(this.isTest));
            var response = await this._datasetFileService.upload(this.datasetId, formData).toPromise();
            if (response.isSuccess) {
                this.backendResult.emit(response.result);
                this._showSnackbar("File uploaded successfully");
            } else {
                this._showSnackbar(`Error uploading file ${this.tempFiles[i].name}. Try again`);
            }
            this.isUploading = false;
        }
    }

    private _showSnackbar(msg: string) {
        this._snackBar.open(msg, 'close', {
            duration: 3000,
            politeness: 'polite',
        });
    }
}