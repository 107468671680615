import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import {
  BaseController,
  DATE_OPERATION,
  FILTERING_OPERATION,
  LoadingState,
} from "@discoverer/core/services";

@Component({
  selector: "search-header-advanced-filter",
  styleUrls: ["./search-header-advanced-filter.component.scss"],
  templateUrl: "./search-header-advanced-filter.component.html",
})
export class SHAFComponent implements OnChanges, OnInit {
  @Input()
  filter;

  @Input()
  columnDictionary;

  @Input()
  level;

  @Input()
  advFilterService;

  @Input()
  separator;

  @Input()
  isOpen: boolean;

  loadingState: LoadingState;

  operation: string;
  title: string = null;

  parenthesesThreshold: number = 1;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  async ngOnInit() {
    // console.log("this.filter", this.filter);
    this.level = this.level++ || 0;
    // console.log(this.level);

    this.populateCalculatedMembers();
  }

  private populateCalculatedMembers() {
    if (this.isTypeIsCollection()) {
      this.operation = this.getSeparator()
      this.title = this.getTitle();
      this.separator = this.getSeparator()
    } else {
      this.operation = this.getFormattedOperation();
      this.title = this.getTitle();
    }
  }

  private getFormattedOperation() {
    this.advFilterService.init(this.filter, this.columnDictionary);
    const value = this.advFilterService.valueFromExpressions(this.filter);
    if (this.getColumn().isDynamicFacet == false) {
      this.advFilterService
        .getDisplayFromExpression(this.filter.expression, this.getColumn())
        .then((v) => value);
    }

    return this.formatAdvFilter(
      this.advFilterService.operationFromExpression(this.filter.expression[0], this.filter.fields[0]),
      value,
      this.filter.expression
    ) + this.getSeparator();

  }

  private getSeparator() {
    return ["facet", "text"].includes(this.filter.type)? "" : this.filter.type === "All" ? " AND" : " OR";
  }

  private isTypeIsCollection() {
    return this.filter.type == "Any" || this.filter.type == "All"
  }

  private getColumn() {
    if (this.filter.fields) {
      return this.columnDictionary[this.filter.fields[0]];
    }
    return null
  }

  private getTitle() {
    const column = this.getColumn()
    if (column) {
      return column.display;
    }
    return null
  }

  private formatAdvFilter(operation, value, originalExpression) {
    if (originalExpression[0].includes("NOW")) {
      return this.formatDateOperation(
        this.formatOperation(operation),
        value,
        originalExpression
      );
    } else {
      return `<b>${this.formatOperation(operation)}</b> ${
        value !== "NULL" ? value : ""
      }`;
    }
  }

  private formatOperation(operation) {
    return FILTERING_OPERATION.filter((o) => o.id === operation)[0].label;
  }

  private formatDateOperation(operation, value, originalExpression) {
    const secondaryOperation =
      this.advFilterService.secondaryOperationFromExpression(
        originalExpression[0]
      );
    const formatedS = DATE_OPERATION.filter(
      (o) => o.id === secondaryOperation
    )[0].label;
    return `<b>${operation}</b> ${value} ${formatedS}`;
  }

  isPresent(obj: any): boolean {
    return obj !== null && obj !== undefined && obj != "";
  }

  isFilterPrintable(): boolean {
    return this.isPresent(this.title) && this.isPresent(this.operation)
  }

  shouldPrintParentheses(): boolean {
    return this.level > this.parenthesesThreshold && this.filter.filters && this.filter.filters.length > 1
  }
}
