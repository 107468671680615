import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-custom-drop-down',
  templateUrl: './api-auto-complete.component.html',
  styleUrls: ['./api-auto-complete.component.scss']
})

export class ApiAutoCompleteComponent extends FieldType implements OnInit {
    public displayMember: string;
    public valueMember;
    public $options: Observable<any[]>;
    private filterBy: string;
    private dynOptionsSubject = new BehaviorSubject<any[]>([]);
    public filteredOptions: any[] = [];
    public dynOptions: any[] = [];
    public isLoading = false;

    constructor(public httpClient: HttpClient,
        private cdRef: ChangeDetectorRef) {
        super();
        this.$options = this.dynOptionsSubject.asObservable();
    }

    ngOnInit() {
        this.displayMember = this.field.templateOptions.labelProp;
        this.valueMember = this.field.templateOptions.valueProp;
        this.subscribeTofilter();
        this.subscribeToOptions();
        this.field.formControl.valueChanges.pipe(distinctUntilChanged()).subscribe(f => {
            if (f && typeof f !== 'string') {
                this.filteredOptions = [this.findOption(f)];
            } else {
                this.filter(f);
            }
        });
    }

    public getDisplayFn() {
        return (val: any) => {
            const option = this.findOption(val && this.valueMember && this.to.entireOptionValue ? val[this.valueMember] : val);
            return this.getDisplayValue(option);
        };
    }

    public getFiltered() {
        return this.filteredOptions;
    }

    public getDisplayValue(option) {
        return option ? option[this.displayMember] : '';
    }

    public subscribeTofilter() {
        this.filterBy = this.field.templateOptions.filterBy;
        const filterByValue = this.model ? this.model[this.filterBy] : null;
        if (this.filterBy) {
            if (this.form.get(this.filterBy)) {
                this.form.get(this.filterBy).valueChanges.pipe(debounceTime(100)).subscribe(q => {
                    if (q !== undefined && q !== null) {
                        this.getOptions(q);
                    } else {
                        this.dynOptionsSubject.next([]);
                        this.updateValueAndValidity(this.field, undefined);
                    }
                });
            }
            if (filterByValue !== undefined) {
                this.getOptions(filterByValue);
            }
        } else {
            this.getOptions(filterByValue);
        }
    }

    private findOption(item: any) {
        return this.dynOptions.find(z => z[this.valueMember] === item);
    }

    private filter(value: string) {
        if (value) {
            const filterValue = value.toLowerCase();
            this.filteredOptions = this.dynOptions.filter(option => this.getDisplayValue(option).toLowerCase().includes(filterValue));
            if (!this.filteredOptions.length && !this.findOption(value) && !this.field.templateOptions.allowNewOptions) {
                this.field.formControl.setErrors({ invalidSelection: true });
            } else {
                if (this.field.formControl.hasError('invalidSelection')) {
                    delete this.field.formControl.errors.invalidSelection;
                    this.field.formControl.updateValueAndValidity();
                }
            }
        } else {
            this.filteredOptions = this.dynOptions;
        }
    }
    private async subscribeToOptions() {
        this.isLoading = true;
        this.$options.subscribe(options => {
            this.isLoading = false;
            this.dynOptions = this.filteredOptions = options;
            if (this.dynOptions.length) {
                const currentOption = this.findOption(this.formControl.value);
                if (!currentOption) {
                    this.updateValueAndValidity(this.field, undefined);
                } else {
                    const key = Array.isArray(this.field.key) ? this.field.key[0] : this.field.key;
                    this.updateValueAndValidity(this.field, this.field.model[key]);
                    this.filter(this.getDisplayValue(currentOption));
                }
            }
        });
        this.dynOptions = this.filteredOptions = [];
        if (!this.cdRef['destroyed']) {
            this.cdRef.markForCheck();
        }
    }

    private async getOptions(filterBy?: any) {
        let options = this.field.templateOptions.options = [];
        if (this.field.templateOptions.url) {
            const response = await (this.httpClient.get(this.field.templateOptions.url).toPromise()) as any[];
            if (response && response.length) {
                const keys = Object.keys(response[0]).filter(x => x !== '__typename');
                if (!this.displayMember) {
                    this.displayMember = keys[1] ? keys[1] : keys[0];
                }
                if (!this.valueMember) {
                    this.valueMember = keys[0];
                }
                options = this.field.templateOptions.options = response;
            } else {
                this.updateValueAndValidity(this.field, null);
            }
            this.dynOptionsSubject.next(options);
        }
    }

    private updateValueAndValidity(field: FormlyFieldConfig, value: any, options?: Object) {
        field.formControl.setValue(value, options);
        field.formControl.markAsDirty();
        field.formControl.updateValueAndValidity();
    }

}
