<div class="report-actions">

    <div [popoverTrigger]="popover" *ngIf="canSwitchReport" [closeOnClickOutside]="true" matTooltip="Open Report"
        class="icon-button-small">
        <mat-icon class="small-icon">folder_open</mat-icon>
    </div>
    <div matTooltip="Get Link" class="icon-button-small" (click)="shareCurrentReport()">
        <mat-icon class="small-icon">link </mat-icon>
    </div>
    <div *ngIf="canExportReport && exportReport?.reportType != 'pivot-view'">
        <div *ngIf="tableHeaderConfig?.resultLength <= MAX_EXCEL_SIZE && (exportReport.exportSevice.oLoadingStatusResult | async).status != 'Busy'"
            matTooltip="Export Data" class="icon-button-small"
            (click)="exportReport.exportToExcel(MAX_EXCEL_SIZE)">
            <mat-icon class="small-icon">save_alt</mat-icon>
        </div>
        <div *ngIf="tableHeaderConfig?.resultLength > MAX_EXCEL_SIZE  && (exportReport.exportSevice.oLoadingStatusResult | async).status != 'Busy'"
            matTooltip="Export Data" class="icon-button-small"
            [matMenuTriggerFor]="exportMenu">
            <mat-icon class="small-icon">save_alt</mat-icon>
        </div>
        <div *ngIf="(exportReport.exportSevice.oLoadingStatusResult | async).status == 'Busy'">
            <mat-spinner [diameter]="20"></mat-spinner>
        </div>
        <mat-menu #exportMenu="matMenu" class="custom-menu">
            <button (click)="exportReport.exportToExcel(MAX_EXCEL_SIZE)" matTooltip="The maximum number of records in Excel is {{MAX_EXCEL_SIZE}}." mat-menu-item>
                <mat-icon class="small-icon">insert_drive_file</mat-icon>
                Export to Excel
            </button>
            <button (click)="exportReport.exportToExcel(tableHeaderConfig?.resultLength)" mat-menu-item>
                <mat-icon class="small-icon">insert_drive_file</mat-icon>
                Export to CSV
            </button>
        </mat-menu>
    </div>
    <export-report #exportReport *ngIf="true" [currentTab]="currentTab" [queryService]="queryService">
    </export-report>

    <button class="icon-button-small" matTooltip="Add to Dashboard" *ngIf="currentApp?.defaultDashboard"
        [popoverTrigger]="dashboardListPopover" [overlayX]="'center'" [closeOnClickOutside]="true">
        <mat-icon class="small-icon"> dashboard_customize </mat-icon>
    </button>

    <div class="icon-button-small" matTooltip="Save" *ngIf="canEditReport" (click)="openSaveReportDialog(true)">
        <mat-icon class="small-icon"> save </mat-icon>
    </div>
    <div class="icon-button-small" matTooltip="Save As" *ngIf="canAddReport" (click)="openSaveReportDialog(false)">
        <mat-icon class="small-icon"> save_as </mat-icon>
    </div>
    <div class="icon-button-small" matTooltip="Delete" *ngIf="canDeleteReport" (click)="deleteReport()">
        <mat-icon class="small-icon"> delete </mat-icon>
    </div>
    <div class="icon-button-small" matTooltip="Filter View" (click)="switchFilter()">
        <mat-icon class="small-icon"> {{reportPersistService.mainViewState.getState().isVerticalFacets? 'horizontal_split' :'vertical_split'}} </mat-icon>
    </div>

    <ng-template #dashboardListPopover>
        <dashboards-list [currentAppKey]="currentTab.app.key" [reportType]="'dashboard-view'" [canEdit]="canEditReport"
            [editable]="false" (hideReportsEvent)="closePopover()" (selectedDashboard)="addToDashboard($event)">
        </dashboards-list>
    </ng-template>
    <ng-template #popover>
        <reports [currentAppKey]="currentTab.app.key" [hidden]="false" (personalDirectoryId)="directoryId = $event"
            (hideReportsEvent)="closePopover()" (saveReport)="openSaveReportDialog($event)"
            (deleteReport)="deleteReport()">
        </reports>
    </ng-template>
</div>

