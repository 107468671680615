import { Component, ViewEncapsulation, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface IConfirmDialog {
  excelNumber: number;
  csvNumber: number;
}

@Component({
  selector: 'app-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss'],
})

export class ExportDialogComponent implements OnInit {

  excelNumber: number;
  csvNumber: number;

  constructor(
      private dialogRef: MatDialogRef<ExportDialogComponent>,
      @Inject(MAT_DIALOG_DATA) private dialogData: IConfirmDialog
  ) {
  }

  public ngOnInit(): void {
      this.excelNumber = this.dialogData.excelNumber;
      this.csvNumber = this.dialogData.csvNumber;
  }

  public close(isConfirmed) {
      this.dialogRef.close(isConfirmed);
  }
  public save(numberOfRow: number) {
      this.close(numberOfRow);
  }

}
