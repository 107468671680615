import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Moment } from 'moment';
import { IDateOperation, IFilterOperation } from "@discoverer/core/services/classes/advanced-filters";
import { DATE_OPERATION, DATE_OPR_DICTIONARY } from '../../../services';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'date-operation-field',
    templateUrl: './date-operation-field.component.html',
    styleUrls: ['./date-operation-field.component.scss'],
    providers: [DatePipe]
})
export class DateOperationField implements OnInit, OnChanges{

    @Input()
    numericMinOrMaxOperations: IFilterOperation[];
    @Input()
    valueIsInclusive: boolean;
    @Input()
    value: number;
    @Input()
    valueDate: Moment;
    @Input()
    dateOperationShortcut: IDateOperation;

    @Output()
    valueIsInclusiveChange = new EventEmitter<boolean>();
    @Output()
    numberValueChange = new EventEmitter<number>();
    @Output()
    dateValueChange = new EventEmitter<any>();
    @Output()
    dateOperationShortcutChange = new EventEmitter<IDateOperation>();

    public minMaxOperationWithEqual: IFilterOperation ;
    public minMaxOperationWithoutEqual: IFilterOperation;
    public OPERATION_SHORTCUTS = DATE_OPERATION;
    public DATE_OPR_DICTIONARY = DATE_OPR_DICTIONARY;
    public selectedShortcut = "THE_DATE";


    constructor(private datePipe: DatePipe) { }

    ngOnInit(): void {
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (this.minMaxOperationWithEqual === undefined) {
        this.minMaxOperationWithEqual = this.numericMinOrMaxOperations.filter(op => op.id.includes("EQUALS"))[0];
        }
        if (this.minMaxOperationWithoutEqual === undefined) {
            this.minMaxOperationWithoutEqual = this.numericMinOrMaxOperations.filter(op => !op.id.includes("EQUALS"))[0];
        }
        if (changes.dateOperationShortcut) {
            this.selectedShortcut = this.dateOperationShortcut.id;
        }
    }
    public emitValueIsInclusiveChangeEvent(value: boolean) {
        this.valueIsInclusiveChange.emit(value);
    }

    public emitdateOperationShortcutChangeEvent(event) {
        var value: IDateOperation = this.OPERATION_SHORTCUTS.filter(sh => sh.id === event.value)[0];
        this.selectedShortcut = value.id;
        this.dateOperationShortcutChange.emit(value);
    }

    public emitNumberValueChangeEvent(value: number) {

        this.numberValueChange.emit(value);
    }

    public emitDateValueChangeEvent(event: any) {
        let timezone = String(event.value?._d).substring( String(event.value?._d).indexOf("(")+1, String(event.value?._d).indexOf(")"));
        let dateValue = this.datePipe.transform(event.value, 'yyyy-MM-dd', timezone) + 'T00:00:00Z';
        this.dateValueChange.emit(dateValue);
    }
 }
