import { Component, forwardRef, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseCustomController } from '../shared/base-custom-controller';
import { PhonePipe } from '../shared/phone-pipe';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'tel',
    template: `<input matInput type="text" [tabIndex]="to?.tabindex"
     (blur)="onBlur()" [formControl]="telControl"/> `,
    styleUrls: [],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TelFieldType),
            multi: true
        },
        PhonePipe
    ]
})
// tslint:disable-next-line:component-class-suffix
export class TelFieldType extends BaseCustomController implements OnInit {
    constructor(private pipe: PhonePipe) {
        super();
    }

    telControl = new FormControl();
    ngOnInit() {
        this.telControl.setValue(this.pipe.transform(this.field.formControl.value));
        this.setControlStatus();
        this.field.formControl.valueChanges.subscribe(x => {
            this.telControl.setValue(this.pipe.transform(x), { emitEvent: false });
        });
        this.field.formControl.statusChanges.subscribe(x => {
            this.setControlStatus();
        });
    }

    public onBlur() {
        const inputVal = this.telControl.value;
        const value = inputVal ? inputVal.replace('-', '').replace('(', '').replace(')', '') : inputVal;
        this.updateValueAndValidity(this.field, value, { emitEvent: false });
        const key = Array.isArray(this.field.key) ? this.field.key[0] : this.field.key;
        this.model[key] = value;
    }
    private setControlStatus() {
        if (this.to && this.to.disabled) {
            this.telControl.disable();
        } else {
            this.telControl.enable();
        }
    }
}
