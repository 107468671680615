<div class="home-wrapper">
    <div class="header">
        <mat-toolbar color="primary">
            <div class="left-item">
                <div class="logo" *ngIf="!toolbarSettings?.showBackButton"> </div>
                <div class="back-item" *ngIf="toolbarSettings?.showBackButton" (click)="back()">
                    <mat-icon>arrow_back</mat-icon>

                </div>
                <div class="title-wrapper">
                    <div class="flex gap-2 items-center dataset-title-wrapper">
                        <span class="app-name">{{ toolbarSettings?.title }}</span>
                        <mat-icon class="icon" *ngIf="toolbarSettings?.showBackButton"
                            (click)="editDataSetTitleDialog()">edit</mat-icon>
                    </div>
                    <span *ngIf="toolbarSettings?.subTitle" class="text-subtitle">
                        {{ toolbarSettings?.subTitle }}
                        <mat-icon class="copy-icon" *ngIf="!isSubTitleCopied"
                            (click)="copyEmail(toolbarSettings?.subTitle, 'subTitle')"
                            matTooltip="Copy">content_copy</mat-icon>
                        <mat-icon class="check-icon" *ngIf="isSubTitleCopied">check</mat-icon>
                    </span>

                </div>
                <div class="reprocess-btn-container" *ngIf="toolbarSettings?.showBackButton">
                    <app-reprocess-action  [datasetId]="datasetId"></app-reprocess-action>
                    <button mat-flat-button color="primary" class="import-doc"  (click)="openDialog()">
                        <mat-icon>add</mat-icon>Import Document
                    </button>
                </div>
            </div>

            <span class="example-spacer"></span>
            <div class="right-item">
                <!-- Button -->
                <button color="basic" mat-fab class="user-info" [matMenuTriggerFor]="userActions">
                    <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu [xPosition]="'before'" class="user-actions" #userActions="matMenu">
                    <button mat-menu-item>
                        <span class="flex flex-col leading-none">
                            <span>Signed in as</span>
                            <span class="mt-1.5 text-md font-medium">{{user?.email}}</span>
                        </span>
                    </button>
                    <mat-divider class="my-2"></mat-divider>
                    <button mat-menu-item [routerLink]="['/user-settings', 'profile']">
                        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
                        <span>Profile</span>
                    </button>
                    <button mat-menu-item [routerLink]="['/user-settings', 'password']">
                        <mat-icon [svgIcon]="'heroicons_outline:lock-closed'"></mat-icon>
                        <span>Password</span>
                    </button>
                    <button mat-menu-item [routerLink]="['/user-settings', 'plans']">
                        <!-- <mat-icon [svgIcon]="'heroicons_outline:banknotes'"></mat-icon> -->
                        <mat-icon>payment</mat-icon>
                        <span>Billing and Plans</span>
                    </button>
                    <!-- <button mat-menu-item>
                        <mat-icon [svgIcon]="'heroicons_outline:table-cells'"></mat-icon>
                        <mat-icon> table_chart</mat-icon>
                        <span>Billing History</span>
                    </button> -->
                    <button mat-menu-item [routerLink]="['/user-settings', 'usage']">
                        <mat-icon [svgIcon]="'heroicons_outline:chart-pie'"></mat-icon>
                        <span>Usage</span>
                    </button>

                    <mat-divider class="my-2"></mat-divider>
                    <button mat-menu-item (click)="signOut()">
                        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
                        <span>Sign out</span>
                    </button>
                </mat-menu>

            </div>
        </mat-toolbar>
    </div>

    <div class="main">
        <router-outlet></router-outlet>
    </div>
</div>
