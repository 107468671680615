import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { SIDEBAR_ITEMS, VerticalSidebarService } from './services/vertical-sidebar.service';
import { Subscription, interval } from 'rxjs';
import { AuthService, UserData, UserDataService } from '@discoverer/app-core';
import { BaseController, DiscovererQueryService } from '@discoverer/core';
import { IToolbarSettings, ToolbarService } from '../../../services/toolbar.service';
import { MatDialog } from '@angular/material/dialog';
import { EditTitleDialogComponent } from '../../home/edit-title-dialog/edit-title-dialog';
import { DatasetService } from '../../../services/api/dataset.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MultiFileUploaderComponent } from '../../attoms/multi-file-uploader/multi-file-uploader.component';
import { EmitterService } from '../../../services/emitter.service';



@Component({
  selector: 'app-vertical-sidebar',
  templateUrl: './vertical-sidebar.component.html',
  styleUrls: ['./vertical-sidebar.component.scss']
})
export class VerticalSidebarComponent extends BaseController implements OnInit, OnDestroy {

  isUpdating: boolean = false
  queueLength: number
  resultsLength: number
  subs: Subscription[] = []
  productKey: string = environment.auth.productKey
  datasetId: string;
  sidebarItems = SIDEBAR_ITEMS;
  user: UserData;
  toolbarSettings: IToolbarSettings = { showBackButton: false, title: 'Dataset' };
  isEmailCopied: boolean = false;
  isSubTitleCopied: boolean = false;

  constructor(
    public queryService: DiscovererQueryService,
    public emitterService: EmitterService,
    private _sidebarService: VerticalSidebarService,
    private _toolbarService: ToolbarService,
    private _userService: UserDataService,
    private _authService: AuthService,
    private _datasetService: DatasetService,
    private _router: Router,
    private dialog: MatDialog,
    private clipboard: Clipboard,
    private _activated: ActivatedRoute) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.subscriptions.push(this._userService.loggedInUser
      .subscribe((user: UserData) => this.user = user));

    this.subscriptions.push(this._toolbarService.oToolbarSettings
      .subscribe((t: IToolbarSettings) => this.toolbarSettings = t));

    this.datasetId = this._activated.snapshot.params['datasetId'];

    await this._setDatasetCounts();
    this.subs.push(interval(4000).subscribe(
      async () => {
        await this._setDatasetCounts();
      }));
  }

  public signOut() {
    const redirectUrl = this._router.url;
    this._authService.goToLogin(redirectUrl);
  }

  public editDataSetTitleDialog() {
    const datasetId = this.datasetId
    const templateName = this.toolbarSettings?.title

    const dlg = this.dialog.open(EditTitleDialogComponent, {
      width: '650px',
      data: { datasetId, templateName },
      autoFocus: true
    });
    dlg.afterClosed().subscribe(async resp => {
      if (!!resp?.templateName) {
        const baseDatasetDetails = await this._datasetService.getCurrentBaseDatasetDetails()
        this._datasetService.updateDatasetDetails({ ...baseDatasetDetails, Title: resp?.templateName })
        this.toolbarSettings.title = resp?.templateName
      }
    })
  }

  public openImportDocument() {
    this.dialog.open(MultiFileUploaderComponent, { width: '800px', data: { datasetId: this.datasetId }, disableClose: false })
      .afterClosed().subscribe((val) => this.queryService.refresh());
  }


  public back() {
    this._router.navigate(['datasets']);
  }

  public copyEmail(email: string, type: string) {
    this.clipboard.copy(email);
    if (type === 'email') {
      this.isEmailCopied = true;
    } else if (type === 'subTitle') {
      this.isSubTitleCopied = true;
    }

    // Reset the copied status after 5 seconds
    setTimeout(() => {
      if (type === 'email') {
        this.isEmailCopied = false;
      } else if (type === 'subTitle') {
        this.isSubTitleCopied = false;
      }
    }, 4000);
  }

  private async _setDatasetCounts() {
    if (this.datasetId) {
      const result = await this._sidebarService.getDatasetCounts(this.datasetId);
      this.sidebarItems.find(i => i.id == 'pipeline')?.children.forEach(child => {
        if (child.id == 'queue') child.badge = String(result.queueLength);
        else if (child.id == 'records') child.badge = String(result.resultLength);
      });
    } else {
      console.warn("Dataset Id is not configured!")
    }

  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe())
    this.sidebarItems.find(i => i.id == 'pipeline')?.children.forEach(child => {
      child.badge = String(0)
    })
  }
}
