import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MultiFileUploaderComponent } from '../../attoms/multi-file-uploader/multi-file-uploader.component';
import { EmitterService } from '../../../services/emitter.service';
import { DiscovererQueryService } from '@discoverer/core';
import { ExportReportComponent } from '@discoverer/dynamic-reports/common/export-to-excel/export.component';
import { TableHeaderService } from '@discoverer/core/services/dynamic-reports-services/table-header.service';

@Component({
  selector: 'app-queue-actions',
  templateUrl: './queue-actions.component.html',
  styleUrls: ['./queue-actions.component.scss']
})
export class QueueActionsComponent implements OnInit {
  @ViewChild('exportReport', { static: false }) exportReport: ExportReportComponent;

  datasetId: string
  constructor(
    private _activated: ActivatedRoute,
    public dialog: MatDialog,
    public queryService: DiscovererQueryService,
    public tableHeaderService: TableHeaderService,
    private _emitterService: EmitterService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.datasetId = this._activated.snapshot.params['datasetId'];
    if (!this.datasetId) this._emitterService.oDatasetId.subscribe((val) => this.datasetId = val)
  }

  openDialog() {
    this.dialog.open(MultiFileUploaderComponent, { width: '800px', data: { datasetId: this.datasetId }, disableClose: false })
      .afterClosed().subscribe((val) => this.queryService.refresh() );
  }
}
