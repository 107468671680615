
    <div class="report-main-grid">
        <disco-tab  [appId]="appId" [tabId]="tabId">
            <ng-template let-tabContext>
                <!-- <textarea rows="20" cols="60">{{tabContext?.currentTab | json}}</textarea> -->
                <disco-report (context)="setContext($event)" [queryService]="queryService" *ngIf="tabContext" 
                    [requestId]="requestId">
                    <ng-template let-reportContext>
                        <!-- <textarea rows="20" cols="60">{{reportContext?.currentReport | json}}</textarea>  -->
                        <div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden noselect">
                            <workspace [hideEditColumn]="hideEditColumn" [enableSelect]="enableMultipleSelect"
                                (rowClick)="navigateToDetail($event, tabContext.currentTab?.businessKey)"></workspace>

                        </div>
                    </ng-template>
                </disco-report>
            </ng-template>
        </disco-tab>
    </div>

