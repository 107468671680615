import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, UserData, UserDataService } from '@discoverer/app-core';
import { AppSettingsService, BaseController, DiscovererQueryService } from '@discoverer/core';
import { DatasetService } from '../../services/api/dataset.service';
import { EmitterService } from '../../services/emitter.service';
import { GeneralSettingsService } from '../../services/general-settings.service';
import { SchemaService } from '../../services/schema.service';
import { IToolbarSettings, ToolbarService } from '../../services/toolbar.service';
import { MultiFileUploaderComponent } from '../attoms/multi-file-uploader/multi-file-uploader.component';
import { EditTitleDialogComponent } from './edit-title-dialog/edit-title-dialog';
import { environment } from '@env/environment';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseController implements OnInit {
    user: UserData;
    toolbarSettings: IToolbarSettings = { showBackButton: false, title: 'Dataset' };
    isSubTitleCopied: boolean = false;
    isEmailCopied = false;
    datasetId: string;
    totalFileCount: number;

    constructor(
        public queryService: DiscovererQueryService,
        public emitterService: EmitterService,
        private _userService: UserDataService,
        private _toolbarService: ToolbarService,
        private _router: Router,
        private _authService: AuthService,
        private clipboard: Clipboard,
        private dialog: MatDialog,
        private _generalSettingsService: GeneralSettingsService,
        private _activated: ActivatedRoute,
        private _datasetService: DatasetService,
        private schemaService: SchemaService,
        private _appSettingsService: AppSettingsService
    ) { super(); }

    async ngOnInit(): Promise<void> {
        await this._appSettingsService.setActiveApp(environment.auth.productKey);
        await this._toolbarService.setSettings(null, this._activated.routeConfig.data)
        this.subscriptions.push(this._userService.loggedInUser
            .subscribe((user: UserData) => this.user = user));

        this.subscriptions.push(this._toolbarService.oToolbarSettings
            .subscribe((t: IToolbarSettings) => this.toolbarSettings = t));

        this.subscriptions.push(this.emitterService.oDatasetId
            .subscribe(async (value: string) => {
                this._generalSettingsService.setDatasetId(value);
                await this.schemaService.updateDatasetSchema(value)
                this.datasetId = value;
            }));

    }


    public signOut() {
        const redirectUrl = this._router.url;
        this._authService.goToLogin(redirectUrl);
    }

    public back() {
        this._router.navigate(['datasets']);
    }

    openDialog() {
        this.dialog.open(MultiFileUploaderComponent, { width: '800px', data: { datasetId: this.datasetId }, disableClose: false })
            .afterClosed().subscribe((val) => this.queryService.refresh());
    }
    copyEmail(email: string, type: string) {
        this.clipboard.copy(email);
        if (type === 'email') {
            this.isEmailCopied = true;
        } else if (type === 'subTitle') {
            this.isSubTitleCopied = true;
        }

        // Reset the copied status after 5 seconds
        setTimeout(() => {
            if (type === 'email') {
                this.isEmailCopied = false;
            } else if (type === 'subTitle') {
                this.isSubTitleCopied = false;
            }
        }, 4000);
    }



    public editDataSetTitleDialog() {
        const datasetId = this.datasetId
        const templateName = this.toolbarSettings?.title

        const dlg = this.dialog.open(EditTitleDialogComponent, {
            width: '650px',
            data: { datasetId, templateName },
            autoFocus: true
        });
        dlg.afterClosed().subscribe(async resp => {
            if (!!resp?.templateName) {
                const baseDatasetDetails = await this._datasetService.getCurrentBaseDatasetDetails()
                this._datasetService.updateDatasetDetails({...baseDatasetDetails, Title: resp?.templateName})
                this.toolbarSettings.title = resp?.templateName
            }
        })
    }
}
