<div class="stats-container" *ngIf="canAddStat">
    <div class="stat-group" *ngFor="let Stat of statsResult;  index as i" (click)="editStat(i)">
        <div class="stat-items">
            <div class="state-info">
                <div class="stat-item">{{Stat.result}}</div>
                <div class="stat-title">
                    <div *ngIf="Stat?.label else DEFAULT" class="stat-name">{{Stat.label}}</div>
                    <ng-template #DEFAULT>
                        <div class="stat-name">
                            {{Stat?.functionType?.toUpperCase()}} of {{Stat?.colName}}</div>
                    </ng-template>
                </div>
            </div>

            <div class="stat-action">
                <button class="close-btn" mat-icon-button (click)="removeStat(i)">
                    <mat-icon class="close-icon">close</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div (click)="openStateDialog()" class="add-new-stat" *ngIf="stats.length < 4">
        <mat-icon class="add-icon" matTooltip="Add Big Number">add_circle_outline</mat-icon>
    </div>
</div>
