<div class="search-header-container header">
    <div class="line1">
        <div *ngIf="(queryService.oQuery | async) as currentQuery" class="filter-groups" layoutDir="row">
            <div class="groups-wrapper">
                <button ml-2 matTooltip="Filter" mat-icon-button color="secondary" [matMenuTriggerFor]="filterList"
                    (click)="$event.preventDefault(); filterInput.focus()">
                    <mat-icon>filter_list
                    </mat-icon>
                </button>
                <mat-menu class="filterList" yPosition="below" #filterList="matMenu">
                    <div (click)="$event.stopPropagation();">
                        <mat-form-field class="filter-input add-filters">
                            <input (keyup)="$event.preventDefault()" #filterInput type="text" placeholder="Filter By"
                                autocomplete="off" matInput [formControl]="filtersListControl">
                        </mat-form-field>
                    </div>
                    <div class="cardFilterList">
                        <div style="max-height: 300px; overflow-y: scroll;">
                            <button (focus)="filterInput.focus()" mat-menu-item
                                *ngFor="let option of filteredcolumns | async"
                                (click)="addEmptyFilter(option.display);">
                                {{option?.display}}
                            </button>
                        </div>
                        <div style="background-color: #f9f9f9;">
                            <button (click)="showAdvancedModal()" class="m-2" mat-raised-button color="primary">
                                Advanced
                            </button>
                            <button (click)="openFiltersClick.emit(true)" class="m-2" mat-raised-button color="primary"
                                style="opacity: 0.9;">
                                Show All
                            </button>
                        </div>
                    </div>
                </mat-menu>
            </div>
        </div>
    </div>
</div>
