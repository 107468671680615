import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IInsertDBOptionDTO, IOption } from '../models/option';
import { IOptionList } from '../models/option-list';

export interface OptionListBlocInput {
    optionListId: number;
    searchText: string;
}
export abstract class OptionListDataServiceInterface {
    abstract get(search: string): Observable<any>;
    abstract add(list: IOptionList): Observable<any>;
    abstract update(list: IOptionList): Observable<any>;
    abstract delete(key): Observable<any>;
}

export abstract class OptionDataServiceInterface {
    abstract getByOptionListId(id: number, search?: string): Observable<IOption[]>;
    abstract getOptionsByListName(listName: string): Observable<IOption[]>;
    abstract getOptionById(id: number): Observable<IOption>;
    abstract getOptionIdByName(name: string): Promise<number>;
    abstract getOptionByOrder(order: number, optionListId: number): Observable<IOption>;
    abstract updateOptionOrder(option: IOption);
    abstract add(object: IOption): Observable<any>;
    abstract update(object: IOption): Observable<any>;
    abstract delete(option: IOption): Observable<any>;
    abstract getByQuery(query: string): Observable<any[]>;
    //abstract addMultiple(options: IInsertDBOptionDTO[]): Observable<any>;
}
export abstract class GraphQlOptionDataServiceInterface {
    abstract getByOptions(query: string, filterBy?: any, fieldName?: string ): Observable<any[]>;

}
