import { HttpClient } from "@angular/common/http";
import { Apollo } from "apollo-angular";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import { distinctUntilChanged, first, last } from "rxjs/operators";
import {
    DRQuery,
    DRResult,
    IColumnSetting,
    LoadingState,
    IKanbanViewState,
    IList,
    List,
    ITab2,
    DRFacetOption,
    FacetValue,
    DRFilter,
    ICard,
    FieldNameAndValue,
    Card,
} from "../classes";
import { DataFormattingService } from "../dynamic-reports-services";
import { StateService } from "../state";
import { BaseDataFacetService } from "./base-data-facet.service";
import {
    DiscovererDataService,
    ExactDataRow,
} from "./discoverer-data-service.service";
import { EsQueryService } from "./discoverer-esquery-service";
import {
    DiscovererFacetTranslationService,
    FacetTranslationInfo,
} from "./discoverer-facet-translation-service.service";
import { DiscovererQueryService } from "./discoverer-query-service.service";

export class KanbanViewDataService extends BaseDataFacetService {
    public oLoadingStatusResult: Observable<LoadingState>;
    public $loadingStatusResult = new BehaviorSubject<LoadingState>({ status: "NoStatus" });
    public $oListsWithCards: Subject<IList>;
    public $oAllListsWithCards: Subject<IList[]>;

    private esQueryService: EsQueryService;
    private dataFormattingService: DataFormattingService;
    constructor(
        private http: HttpClient,
        private apollo: Apollo,
        private queryService: DiscovererQueryService,
        private kanbanViewState: StateService<IKanbanViewState>,
        private tabcolumns: IColumnSetting[],
        private tabServiceUrl: string,
        private tab: ITab2
    ) {
        super(tabcolumns, tabServiceUrl);
        this.$oListsWithCards = new Subject<IList>();
        this.$oAllListsWithCards = new Subject<IList[]>();
        this.oLoadingStatusResult = this.$loadingStatusResult.asObservable();
    }

    public intKanbanViewListCardsDataService(
        esQueryService: EsQueryService,
        dataFormattingService: DataFormattingService
    ) {
        this.esQueryService = esQueryService;
        this.dataFormattingService = dataFormattingService;
        if (this.queryService != null) {
            this.subscriptions.push(
                this.queryService.oQuery
                    .pipe(
                        distinctUntilChanged((prev, curr) => {
                            return !this.isDifferentQuery(prev, curr);
                        })
                    )
                    .subscribe(async (query) => {
                        this.$loadingStatusResult.next({status: 'Busy'});
                        const lists = this.kanbanViewState?.getState()?.listsInfo?.filter(l => l.isShowing);
                        if (lists) {
                            lists.forEach(async (list) => {
                                list.cards = [];
                                const cards = await this.getCards(query, list, 10);
                                list.cards = cards;
                                this.$oListsWithCards.next(list);
                            });
                        }
                        this.$loadingStatusResult.next({status: 'Success'});
                    })
            );

            this.subscriptions.push(
                this.kanbanViewState.oState
                    .pipe(
                        distinctUntilChanged((prev, curr) => {
                            return !this.isDifferentKanbanState(prev, curr);
                        })
                    )
                    .subscribe(async (kanbanState) => {
                        this.$loadingStatusResult.next({status: 'Busy'});
                        let lists = kanbanState?.listsInfo?.filter(l => l.isShowing);
                        if (lists) {
                            const query = await this.queryService.oQuery
                                .pipe(first())
                                .toPromise();
                            for await (const list of lists) {
                                list.cards = [];
                                const cards = await this.getCards(query, list, 10);
                                list.cards = cards;
                            }
                            this.$oAllListsWithCards.next(lists);
                        }
                        this.$loadingStatusResult.next({status: 'Success'});
                    })
            );
        }
    }

    public async getCards(
        query: DRQuery,
        list: IList,
        pageSize: number
    ): Promise<ICard[]> {
        let queryService: DiscovererQueryService = new DiscovererQueryService(
            "kanban-view"
        );
        let dataService: DiscovererDataService<ExactDataRow>;
        dataService = new DiscovererDataService(this.http, this.esQueryService, {
            create: (row) => [row],
        });
        dataService.init(
            this.tabServiceUrl,
            queryService,
            "?data=kanban-view-list-cards&qname=main"
        );
        dataService.setFields([
            ...new Set(
                this.kanbanViewState
                    .getState()
                    .columnSettings.map((col) => col.fieldName)
                    .concat(this.tab.businessKey)
            ),
        ]);
        query.filters.forEach((f) => dataService.setFilter(f.fields[0], f));
        query.sorts.forEach((s) => dataService.setOrderBy(s.sortField, s.dir));
        query.facets.forEach((f) => dataService.setFacet(f.name, f));
        query.stats.forEach((s) => dataService.setStat(s.field, s.functionType));
        const groupByColumnName =
        this.kanbanViewState.getState().groupByColumn.field;
        const type = this.columns[groupByColumnName]?.type;
        let timestampISO = list.id
        if(type === 'date'){
            timestampISO =  `[${new Date(+list.id).toISOString()} TO ${new Date(+list.id).toISOString()}]`
        };
        const dRFacetOption = new DRFacetOption( timestampISO, timestampISO, timestampISO);
        const facetValue = new FacetValue(dRFacetOption);
        dataService.setFilter(
            groupByColumnName,
            new DRFilter("facet", [groupByColumnName], [facetValue])
        );
        if (list.cards.length == 0) {
            dataService.setPageStart(0, pageSize);
            return await this.refreshDataServiceAndGetCards(dataService);
        } else {
            dataService.setPageStart(pageSize, 10);
            if (list.cards.length != list.cardsCount) {
                return await this.refreshDataServiceAndGetCards(dataService);
            } else {
                return [];
            }
        }
    }

    private async refreshDataServiceAndGetCards(
        dataService: DiscovererDataService<ExactDataRow>
    ) {
        dataService.refresh(true);
        const listCards = await dataService.oData.pipe(first()).toPromise();
        return this.mapListCardsData(listCards);
    }
    private mapListCardsData(data: any[]): ICard[] {
        var cards: ICard[] = [];
        var cardsData = data;
        if (cardsData.length > 0) {
            cardsData.forEach((data) => {
                const columnToDisplay = this.kanbanViewState
                    .getState()
                    .columnSettings.sort((column) => column?.displayOrder)
                    .map((col) => col.fieldName);
                const headerColumn = columnToDisplay[0];
                var cardHtmlContent: FieldNameAndValue[] = [];
                columnToDisplay.slice(1).forEach(async (col) => {
                    var htmlContent = new FieldNameAndValue();
                    const columnSetting = this.tabcolumns.find((c) => c.fieldName == col);
                    htmlContent.fieldName = columnSetting?.display;
                    var columnValue = data.source[col];
                    if (columnSetting.isDynamicFacet == false) {
                        const facetInfo = await this.getFacetTranslation(columnSetting);
                        if (facetInfo != null) {
                            columnValue = this.getTranslationValue(
                                facetInfo,
                                data.source[col]
                            );
                        }
                    }
                    htmlContent.fieldValue = this.dataFormattingService.getValue(
                        data.source[col],
                        columnSetting.dataType
                    );
                    cardHtmlContent.push(htmlContent);
                });
                var card = new Card(
                    data.source[this.tab.businessKey],
                    data.source[headerColumn],
                    cardHtmlContent
                );
                cards.push(card);
            });
        }
        return cards;
    }

    private async getFacetTranslation(
        column: IColumnSetting
    ): Promise<FacetTranslationInfo[]> {
        let facetTranslationService = new DiscovererFacetTranslationService(
            this.http,
            this.apollo
        );
        const queryInfoServiceUrl = this.tab.facetTranslationUrl;
        await facetTranslationService.init(
            `${queryInfoServiceUrl}/${column.fieldName}/${column.tableName}`,
            column?.form?.templateOptions?.optionsListId
        );
        const facetInfo = facetTranslationService.facetTranslationInfo;
        return facetInfo;
    }

    private getTranslationValue(facetInfo: FacetTranslationInfo[], value) {
        if (Array.isArray(value)) {
            const display = value?.map((e) => {
                const info = facetInfo?.find((fi) => fi.Id === e);
                return info?.Display;
            });
            if (display) {
                return display.join(", ");
            } else {
                return "";
            }
        } else {
            const info = facetInfo?.find((fi) => fi.Id === value);
            if (info) return info.Display;
            else return "";
        }
    }

    public isDifferentKanbanState(
        prev: IKanbanViewState,
        curr: IKanbanViewState
    ): boolean {
        if (!prev.groupByColumn || !curr.groupByColumn) return true;
        if (prev?.groupByColumn?.field != curr?.groupByColumn?.field) return true;
        if (this.isDifferentArrays(prev.columnSettings, curr.columnSettings))return true;
        if (prev.sorts != curr.sorts)return true
        const prevKanbanLists = prev.listsInfo.filter((c) => c.isShowing === true);
        const currKanbanLists = curr.listsInfo.filter((c) => c.isShowing === true);
        if (this.isDifferentArrays(prevKanbanLists.map(l => l.name), currKanbanLists.map(l => l.name))) return true;
        if (this.isDifferentArrays(prevKanbanLists.map(l => l.order), currKanbanLists.map(l => l.order))) return true;



        return false;
    }

    public isDifferentQuery(prev: DRQuery, curr: DRQuery) {
        return this.isDifferentArrays(prev.filters, curr.filters);
    }

    private isDifferentArrays(firstArray: any, secondArray: any): boolean {
        if (firstArray == null || secondArray == null) return false;
        else if (firstArray.length !== secondArray.length) return true;
        else
            for (var index = 0; index < firstArray.length; ++index) {
                if (firstArray[index] !== secondArray[index]) return true;
            }
        return false;
    }
}
