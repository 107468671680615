
import { DateGroupBy, GroupBy, NumberGroupBy } from '@discoverer/core/services';
import { BaseController, IColumnSetting } from '@discoverer/core/services';
import { ChartAxis } from '../models';

export const NUMERIC_LIST = ['0.1', '1', '10', '100', '1000', '10000'];
export const DATE_LIST = ['DAY', 'WEEK', 'MONTH', 'QUARTER', 'YEAR', 'DAILY WEEK', 'DAILY MONTH'];

export class BaseChartDetails extends BaseController {

    protected getGroupBy(
        type: string,
        g: any,
        months: number = 24,
        min: number = 0,
        max: number = 10000,
        offset = 0,
        compareOffset = 0,
        comparasionMode: 'Values' | 'Percentage'='Percentage',
        stepsNo: number = 10): GroupBy {
        let groupBy;
        switch (type) {
            case 'date':
                groupBy = new DateGroupBy(g, months, offset, compareOffset,0, comparasionMode);
                break;
            case 'numeric':
                  groupBy = new NumberGroupBy(g, min, max, stepsNo);
                break;
        }
        return groupBy;
    }

    protected getGroupByList(col: IColumnSetting) {
        let l = null;
        if (col?.type === 'date') {
            l = DATE_LIST;
        } else if (col?.type === 'numeric') {
            l = NUMERIC_LIST;
        }
        return l;
    }

    protected getMonths(axis: ChartAxis, numberOfGroupBy: number): number {
        let months = 0;
        switch (axis.groupByName) {
            case 'QUARTER':
                months = numberOfGroupBy * 3;
                break;
            case 'MONTH':
                months = numberOfGroupBy;
                break;
            case 'YEAR':
                months = numberOfGroupBy * 12;
                break;
            case 'DAY':
                months = numberOfGroupBy;
                break;
            case 'DAILY WEEK':
            case 'DAILY MONTH':
                months = 1;
                break;
            default:
                months = 24;
        }
        return months;
    }

    protected reverseChartAxisGroupBy(axis: ChartAxis, months: number = 24) {
        let numberOfGroup = 0;
        switch (axis.groupByName) {
            case 'QUARTER':
                numberOfGroup = months / 3;
                break;
            case 'MONTH':
                numberOfGroup = months;
                break;
            case 'YEAR':
                numberOfGroup = months / 12;
                break;
            case 'DAY':
                numberOfGroup = months;
                break;
            default:
                numberOfGroup = 24;
        }
        return numberOfGroup;
    }
}
