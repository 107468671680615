import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseController, DRFacetOption, DRFilter, DiscovererQueryService, FacetValue, ReportPersistService, TabSettingsService } from '@discoverer/core';
import { ToolbarService } from '../../services/toolbar.service';
import { environment } from '@env/environment';
import { EmitterService } from '../../services/emitter.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'pipeline-results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.scss', '../../../../../../../src/styles/ai-shared-styles.scss']
})
export class ResultsComponent extends BaseController implements OnInit, OnDestroy {
    datasetId: any;
    showUploader = false
    showWorkspace = false;
    appId: string = environment.auth.productKey;
    subs: Subscription[] = []
    public groupsToQuery = []
    public groups: {
        fieldName: string,
        dir: 'asc' | 'desc'
    }[];

    constructor(
        public queryService: DiscovererQueryService,
        private _activated: ActivatedRoute,
        private _toolbarService: ToolbarService,
        private _router: Router,
        private _emitterService: EmitterService,
        private _tabSettingsSer: TabSettingsService
    ) {
        super();
        this.subscriptions.push(this._activated.queryParams.pipe(filter(params => !!params?.refresh)).subscribe(params => {
            this._router.navigate([], { queryParams: { refresh: null }, queryParamsHandling: 'merge' })
        }));

    }
    async ngOnInit(): Promise<void> {
        this.datasetId = this._activated.snapshot.params['datasetId'];
        this._tabSettingsSer.setCurrentTab(this.datasetId);
        this._emitterService.setDatasetId(this.datasetId);
        await this._toolbarService.setSettings(this.datasetId, this._activated.snapshot.routeConfig.data);
        this.queryService.resetFilters();
        this.queryService.refresh();
    }


    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this.subs.forEach(sub => sub.unsubscribe());
    }

    async displayDetailsDialog(data: any, businessKey: string, workSpaceReport: ReportPersistService) {
        const fileId = data?.currentObject[businessKey][0];
        const currentReqId = (await workSpaceReport.createRequest());
        const requestCopy = workSpaceReport.oLastRequestData.getValue();
        requestCopy.mainView.dirId = null;
        await workSpaceReport.updateRequest(currentReqId, requestCopy, false);
        const queryParams = { currentReqId, start: data?.rowIndex };
        this._router.navigate([fileId], {
            queryParams,
            relativeTo: this._activated,
            queryParamsHandling: 'merge',
            state: {
                pipelineid: this.datasetId
            }
        });
    }
}
