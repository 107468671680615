<div class="wrapper">
    <div *ngFor="let item of sidebarItems">
        <ng-container *ngTemplateOutlet="ai_group; context: {item: item}"></ng-container>
        <div *ngIf="item?.children">
            <ng-container *ngFor="let child of item?.children">
                <ng-container *ngTemplateOutlet="ai_basic; context: {item: child}"></ng-container>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #ai_basic let-item="item">
    <a *ngIf="item?.route" [routerLink]="[item?.route]" [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{exact: false}">
        <div class="flex items-center justify-between item-wrapper item-child">
            <div class="flex items-center justify-start ">
                <mat-icon *ngIf="item?.icon" [svgIcon]="item?.icon"></mat-icon>
                <p>{{item?.title}}</p>
            </div>
            <span *ngIf="item?.badge && !item.isLoading" class="badge">{{item?.badge || '0'}}</span>
            <span *ngIf="item?.isLoading">
                <mat-spinner diameter="20"></mat-spinner>
            </span>
        </div>
    </a>

    <div *ngIf="!item?.route" class="flex items-center justify-between item-wrapper item-child">
        <div class="flex items-center justify-start ">
            <mat-icon *ngIf="item?.icon" [svgIcon]="item?.icon"></mat-icon>
            <p>{{item?.title}}</p>
        </div>
        <span *ngIf="item?.badge" class="badge">{{item?.badge || '0'}}</span>
    </div>
</ng-template>



<ng-template #ai_group let-item="item">
    <div class="flex items-center justify-between item-wrapper item-group">
        <div class="flex items-center justify-start ">
            <mat-icon *ngIf="item?.icon" [svgIcon]="item?.icon"></mat-icon>
            <p>{{item?.title}}</p>
        </div>
        <span *ngIf="item?.badge" class="badge">{{item?.badge || '0'}}</span>
    </div>
</ng-template>