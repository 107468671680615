import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { IToolbarSettings, ToolbarService } from '../../services/toolbar.service';
import { ActivatedRoute } from '@angular/router';
import { SidenavService } from './services/sidenav.service';
// import { SidenavService } from './services/sidenav.service';

export interface ISidenavItem {
  id: any,
  icon: string
  title: string
  description: string
  route?: string
  href?: string
}

const items: ISidenavItem[] = [
  { id: 1, icon: 'account_circle', title: 'Profile', description: 'Manage your profile', route: 'profile' },
  { id: 2, icon: 'lock', title: 'Password', description: 'Manage your password', route: 'password' },
  { id: 3, icon: 'credit_card', title: 'Billing and Plans', description: 'Manage your subscription plan, payment method and billing information', route: 'plans' },
  // { icon: 'table_chart', title: 'Billing History', description: 'View your billing history and download invoices', route: 'billing-history' },
  { id: 4, icon: 'pie_chart', title: 'Usage', description: 'View monthly usage', route: 'usage' },
]

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;

  showCloseIcon: boolean = false
  breakpointWidth: number
  isSidenavOpen: boolean = true
  sidenavMode: 'over' | 'push' | 'side' = 'side'
  isLoading: boolean
  portalUrl: string;
  sidenavItems: ISidenavItem[] = items;
  constructor(private _toolbarSer: ToolbarService, private _activated: ActivatedRoute, private _sidenavSer: SidenavService
  ) { }

  ngOnInit(): void {
    this._toolbarSer.setSettings(null, {showBackButton: false, title: 'Dataset'} as IToolbarSettings);
    this._sidenavSer.getScreenSize().subscribe(size => {
      if (size.width < 768) {
        this.sidenavMode = 'over'
        this.isSidenavOpen = false
        this.showCloseIcon = true
      } else {
        this.sidenavMode = 'side'
        this.isSidenavOpen = true
        this.showCloseIcon = false
      }
    });

    this._sidenavSer.oSidenavToggle.subscribe(
      () => this.sidenav.toggle()
    )
  }
}
