import {
    BaseController
} from "../../base-controller";
import {
    DRQuery,
    IDRFilter
} from "../../classes";
import {
    IDashboardViewState
} from "../../dynamic-reports-services";
import {
    StateService
} from "../../state";
import {
    DiscovererQueryService
} from "../discoverer-query-service.service";

export interface IDashboardFiltersService {
    mainQueryService: DiscovererQueryService;
    tabsQueryServices: { [key: string]: DiscovererQueryService };
    dashboardState: StateService<IDashboardViewState>;

    init(_dashboardState: StateService<IDashboardViewState>);
    removeFilterFromAllTabs(queryFilter: IDRFilter);
    setTabsQueriesFilter(tabKey: string[], newFilter: IDRFilter);
    setTabQueryFilter(tabKey: string, newFilter: IDRFilter);
    unsetTabQueryFilter(tabKey: string, filterName: string)
    initTabQuery(tabKey: string);
    initTabsQuery(tabKeys: string[]);
    removeTabQuery(tabKey);
}
export class DashboardFiltersService extends BaseController implements IDashboardFiltersService {

    public tabsQueryServices: { [key: string]: DiscovererQueryService } = {};
    public mainQueryService: DiscovererQueryService;
    public dashboardState: StateService<IDashboardViewState>
    public get initialized() {
        return this._initialized;
    }

    private _initialized = false;

    constructor() {
        super();
    }
    public init(_dashboardState: StateService<IDashboardViewState>) {
        if (this.initialized) return;

        this._initialized = true;
        this.mainQueryService = new DiscovererQueryService('dashboard-filters');
        this.dashboardState = _dashboardState;

        this.subscriptions.push(this._subscribeToFilterQuery())
        this.subscriptions.push(this._subscribeToStateChanges())
        this._setSavedFilters();
    }
    public removeFilterFromAllTabs(queryFilter: IDRFilter) {
        if (!this.initialized) return;
        const tabsKey = Object.keys(this.tabsQueryServices);
        tabsKey.forEach(tabKey => this.tabsQueryServices[tabKey].unSetFilter(queryFilter.fields[0]).refresh());
    }

    public setTabsQueriesFilter(tabKeys: string[], newFilter: IDRFilter) {
        if (!this.initialized) return;
        for (const tabKey in this.tabsQueryServices) {
            if (tabKeys.includes(tabKey) && newFilter.facetValues.length > 0) {
                this.setTabQueryFilter(tabKey, newFilter);
            } else {
                this.unsetTabQueryFilter(tabKey, newFilter.fields[0]);
            }
        }
    }

    public setTabQueryFilter(tabKey: string, newFilter: IDRFilter) {
        if (!this.initialized) return;
        this.tabsQueryServices[tabKey].setFilter(newFilter.fields[0], newFilter).refresh();
    }
    public unsetTabQueryFilter(tabKey: string, filterName: string) {
        if (!this.initialized) return;
        this.tabsQueryServices[tabKey].unSetFilter(filterName).refresh();
    }

    public initTabQuery(tabKey: string) {
        if (!this.initialized || !!this.tabsQueryServices[tabKey]) return;
        this.tabsQueryServices[tabKey] = new DiscovererQueryService(`${tabKey}-dashboard`);
    }


    public initTabsQuery(tabKeys: string[]) {
        if (!this.initialized) return;

        tabKeys.filter(tabKey => !this.tabsQueryServices[tabKey]).forEach(tabKey => {
            this.tabsQueryServices[tabKey] = new DiscovererQueryService(`${tabKey}-dashboard`);
        });
    }

    public removeTabQuery(tabKey: string) {
        if (!this.initialized) return;

        delete this.tabsQueryServices[tabKey];
    }

    private _setSavedFilters() {
        this.dashboardState.getState().filters
            ?.forEach(filter => this.mainQueryService.setFilter(filter.filter.fields[0], filter.filter));
        this.mainQueryService.refresh();
    }

    private _subscribeToFilterQuery() {
        return this.mainQueryService.oQuery
            .subscribe(
                query => this._handleQueryChanges(query),
                err => console.log(err)
            );
    }

    private _subscribeToStateChanges() {
        return this.dashboardState.oState
            .subscribe(
                state => this._handleStateChanges(state),
                err => console.log(err)
            );
    }

    private _handleQueryChanges(query: DRQuery) {
        const queryFilters = query.filters;
        let stateFilters = this.dashboardState.getState().filters;
        queryFilters?.forEach(filter => {
            const index = stateFilters?.findIndex(stfl => stfl.filter.fields[0] === filter.fields[0])
            stateFilters[index].filter = filter;
        });
        const queryFilterFields = queryFilters?.map(filter => filter.fields[0]);
        stateFilters?.forEach(stateFilter => {
            if (!queryFilterFields.includes(stateFilter.filter.fields[0])) {
                stateFilter.filter.expression = [];
                stateFilter.filter.facetValues = [];
            }
        });
        this.dashboardState.setField('filters', stateFilters);
    }

    private _handleStateChanges(state: IDashboardViewState) {
        this.initTabsQuery(state.reports.map(report => report.key));
        state.filters?.forEach(filter => {
            this.initTabsQuery(filter.tabs);
            this.setTabsQueriesFilter(filter.tabs, filter.filter);
        });
        const stateFilterFields = state.filters?.map(stateFilter => stateFilter.filter.fields[0]) || [];
        this._unsetFilter(this.mainQueryService.filters, stateFilterFields);
    }

    private _unsetFilter(queryFilters: IDRFilter[], stateFilterFields: string[]) {
        queryFilters?.forEach((queryFilter) => {
            if (!stateFilterFields.includes(queryFilter.fields[0])) {
                this.removeFilterFromAllTabs(queryFilter);
                this.mainQueryService.unSetFilter(queryFilter.fields[0]).refresh();
            }
        })
    }

}
