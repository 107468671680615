<div *ngIf="isLoaded; else loading" class="height-100" id="pivot-wrapper">
    <div class="pivot-controls">
        <div class="pivot-controls-left" [hidden]="!isEditMode">
            <div *ngIf="!!errorMsg && isError" class="error">{{errorMsg}}</div>
        </div>
    </div>
    <pivot-chart-details class="height-100" #PIVOT_CHART *ngIf="!!reportPersist.pivotChartState" [isEditMode]="allowEditMode" [expandRows]="isEditMode" [visibleDetailsHeader]="visibleDetailsHeader"
        [pivotState]="reportPersist.pivotChartState" (isValid)="checkPivotStatus($event)"></pivot-chart-details>

</div>
<ng-template #loading>Loading Pivot Chart...</ng-template>
