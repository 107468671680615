import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
    selector: 'list-form',
    templateUrl: './list-form.component.html',
    styleUrls: ['./list-form.component.scss']
})
export class ListFormComponent implements OnInit, OnChanges {
    @Input() title: string;
    @Input() subTitle: string;
    @Input() info: string[];
    @Input() initializeOpened: boolean = false;
    @Input() showFooter: boolean = true;
    @Input() closeMenuInput: boolean = false;
    @Output() menuOpened: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() done: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('MenuTrigger', { static: false }) menuTrigger: MatMenuTrigger;
    public open: boolean = false;

    constructor() { }

    ngOnInit(): void {
        if (this.initializeOpened) {
            setTimeout(() => this.menuTrigger.openMenu());
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if(typeof changes.initializeOpened?.currentValue === 'boolean' && changes.initializeOpened?.currentValue == false) {
            setTimeout(() => this.menuTrigger.closeMenu());
        }
    }
    public stopPropagation(event) {
        event.stopPropagation();
    }
    public closeMenu() {
        this.menuTrigger.closeMenu();
    }
    public openMenu() {
        this.menuTrigger.openMenu();
    }

    public onMenuOpened() {
        this.open = true;
        this.menuOpened.emit(true);
    }

    public onMenuClosed() {
        this.open = false;
        this.menuOpened.emit(false);
    }

    public sort(info: string[]) {
        return info.sort((a,b) => a.localeCompare(b)  );
    }
}
