<div class="filter-groups" layoutDir="row">
    <div class="groups-wrapper" style="flex-grow: 1;overflow: auto"
        *ngIf="(queryService?.oQuery | async) as currentQuery">
        <ng-container
            *ngFor="let pinnedFilter of _reportPersistService.mainViewState.getState().facets | filterPinnedFilters: currentQuery?.filters">
            <div class="filter-group">
                <div class="top-label">
                    <div class="filter-title">
                        <list-form [title]="getFacet(pinnedFilter).display" class="list"
                            (click)="addEmptyFilter(pinnedFilter)">
                        </list-form>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
