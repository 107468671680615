import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DiscovererDataService, DiscovererQueryService, EsQueryService, FacetFieldAccumulator, GeneralDataType, GroupBy, IColumnSetting,  getNearestValueToGap, roundUpToHighestDigit } from '@discoverer/core/services';
import { BaseChartDetails, DATE_LIST } from '@discoverer/dynamic-reports/customize-report/base-chart-details';
import { filter, map, take } from 'rxjs/operators';

@Component({
    selector: 'app-category-picker',
    templateUrl: './category-picker.dialog.html',
    styleUrls: ['./category-picker.dialog.scss']
})
export class CategoryPickerDialog extends BaseChartDetails implements OnInit, OnDestroy {
    public columns: IColumnSetting[];
    public selectedColumn: { colName?: string, groupByName?: string, numberOfGroups?: number, type?: GeneralDataType, groupBy?: GroupBy } ={};
    public availableDateGroups: string[] = DATE_LIST;
    public groupByDataService: DiscovererDataService<any>;
    public loading = false;

    constructor(
        private http: HttpClient,
        private esQueryService: EsQueryService,
        public dialogRef: MatDialogRef<CategoryPickerDialog>,
        @Inject(MAT_DIALOG_DATA) public data: { columns: IColumnSetting[], serviceUrl: string, mainQueryService: DiscovererQueryService }) {
        super()
        this.columns = data.columns.filter(col => !(col.fieldName ==="DefaultColumn" && !col.display));
        this.groupByDataService = new DiscovererDataService(this.http, this.esQueryService, { create: (row: any) => row });
        this.groupByDataService.init(data.serviceUrl, data.mainQueryService, 'category-group-by');
        this.groupByDataService.enabled = true;
        this.dataServices.push(this.groupByDataService);
    }

    async ngOnInit() {

    }

    public onNoClick(): void {
        this.dialogRef.close();
    }
    public open() {
        this.dialogRef.close(this.selectedColumn);
    }
    public numericGroupByChanged() {
        const {start, end, stepsNo} = this.selectedColumn.groupBy;
        this.selectedColumn.groupBy.orginalGap = this._calculateGap(start, end, stepsNo);
        this.selectedColumn.groupBy.gap = this.selectedColumn.groupBy.orginalGap;
    }
    public dateGroupByChanged() {
        const {colName, groupByName, numberOfGroups} = this.selectedColumn
        const col = this.columns.find(cl => cl.fieldName === colName);
        this.selectedColumn.groupBy = this.getGroupBy(col.type, groupByName, +numberOfGroups);
    }
    public async select(column: string) {        const col = this.columns.find(cl => cl.fieldName === column);
        this.selectedColumn.colName = column;
        this.selectedColumn.type = col.type;
        if (col.type === 'date') {
            this.selectedColumn.groupByName = 'YEAR';
            this.selectedColumn.numberOfGroups = 24;
            this.selectedColumn.groupBy = this.getGroupBy(col.type, this.selectedColumn.groupByName);
        } else if (col.type === 'numeric') {
            this.selectedColumn.groupBy = { start: null, end: null, gap: null, orginalGap: null };
            this._getNumericLimits(this.selectedColumn);
        } else {
            this.selectedColumn.groupBy = null;
        }
    }
    private async _getNumericLimits(col: { colName?: string, groupByName?: string, numberOfGroups?: number, type?: GeneralDataType, groupBy?: GroupBy }) {        this.loading = true;
        this.groupByDataService.setDataIsRequested('All', true);
        this.groupByDataService.resetStatFacet();
        this.groupByDataService.setStat(col.colName, 'min');
        this.groupByDataService.setStat(col.colName, 'max');
        await this.groupByDataService.refresh();
        const facet = await this.groupByDataService.oFacetResults.pipe(
            filter(facets => !!facets.find((x) => x.field === 'All')?.getKeyMap()[col.colName]),
            map(facets => facets.find((x) => x.field === 'All')),
            take(1))
            .toPromise();
        this.selectedColumn.groupBy = this._getGroupByFromFacetFieldAccumulator(facet, col.type);
        this.loading = false;
    }
    private _getGroupByFromFacetFieldAccumulator(facet: FacetFieldAccumulator, type: string) {
        const min: number = +facet.getValueStringState('stat1') || 0;
        const max: number = +facet.getValueStringState('stat2') || 10000;
        const gap = Math.ceil((max - min) / 10);
        const roundedGap = roundUpToHighestDigit(gap);
        const roundedMin = getNearestValueToGap(min, roundedGap);
        return this.getGroupBy(type, +roundedGap, 10, roundedMin, max);
    }
    private _calculateGap(min, max, stepsNo) {
        const gap = Math.ceil((max - min) / stepsNo);
        const roundedGap = roundUpToHighestDigit(gap);
        return roundedGap.toString();
    }

}
