<div class="height-100" id="dashboard">
    <mat-card class="one-chart height-100" [ngClass]="{'full-width':chart.isFull}" *ngFor="let chart of charts;let i = index">
        <kendo-chart-item [chartState]="chartStates[i]"
            [queryService]="queryService" [serviceUrl]="serviceUrl" [availableColumns]="columns" [enableEditMode]="true"
            [previewMode]="true" (editChart)="editChart(chart,i)" [isFull]="chart.isFull" (changeChartSize)="changeChartSize($event,chart,i)"></kendo-chart-item>
    </mat-card>

    <div class="add-card" (click)="addChart()">
        <mat-icon>add</mat-icon>
    </div>
</div>
