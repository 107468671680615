<div *ngIf="!to.disabled; else temp">
    <igx-time-picker
        #timePicker
        [mode]="mode"
        [spinLoop]="false"
        [inputFormat]="format"
        [formControl]="control"
        [minValue]="field?.templateOptions?.minValue | toDate"
        >
    </igx-time-picker>
</div>
<ng-template #temp>
    <div class="flex flex-col">
        <input type="text" [value]="returnDate()" disabled>
    </div>
</ng-template>
