import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { DatasetFileService } from '../../../services/api/dataset-file.service';
import { FileLog } from '../../../models/datasetFile.models';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-file-log-displayer',
  templateUrl: './file-log-displayer.component.html',
  styleUrls: ['./file-log-displayer.component.scss']
})
export class FileLogDisplayerComponent implements OnInit, OnChanges {

  @Input() datasetId: string
  @Input() fileId: string

  logSteps: FileLog[] = []

  constructor(
    private _datasetFileService: DatasetFileService,
    private _clipboard: Clipboard
  ) { }

  async ngOnInit(): Promise<void> {
    await this.updateLogFile();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.fileId && changes.fileId.previousValue) || (changes.datasetId && changes.datasetId.previousValue)) {
      this.updateLogFile();
    }
  }
  public copy(event: Event, log: FileLog) {
    event.stopPropagation();
    const resultJson = JSON.stringify(log.jsonObject);
    this._clipboard.copy(resultJson);
    log.isCopied = true;
    setTimeout(() => {
      log.isCopied = false
    }, 2000);
  }

  private async updateLogFile(): Promise<void> {
    this.logSteps = [];
    const fileLogRes = await this._datasetFileService.getLogs(this.datasetId, this.fileId).toPromise();
    if (fileLogRes.isSuccess) {
      fileLogRes?.result?.forEach(step => {
        this.logSteps.push({ stepName: step['name'], jsonObject: step, isCopied: false })
      });
    }
  }

}
