import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BackendResponse } from '../../models/common';
import { BaseDatasetDetailsDto } from '../../models/dataset.model';
import { CreateDatasetDto } from '../../models/dtos/create-dataset-dto';
import { BackendRouteEndpoint } from './constants/backend-route-constants';
import { Field } from '../../helpers/docpipeline-function';
import { FileInfoDto } from '../../models/datasetFile.models';


@Injectable({
    providedIn: 'root'
})
export class DatasetService {

  _datasetDetails$: BehaviorSubject<BaseDatasetDetailsDto> = new BehaviorSubject<BaseDatasetDetailsDto>(null);
  oDatasetDetails: Observable<BaseDatasetDetailsDto> = this._datasetDetails$.asObservable();

    constructor(
        private httpClient: HttpClient
    ) { }

    // TODO: Add Types instead of any

    getAll(): Observable<BackendResponse<any>> {
        return this.httpClient.get<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}`)
    }
    async getById(datasetId: string): Promise<BackendResponse<any>> {
        const datasetDetails = await this.oDatasetDetails.pipe(take(1)).toPromise()
    if (datasetDetails?.Id === datasetId) return {isSuccess: true, result: datasetDetails};;
    const {isSuccess, result} = await this.httpClient.get<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}`).toPromise();
    if (isSuccess && result != null){
      this._datasetDetails$.next(result);
    }
    return {isSuccess, result};
  }
  updateDatasetDetails(datasetDetails: BaseDatasetDetailsDto) {
    this._datasetDetails$.next(datasetDetails);
  }
  async getCurrentBaseDatasetDetails(){
    return await this.oDatasetDetails.pipe(take(1)).toPromise();
    }
    getCounts(datasetId: string): Observable<BackendResponse<any>> {
        return this.httpClient.get<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/counts`);
    }
    getFields(datasetId: string): Observable<BackendResponse<{ [key: string]: Field }>> {
        return this.httpClient.get<BackendResponse<{ [key: string]: Field }>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/fields`)
    }
    getTemplateFields(templateName: string) {
        return this.httpClient.get<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/fields/by-template-name/${templateName}`);
    }
    getGeneralSettings(datasetId: string) {
        return this.httpClient.get<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/general-settings`);
    }
    getAllTemplatesFields() {
        return this.httpClient.get<BackendResponse<{ [key: string]: { [key: string]: Field } }>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/templates`);
    }

    create(dataset: CreateDatasetDto): Observable<BackendResponse<any>> {
        return this.httpClient.post<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}`, dataset);
    }
    createFieldsByFile(datasetId: string, obj: FormData ): Observable<BackendResponse<FileInfoDto>> {
        return this.httpClient.post<BackendResponse<FileInfoDto>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/fields`, obj)
    }
    createOrUpdateAssets(datasetId: string, assetsFiles) {
        return this.httpClient.post<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/assets`, assetsFiles)
    }

    updateFields(datasetId: string, fields): Observable<BackendResponse<any>> {
        return this.httpClient.put<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/fields`, fields)
    }
    updateFieldsByTemplate(datasetId: string, templateName: string): Observable<BackendResponse<any>> {
        return this.httpClient.put<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/fields/by-template-name/${templateName}`, null)
    }
    updateTemplateName(datasetId: string, newTemplateName: string): Observable<BackendResponse<any>> {
        return this.httpClient.put<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/name/${newTemplateName}`, null)
    }
    updateDatasetGeneralSettings(datasetId: string, generalSettings: any): Observable<BackendResponse<any>> {
        return this.httpClient.put<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/general-settings`, generalSettings)
    }

    delete(datasetId: string): Observable<BackendResponse<any>> {
        return this.httpClient.delete<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}`);
    }
}
