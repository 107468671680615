import {
    Component,
    Input,
    OnInit
} from '@angular/core';
import {
    BaseController,
    DiscovererQueryService,
    ITableHeader,
    ReportPersistService,
    TabSettingsService
} from '@discoverer/core/services';
import {
    TableHeaderService
} from '@discoverer/core/services/dynamic-reports-services/table-header.service';
import {
    DialogsManagerService
} from '@discoverer/dynamic-reports/dialogs';

@Component({
    selector: 'main-view-control',
    templateUrl: './main-view-control.component.html',
    styleUrls: ['./main-view-control.component.scss']
})
export class MainViewControlComponent extends BaseController implements OnInit {

    @Input() queryService: DiscovererQueryService;
    @Input() public tableHeaderService: TableHeaderService;
    public tableHeaderConfig: ITableHeader;
    public fetchedRecordsLength;
    public tabTitle: string;
    constructor(
        public reportPersist: ReportPersistService,
        public dialogsManagerService: DialogsManagerService,
        private tabSettings: TabSettingsService
    ) { super(); }

    async ngOnInit() {
        this.tabTitle = (await this.tabSettings.getCurrentTab()).name;

    }

    public min(v: number, v2: number) { return (v < v2) ? v : v2; }

}
