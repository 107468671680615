<div class="advanced-filter-overlay" *ngIf="isOpen" (click)="facetTitleClicked()"></div>
<div (click)="facetTitleClicked()" style="cursor: pointer;width: 300px;padding:10px">
  <div layoutDir="row" class="title-container">
    <div flex="4" class="facet-title">{{selectedValuesText}}</div>
    <button mat-icon-button mat-button>
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </div>
  <div *ngIf="this.checkedFacetValues.length >0 && false" flex="1" layoutDir="row" style="padding-left:10px;margin-top:-5px;font-size:12px;color:grey;">
    <span>{{this.checkedFacetValues.length}} Selected</span>
  </div>
</div>
<mat-list *ngIf="isOpen" class="filterList">
  <div *ngIf="isSearchable; else closeButton" class="list-header">
    <mat-list-item class="search-header">
      <mat-form-field floatLabel="never">
        <input matInput color="primary" [ngModel]="searchText" (input)="searchTextUpdate($any($event).target.value)" placeholder="filter" autocomplete="off" />
        <div class="pagination-btn" matSuffix *ngIf="sortedFacetValues.length < totalFiltered && isOpen || true">
          <button [disabled]="pageNumber < 1" matSuffix mat-icon-button (click)="prevPage($event)">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button [disabled]="pageTo >= totalFiltered" mat-icon-button (click)="nextPage($event)">
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </div>
        <mat-hint [align]="'end'" *ngIf="sortedFacetValues.length < totalFiltered && isOpen || true">{{pageFrom + 1}} - {{pageTo}} of {{totalFiltered}}</mat-hint>
      </mat-form-field>
    </mat-list-item>
    <button class="close-button" mat-icon-button (click)="facetTitleClicked()"><mat-icon>close</mat-icon></button>
  </div>
  <ng-template #closeButton>
    <button class="close-button" mat-icon-button (click)="facetTitleClicked()"><mat-icon>close</mat-icon></button>
  </ng-template>
  <div layoutDir="row" class="short" *ngFor="let facetVal of sortedFacetValues ">
    <mat-checkbox class="checkbox" [ngClass]="{'excluded': facetVal.isExclusion}" (click)="selectFacetValue($event, facetVal)" [ngModel]="facetVal.isChecked">{{
     facetVal.title==="" ? emptyString : !facetVal.title? blank : facetVal.title }}
    </mat-checkbox>
  </div>
</mat-list>
