import { Component, OnInit } from '@angular/core';
import { BaseCustomController } from '../shared/base-custom-controller';

@Component({
  selector: 'text-input-with-history',
  templateUrl: './text-input-with-history.component.html',
})

export class TextInputWithHistory extends BaseCustomController implements OnInit {
    private historyKey = '';
    history: string[] = [];
    keyup: any = null

    ngOnInit() {
      this.historyKey = this.field.templateOptions.label.toLowerCase().replace(/\s+/g, '_');
      this.history = this.getHistory();
    }

    onOptionSelected(event: any): void {
        this.field.formControl.setValue(event.option.value);
        this.addToHistory(this.field.formControl.value);
    }

    updateTyping(event){
        if(this.keyup) {
            clearTimeout(this.keyup);
        }

      this.keyup = setTimeout( ()=>{
        this.addToHistory(this.field.formControl.value)
      }, 1000);
    
    }

    getHistory(): string[] {
      const historyString = localStorage.getItem(this.historyKey);
      return historyString ? JSON.parse(historyString) : [];
    }

    addToHistory(value: string): void {
        if (value && value.trim() !== '') {
            const index = this.history.indexOf(value);
            if (index !== -1) {
                this.history.splice(index, 1);
            }

            this.history.unshift(value); // Add to the beginning of the array

            // Limit the history to a maximum of 5 items
            if (this.history.length > 5) {
              this.history = this.history.slice(0, 5);
            }

            localStorage.setItem(this.historyKey, JSON.stringify(this.history));
            this.history = this.getHistory();
        }
    }
}
