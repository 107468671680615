import {
    HttpClient
} from '@angular/common/http';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {
    MatSnackBar
} from '@angular/material/snack-bar';
import {
    DefaultPivotLimit,
    DiscovererDataService,
    EsQueryService,
    IChartDataConfig,
    IChartState,
    IColumnSetting,
    IMetric,
    IPivotConfig,
    NUMBER_OF_ROWS_METRIC,
    ReportPersistService,
    StateService,
    TabSettingsService,
    cloneDeep
} from '@discoverer/core/services';
import {
    BaseChartDetails
} from '@discoverer/dynamic-reports/customize-report/base-chart-details';
import {
    CHART_TYPES,
    ChartAxis,
    ChartControlAxis,
    IChartType,
    ViewTypes
} from '@discoverer/dynamic-reports';
import {
    PivotChartControlService
} from './chart-control.service';
import {
    KendoChartControlService
} from './kendo-chart-control.service';
import { ChartSort } from './chart-control.model';
import { PopoverService } from '@discoverer/dynamic-reports/popover/popover.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
    selector: 'charts-control',
    templateUrl: './chart-control.component.html',
    styleUrls: ['./chart-control.component.scss']
})

export class ChartControlComponent extends BaseChartDetails implements OnInit {
    public sortSubtitle = '';
    public modes: ('Values' | 'Percentage')[] = ['Values', 'Percentage'];
    @Input() public state!: StateService<IPivotConfig | IChartState>;
    @Input() public chartType: ViewTypes.Chart | ViewTypes.Pivot = ViewTypes.Chart;
    @Input() public chartControlService: PivotChartControlService | KendoChartControlService;
    @Input() public metricList = ['sum', 'avg', 'min', 'max', 'count'];

    @Output() public periodChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public displayModeChanged: EventEmitter<'Values' | 'Percentage'> = new EventEmitter<'Values' | 'Percentage'>();

    public currentMetricList = (type: string): string [] => (type === 'text') ? ['count'] : this.metricList;
    public selectedPeriod: string = 'week'; // Default value
    public periodOptions: { label: string, value: string }[] = [
        { label: 'MONTH', value: 'month' },
        { label: 'WEEK', value: 'week' }
    ];
    public availableColumns: IColumnSetting[];
    public sortList: string[] = [];
    public chartCategoryLabel: string;
    // public chartValueLabel: string;

    public labelSortBy = 1;
    public directionSortBy = 1;
    public seriesSortBy = 1;

    public metricColumns: IColumnSetting[];
    public numberOfRowsMetric: IColumnSetting;
    public viewTypes = ViewTypes;
    public chartTypes: IChartType[] = CHART_TYPES;
    public columnDictionary: {[key: string]: IColumnSetting};
    constructor(
        private snackBar: MatSnackBar,
        private http: HttpClient,
        private tabSettings: TabSettingsService,
        private reportPersist: ReportPersistService,
        private _popoverService: PopoverService,
        private esQueryService: EsQueryService
    ) { super(); }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.chartControlService.destroy();
    }

    public async ngOnInit() {
        this.chartControlService.setChartType(this.chartType);

        this.availableColumns = await this.tabSettings.getAllColumns();
        this.columnDictionary = (await this.tabSettings.getColumnDictionary());
        this.metricColumns = this.availableColumns.filter((x) => x.type === 'numeric' || x.type === 'text' );
        this.numberOfRowsMetric = cloneDeep(NUMBER_OF_ROWS_METRIC);
        this.chartType === ViewTypes.Pivot && this.metricColumns.push(this.numberOfRowsMetric);
        const serviceUrl = (await this.tabSettings.getCurrentTab()).serviceUrl;
        let groupByDataService = new DiscovererDataService(this.http, this.esQueryService, { create: (row: any) => row });
        groupByDataService.init(serviceUrl, this.reportPersist.mainQueryService, 'pivot-chart-group-by');
        groupByDataService.enabled = true;
        this.chartControlService.setgroupByDataService(groupByDataService);
        const cols = await this.tabSettings.getColumnDictionary()
        this.chartControlService.setService(this.state, cols);
    }

    public initSelectedPeriod(selPer: string) {
        if(selPer.includes("DAILY")) {
            this.selectedPeriod =  selPer.split(" ")[1].trim().toLowerCase();
        }
        return this.selectedPeriod;
    }

    public closePopover() {
        this._popoverService.setState(true);
    }

    public onGroupChange(axis: ChartAxis, jj: number) {
        if (axis?.groupBy?.stepsNo > 0) {
            axis.groupBy = this.getGroupBy(
                axis.colType,
                +axis.groupBy.gap,
                axis.numberOfGroups,
                +axis.groupBy.start,
                +axis.groupBy.end,
                axis.offset,
                axis.compareOffset,
                axis.compareType,
                axis.groupBy.stepsNo
            );
            this.setCategories(axis, jj)
        } else {
            this.snackBar.open('Gap should be > 0');
        }
    }


    public clearSeries(i: number) {
        this.chartControlService.clearGroupBy(i)
    }

    public async prepareGroups(colName: string, jj = 0) {
        if (!!colName) {
            const col = (await this.tabSettings.getColumnDictionary())[colName];
            console.log('fieldName', colName);
            const type = jj === 0 ? 'categories' : 'series';
            this.chartControlService.prepareGroups(col, jj, type);
        }
    }

    public setGroupby(
        axis: ChartAxis,
        groupByName: string,
        index: number,
        groupByNumber: number = 24
    ) {
        this.chartControlService.setGroupby(axis, groupByName, groupByNumber, index)
    }

    public onPeriodChange(event: MatSelectChange): void {
        var groupByNumber: number = 1;
        this.selectedPeriod = event.value;
        var axis = (this.chartControlService as PivotChartControlService).getGroupBysFromState();
        this.chartControlService.setGroupby(axis[0], `DAILY ${this.selectedPeriod.toUpperCase()}`, groupByNumber, 0)
        this.chartControlService.commandUpdate();
    }

    public moveSeriesUp(i: number) {
        this.chartControlService.moveGroupByUp(i)
    }

    public moveSeriesDown(i: number) {
        this.chartControlService.moveGroupByDown(i)
    }
    public setSort(sort: ChartSort) {
        this.chartControlService.setSort(sort)
    }

    public enableCompareOffset(event, axis: ChartAxis, index: number) {
        if (!event.checked) {
            axis.compareOffset = 0;
            this.setCategories(axis, index)
        }
    }

    public changeLimit(axis: ChartAxis, limit: number, index: number) {
        if (!isNaN(+limit)) {
            axis.limit = limit;
            this.setCategories(axis, index)
        }
    }

    public changeMinCount(axis: ChartAxis, minCount: number, index) {
        if (!isNaN(+minCount)) {
            axis.minCount = minCount;
            this.chartControlService.onGroupsChange(axis, index)
        }
    }

    public onNumberOfGroupsChange(axis: ChartAxis, numberOfGroups: number, index) {
        if (!isNaN(+numberOfGroups)) {
            axis.numberOfGroups = +numberOfGroups;
            this.chartControlService.onGroupsChange(axis, index)
        }
    }
    public onOffsetChange(axis: ChartAxis, offset: string, index) {
        if (!isNaN(+offset)) {
            axis.offset = +offset;
            this.chartControlService.onGroupsChange(axis, index)
        }
    }

    public onCompareOffsetChange(axis: ChartAxis, offset: string, index) {
        if (!isNaN(+offset)) {
            axis.compareOffset = +offset;
            this.chartControlService.onGroupsChange(axis, index)
        }
    }

    public addSeries(ind: number) {
        const newAxies = new ChartAxis('series');
        newAxies.index = ind - 1;
        this.setState(newAxies, ind);

    }
    public getGroupsSubTitle(groupBys) {
        return groupBys?.map(g => g.subTitle).join(', ');
    }

    public changePeriod(previous = false) {
        this.periodChanged.emit(previous);
    }

    public onCompareTypeChange(axis: ChartAxis, offset: 'Values' | 'Percentage') {
        axis.compareType = offset;
        this.displayModeChanged.emit( offset);
        this.setState(axis, 0);
        this.applyCurrentState();
    }

    public seriesListContainsDateField(list): boolean {
        return list?.filter((s, i) => i > 0 && s.colType == 'date').length > 0;
    }

    public async changeSummarizeBy(colName, func: 'count' | 'sum' | 'avg' | 'min' | 'max' | 'stddev' | 'sumsq' = 'count', i: number) {
        if (!colName) return;
        const columnDictionary = (await this.tabSettings.getColumnDictionary());
        const display = columnDictionary[colName]?.display || colName;
        const functionType = (colName === this.numberOfRowsMetric.fieldName) ? 'count' : func;
        const businessKey = (await this.tabSettings.getCurrentTab()).businessKey;
        const overrideField = (colName === this.numberOfRowsMetric.fieldName)
            ? businessKey || this.metricColumns[0]?.fieldName
            : null;
        const metric: IMetric = { colName, functionType, display, overrideField };
        this.chartControlService.setSummarizeByFields(metric, i);
    }
    public async setCategories(dimension: ChartControlAxis, index: number) {
        const columnDictionary = (await this.tabSettings.getColumnDictionary());
        dimension.display = columnDictionary[dimension.colName]?.display;
        this.chartControlService.setCategoriesFields(dimension, index);
    }
    public applyCurrentState() {
        this.chartControlService.commandUpdate();
        this.closePopover();
    }

    private setState(axis: ChartAxis, index) {
        axis.groupBy = this.getGroupBy(
            axis.colType,
            +axis?.groupBy?.gap || axis.groupByName,
            axis.numberOfGroups,
            +axis?.groupBy?.start || 0,
            +axis?.groupBy?.end || 0,
            axis.offset,
            axis.compareOffset,
            axis.compareType
        );
        this.setStateFromAx(axis, index);
    }
    private setStateFromAx(axis: ChartAxis, index: number) {
        axis.sortDir = !!axis.sortDir ? axis.sortDir : 'asc';
        axis.sortType = !!axis.sortType ? axis.sortType : 'label';

        this.chartControlService.setCategoriesFields(axis, index);
    }
    public addSummarizeBy(i: number) {
        const metric = {
            colName: null,
            functionType: null,
            display: null,
            overrideField: null
        }
        this.chartControlService.setSummarizeByFields(metric, i);
    }
    removeMetric(i: number) {
        this.chartControlService.clearSummarizeBy(i);
        // remove metrics only if charttype is chart
    }
    toggleLogarithmScale() {

    }
    // setCountSubRecords(isCount: boolean) {
    //     this.chartControlService.setCountSubRecords(isCount);
    // }
    setChartType(type) {
        this.chartControlService.setSubChartType(type);
        this.chartControlService.commandUpdateChartType();
    }
}
