import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';

@Injectable()
export class ChartViewErrorHandler {

    private $errorMsg: ReplaySubject<string>;
    public errorMsg: Observable<string>;

    private $isError: ReplaySubject<boolean>;
    public isError: Observable<boolean>;

    constructor() {
        this.$errorMsg = new ReplaySubject<string>(1);
        this.errorMsg = this.$errorMsg.asObservable();

        this.$isError = new ReplaySubject<boolean>(1);
        this.isError = this.$isError.asObservable();
    }

    public setErrorMsg(msg:string) {
        this.$errorMsg.next(msg);
    }

    public setIsError(error:boolean) {
      this.$isError.next(error);
  }
}
