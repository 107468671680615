import { Injectable } from '@angular/core';
import { NoDataRow, DiscovererQueryService, DiscovererDataService } from '../services';
import { HttpClient } from '@angular/common/http';
import { filter, startWith, pairwise } from 'rxjs/operators';
import { BlocPatternServiceBase } from '../services/bloc/bloc-service-base';
import { basename } from 'path';
import { EsQueryService } from '../services/core-data-services/discoverer-esquery-service';

@Injectable()
export class FacetDataSourceBloc extends BlocPatternServiceBase<FacetInputs, DiscovererDataService<NoDataRow>> {
    private _input = new FacetInputs();
    private prevDataService: DiscovererDataService<NoDataRow>;

    constructor(private httpClient: HttpClient,private esQueryService:EsQueryService) {
        super();

        this._$input.pipe(filter((input) => !!input && input.queryService &&
            input.facetKey && input.serviceUrl))
            .subscribe((s: FacetInputs) => {
                if (!!this.prevDataService) {
                  this.prevDataService.destroy();
                }
                this.prevDataService = this.initDataService(s.queryService, s.facetKey, s.serviceUrl, s.facetName, s.title, s.enable);
                this._$output.next({ input: s, output: this.prevDataService });
            }
            ,() => {
              if (!!this.prevDataService) this.prevDataService.destroy();
            }
            ,() => {
              if (!!this.prevDataService) this.prevDataService.destroy();
            });
    }

    public setFacetInputs(input: FacetInputs) {
        Object.keys(input).forEach(key => this._input[key] = input[key]);
        this._$input.next(Object.assign({}, this._input));
    }

    private initDataService(queryService: DiscovererQueryService, facetKey: string, serviceUrl: string,
                            facetName?: string, title?: string, enable?: boolean): DiscovererDataService<NoDataRow> {
        const dataService = new DiscovererDataService<NoDataRow>(this.httpClient,this.esQueryService, { create: (a) => [a] });
        const name = !!facetName && facetName !== '' ? facetName : facetKey;
        dataService.enabled = false;
        dataService.init(serviceUrl, queryService, name);
        if (!!facetName && facetName) {
            dataService.setFacet(facetName, {
                name: facetName,
                type: 'named'
            });
        } else {
            dataService.setFacet(facetKey, {
                name: facetKey,
                type: 'terms',
                field: facetKey,
                excludeTags: [facetKey],
                mincount: 1,
                limit: -1,
                display: title,
                shortDisplay: title
            });
        }
        dataService.enabled = enable;
        dataService.refresh();

        return dataService;
    }

}

export class FacetInputs {
    queryService?: DiscovererQueryService;
    facetKey?: string;
    serviceUrl?: string;
    facetName?: string;
    title?: string;
    enable ?= false;

}

