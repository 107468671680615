import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'minutesToHours'
  })
  export class MinutesToHoursPipe implements PipeTransform {
    transform(value: number): string {
       if(value > 0 && value < 60 ) {
         return value + 'm';
       } else {
         return Math.floor(value/60) + 'h ' + Math.floor(value % 60) + 'm';
       }
    }
  }