import {
    Component,
    OnInit,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import {
    BaseController, DiscovererQueryService, FacetValue, DRFacetOption
} from '../services';
import { FacetSourceContext } from '../facet-data-source';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'select-facet',
    styleUrls: ['./select-facet.component.scss'],
    templateUrl: './select-facet.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SelectFacetComponent extends BaseController implements OnInit {
    private _serviceUrl: string;
    private _queryService: DiscovererQueryService;
    private _facetKey: string;

    @Input() facetName: string;
    @Input() title: string;
    @Input() singleMode: boolean;
    @Input() displayValues: string[];
    @Input() showCount = true;
    displayFacets: FacetValue[];

    selectedFacet: string = 'All';

    public get serviceUrl() { return this._serviceUrl; }
    @Input() public set serviceUrl(value: string) {
        this._serviceUrl = value;
    }
    public get facetKey() { return this._facetKey; }
    @Input() public set facetKey(value: string) {
        this._facetKey = value;
    }
    public get queryService() { return this._queryService; }
    @Input() public set queryService(value: DiscovererQueryService) {
        this._queryService = value;
    }

    ngOnInit(): void {
    }

    public getDisplayFacets(facetValues: FacetValue[]) {

    }

    public updateFacets(context: FacetSourceContext) {
        const facetValues = context.facetValues;
        const dictionary = {};
        facetValues.forEach(val => {
            dictionary[val.facetKey] = val;
        });
        this.displayFacets = this.displayValues ? this.displayValues.map(v => !!dictionary[v] ? dictionary[v] : new FacetValue(new DRFacetOption(v, v, v)))
            : facetValues;
    }
}
