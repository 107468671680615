import { EventEmitter, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { DateRangeFilterService } from '../services/date-range-filter.service';
import { FILTERING_OPERATION, FILTERING_OPR_DICTIONARY, DATE_OPERATION, DATE_OPR_DICTIONARY, FIELDS_TYPES } from '../../services';


@Component({
    selector: 'mat-daterange-picker',
    templateUrl: './mat-daterange-picker.component.html',
    styleUrls: ['./mat-daterange-picker.component.scss']
})
export class MatDaterangePicker implements OnInit, OnChanges {
    @Input() isPinned: boolean;
    @Input() showPin: boolean;
    @Input() filteringOperation;

    @Output() pinClicked = new EventEmitter<any>();

    numericMinOperations = FILTERING_OPERATION.filter(
        (o) => o.allowedTypes.includes("numeric") && o.label.includes("more")
      );

      numericMaxOperations = FILTERING_OPERATION.filter(
        (o) => o.allowedTypes.includes("numeric") && o.label.includes("less")
      );

    public FILTERING_OPERATION_BETWEEN = { id: 'IS_BETWEEN', label: 'is between', allowedTypes: FIELDS_TYPES.DATE };
    public OPERATION_SHORTCUTS = DATE_OPERATION;
    public DATE_OPR_DICTIONARY = DATE_OPR_DICTIONARY;
    public FILTERING_OPR_DICTIONARY = FILTERING_OPR_DICTIONARY;
    public DATE_FILTERING_OPERATION = FILTERING_OPERATION.filter(f => f.allowedTypes.includes(FIELDS_TYPES.DATE));

    constructor(public dateRangeFilterService:DateRangeFilterService) { }

    ngOnInit() { }


  ngOnChanges(changes: SimpleChanges) {
      if (changes.filteringOperation) {
          this.dateRangeFilterService.setMasterSelection(this.filteringOperation);
      }
  }

}
