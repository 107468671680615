import { Component } from '@angular/core';

@Component({
  selector: 'app-ai-layout',
  templateUrl: './ai-layout.component.html',
  styleUrls: ['./ai-layout.component.scss']
})
export class AiLayoutComponent {

  constructor() {
  }

}
