import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseCustomController } from '../shared/base-custom-controller';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'currency',
    template: `<input  matInput type="text" (blur)="onBlur()" [formControl]="currencyControl"  [tabIndex]="to?.tabindex" />`,
    styleUrls: ['./currency-input.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [CurrencyPipe]
})
// tslint:disable-next-line:component-class-suffix
export class CurrencyFieldType extends BaseCustomController implements OnInit {
    constructor(private pipe: CurrencyPipe) {
        super();
    }
    currencyControl = new FormControl('');
    ngOnInit() {
        this.currencyControl.setValue(this.pipe.transform(this.field.formControl.value));
        this.setControlStatus();
        this.field.formControl.valueChanges.subscribe(x => {
            this.currencyControl.setValue(this.pipe.transform(x), { emitEvent: false });
        });
        this.field.formControl.statusChanges.subscribe(x => {
            this.setControlStatus();
        });
    }
    public onBlur() {
        const inputVal = this.currencyControl.value;
        const value = Number(inputVal ? inputVal.replace(/[^0-9.-]+/g, '') : inputVal);
        this.updateValueAndValidity(this.field, value, { emitEvent: false });
        const key = Array.isArray(this.field.key) ? this.field.key[0] : this.field.key;
        this.model[key] = value;
    }
    private setControlStatus() {
        if (this.to && this.to.disabled) {
            this.currencyControl.disable();
        } else {
            this.currencyControl.enable();
        }
    }

}
