<div class="align-center" *ngIf="(reportPersist.oLastRequestData | async) as state">
    <button mat-mini-fab [matMenuTriggerFor]="viewTypesMenu" color="accent" class="title"
        #viewTypesTrigger="matMenuTrigger">
        <mat-icon *ngIf="currentView(state.mainView.type) as selecetedView" class="chart-icon" style="color: var(--base-color);">{{selecetedView?.icon}}</mat-icon>
       
    </button>
    <mat-menu #viewTypesMenu="matMenu">
        <div class="chart-types">
            <button mat-menu-item class="chart-type" [ngClass]="{ selected: state.mainView.type === view.name }"
                mat-menu-item *ngFor="let view of viewTypes" (click)="changeReportType(view.name);">
                <mat-icon>{{ view.icon }} </mat-icon>
                <span>{{ view.title }}</span>
            </button>
        </div>
    </mat-menu>
</div>
