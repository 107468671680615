<div class="wrapper">
    <div class="header">
        <button *ngIf="showIcon" class="header-icon" mat-icon-button (click)="toggleOuterSidenav()">
            <mat-icon>menu</mat-icon>
        </button>
        Password
    </div>

    <div class="main">
        <div class="password">
            <mat-label>New Password</mat-label>
            <mat-form-field appearance="outline">
                <input matInput placeholder="New Password" (input)="checkPasswordStrength($event)"
                    [type]="hide ? 'password' : 'text'" [formControl]="newPassword" />
                <button mat-icon-button matSuffix (click)="hide = !hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="newPassword.invalid">Password is required</mat-error>
            </mat-form-field>
            <div class="password-checker"
                [ngClass]="{'short' : checkerText == passwordCheckerTextEnum.SHORT, 'weak' : checkerText == passwordCheckerTextEnum.WEAK, 'good' : checkerText == passwordCheckerTextEnum.GOOD, 'excellent' : checkerText == passwordCheckerTextEnum.EXCELLENT }">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="password-text">
                {{checkerText}}
            </div>
        </div>
        <div class="confirm-password">
            <mat-label>Confirm New Password</mat-label>
            <mat-form-field appearance="outline">
                <input matInput placeholder="Confirm New Password" [type]="hide ? 'password' : 'text'"
                    [formControl]="confirmPassword" />
                <button mat-icon-button matSuffix (click)="hide = !hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="newPassword.invalid">Confirm Password is required</mat-error>
            </mat-form-field>
        </div>
        <span class="unmatched-password" *ngIf="isUnMatch">Password do not match</span>
        <span class="weak-password" *ngIf="isPasswordWeak">Password value is weak</span>
    </div>

    <div class="footer">
        <button mat-stroked-button color="primary" (click)="savePassword()">
            <mat-icon>save</mat-icon>
            Save
        </button>
    </div>
</div>