
import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chunkList'
})
@Injectable()
export class ChunkListPipe implements PipeTransform {
 
  transform<T>(a: T[], numberOfChunks: number = 0): T[][] {
    var cl = a.slice(0);//clone
    var results: T[][] = [];
    let chunkSize = Math.ceil(cl.length / numberOfChunks);
    while (cl.length) {
        results.push(cl.splice(0, chunkSize));
    }

    return results;
  }
}
