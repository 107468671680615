import {
    Component,
    Inject,
    Input,
    OnInit,
    Optional
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
    AppSettingsService,
    DRFacetOption,
    DRFilter,
    DRSortCriteria,
    DiscovererQueryService,
    FacetValue,
    IApp,
    IColumnSetting,
    IDashboardFilter,
    ITab2,
    TabSettingsService,
    searchForText
} from '@discoverer/core/services';
import {
    Observable
} from 'rxjs';
import {
    map,
    startWith,
    take
} from 'rxjs/operators';
import {
    IFacet
} from '../facet-list/facet-list.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';

class TabColumns implements ITab2 {
    icon: string;
    name: string;
    key: string;
    serviceUrl: string;
    app: IApp;
    pageSize: number;
    claim: string;
    defaultRequestId?: string;
    requiredClaims?: string;
    defaultFacets?: string[];
    defaultSorts?: DRSortCriteria[];
    order?: number;
    businessKey: string;
    facetTranslationUrl: string;
    nestedPathsKeys: { [key: string]: string; }[];

    facets: IColumnSetting[];
    isSelected?: boolean;
    isValid?: boolean;
}
@Component({
    selector: 'dashboard-filters',
    templateUrl: './dashboard-filters.component.html',
    styleUrls: ['./dashboard-filters.component.scss']
})
export class DashboardFiltersComponent implements OnInit {
    public tabs: TabColumns[] = [];
    tabsDic: { [key: string]: TabColumns } = {}
    myControl = new FormControl();
    filteredfacets: Observable<IColumnSetting[]>;
    selectedFacet: { key: string, name: string, tabs: string[] };
    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public data: { filter: IDashboardFilter },
        @Optional() public dialogRef: MatDialogRef<any>,
        private _appSettingsService: AppSettingsService,
        private _tabSettingsService: TabSettingsService
    ) {
    }

    async ngOnInit() {
        var tabs = (await this._appSettingsService.getCurrentAppTabs());
        this.tabs = await Promise.all(tabs.map(async (tab, i) => {
            const facets = await this._tabSettingsService.getTabColumns(tab);
            return { ...tab, facets };
        }));
        this.tabsDic = Object.assign({}, ...this.tabs.map((tab) => ({ [tab.key]: tab })));

        this.filteredfacets = this.myControl.valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.display),
                map(display => this._filter(display || '')));

        if (this.data?.filter) {
            const key = this.data?.filter?.filter?.fields[0];
            const col = (await this.filteredfacets.pipe(take(1)).toPromise()).find(x => key === x.fieldName);
            const name = col.display;
            const selectedTabs = this.data?.filter?.tabs
            this.selectedFacet = { key, name, tabs: selectedTabs };

            this.tabs.forEach(t => {
                const isValid = !!t.facets.find(facet => facet.fieldName === key);
                const isSelected = selectedTabs.includes(t.key)
                this._updateTabStatus(t.key, isSelected, isValid);
            });
            this.myControl.setValue(col);
            this.myControl.disable();
        }
    }

    public async onFacetChanged(event: MatAutocompleteSelectedEvent) {
        this.selectedFacet = {
            key: event.option.value.fieldName,
            name: event.option.value.display,
            tabs: []
        };
        this.tabs.forEach(t => {
            const isValid = !!t.facets.find(facet => facet.fieldName === event.option.value.fieldName);
            this._updateTabStatus(t.key, false, isValid);
        });
    }
    
    public close() {
        this.dialogRef.close();
    }
    
    public async addFilter() {
        const filter = this.data?.filter?.filter || await this._addEmptyFilter(this.selectedFacet.key);
        const { tabs } = this.selectedFacet;
        const dashboardFilter: IDashboardFilter = { tabs, filter };
        this.dialogRef.close(dashboardFilter);
    }

    public displayFn(selectedoption) {
        return selectedoption?.display;
    }

    public selectionChanged(event: MatCheckboxChange, tab: ITab2) {
        if (event.checked) {
            this.selectedFacet.tabs.push(tab.key);
        } else {
            this.selectedFacet.tabs = this.selectedFacet.tabs.filter(t => t !== tab.key);
        }
    }

    private _filter(value: string): IColumnSetting[] {
        const filterValue = value.toLowerCase();
        let filteredFacets: IColumnSetting[] = [];
        this.tabs.forEach(tab => {
            const filteredTabFacets = tab.facets.filter(option => searchForText(filterValue, option?.display));
            filteredFacets = filteredFacets.concat(filteredTabFacets);
        });
        return filteredFacets;
    }

    private async _addEmptyFilter(value: string) {
        const newFilter = new DRFilter('facet', [value], [], [], '0');
        newFilter.openFacet = true;
        return newFilter;
    }

    private _updateTabStatus(key: string, isSelected: boolean, isValid: boolean) {
        this.tabsDic[key].isSelected = isSelected;
        this.tabsDic[key].isValid = isValid;
    }
}
