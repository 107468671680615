import { Component, Inject, Input } from '@angular/core';

@Component({
    selector: 'number-card',
    styleUrls: ['./number-card.component.css'],
    templateUrl: './number-card.component.html'
})

export class NumberCardComponent {

    @Input()
    num: number;

    @Input()
    description: string;

    constructor() { }
}
