import { ReportSubType } from "../../core/services/classes/report-state";


export interface IChartType {
    id: number;
    icon: string;
    title: string;
    type: string;
    isStack: boolean;
    isHorizontal: boolean;
    subType: ReportSubType;
}

export const CHART_TYPES: IChartType[] = [
    {
        id: 0,
        icon: 'bar_chart',
        title: 'Bar',
        type: 'column',
        isStack: false,
        isHorizontal: false,
        subType: ReportSubType.COLUMN_BAR
    },
    {
        id: 1,
        icon: 'insert_chart',
        title: 'Stack',
        type: 'column',
        isStack: true,
        isHorizontal: false,
        subType: ReportSubType.COLUMN_STACK
    },
    // {
    //     id: 2,
    //     icon: 'equalizer',
    //     title: 'Area',
    //     type: 'area',
    //     isStack: false,
    //     isHorizontal: false
    // },
    {
        id: 3,
        icon: 'show_chart',
        title: 'Line',
        type: 'line',
        isStack: false,
        isHorizontal: false,
        subType: ReportSubType.LINE
    },
    // {
    //     id: 4,
    //     icon: 'branding_watermark',
    //     title: 'Waterfall',
    //     type: 'waterfall',
    //     isStack: false,
    //     isHorizontal: false
    // },
    {
        id: 5,
        icon: 'short_text',
        title: 'Horizontal Stack',
        type: 'bar',
        isStack: true,
        isHorizontal: true,
        subType: ReportSubType.HORIZONTAL_STACK
    },
    {
        id: 6,
        icon: 'short_text',
        title: 'Horizontal Bar',
        type: 'bar',
        isStack: false,
        isHorizontal: true,
        subType: ReportSubType.HORIZONTAL_BAR
    },
    {
        id: 7,
        icon: 'pie_chart',
        title: 'Pie',
        type: 'pie',
        isStack: false,
        isHorizontal: false,
        subType: ReportSubType.CIRCULAR_PIE
    },
    {
        id: 8,
        icon: 'grid_on',
        title: 'Indicator',
        type: 'indicator',
        isStack: false,
        isHorizontal: false,
        subType: ReportSubType.INDICATOR
    },{
        id: 9,
        icon: 'calendar_view_week',
        title: 'Vertical Indicator',
        type: 'vertical-indicator',
        isStack: false,
        isHorizontal: false,
        subType: ReportSubType.VERTICAL_INDICATOR,
    }
];
