import { Inject, Injectable } from '@angular/core';
import { Router, ActivationEnd, ActivationStart } from '@angular/router';
import { filter, first, map, switchMap, take, throttleTime } from 'rxjs/operators';
import { Observable, BehaviorSubject, interval, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { AUTH_SERVER_URL, AUTH_PRODUCT_ID, AUTH_USE_PRODUCT_LOGIN, AUTH_MAIN_ACCOUNT_ID } from './auth.model';
import { environment } from '@env/environment';

export class GatewayIdentityUser {
    constructor(
        public userId: number,
        public email: string,
        public fullName: string,
        public roles: string[],
        public claims: string[],
        public token: string,
        public accountId: number,
        public accountName: string
    ) { }

}

export class Credentials {
    constructor(public userId: string, public token: string) {

    }
}

@Injectable(
    {
        providedIn: 'root',
    }
)
export class AuthService {

    public gatewayIdentityUserObservable: Observable<GatewayIdentityUser>;
    private tokenObservable: Observable<Credentials>;

    private _UserSubject: BehaviorSubject<GatewayIdentityUser>;
    private _TokenSubject: BehaviorSubject<Credentials>;

    constructor(private _router: Router,
        private _httpClient: HttpClient,
        public location: Location,

        @Inject(AUTH_SERVER_URL) public _authServerUrl: string,
        @Inject(AUTH_PRODUCT_ID) private _productCode: string,
        @Inject(AUTH_MAIN_ACCOUNT_ID) private _authMainAccountId: number,
        @Inject(AUTH_USE_PRODUCT_LOGIN) private _authUseProductLogin: boolean) {


        const token = localStorage.getItem('access-token');
        const userId = localStorage.getItem('user-id');

        this._UserSubject = new BehaviorSubject(null);
        this._TokenSubject = new BehaviorSubject(userId != null && token != null ? new Credentials(userId, token) : null);

        this.gatewayIdentityUserObservable = this._UserSubject.asObservable();
        this.tokenObservable = this._TokenSubject.asObservable();

        this.monitorForTokenParamAndFetchUser();

        //this.setToken(token);
    }


    private monitorForTokenParamAndFetchUser() {
        this.whenRouterTokenParameterIsSetSaveToken();
        this.whenIsAuthenticatedFetchUser();

        //every 10 min try to get a new token from existing one
        interval(600000)
            .pipe(take(100), switchMap(x => this.tokenObservable.pipe(first())))
            .subscribe(at => this.getLongToken(at.token, at.userId));
    }


    private setLongToken(val: Credentials): void {
        this._TokenSubject.next(val);
    }

    private whenRouterTokenParameterIsSetSaveToken() {
        this._router.events.pipe(filter(e => (e instanceof ActivationStart) && !!e.snapshot.queryParams.token),
            map((e: ActivationStart) => e.snapshot.queryParams), throttleTime(100))
            .subscribe(async p => {
                console.log('Received Token: ' + p.token + ' UserId:' + p.userId);
                await this.getLongToken(p.token, p.userId);
                this._router.navigate([], {
                    queryParams: {
                        'token': null,
                        'key': null,
                        'userId': null,
                    },
                    queryParamsHandling: 'merge'
                });
            });
    }

    private sendRemoteLoginRequest(token: string, userId: string): Promise<any> {
        return this._httpClient.post<any>(
            `${this._authServerUrl}api/${this._productCode}/login`,
            {
                userId: userId,
                token: token
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + token,
                },
                observe: 'response',
                withCredentials: false
            }).pipe(first()).toPromise();
    }

    private async getLongToken(token: string, userId: string) {
        var result =  await this.sendRemoteLoginRequest(token, userId);
        var accessToken = result.headers.get('Authorization');
        console.log('Setting access Token: ' + accessToken);
        localStorage.setItem('access-token', accessToken);
        localStorage.setItem('user-id', userId);
        localStorage.setItem('last-logged-in', result.body.lastLoggedIn);
        this.setLongToken(new Credentials(userId, token));
    }

    private whenIsAuthenticatedFetchUser() {
        this.tokenObservable.pipe(
            filter(i => i != null)
        ).pipe(
            switchMap(t => this._httpClient.get<any>(`/api/es/v1/user`)),
            map(result => new GatewayIdentityUser(
                result.userId,
                result.email,
                result.fullName,
                result.roles,
                result.claims,
                result.token,
                result.accountId,
                result.accountName
            ))
        ).subscribe(this._UserSubject);
    }


    /**
    * Check the authentication status
    */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._UserSubject.getValue() != null) {
            return of(true);
        }

        if (this._TokenSubject.getValue() != null) {
            return this._UserSubject.asObservable().pipe(filter(i => i != null), take(1), map(x => x != null));
        }

        return of(false);
    }

    public goToLogin(redirectURL: string) {
        localStorage.setItem('redirect-url', redirectURL);

        localStorage.removeItem('access-token');
        localStorage.removeItem('user-id');

        const landingUrl = new URL(window.location.origin + '/auth/landing');
        const landingUrlEncoded = encodeURIComponent(landingUrl.toString());
        const casoAuthTokenGenerator = encodeURIComponent(`/api/${this._productCode}/redirect?url=${landingUrlEncoded}`);
        const queryString = this._authUseProductLogin ? this.getProductLoginUrl(casoAuthTokenGenerator) : `?redirect=${casoAuthTokenGenerator}`;

        if (this._authServerUrl.endsWith('public/')) {
            window.location.href = '/';
        }
        else {
            window.location.href = `${this._authServerUrl}/auth/login${queryString}`;
        }
    }

    private getProductLoginUrl(redirect: string): string {

        let productLoginUrl = `?productKey=${this._productCode}`;

        if (this._authMainAccountId > 0)
            productLoginUrl += `&accountId=${this._authMainAccountId}`;

        productLoginUrl += `&redirect=${redirect}`;

        return productLoginUrl;
    }

}
