import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule} from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DynFormsModule } from "@discoverer/dynamic-forms/dyn-forms/dyn-forms.module";
import { DiscoFormControlComponent } from './disco-form-control.component';
import { DiscoFormComponent, } from './disco-form.component';
import { ValidationDialogComponent } from './validation-dialog/message-component';

@NgModule({
  declarations: [
    DiscoFormComponent,
    DiscoFormControlComponent,
    ValidationDialogComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    DynFormsModule,
    MatDialogModule
  ],
  exports: [
    DiscoFormComponent,
    DiscoFormControlComponent],
  entryComponents: [
    DiscoFormComponent,
    DiscoFormControlComponent,
    ValidationDialogComponent
  ]
})
export class DiscoFormModule {

}
