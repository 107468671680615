import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';

@Component({
  selector: 'dyn-hyper-link',
  template: `
  <ng-container *ngIf="!to.disabled">
  <input
      *ngIf="to.type !== 'number'; else numberTmp"
      matInput
      [id]="id"
      [type]="'type' || 'text'"
      [readonly]="to.readonly"
      [required]="to.required"
      [errorStateMatcher]="errorStateMatcher"
      [formControl]="$any(formControl)"
      [formlyAttributes]="field"
      [tabIndex]="to.tabindex"
      [placeholder]="to.placeholder"
    />
   
<ng-template #numberTmp>
  <input
    matInput
    [id]="id"
    [type]="'number'"
    [readonly]="to.readonly"
    [required]="to.required"
    [errorStateMatcher]="errorStateMatcher"
    [formControl]="$any(formControl)"
    [formlyAttributes]="field"
    [tabIndex]="to.tabindex"
    [placeholder]="to.placeholder"
  />
  </ng-template>
</ng-container>

  <a href="{{model[field.key.toString()]}}" *ngIf="to.disabled">{{model[field.key.toString()]}}</a>
`,
  styleUrls: ['./formly-hyper-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyHyperLinkComponent extends FieldType implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }
}
