
<form [formGroup]="form" *ngIf="!to.disabled; else temp">
    <formly-form [model]="model"  [fields]="fields" [options]="options" [form]="form"></formly-form>
</form>

<ng-template #temp>
    <div class="flex flex-col">
        <label class="container_placeholder">{{this.field.templateOptions.label}} *</label>
        <input type="text" value="{{formControl.value}}">
    </div>
</ng-template>

