import { Clipboard } from '@angular/cdk/clipboard';
import { Location } from '@angular/common';
import { ChangeDetectorRef, Directive, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BaseController, DataSourceContext, DiscovererQueryService, TabSettingsService } from '@discoverer/core';
import { DatasetFileService } from '../../services/api/dataset-file.service';
import { ConfirmationDialogComponent } from '../attoms/confirmation-dialog/confirmation-dialog.component';
import { ERROR_SNACKBAR_OPTIONS, SUCCESS_SNACKBAR_OPTIONS } from '../home/edit-title-dialog/edit-title-dialog';
import { EmitterService } from '../../services/emitter.service';

@Directive({})
export class DocumentViewDetailsDirective extends BaseController implements OnInit, OnDestroy {
    public source!: 'records' | 'queue';
    public fileKey!: 'File.file_id' | 'Id';
    public start: number = 0;
    public currentReqId = 'default';
    public fileId: string;
    public datasetId: string;
    public fileName: string;
    public fileExtension;
    public dateReceived: string;
    public isCopied = false;
    public isEditMode = false;
    public documentIsCollapsed = false;
    public showLogs = false;
    public dragging = false;
    public dataSourceContext: DataSourceContext;
    public queryService: DiscovererQueryService = new DiscovererQueryService()
    public loading = false;
    public result: any;
    public fields: string[] = [];
    public fieldsLoading: boolean = true;
    public saveLoading: boolean;

    constructor(
        private data,
        private _datasetFileService: DatasetFileService,
        private clipboard: Clipboard,
        private location: Location,
        private cdr: ChangeDetectorRef,
        private _router: Router,
        private _tabSettingService: TabSettingsService,
        private _dialog: MatDialog,
        private _snackBar: MatSnackBar,

        private _emitterService: EmitterService,
    ) { super() }

    public copyResult(result: any) {
        const resultJson = JSON.stringify(result);
        this.isCopied = true;
        this.clipboard.copy(resultJson);
        setTimeout(() => this.isCopied = false, 4000);
    }
    public async setContext($event: DataSourceContext) {
        this.loading = true
        this.dataSourceContext = $event
        this.fileId = this.dataSourceContext.data[0][this.fileKey]
        this.location.replaceState(this._buildNewUrl(this.fileId));
        await this._fetchFileLog();
        this.loading = false
    }
    dataFetched($event) {
        this.result = $event
    }

    public move(direction: 'next' | 'prev') {
        if (direction === 'next' && this.start + 1 >= this.dataSourceContext?.total) return;
        if (direction === 'prev' && this.start <= 0) return;
        direction == 'prev' ? this.start-- : this.start++;
        this.dataSourceContext.pageChange(this.start, 1);
    }
    public async ngOnInit(): Promise<void> {
        this._initializeRouteParams();
        this._initializeQueryParams();
        await this._fetchFileLog();
    }
    closeDialog() {
        this._router.navigate(['datasets', this.datasetId, this.source], { queryParams: { refresh: true } });
    }
    public async deleteRow() {
        const fileId = this.fileId;
        const datasetId = this.datasetId;
        const deleteDialogRes = await this._dialog.open(ConfirmationDialogComponent, { width: '420px', data: { message: "Are you sure you want to delete this file ?" } }).afterClosed().toPromise();
        if (deleteDialogRes) {
            const response = await this._datasetFileService.deleteById(datasetId, fileId).toPromise();
            if (!response.isSuccess) this._showMsg(`Error deleting the file`, ERROR_SNACKBAR_OPTIONS);
            else {
                this._showMsg(`File deleted successfully`, SUCCESS_SNACKBAR_OPTIONS);
                await this._emitterService.getTotalNeedsReprocessingFilesCount();
                this.closeDialog();
            }
        }
        this.cdr.detectChanges();
        this.queryService.refresh();
    }
    public async cancelEditMode() {
        this.isEditMode = false;
        // this.queryService.refresh();
    }

    private _getFieldResult(field: string) {
        const keys = field.split('.');
        var result = this.result
        for (const key of keys) {
            result = result[key];
        }
        return result;
    }

    private updateFieldResult(field: string, fieldResult: any) {
        const keys = field.split('.');
        let result = this.result;

        keys.forEach((key, index) => {
            if (index === keys.length - 1) {
                // Last key, set the value
                result[key] = fieldResult;
            } else {
                // Navigate deeper into the nested structure
                result = result[key];
            }
        });
    }


    async save() {
        this.saveLoading = true;
        setTimeout(async () => {
            var tabColumns = (await this._tabSettingService.getAllColumns())
            var arrayCols = tabColumns.filter(col => col.dataType === 'object_array')
            arrayCols.forEach(col => {
                var fieldResult = this._getFieldResult(col.fieldName)
                this.updateFieldResult(col.fieldName, fieldResult.filter(data => Object.values(data).some(r => !!(r?.toString()))))
            })
            console.log(' result', this.result)
            await this._datasetFileService.updateFileResults(this.datasetId, this.fileId, { parser_0: this.result }).toPromise()
            this.isEditMode = false;
            this.saveLoading = false;
        }, 100);
    }
    async reprocessFile() {
        const reprocessFile = await this._dialog.open(ConfirmationDialogComponent, { data: { message: "Are you sure you want to reprocess this file ?" } }).afterClosed().toPromise()
        if (reprocessFile) {
            const response = await this._datasetFileService.reprocessById(this.datasetId, this.fileId).toPromise()
            if (!response.isSuccess) this._showMsg(`Error reprocessing the file`, ERROR_SNACKBAR_OPTIONS);
            else {
                await this._emitterService.getTotalNeedsReprocessingFilesCount();
                this._showMsg(`File started reprocessing successfully`, SUCCESS_SNACKBAR_OPTIONS);
            }
        }
    }

    private _showMsg(msg: string, config: MatSnackBarConfig) {
        this._snackBar.open(msg, 'close', config)
    }
    private _getQueryParamsString() {
        const { currentReqId } = this.data.routeSnapshot.parent.queryParams;
        return `currentReqId=${currentReqId}&start=${this.start}`;
    }
    private _buildNewUrl(fileId: string) {
        const queryParams = this._getQueryParamsString();
        return `/datasets/${this.datasetId}/${this.source}/${fileId}?${queryParams}`;
    }
    private _initializeRouteParams(): void {
        const { datasetId: datasetId, fileId } = this.data.routeSnapshot.parent.params;
        this.datasetId = datasetId;
        this.fileId = fileId;
    }
    private _initializeQueryParams(): void {
        const { currentReqId, start } = this.data.routeSnapshot.parent.queryParams;
        this.currentReqId = currentReqId;
        this.start = +start;
    }
    private async _fetchFileLog(): Promise<void> {
        try {
            const fileLogRes = await this._datasetFileService.getLogs(this.datasetId, this.fileId).toPromise();
            if (fileLogRes?.isSuccess && fileLogRes?.result) {
                const inputInfo = fileLogRes.result[0]?.input_info;
                this.fileName = inputInfo?.['original_file_name'];
                this.fileExtension = inputInfo?.['file_extension'];
                this.dateReceived = inputInfo?.['uploaded_on'];
            }
        } catch (error) {
            console.error('Error fetching file log:', error);
        }
    }
}
