import { FormControl } from '@angular/forms';
import { GroupBy, DRSortCriteria } from '@discoverer/core/services'
import { Subscription } from 'rxjs';

export const DEFAULT_AXIS_LIMIT = 5000;

export class ChartAxis {
    public colName: string;
    public colType: string;
    public groupBy: GroupBy;
    public groupByName: string;
    public availableGroups: Array<string>;
    public numberOfGroups: number;
    public type: string;
    public minMaxSubscription: Subscription;
    public sortDir: 'asc' | 'desc';
    public minCount: number;
    public sortType: ('value' | 'label');
    public limit: number;
    public index?: number;
    public isValid: boolean;
    public offset?: number;
    public compareOffset: number;
    public seriesSortDir: 'asc' | 'desc';
    public seriesSort: string;
    public compareType: 'Values' | 'Percentage';
    public display? : string;
    constructor(t: string) {
        this.type = t;
        this.sortDir = 'asc';
        this.seriesSort = 'value';
        this.seriesSortDir = 'desc';
        this.isValid = false;
        this.offset = 0;
        this.limit = DEFAULT_AXIS_LIMIT;
    }
}
export class ChartControlAxis extends ChartAxis {
    public display?: string;
    public subTitle?: string;
    constructor(t: string) {
        super(t);
    }
}
