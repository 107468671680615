import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FadeInOutOptimizedAnimation, heightExpand } from '@app/app.animation';

import { switchMap, filter } from 'rxjs/operators';

import { OneChartViewComponent, PivotChartComponent } from '../common';
import { Router } from '@angular/router';
import { BaseController, IDRFilter, ITab2, ReportPersistService, TabSettingsService } from '@discoverer/core/services';
import { KendoTableViewComponent } from '@discoverer/core/kendo-table-view'

@Component({
  selector: 'reports-view',
  templateUrl: './reports-view.component.html',
  styleUrls: ['./reports-view.component.scss'],
  animations: [FadeInOutOptimizedAnimation, heightExpand],
  providers: []
})
export class ReportsView extends BaseController implements OnInit {
  public currentTab: ITab2;
  public selectedView = null;
  public currentRequestId;

  @Output()
  public rowClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() public height;
  @Input() public chartMaxLabels: number = 10;
  @Input() public isPreview = false;
  @Input() public filters: { [key: string]: IDRFilter; };
  @Input() public navigateToDetailDirectly: boolean = true;
  @Input() visibleDetailsHeader = true;
  @Input() enableMultiSelect;
  @ViewChild('chart_view') chartComponent: OneChartViewComponent;
  @ViewChild('pivot_view') pivotComponent: PivotChartComponent;
  @ViewChild('table_view') tableComponent: KendoTableViewComponent;
  @Output() public navigateToOtherDetails?: EventEmitter<any> = new EventEmitter<any>();
  @Output() public numberOfRecordsEvent?: EventEmitter<number> = new EventEmitter<number>();
  @Input() isEditMode: boolean;
  constructor(
    public reportPersist: ReportPersistService,
    private _tabSettings: TabSettingsService,
    private _router: Router
  ) {
    super();
  }

  public sendTotalNumberOfRecords(total: number) {
    this.numberOfRecordsEvent.next(total)
  }

  public exportPivot() {
    this.pivotComponent.exportToExcel();
  }
  public exportChart(type: string) {
    this.chartComponent.exportChart(type);
  }

  public navigateToDetail(data: any) {
    this._router.navigate([{ outlets: { dialog: [this.currentTab.app.key, 'detail', this.currentTab.key, data?.currentObject[this.currentTab.businessKey]] } }]);
  }
  public async export(type?: string) {
    switch (this.selectedView) {
      case 'pivot-view':
        this.pivotComponent.exportToExcel();
      case 'chart-view':
        this.chartComponent.exportChart(type);
      case 'table-view':
        await this.tableComponent.exportToExcel(this.currentRequestId);
      default:
        console.warn('Export not implemented');
    }
  }
  public navigateToDetails(data: any) {
    if (this.navigateToDetailDirectly) {
      this.navigateToDetail(data);
    }
    else {
      this.navigateToOtherDetails.emit(data);
    }
  }
  async ngOnInit() {
    this.currentTab = await this._tabSettings.getCurrentTab();
    this.subscriptions.push(
      this.reportPersist.oRequestLoaded.pipe(
        filter(loaded => !!loaded),
        switchMap(() => this.reportPersist.mainViewState.oState)
      ).subscribe((state) => {
        this.currentRequestId = this.reportPersist.loadReqId;
        if (!!state) {
          this.selectedView = (state.type) ? state.type : 'table-view';
          if (this.filters) {
            Object.keys(this.filters).forEach(key => {
              this.reportPersist.mainQueryService.setFilter(key, this.filters[key]);
            });
            this.reportPersist.mainQueryService.refresh();
          }
        }
      })
    );
  }
}

