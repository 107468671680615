export interface Theme {
    name: string;
}
export const light: Theme = {
    name: 'light'
};

export const dark: Theme = {
    name: 'dark'
};

export const calson: Theme = {
  name: 'calson'
};
export const sett: Theme = {
  name: 'sett'
};

