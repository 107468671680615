<ng-container *ngIf="loadingState && loadingState.status == 'Busy' "> 
    <div class="loader-center">
        <mat-spinner></mat-spinner>
    </div>
</ng-container>

<ng-container  *ngTemplateOutlet="template; context: {$implicit: context | async}"></ng-container>

<ng-container *ngIf="loadingState && loadingState.status == 'Failure' "> 
    <div class="loader-center">
        {{loadingState.errorDescription}}
    </div>
</ng-container>