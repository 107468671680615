<div class="wrapper">
    <div class="header flex justify-between">
        <div class="left-wrapper">
            <div class="close-icon">
                <button mat-icon-button (click)="dialogRef.close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <div class="integration-info">Logs</div>
        </div>
    </div>
   <div class="main">
       <div class="dialog-body">
           <disco-tab [appId]="appId" [tabId]="'logs'">
               <ng-template let-tabContext>
                   <disco-report [queryService]="queryService" *ngIf="tabContext"
                   [requestId]="'82668799-f15f-409c-95ce-11979a860000'">
                       <ng-template let-reportContext>
                           <div class=" flex flex-col min-w-0 overflow-hidden noselect">
                               <div class="flex align-center gap-2 search-input">
                                   <disco-search [queryService]="queryService"></disco-search>
                               </div>
                               <reports-view [visibleDetailsHeader]="false"
                                   [height]="'calc(100vh - 240px) !important'"
                                   [isEditMode]="false" [filters]="filters"></reports-view>
                           </div>
                       </ng-template>
                   </disco-report>
               </ng-template>
           </disco-tab>
       </div>
   </div>
</div>
