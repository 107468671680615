import { Injectable, Inject } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { BaseController } from '../base-controller';
import { HttpClient } from '@angular/common/http';
import { ITab, ITab2, TabSettingsService } from '.';
import { disLogger } from '../functions';
import { first, map, take } from 'rxjs/operators';
import { ThemeService } from '@app/theme/theme.service';
import { UserPermissionService } from './user-permission.service';

export interface IApp {
    name: string;
    key: string;
    color: string;
    serviceUrl: string;
    theme: string;
    claims: string[];
    defaultTabKey: string;
    logo?: string;
    permissioningIsEnabled?: boolean;
    defaultDashboard?: string;
}

@Injectable()
export class AppSettingsService extends BaseController {

    public oCurrentApp: Observable<IApp>;
    public oCurrentAppTabs: Observable<ITab2[]>;

    private _$currentApp = new ReplaySubject<IApp>(1);
    private _$currentAppTabs = new ReplaySubject<ITab2[]>(1);

    constructor(
        private _httpClient: HttpClient,
        private _themeService: ThemeService,
    ) {
        super();

        this.oCurrentApp = this._$currentApp.asObservable();
        this.oCurrentAppTabs = this._$currentAppTabs.asObservable();
        this.whenCurrentAppChangedEmitCachedTabs();
    }


    public async getCurrentApp() {
        return this.oCurrentApp.pipe(first()).toPromise();
    }
    public async getCurrentAppTabs() {
        return this.oCurrentAppTabs.pipe(first()).toPromise();
    }

    public async setActiveApp(key: string) {
        const currentApp = await this._httpClient.get(`/api/es/apps/${key}`).pipe(map((data) => {
            return data as IApp;
        })).toPromise();
        if (!currentApp) {
            throw `App ${key} was not found`;
        }
        this._$currentApp.next(currentApp);
    }

    public async cacheTab(tab: ITab2) {
        const activeApp = await this.oCurrentApp.pipe(take(1)).toPromise()
        console.log('fafa activeApp: ', activeApp);
        _appSettingsCache[activeApp.key] = _appSettingsCache[activeApp.key] ?? [];
        _appSettingsCache[activeApp.key].push(tab);

        // _appSettingsCache[activeApp.key].push(tab)
        console.log('fafa tabs', _appSettingsCache[activeApp.key])
        this._$currentAppTabs.next(_appSettingsCache[activeApp.key]?.sort((c, b) => c.order - b.order));
        return true;
    }

    public getCachedTabs(app: IApp) {
        return _appSettingsCache[app.key]
    }


    private async whenCurrentAppChangedEmitCachedTabs() {
        this.subscriptions.push(
            this.oCurrentApp.subscribe(async app => {
                console.log('fafa whenCurrentAppChangedLoadTabs called in subs')
                // let tabExists: boolean = !!_appSettingsCache[app.key].find(t => t.key == currentTab.key)
                // if (!tabExists) {

                //     console.log('fafa tab', currentTab)
                //     const _tabs = await this._getAndUpdateAppTabsCache(currentTab.key);
                // }
                // _tabs?.filter(t => !!t?.app).forEach(t => t.app = app); // add the current app.
                // const sorted = _tabs?.sort((c, b) => c.order - b.order);
                this._themeService.setActiveThemeByName(app.theme);
                this._$currentAppTabs.next(_appSettingsCache[app.key]?.sort((c, b) => c.order - b.order));

            })
        );
    }

    private async _getAndUpdateAppTabsCache(tabId: string) {
        const currentApp: IApp = await this.oCurrentApp.toPromise()
        const tabs = await this._httpClient.get(`/api/es/apps/${currentApp.key}/tabs`)?.pipe(map((data: any[]) => {
            return data.map(r => {
                r.app = currentApp;
                r.appId = currentApp.key;
                //r.hideReportControlFeatures = false;
                return r;
            }) as ITab2[];
        })).toPromise();
        console.log('fafa tabs', tabs)
        const targetTab = tabs.find(t => t.key == tabId)[0]
        _appSettingsCache[currentApp.key].push(targetTab)
        console.log('fafa app cache', _appSettingsCache[currentApp.key])
    }

    private async setTabCache(key: string, tab: ITab2) {
        if (!_appSettingsCache[key])
            _appSettingsCache[key] = []
        const index = _appSettingsCache[key].findIndex(cahcedTab => cahcedTab.key == tab.key);
        if (index >= 0) {
            _appSettingsCache[key].splice(index, 1);
        }
        _appSettingsCache[key].push(tab);
    }

    private async getTabs(app: IApp) {
        this._themeService.setActiveThemeByName(app.theme);
        if (!_appSettingsCache[app.key]) {
            const tabs =
                await this._httpClient.get(`/api/es/apps/${app.key}/tabs`)?.pipe(map((data: any[]) => {
                    return data.map(r => {
                        r.app = app;
                        r.appId = app.key;
                        //r.hideReportControlFeatures = false;
                        return r;
                    }) as ITab2[];
                })).toPromise();
            tabs?.forEach(tab => {
                this.setTabCache(app.key, tab);

            });
        }
        return _appSettingsCache[app.key];
    }

    public async updateAppTabs(tabId: string) {
        const app = await this.getCurrentApp()
        if (!_appSettingsCache[app.key]) this._getAndUpdateAppTabsCache(tabId);
        else {
            const tab = await this._httpClient.get(`/api/es/apps/${app.key}/tabs`).pipe(map((tabs: any[]) => {
                return tabs.find(t => t.key == tabId) as ITab2
            })).toPromise();
            if (!!tab) {
                _appSettingsCache[app.key]?.splice(_appSettingsCache[app.key].findIndex(t => t?.key == tabId), 1, tab);
            } else {
                _appSettingsCache[app.key].push(tab);
            }
        }
    }


    public async updateAppTabsCache(tab: ITab2) {
    }
}

const _appSettingsCache: { [key: string]: ITab2[] } = {};
