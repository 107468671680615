<div *ngIf="!to.disabled">
  <mat-card-content class="demo-card-content">
    <div style="padding-top: 30px">
      <cron-editor [formControl]="cronForm" [options]="cronOptions"></cron-editor>
    </div>
    <mat-form-field appearance="fill">
      <input matInput class="alignleft" placeholder="Enter Cron Exp" [tabIndex]="to?.tabindex" type="text"
        [formControl]="cronForm" [value]="cronExpression" required />
    </mat-form-field>
  </mat-card-content>
</div>
<div *ngIf="to.disabled">
  <p>{{cronExpression}}</p>
</div>