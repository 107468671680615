import { Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-stepper',
    template: `
    <mat-horizontal-stepper>
      <mat-step *ngFor="let step of field.fieldGroup; let index = index; let last = last">
        <ng-template matStepLabel>{{ step.templateOptions.label }}</ng-template>
        <formly-field [field]="step"></formly-field>

        <div class="mt-2 mb-2">
          <button matStepperPrevious *ngIf="index !== 0" style="margin-right:10px;background-color:var(--base-color); color: var(--main-color);" mat-raised-button class="btn btn-primary" type="button">Back</button>

          <button matStepperNext *ngIf="!last" mat-raised-button style="background-color:var(--base-color); color: var(--main-color);" class="btn btn-primary" type="button" [disabled]="!isValid(step)">
            Next
          </button>

        </div>
      </mat-step>
    </mat-horizontal-stepper>
  `,
})
export class DynStepperComponent extends FieldType {
    isValid(field: FormlyFieldConfig): boolean {
        if (field.key) {
            return field.templateOptions.hidden ? true : field.formControl.status == 'DISABLED' ? true : field.formControl.valid;
        }

        return field.fieldGroup ? field.fieldGroup.every((f) => this.isValid(f)) : true;
    }
}
