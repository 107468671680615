import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';

@Injectable()
export class ControlBarDataService {

    private $detailsTitle: ReplaySubject<string>;
    public detailsTitle: Observable<string>;
    constructor() {
        this.$detailsTitle = new ReplaySubject<string>(1);
        this.detailsTitle = this.$detailsTitle.asObservable();
    }

    public setCurrentDetailsTitle(title: string) {
        this.$detailsTitle.next(title);
    }
}
