
import { HttpClient } from '@angular/common/http';
import { AppliedFiltersDirective } from '../applied-filters-list/applied-filters.directive';
import { MatDialog } from '@angular/material/dialog';
import { DiscovererFacetTranslationService, ReportPersistService, TabSettingsService } from '@discoverer/core/services';
import { Apollo } from 'apollo-angular';
import { Component } from '@angular/core';


@Component({
    selector: 'vertical-applied-filters',
    templateUrl: './vertical-applied-filters.component.html',
    styleUrls: ['./vertical-applied-filters.component.scss']
})
export class VerticalAppliedFiltersComponent extends AppliedFiltersDirective {

    constructor(
        dialog: MatDialog,
        facetTranslationService: DiscovererFacetTranslationService,
        http: HttpClient,
        apollo: Apollo,
        _tabSettings: TabSettingsService,
        _reportPersistService: ReportPersistService
    ) {
        super(dialog, facetTranslationService, http, apollo, _tabSettings, _reportPersistService);
    }

}
