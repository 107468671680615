<div class="wrapper">
    <div *ngIf="true && data.value" class="table-wrapper">
        <p class="field-name">{{data.display}}
            <mat-icon class="copy-icon" *ngIf="!isCsvCopied" (click)="copyAsCsv()"
                matTooltip="Copy As CSV ">content_copy</mat-icon>
            <mat-icon class="copy-icon" *ngIf="isCsvCopied">check</mat-icon>
        </p>

        <kendo-grid #grid [data]="data.value" [resizable]="true">
            <kendo-grid-column [width]="200" *ngFor="let col of displayedColumns" field="{{col.fieldName}}"
                title="{{col.display}}" ></kendo-grid-column>
        </kendo-grid>
    </div>
</div>
