<div layout="row">
    <ng-container *ngIf="(tableHeaderService.tableHeader | async) as tableHeaderConfig">
        <span class="filter-groups" *ngIf="(queryService.oQuery | async) as currentQuery">
            <span class="grouping-warning"
                *ngIf="currentQuery.groups?.length && tableHeaderConfig?.resultLength > 2000">
                Only 2000 rows are currently viewed.
            </span>
        </span>
        <ng-container *ngIf="reportPersist.oLastRequestData | async as state">
            <div class="line2" *ngIf="state?.mainView?.type === 'table-view'">
                <span class="info" *ngIf="tableHeaderConfig?.resultLength !== 0 ">
                    1 - {{ min(tableHeaderConfig?.skip + tableHeaderConfig?.pageSize, tableHeaderConfig?.resultLength) }} of
                    {{tableHeaderConfig?.resultLength | number}} {{tableHeaderConfig?.lengthIsComplete ? '': '+'}}  {{tabTitle}}</span>

                <b *ngIf="tableHeaderConfig?.resultLength === 0">
                    No Records Found
                </b>

            </div>
        </ng-container>
    </ng-container>
</div>
