import { NgModule } from '@angular/core';

import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { DialogRouterComponent } from './dialog-router.component';
import { DialogResolverService } from './dialog-resolver.service';
import { CommonModule } from '@angular/common';

const dialogOutlet = {
  path: 'dialog-outlet',
  component: DialogRouterComponent,
};

@NgModule({
  declarations: [
    DialogRouterComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    RouterModule.forChild([dialogOutlet]),
  ],
  providers: [
    DialogResolverService,
  ]
})
export class DialogRouterModule { }