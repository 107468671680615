<div class="wrapper">
    <div class="header flex justify-between">
        <div class="left-wrapper">
            <div class="close-icon">
                <button mat-icon-button (click)="dialogRef.close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <div class="integration-info">{{(isEditMode ? 'Edit ' : 'Create ') + getIntegrationName()}}</div>
            <div class="action">
                <button mat-flat-button color="basic" (click)="save()">Save</button>
            </div>
        </div>
    </div>
    <div class="main">
        <div class="overlay" *ngIf="isLoading">
            <mat-spinner></mat-spinner>
        </div>
        <app-dyn-form (result)="onSubmit($event)" #dynForm class="form" [key]="'new-webhooks'" [model]="data"
            [isEditMode]="true">
        </app-dyn-form>
    </div>
</div>