import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IDirRequest } from '../classes/request-state.class';
import { AppSettingsService } from './app-settings.service';



@Injectable()
export class RequestFilingService {


  public sReloadRequests: Subject<boolean> = new Subject<boolean>();
  public reloadRequests: Observable<boolean> = this.sReloadRequests.asObservable();



  constructor(
    private http: HttpClient,
    private _appSettings: AppSettingsService
  ) {

  }
  public loggedInUser(): Observable<any> {
    const url = '/api/authentications';
    return this.http.get(url);
  }


  public async setRequestDirectory(reqId: string, reportName: string, dirId: number, appKey: string, tabId: string) {
    const appId = (await this._appSettings.getCurrentApp()).key;
    const url = `/api/es/directories/${appId}/${dirId}/requests/${reqId}`;
    const isSuccess = await this.http.post(url, { reportName, tabId }).pipe(first()).toPromise();
    this.sReloadRequests.next(true);
    return isSuccess;
  }


  public async addDirectroyForTab(tabId: string, directoryName: string): Promise<any> {
    const appId = (await this._appSettings.getCurrentApp()).key;
    const url = `/api/es/directories/${appId}?tabId=${tabId}&dirName=${directoryName}`;
    const directory = await this.http.post(url, null).toPromise();
    this.sReloadRequests.next(true);
    return directory;
  }
  public async createPublicDirectory(directoryId): Promise<any> {
    const appId = (await this._appSettings.getCurrentApp()).key;
    const url = `/api/es/directories/${appId}/publish/${directoryId}`;
    const directory = await this.http.put(url, null).toPromise();
    this.sReloadRequests.next(true);
    return directory;
  }

  public async editDirectoryName(tabId: string,dirId, newDirName) {
    const appId = (await this._appSettings.getCurrentApp()).key;
    const dirNewName = `${tabId.toUpperCase()}|${newDirName}`;
    const url = `/api/es/directories/${appId}/${dirId}?directoryName=${dirNewName}`;
    const directory = await this.http.put(url, null).toPromise();
    this.sReloadRequests.next(true);
    return directory;
  }

  public async addCommonDirectroyForTab(appId: string, tabId: string, directoryName: string): Promise<any> {
    const url = `/api/es/directories/${appId}/commonDirectory?tabId=${tabId}&dirName=${directoryName}`;
    const directory = await this.http.post(url, null).toPromise();
    this.sReloadRequests.next(true);
    return directory;
  }

  public async deleteDirectory(directoryId): Promise<any> {
    const appId = (await this._appSettings.getCurrentApp()).key;
    const url = `/api/es/directories/${appId}/${directoryId}`;
    const directory = await this.http.delete(url).toPromise();
    this.sReloadRequests.next(true);
    return directory;
  }

  public async getDirectories(appKey: string, tabId: string, filtered: boolean): Promise<IDirRequest[]> {
    const url = `/api/es/directories/${appKey}/${tabId}?filtered=${filtered}`;
    return this.http.get<IDirRequest[]>(url).toPromise();
  }
}
