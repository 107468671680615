export class FacetFieldAccumulator {
  _data: { [id: string]: number | string; } = {};
  _keyMap: { [key: string]: string } = {};

  constructor(public readonly field: string) {
  }

  public accumValues(key: Array<string>, val: number) {
    key.forEach(a => {
      if (a !== undefined) {
        this.accumValue(a, val, 'count');
      }
    });
  }


  public accumValue(key: string, val: number, valueType: 'count' | 'stat1' | 'stat2' | 'stat3' | 'stat4' = 'count') {
    if (key === undefined) {
      return;
    }
    if (valueType === 'count') {
      this._keyMap[key] = key;
    }
    const myKey = key + '||' + valueType.toLowerCase();
    if (this._data[myKey] === undefined) {
      this._data[myKey] = 0;
    }
    this._data[myKey] = +this._data[myKey] + val;

    // Below code is for -ve Number values if the below code is not present( this._data[myKey] = 0-NO instead of a -NO)
    if (val < 0) {
      this._data[myKey] = val;
    }
  }

  public getData() {
    return Object.keys(this._data).map(k => {
      const kType = k.split('||');
      const keySplit = kType[0].split('!>');
      const category = keySplit[0];
      const series = keySplit.length === 2 ? kType[0].split('!>')[1] : '+++';
      const type = kType[1];
      return { category, series, type, val: this._data[k] };
    });
  }


  public getKeyMap() {
    return Object.assign({}, this._keyMap);
  }

  public getLength() {
    return Object.keys(this._data).length;
  }

  public getValue(key: string, valueType = 'count'): number {
    const myKey = key + '||' + valueType;
    if (this._data[myKey] === undefined) {
      return null;
    }

    return +this._data[myKey];
  }

  public getValueString(key: string, valueType = 'count'): string {
    const myKey = key.toLowerCase() + '||' + valueType.toLowerCase();
    if (this._data[myKey] === undefined) {
      return null;
    }

    return this._data[myKey] + '';
  }

  // state1 or state2
  public getValueStringState(valueType: string): string {
    const myKey = Object.keys(this._data).find(k => k.includes(valueType));
    if (this._data[myKey] === undefined) {
      return null;
    }

    return this._data[myKey] + '';
  }

  public getValueByCategoryAndSeries(category: string, series: string, valueType = 'count'): number {
    if (!!series) {
      return this.getValue(category + '!>' + series, valueType);
    } else {
      return this.getValue(category, valueType);
    }
  }
}
