import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { DatasetService } from './api/dataset.service';

export interface GeneralSettings {
    extract_signatures: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class GeneralSettingsService {
    public oGeneralSettings: Observable<GeneralSettings>;
    public datasetId: Observable<string>;
    private _generalSettings$: ReplaySubject<GeneralSettings> = new ReplaySubject<GeneralSettings>(1);
    private _datasetId$: ReplaySubject<string> = new ReplaySubject<string>();
    private _stringDatasetId: string;

    constructor(private _datasetService: DatasetService) {
        this.oGeneralSettings = this._generalSettings$.asObservable();
        this.datasetId = this._datasetId$.asObservable();

        this.datasetId.subscribe((id: string) => {
            if (!this._stringDatasetId || this._stringDatasetId !== id) {
                this._stringDatasetId = id;
                this.refreshGeneralSettings(id);
            }
        });
    }

    public setDatasetId(id: string) {
        this._datasetId$.next(id);
    }

    public refreshGeneralSettings(id: string) {
        this._datasetService.getGeneralSettings(id).toPromise().then(value => {
            const result = value.result || { extract_signatures: false } as GeneralSettings;
            this._generalSettings$.next(result);
        });
    }
}