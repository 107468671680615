import { Component, Input, TemplateRef, ElementRef, ContentChild, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { filter, first } from 'rxjs/operators';

import { DiscovererQueryService, IDynamicReport, TabSettingsService, ReportPersistService, BaseController } from '@discoverer/core/services';
import { ActivatedRoute } from '@angular/router';

export class DiscoReportContext {
    constructor(
        public readonly currentReport: IDynamicReport,
        public readonly mainQueryService: DiscovererQueryService,
        public readonly currentReqId: string,
        public readonly loadReqId: string,
        public readonly reportPersistService: ReportPersistService
    ) {
    }
}

@Component({
    selector: 'disco-report',
    templateUrl: './disco-report.component.html',
    styleUrls: ['./disco-report.component.scss'],
    providers: [ReportPersistService]
})
export class DiscoReportComponent extends BaseController implements OnInit, OnChanges {

    @Input()
    set requestId(val: string) {
        this._requestId = val;
    }

    @Input()
    set queryService(val: DiscovererQueryService) {
        this._queryService = val;
    }

    @Output()
    context = new EventEmitter<DiscoReportContext>(true);

    @ContentChild(TemplateRef) template: TemplateRef<ElementRef>;


    private _requestId: string;
    private _queryService: DiscovererQueryService;

    constructor(private _reportPersist: ReportPersistService, private _tabSettings: TabSettingsService, private activatedRouter: ActivatedRoute) {
        super();
        this.activatedRouter.queryParams.subscribe(params => {
            if (params && params.refresh === 'true') {
                if (this._reportPersist.mainQueryService) {
                    this._reportPersist.mainQueryService.refresh();
                }
            }
        });
    }

    private async setRequestId() {

        if (this._requestId) {
            const tab = await this._tabSettings.getCurrentTab();

            var isDefault = this._requestId == 'default';
            var requestId = isDefault ? tab.defaultRequestId : this._requestId;
           

            if (!this._reportPersist.mainViewState) {
              this._reportPersist.createState(this._queryService);
            }
            await this._reportPersist.loadRequest(requestId);

            var request = await this._reportPersist.oLastRequestData.pipe(first()).toPromise();
            const context = new DiscoReportContext(
                request, //currentReport
                this._reportPersist.mainQueryService, //mainQueryService
                this._reportPersist.currentReqId,
                this._reportPersist.loadReqId,
                this._reportPersist);
            
            this.context.emit(context);
            
        }
    }

    public async ngOnChanges(change: SimpleChanges) {
        if (change['requestId']) {
            await this.setRequestId();
            //await this.setRequestId();
        }
    }


    public async ngOnInit() {
      const subscription =
      this._reportPersist
          .oRequestLoaded
          .pipe(filter(request => !!request))
          .subscribe(async request => {
              if( request != null ) {
                const context = new DiscoReportContext(
                    request, //currentReport
                    this._reportPersist.mainQueryService, //mainQueryService
                    this._reportPersist.currentReqId,
                    this._reportPersist.loadReqId,
                    this._reportPersist);
                this.context.emit(context);
                
              }
          });


      this.subscriptions.push(subscription);
    }


}
