import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { FileDetailsDto, FileStatusEnum } from '../../models/datasetFile.models';
import { DatasetFileService } from '../../services/api/dataset-file.service';
import { TaskService } from '../../services/api/task.service';

@Component({
  selector: 'app-document-result',
  templateUrl: './document-result.component.html',
  styleUrls: ['./document-result.component.scss']
})
export class DocumentResultComponent implements OnInit, OnDestroy {
  @Input() datasetId: string
  @Input() sidenavRef: MatSidenav
  @Input() fileId: string;

  isDataExists: boolean;
  isLoading: boolean = false;
  result: any;
  subs: Subscription[] = []

  constructor(
    private _datasetFileService: DatasetFileService,
    private snackbar: MatSnackBar,
    private _taskService: TaskService
  ) { }

  async ngOnInit(): Promise<void> {
    await this._setupObservables();
    await this._getOrWaitForFileResult();
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe())
  }

  private async _setupObservables() {
    await this._getOrWaitForFileResult();
  }

  private async _getOrWaitForFileResult() {
    this.setLoadingValue(true);

    var response = await this._datasetFileService.getFileDetails(this.datasetId, this.fileId).toPromise();
    if (!response.isSuccess) {
      this.setDataExistsValue(false);
      return;
    }

    var fileDetails: FileDetailsDto = response?.result
    var sucessOpt: boolean = fileDetails.Status == FileStatusEnum.PROCESSED ? true : await this._taskService.waitForTask(fileDetails.FileTaskId);
    if (!sucessOpt) {
      this.setDataExistsValue(false);
    }
    var response = await this._datasetFileService.getResults(this.datasetId, this.fileId).toPromise();
    if (!response.isSuccess) {
      this._showMsg(`Error fetching file result, error: ${response?.result}`)
      this.setDataExistsValue(false);
      return;
    } else {
      this.result = response?.result;
      this.setDataExistsValue(true);
    }
    this.setLoadingValue(false);
  }

  private _showMsg(msg: string) {
    this.snackbar.open(msg, 'close', { duration: 2000, politeness: 'polite' })
  }

  setDataExistsValue(value: boolean) {
    this.isDataExists = value;
  }

  setLoadingValue(value: boolean) {
    this.isLoading = value;
  }
}
