import { Directive, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
    selector: '[appDragDrop]'
})
export class DragDropDirective {

    // tslint:disable-next-line: no-output-on-prefix
    @Output() onFilesDrop = new EventEmitter<any>();
    // tslint:disable-next-line: no-output-on-prefix
    @Output() onFilesDragOver = new EventEmitter<any>();
    // tslint:disable-next-line: no-output-on-prefix
    @Output() onFilesDragLeave = new EventEmitter<any>();

    @HostBinding('style.opacity') public opacity = '1';

    @HostListener('dragover', ['$event']) onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.opacity = '0.8';
        this.onFilesDragOver.emit(true);
    }

    @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.opacity = '1';
        this.onFilesDragLeave.emit(true);
    }

    @HostListener('drop', ['$event']) public onDrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.opacity = '1';
        const files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.onFilesDrop.emit(files);
        }
    }
}
