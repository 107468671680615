import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DragulaService } from 'ng2-dragula';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TabSettingsService, IMainViewState } from '@discoverer/core/services/dynamic-reports-services';
import { BaseController, IColumnSetting } from '@discoverer/core/services'
import { disLogger, generateHashFromString } from '@discoverer/core/services'
import { StateService, DiscovererQueryService } from '@discoverer/core/services';
import { first } from 'rxjs/operators';
import { GroupDescriptor } from '@progress/kendo-data-query';
import { AppSettingsService } from '@app/discoverer/core/services/dynamic-reports-services/app-settings.service'
export interface IColumnSettingsDialogData {
    reportColumns: IColumnSetting[];
    groupByColumns :  GroupDescriptor[];
    isColumns: boolean;
    tabSettings: TabSettingsService;
    queryService: DiscovererQueryService;
    mainViewState: StateService<IMainViewState>;
    selectedView: string;
    showGroupFooter: boolean;
    selectedSetting: string;
    // isDefualtFilter: boolean;
}

@Component({
    selector: 'column-settings-customization',
    templateUrl: './column-settings-customization.dialog.html',
    styleUrls: ['./column-settings-customization.dialog.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [DragulaService]
})
// tslint:disable-next-line:component-class-suffix
export class ColumnSettingsCustomizationDialog extends BaseController implements OnInit {
    public selectedStatus = true;
    public rightColumns: IColumnSetting[] = [];
    public defaultColumns: IColumnSetting[] = [];
    public allColumns: IColumnSetting[] = [];
    public defaultFilters: IColumnSetting[] = [];
    public columnsToFlattenData: string[] = [];
    public groups: {
        fieldName: string,
        dir: 'asc' | 'desc'
    }[];
    public sorts: {
        fieldName: string,
        dir: 'asc' | 'desc'
    }[];
    public isDefaultSorts = false;
    public isDefaultColumns = true;
    public isDefaultFilters = true;
    public dataFlattenBy: string = '';
    public currentApp: string;
    public showGroupFooter: boolean;
    public randomSeed:number = 0;

    private groupsToQuery= [];
    private sortsToQuery=[];
    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogInputSettings: IColumnSettingsDialogData,
        private dialogRef: MatDialogRef<ColumnSettingsCustomizationDialog>,
        private snackBar: MatSnackBar,
        private appSettingsService: AppSettingsService) {
        super();
    }

    public async ngOnInit() {
        this.currentApp = (await this.appSettingsService.getCurrentApp()).key;
        disLogger('ngOnInit columns dialog', this.dialogInputSettings);
        this.showGroupFooter = this.dialogInputSettings?.showGroupFooter || false;
        const tabSettings = this.dialogInputSettings.tabSettings;
        this.rightColumns = this.dialogInputSettings.reportColumns;
        if (this.rightColumns == undefined) {
            this.rightColumns = [];
        }
        this.allColumns = (await tabSettings.getAllColumns()).filter(c => !c.hidden);
        this.defaultColumns = await tabSettings.getDefaultColumns();
        this.defaultFilters = await tabSettings.getDefaultFacetColumns();
        this.columnsToFlattenData = this.allColumns.filter(c => c.nestedPath).filter(c => c.nestedPath.split('.').length == 1).map(c => c.nestedPath).filter((value, index, groupName) => groupName.indexOf(value) === index);

        disLogger('ngOnInit columns dialog', this.allColumns, this.defaultColumns, this.defaultFilters, this.columnsToFlattenData);

        const rc = this.rightColumns.map(s => s.fieldName).join('');
        const dc = this.defaultColumns.map(s => s.fieldName).join('');
        const df = this.defaultFilters.map(s => s.fieldName).join('');
        this.isDefaultColumns = (generateHashFromString(rc) === generateHashFromString(dc));
        this.isDefaultFilters = (generateHashFromString(rc) === generateHashFromString(df));
        const queryService = (await this.dialogInputSettings.queryService.oQuery.pipe(first()).toPromise());
        const columnGroups =this.dialogInputSettings.groupByColumns;
        this.dataFlattenBy = queryService.dataFlattenBy;
        this.randomSeed = queryService.randomSeed;
        this.groups = columnGroups && columnGroups.length ? columnGroups.map(g => (
            {
                fieldName: g.field,
                dir: g.dir
            } as {
                fieldName: '',
                dir: 'asc' | 'desc'
            })) : [];
        const sortColumns = (await this.dialogInputSettings.queryService.oQuery.pipe(first()).toPromise()).sorts.map(s => ({
            fieldName: s.sortField,
            dir: s.dir
        }));
        this.sorts = sortColumns && sortColumns.length ? sortColumns.map(g => (
            {
                fieldName: g.fieldName,
                dir: g.dir
            } as {
                fieldName: '',
                dir: 'asc' | 'desc'
            })) : [];
    }

    public close() {
        this.dialogRef.close();
    }

    public save() {
        if (this.rightColumns.length === 0) {
            if (this.dialogInputSettings.isColumns) {
                this.fireErrorMessage(`You don’t have any columns to display. Please add columns to display. `);
            } else {
                this.fireErrorMessage(`You dont have any filter to display .Please choose filter you want `);
            }
        } else {
                this.updateQueryGroups();
                this.updateQueryDataFlattenBy();
                this.updateQuerySorts();
                this.addGroupsColumns();
                // hidden not needed now i table view; if anyone can make this better
                this.rightColumns?.forEach((x, i) => x.order = i + 1);

                const rc = this.rightColumns.map(s => s.fieldName + s.width + this.rightColumns.indexOf(s)).join('');
                const dc = this.defaultColumns.map(s => s.fieldName + s.width + this.defaultColumns.indexOf(s)).join('');
                this.rightColumns.map(x => x.isSelected = false);
                this.defaultColumns.map(x => x.isSelected = false);
                this.allColumns.map(x => x.isSelected = false);
                this.defaultFilters.map(x => x.isSelected = false);

                this.isDefaultColumns = (generateHashFromString(rc) === generateHashFromString(dc));
                if (!!this.dialogInputSettings.mainViewState) {
                    this.dialogInputSettings.mainViewState.setValue({ isDefaultSorts: this.isDefaultSorts });
                }
                this.dialogRef.close({
                    rightColumns: this.rightColumns,
                    isDefault: this.isDefaultColumns,
                    groupByColumn: this.groups.length != 0 ? { field: this.groups[0].fieldName, dir: this.groups[0].dir } : '',
                    dataFlattenBy: this.dataFlattenBy,
                    groups: this.groupsToQuery ,
                    sorts:this.sortsToQuery,
                    showGroupFooter: this.showGroupFooter,
                    randomSeed: this.randomSeed
                });
        }
    }

    private updateQuerySorts() {
        this.dialogInputSettings.queryService.resetOrderBy();
        this.randomSeed = 0;
        if (!!this.sorts) {
            const mainSorts = this.sorts.filter(sort => sort.fieldName != '');
            this.sortsToQuery=mainSorts.map(x => ({ field: x.fieldName, dir: x.dir }));
            mainSorts.forEach(s => {
                this.dialogInputSettings.queryService.setOrderBy(s.fieldName, s.dir);
            });
        }
        this.dialogInputSettings.queryService.refresh();
    }

    private updateQueryGroups() {
        if (this.groups && this.groups.length) {
            this.dialogInputSettings.queryService.resetGroupBy();
            const mainGroups = this.groups.filter(group => group.fieldName != '').map(x => ({ field: x.fieldName, dir: x.dir }));
            this.dialogInputSettings.queryService.setGroups(mainGroups);
            this.groupsToQuery=mainGroups;
            this.dialogInputSettings.queryService.refresh();
        }

    }

    private updateQueryDataFlattenBy() {
        this.dialogInputSettings.queryService.resetDataFlattenBy();
        this.dialogInputSettings.queryService.setDataFlattenBy(this.dataFlattenBy);
        this.dialogInputSettings.queryService.refresh();

    }

    private addGroupsColumns() {
        if (this.groups?.length) {
            const excludedColumns = this.groups.filter(x =>
                this.rightColumns.every(z => z && z.fieldName !== x.fieldName)
            );

            if (excludedColumns?.length) {
                const groupColumns = excludedColumns.filter(col => col !== null).map(excluded => {
                    const col = this.allColumns?.find(z => z && z.fieldName === excluded.fieldName);
                    return { ...col }; // Use a clone of the column if found
                });

                this.updateColumnDisplay(groupColumns.concat(this.rightColumns));
            }
        }
    }
    private fireErrorMessage(msg: string) {
        this.snackBar.open(msg, 'X', {
            duration: 8000,
            verticalPosition: 'top',
            horizontalPosition: 'right'
        });
    }

    public setDefaultColumns(event: boolean) {
        if (this.dialogInputSettings.isColumns) {
            this.updateColumnDisplay(this.defaultColumns);
        } else {
            this.updateColumnDisplay(this.defaultFilters);
        }
    }

    public setFlattenDataBy(goupName) {
        this.dataFlattenBy = goupName;
    }
    public updateColumnDisplay(columns) {
        this.rightColumns = Object.assign([], columns);
    }
}
