
import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'containsFilter'
})

@Injectable()
export class ContainsFilterPipe implements PipeTransform {
    transform(value: any, args: string[]): any {
        let filter = args[0] ? args[0].toLocaleLowerCase() : undefined;
        return filter ? value.filter(x => x.title.toLocaleLowerCase().indexOf(filter) !== -1) : value;
    }
}
