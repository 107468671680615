import { Injectable } from '@angular/core';
import { FormsDataServiceInterface } from '../../../../discoverer/dynamic-forms/dyn-forms/services/forms-data-service.interface';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import IntegrationForms from '../common/integrations/integrations.form.json'
import { of } from 'rxjs';
import 'rxjs/add/operator/delay';
import { IntegrationService } from './api/integration.service';


@Injectable()
export class IntegrationFormService implements FormsDataServiceInterface {

    constructor(private _httpClient: HttpClient, private _integrationService: IntegrationService) {
    }

    public getFormByKey(key: string): Observable<any> {
        const form = IntegrationForms.filter(f => f.Key === key)[0];
        const obj = {
            Key: key,
            Title: form.Title,
            Configuration: JSON.stringify(form.Configuration)
        };
        return of(obj).delay(1);
    }

    public postFormData(data: any) {
        if (!!data.id)
            return this._integrationService.update(data);


        return this._integrationService.create(data);
    }
}
