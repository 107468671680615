import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DiscovererQueryService, DRQuery } from '@discoverer/core/services';
import moment, { Moment } from 'moment';
import { takeWhile } from 'rxjs/operators';
import { HistogramTag, HistogramData } from '../histogram/histogram.component';
import { ChartGroupBy, HistogramService } from '../service/histogram.service';

@Component({
    selector: 'disco-date-histogram',
    templateUrl: './date-histogram.component.html',
    styleUrls: ['./date-histogram.component.scss']
})
export class DateHistogramComponent implements OnInit {
    @Input() min: Moment;
    @Input() max: Moment;
    @Input() fieldName: string;
    @Input() serviceUrl: string;
    @Input() queryService: DiscovererQueryService;
    @Output() seriesClicked: EventEmitter<HistogramTag> = new EventEmitter<HistogramTag>();
    public get formatedRange(): { min, max } {
        return { min: this.formatMoment(this.min), max: this.formatMoment(this.max) };
    }
    public histogramData: HistogramData[] = [];
    private _step = 1;
    private _alive = true;
    constructor(
        private _histogramService: HistogramService,
        private _cdr: ChangeDetectorRef
    ) { }
    ngOnDestroy(): void {
        this._alive = false;
    }

    ngOnInit(): void {
        this.queryService.oQuery.pipe(takeWhile(() => this._alive)).subscribe(async (req: DRQuery) => {
            this.histogramData = [];
            this._step = ((this.max.diff(this.min, 'days')) / 15);

            const groupBy: ChartGroupBy = this._step >= 1 ?
                this._getGroupBy(moment().diff(this.min, 'days'), moment().diff(this.max, 'days'), this._step)
                : this._getGroupBy(moment().diff(this.min, 'days'));

            this.histogramData = await this._histogramService.getDateHistogramData(req, groupBy, this.fieldName, this.serviceUrl);
            this._cdr.detectChanges();
        });
    }

    public seriesClick(minDate: string) {
        const min = moment(minDate).toString();
        const max = moment(minDate).add(this._step, 'days').toString();
        this.seriesClicked.emit({ min, max });
    }

    public formatMoment(x) {
        return x.format('MMM, D YYYY');
    }

    private _getGroupBy(minDiff: number, maxDiff = minDiff - 1, _step = 1): ChartGroupBy {
        var groupBy = {
            start: `NOW/DAY-${minDiff}DAYS`,
            end: `NOW/DAY-${maxDiff}DAYS`,
            gap: `+${_step}DAY`
        }
        return groupBy;
    }
}
