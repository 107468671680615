import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';

import {
    StateService, IChartDataConfig, IChartFormatConfig,
    DiscovererQueryService, ChartDataService,
    IChartResults, BaseController, DiscovererDataService, FacetFieldAccumulator, IColumnSetting, IMetric, DiscovererFacetTranslationService, DataFormattingService, AppSettingsService, TabSettingsService, EsQueryService, ChartRoutingService, ReportPersistService, ChartFilter, disLogger, IChartState, IDynamicReport, mapFunctionName, ReportSubType
} from '../services';
import { saveAs } from '@progress/kendo-file-saver';
import { exportPDF, exportImage, exportSVG } from '@progress/kendo-drawing';

import { HttpClient } from '@angular/common/http';
import { ChartViewErrorHandler } from './models';
import { FadeInOutAnimation } from '@app/app.animation';
import { round } from 'lodash';
import { first, take } from 'rxjs/operators';
import { DialogsManagerService } from '../../dynamic-reports/dialogs/dialogs-manager.service'

const MAX_DIVISIONS_CATEGORIES_V = 20;
const CHART_HEIGHT_EDIT = 375;
const CHART_HEIGHT_DASHBOARD = 500;
const BONUS_CHART_HEIGHT = 200;
const MAX_DIVISIONS_CATEGORIES_H = 40;

@Component({
    selector: 'kendo-chart-item',
    styleUrls: ['./kendo-chart-item.widget.scss'],
    templateUrl: './kendo-chart-item.widget.html',
    animations: [FadeInOutAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush
})
// tslint:disable-next-line:component-class-suffix
export class KendoChartItemWidget extends BaseController implements OnInit {

    @Input() public previewMode = false;
    @Input() public isFull: boolean;
    @Input() public chartState: StateService<IChartState>;
    @Input() public queryService: DiscovererQueryService;
    @Input() public serviceUrl: string;
    @Input() public pageTitle: string;
    @Input() public availableColumns: IColumnSetting[] = [];
    @Input() public enableEditMode: boolean;
    @Input() public chartMaxLabels: number;

    @Output() public editChart: EventEmitter<string> = new EventEmitter<string>();
    @Output() public changeChartSize: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('chart') private chart: any; // ChartComponent;

    public categoriesLabels = {};
    public legendLabels = {};
    isSingleIndicator: boolean;
    public categories: any;
    public tooltipFormat = '{0:n}';
    public seriesArray = [];
    public chartData: any;
    public seriesColors = ['#165981', '#098461', '#486AAF', '#7ABBC7', '#9073BD', '#A05195', '#D45087', '#F95D6A', '#FF7C43', '#FFA600', '#910C8B', '#D80062', '#BA1C37', '#C64B20', '#6B3D07'];
    public metrics = new Array<any>();
    public series = [];
    public formatSeries = {
        title: ''
    };
    public category = {
        title: ''
    };
    public formattedMetrics: any[] = [{
        title: '',
        name: ''
    }];
    public isLogarithmScale: boolean;
    public titles: { header: string, value: string }[];
    public isIndicator: boolean  = false;
    public isVerticalIndicator: boolean = false;
    public isStack: boolean;
    public isCircular = false;
    public isTwistedCategories = false;
    public pieData = [];
    public facet: FacetFieldAccumulator;
    private chartDataService: ChartDataService;
    private isHorizontalChart = false;
    private dataService: DiscovererDataService<any>;
    public hideTitle: boolean;
    public seriesTotalList: any[];
    private _categoriesDataType: string;
    private _appId: string
    constructor(
        private http: HttpClient,
        private chartViewErrorHandler: ChartViewErrorHandler,
        public formattingService: DataFormattingService,
        private _appSettingsService: AppSettingsService,
        private tabSettingsService: TabSettingsService,
        private facetTranslationService: DiscovererFacetTranslationService,
        private esQueryService: EsQueryService,
        private _chartDetailsService: ChartRoutingService,
        private _cdr: ChangeDetectorRef,
        private _dialogsManagerService: DialogsManagerService,
        private reportPersist: ReportPersistService) {
        super();

        this.dataService = new DiscovererDataService(this.http, this.esQueryService, { create: (row: any) => row });
        this.dataService.enabled = false;
        this.dataServices.push(this.dataService);
    }
    public findSeries(metric, series) {
        return this.seriesArray.find(x => x.axis === metric?.name && x?.name?.Display === series?.Display);
    }

    public formatAxis(e: any): string {
        const suffixes = ['K', 'M', 'G', 'T', 'P', 'E'];
        if (Number.isNaN(e.value)) {
            return null;
        }
        if (e.value < 1000 && e.value !== null) {
            return e.value.toFixed(2);
        }
        const exp = Math.floor(Math.log(e.value) / Math.log(1000));
        return ((e.value / Math.pow(1000, exp)).toFixed(2) + suffixes[exp - 1]);
    }
    public labelContent = (e: any) => {
        const formattedPercent = this.formattingService.getValue(e?.percentage, 'percent');
        return `${e.category.Display} - ${e?.dataItem?.stringValue} - (${formattedPercent})`;
    }

    public labelTotalContent = (e: any) => {
        const { dataType, value, stringValue } = e?.dataItem;
        const formattedValue = dataType ? this.formattingService.getValue(value, dataType) : stringValue;
        return `${formattedValue}`;
    }
    public async ngOnInit() {
        this._appId = (await this._appSettingsService.getCurrentApp()).key;
        if (window.innerWidth <= 480) {
            this.chartMaxLabels = 6;
            this.hideTitle = true;
        } else if (!!this.chartMaxLabels && this.chartMaxLabels < 15) {
            this.hideTitle = true;
        }
        this.chartViewErrorHandler.setIsError(false);
        // const chartConfigState = new StateService<IChartDataConfig>(this.chartState.getState().chartConfig)
        this.chartDataService = new ChartDataService(
            this.http,
            this.serviceUrl,
            this.queryService,
            this.chartState,
            this.formattingService,
            this.tabSettingsService,
            this.facetTranslationService,
            this.availableColumns.map(s => Object.assign({}, { name: s.fieldName }, s)),
            this.esQueryService
        );
        this.dataServices.push(this.chartDataService as any);

        this.subscriptions.push(
            this.chartDataService.oData.subscribe((data) => {
                this.chartData = data;
                const formatMetric = this.chartState.getState().chartConfig?.metrics;
                this.formattedMetrics = this.metrics?.map((x, i) => {
                    const title = formatMetric ? formatMetric[i] : ''
                    return x?.colName
                        ? { title, name: `stat${i + 1}`, dataType: this.availableColumns.find(col => col.fieldName === x?.colName).dataType }
                        : { title: '', name: 'count', dataType: 'int' };
                }) || [];

                this.titles = data?.totals?.map(this._mapTitleFormTotals);

                if (data.categories.length <= 300 && data.series.length <= 50) {
                    if (this.isCircular) {
                        this.pieData = this.formatPieChartData(data);
                    } else {
                        this.series = data.series;
                        this._categoriesDataType = data.categoriesDataType;
                        this.categories = data.categories;
                        this.seriesArray = this.seriesValueFormat(data);
                        this.seriesArray = this.seriesArray.map(s => ({
                            ...s,
                            name: this.series.find(ser => (ser.Id || ser.Display) === s?.name),
                            values: s.values.map(value => ({ value, stringValue: this.formattingService.getValue(value, s.dataType || 'number') })),
                            dataType: s.dataType || 'number'
                        }));
                        this.categoriesLabels = this.formatLabels(this.categories, !this.isHorizontalChart);
                        this.legendLabels = this.legendContents();
                        if (this.seriesArray && this.seriesArray.length > 1) {
                            this.getTotalValuesForAllSeries(this.seriesArray);
                        }
                    }
                    this.chartViewErrorHandler.setIsError(false);
                } else {
                    disLogger('categories or series too large');
                    this.chartViewErrorHandler.setIsError(true);
                    if (data.series.length > 50) {
                        this.chartViewErrorHandler.setErrorMsg('Selected series field results in more than 50 categories, select another or filter down results.');

                    } else {
                        this.chartViewErrorHandler.setErrorMsg('Selected category field results in more than 300 categories, select another or filter down results.');
                    }
                }
                this._cdr.markForCheck();
            }));

        this.subscriptions.push(
            this.chartState.oState.subscribe((x) => {
                const formatState = x?.chartFormat
                this.formatSeries.title = formatState?.series;
                this.category.title = formatState?.categories;
                this.isStack = formatState?.isStack;
                this.isLogarithmScale = formatState?.isLogarithmScale;
                this.categoriesLabels = this.formatLabels(this.categories, !this.isHorizontalChart);
                this.legendLabels = this.legendContents();
                this._cdr.markForCheck();
            }));

        this.subscriptions.push(this.chartState.oState.subscribe(visualizationData => {
            const state = visualizationData.chartConfig
            this.metrics = state?.metrics?.map(x => {
                return { ...x, dataType: this.availableColumns.find(col => col.fieldName === x.colName)?.dataType }
            }) || [{}];
            this.isHorizontalChart = (state?.type === 'bar');
            this.isCircular = (state?.type === 'pie');
            this.isIndicator = (state?.type === ReportSubType.INDICATOR);
            this.isVerticalIndicator = (state?.type === ReportSubType.VERTICAL_INDICATOR)
            this.isSingleIndicator = this.isIndicator && (state?.categories?.colName === 'DefaultColumn' && !state?.series)
            this.chartDataService.isHorizontalChart = this.isHorizontalChart;
            if (this.isCircular && state?.categories && state?.categories?.colName && (state?.categories?.colName?.split('_')[1] === 's')) {
                this.dataService.enabled = true;
                this.getCountForEachSeries(state?.categories?.colName);
            } else {
                this.dataService.enabled = false;
            }
            this._cdr.markForCheck();
        }));


    }
    public fireChangeChartSize(isFull: boolean) {
        this.changeChartSize.emit(isFull);
    }

    public fireEditChart() {
        this.editChart.emit('h');
    }

    public exportChartAsImage(ext: string = 'png'): void {
        const visual = this.chartExportStyle();
        exportImage(visual).then((dataURI) => {
            saveAs(dataURI, `${this.pageTitle}.${ext}`);
        });
    }

    public exportChartAsPDF(): void {
        const visual = this.chartExportStyle();
        exportPDF(visual, {
            paperSize: 'A4',
            landscape: true
        }).then((dataURI) => {
            saveAs(dataURI, `${this.pageTitle}.pdf`);
        });
    }

    public exportChartAsSvg(): void {
        const visual = this.chartExportStyle();
        exportSVG(visual).then((dataURI) => {
            saveAs(dataURI, `${this.pageTitle}.svg`);
        });
    }

    private chartExportStyle() {
        return this.chart.exportVisual({
            options: {
                chartArea: {
                    background: '#fff',
                    width: 830
                },
                legend: {
                    labels: {
                        color: '#000'
                    }
                }
            }
        });
    }

    public getChartAreaStyle() {
        const bonus = (this.isTwistedCategories) ? BONUS_CHART_HEIGHT : 0;
        const minChartHeight = (this.previewMode) ? CHART_HEIGHT_DASHBOARD : CHART_HEIGHT_EDIT;
        const style = {
            height: minChartHeight + bonus
        };
        return style;
    }

    private seriesValueFormat(chartRes: IChartResults) {
        let ser = new Array<any>();
        ser = chartRes.values.filter(v => this.formattedMetrics.some(h => h.name === v.axis));
        return ser;
    }

    private getCountForEachSeries(facetKey) {
        this.dataService.init(this.serviceUrl, this.queryService);

        this.dataService.setFacet(facetKey, {
            name: facetKey,
            type: 'terms',
            field: facetKey,
            excludeTags: [facetKey],
            mincount: 0,
            limit: -1,
            display: '',
            shortDisplay: ''
        });
        this.dataService.refresh();
        this.dataService.oFacetResults.subscribe(facets => {
            this.facet = facets[1];
        });
    }
    getTotal(values: any[]) {
        return values.filter(val => !!val).reduce((partialSum, a) => partialSum + a, 0);
    }
    private formatPieChartData(data: IChartResults): any[] {
        const result = [];
        if (!!data.categories && data.categories.length > 0 && !!data.values && data.values.length > 0) {
            let sum = 0;
            let dataType = this.metrics.length >= 1 && !!this.metrics[0]?.dataType ? this.metrics[0].dataType : 'number';

            const pieData = data.values
                .filter(x => this.metrics.length >= 1 && !!this.metrics[0].colName ? x.stat === '1' : x.stat === 'count')
                .map(z => z.values)
                .reduce((a, b) => a.concat(b));
            pieData.forEach((v) => { sum += v; });

            data.categories.forEach((cat, i) => {
                result.push({
                    category: cat,
                    value: round(((pieData[i] / sum)) * 100, 2),
                    color: this.seriesColors[i],
                    numberValue: pieData[i],
                    dataType: dataType,
                    stringValue: this.formattingService.getValue(pieData[i], dataType)
                });
            });
        }
        return result;
    }

    private formatLabels(strArr, isVertical: boolean = false) {
        strArr = strArr?.map(val => val?.Display);
        let diffStep;
        if (!!this.chartMaxLabels) {
            diffStep = Math.floor((strArr.length / this.chartMaxLabels));
        } else {
            if (this._categoriesDataType === 'date') {
                diffStep = Math.floor((strArr.length / 10));
            } else {
                diffStep = 1;
            }
        }
        let lettersLength = 0;
        if (strArr) {
            if (isVertical) {
                strArr.forEach(cat => {
                    lettersLength += (cat) ? cat.length : 0;
                });
                if ((lettersLength > 80 && lettersLength <= 180) && strArr.length <= MAX_DIVISIONS_CATEGORIES_H) {
                    this.isTwistedCategories = true;
                    if (this.hideTitle) {
                        return {
                            rotation: '-35',
                            step: diffStep, content: this.labelDisplay
                        };
                    } else {
                        return {
                            rotation: '-15',
                            step: diffStep, content: this.labelDisplay
                        };
                    }
                } else if ((lettersLength > 180) || strArr.length > MAX_DIVISIONS_CATEGORIES_H) {
                    this.isTwistedCategories = true;
                    if (this.hideTitle) {
                        return {
                            rotation: '-35',
                            step: diffStep, content: this.labelDisplay
                        };
                    } else {
                        return {
                            rotation: '-25',
                            step: diffStep, content: this.labelDisplay
                        };
                    }
                }
                else if (this.hideTitle) {
                    this.isTwistedCategories = true;
                    return {
                        rotation: '-35',
                        step: diffStep, content: this.labelDisplay
                    };
                }
            } else {
                if (strArr.length >= MAX_DIVISIONS_CATEGORIES_V) {
                    this.isTwistedCategories = false;
                    return {
                        rotation: '0',
                        step: diffStep, content: this.labelDisplay
                    };
                }
            }
            this.isTwistedCategories = false;
            return { rotation: '0', content: this.labelDisplay };
        }
    }

    public legendContents() {
        return { color: 'var(--text-color)', content: this.legendDisplay };
    }

    labelDisplay = (e) => {
        return e?.value?.Display;
    }

    legendDisplay = (e) => {
        let legend = e?.text?.Display
        if (!legend) return null;
        if(legend === "Series1") return null;
        const metric = this.formattedMetrics.find(metric => metric?.name === e?.series?.axis)
        const series = this.findSeries(metric, e?.text);
        if (series) {
            const formattedTotal = this.formatMetricTotal(metric?.title?.colName, series?.total);
            legend += ` - (${formattedTotal})`
        }
        return legend;
    }

    public async openChartRecords(category, item, view) {
        this._cdr.markForCheck();
        const req: IDynamicReport = await this.reportPersist.oLastRequestData.pipe(take(1)).toPromise();
        const state = await this.chartState.oState.pipe(take(1)).toPromise();
        const param: { categoriesFilter: ChartFilter, seriesFilters: ChartFilter[] } = {
            categoriesFilter: this._prepareCategoriesParam(category, state.chartConfig.categories),
            seriesFilters: state.chartConfig.series ? this._prepareSeriesParam(item, state.chartConfig.series) : []
        };
        delete req.chart.chartConfig.categories['minMaxSubscription']
        const newReq = JSON.parse(JSON.stringify(req)); // Copy object without refrences

        if (view === "table-view") {
            await this._chartDetailsService.openSelectedRecords(this.reportPersist, this._appId, this.tabSettingsService.tabKey, param, newReq, view);
        } else {
            const serviceUrl = (await this.tabSettingsService.getCurrentTab()).serviceUrl;
            const dialogRef = await this._dialogsManagerService.openCategoryPickerDialog(this.availableColumns, serviceUrl, this.reportPersist.mainQueryService)
            const col = await dialogRef.toPromise();
            if (col) {
                await this._chartDetailsService.openSelectedRecords(this.reportPersist, this._appId, this.tabSettingsService.tabKey, param, newReq, view, col);
            }
        }
        this._cdr.markForCheck();
    }

    public async openIndicatorRecords(series, category, view) {
        this._cdr.markForCheck()
        const req = await this.reportPersist.oLastRequestData.pipe(take(1)).toPromise();
        const state = await this.chartState.oState.pipe(take(1)).toPromise();
        const param: { categoriesFilter: ChartFilter, seriesFilters: ChartFilter[] } = {
            categoriesFilter: category ? this._prepareIndicatorSeriesParam(state.chartConfig.categories, category) : null,
            seriesFilters: this._prepareIndicatorSeriesParams(series, state.chartConfig.series)
        };
        const newReq = JSON.parse(JSON.stringify(req)); // Copy object without refrences
        if (view === "table-view") {
            await this._chartDetailsService.openSelectedRecords(this.reportPersist, this._appId, this.tabSettingsService.tabKey, param, newReq, view);
        } else {
            const serviceUrl = (await this.tabSettingsService.getCurrentTab()).serviceUrl;
            const dialogRef = await this._dialogsManagerService.openCategoryPickerDialog(this.availableColumns, serviceUrl, this.reportPersist.mainQueryService)
            const col = await dialogRef.toPromise();
            if (col) {
                await this._chartDetailsService.openSelectedRecords(this.reportPersist, this._appId, this.tabSettingsService.tabKey, param, newReq, view, col);
            }
        }
        this._cdr.markForCheck();
    }

    private _prepareIndicatorSeriesParam(categories, category) {
        const fieldType = this.availableColumns.find(col => col.fieldName === categories.colName).dataType
        const gap = categories.groupBy?.orginalGap || ((fieldType == 'date' || fieldType == 'timestamp' || fieldType == 'timestamptz') ? 'MONTH' : '');
        return { fieldName: categories.colName, fieldType, gap, values: [category.Id || category?.Display] }
    }

    private _prepareIndicatorSeriesParams(value, series): ChartFilter[] {
        const filters: ChartFilter[] = [];
        if (series) {
            filters.push({
                fieldName: series.colName,
                fieldType: this.availableColumns.find(col => col.fieldName === series.colName).dataType,
                gap: series.groupBy?.orginalGap || '',
                values: [value.Id || value?.Display]
            })
        }
        return filters;
    }

    private _prepareCategoriesParam(value, category): ChartFilter {
        value = value.Id || value.Display;
        const fieldType = this.availableColumns.find(col => col.fieldName === category.colName).dataType
        const gap = category.groupBy?.orginalGap || ((fieldType == 'date' || fieldType == 'timestamp' || fieldType == 'timestamptz') ? 'MONTH' : '');
        return { fieldName: category.colName, fieldType, gap, values: [value] }
    }

    private _prepareSeriesParam(item, series): ChartFilter[] {
        const filters: ChartFilter[] = [];
        if (item.name) {
            filters.push({
                fieldName: series.colName,
                fieldType: this.availableColumns.find(col => col.fieldName === series.colName).dataType,
                gap: series.groupBy?.orginalGap || '',
                values: [item.name.Id || item.name.Display]
            })
        }
        return filters;
    }

    public getTotalValuesForAllSeries(chartValues: any[]) {
        var catTitles = Array(chartValues[0].values.length).fill('');
        var seriesTotalList = Array(chartValues[0].values.length).fill(0);
        for (let x = 0; x < chartValues.length; x++) {
            for (let y = 0; y < chartValues[x].values.length; y++) {
                catTitles[y] = this.categories[y];
                seriesTotalList[y] += chartValues[x]?.values[y]?.value || 0;
            }
        }
        let dataType = this.metrics.length >= 1 && !!this.metrics[0]?.dataType ? this.metrics[0].dataType : 'number';
        this.seriesTotalList = seriesTotalList.map((v, i) => ({
            value: v,
            stringValue: this.formattingService.getValue(v, dataType),
            catTitle: catTitles[i],
            dataType
        }));
    }

    public getPercentage(value, category): number {
        var total = ((this.seriesTotalList || []).find(ser => ser?.catTitle?.Display === category.Display)?.value) || value;
        return (value / total) * 100;
    }
    log(series) {
        console.log(series)
    }
    private _mapTitleFormTotals = (tot) => {
        let metric = this.formattedMetrics.find(metric => metric.name === tot.metric.name);
        if (metric?.title) {
            return {
                header: `${mapFunctionName(tot?.metric?.functionType)} of ${metric?.title?.display}`,
                value: this.formatMetricTotal(metric?.title?.colName, tot?.total)
            }
        }
    }

    private formatMetricTotal(colName: string, total:number) {
        let col = this.availableColumns.find(col => col.fieldName === colName)
        return this.formattingService.getValue(total, col?.type !== 'numeric' ? 'int' : col?.dataType)
    }
}
